import React from "react";
import "./header.css";
import "../sideBar/sidebar.css";
import { connect } from "react-redux";
import logo from "../../img/thumbnail_hs_logo_horiz.png";
import healthswim from "../../img/Logo Name.png";
import { Link } from "react-router-dom";
import { getPersonalProfile } from "../../actions/personalProfile.actions";
import { Dropdown } from "react-bootstrap";
import { logOut } from "../../actions/login.actions";
import ReactHtmlParser from "react-html-parser";
import {
  SHARE_LINK_LOGIN,
  SHARE_LINK_REGISTER,
} from "../../middleware/baseApiService";
import {
  ROUTE_DASHBOARD,
  ROUTE_EDIT_PROFILE,
  ROUTE_ORG_MANAGE_PROFILE,
  ROUTE_ORG_MANAGE_MYPROFILE,
  ROUTE_ORG_MANAGE_PROVIDERS,
  ROUTE_ORG_MANAGE_DEPTADMIN,
  ROUTE_ORG_MANAGE_LOCATION,
  ROUTE_ORG_MANAGE_DEPARTMENTS,
  ROUTE_LOGOUT,
  ROUTE_ORG_MANAGE_ADMIN,
  ROUTE_CLIENT_PROFILE_EDIT,
  ROUTE_MANAGE_MY_CONTENT,
  ROUTE_MAILING_LIST,
  ROUTE_MANAGE_LISTS,
  ROUTE_MANAGE_ACCOUNTS,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_MANAGE_CHANNELS,
  ROUTE_DISABLE_ACCOUNT,
  ROUTE_DELETE_ACCOUNT,
} from "../../containers/RouteConstants";
import { getNextStep } from "../../actions/onboardingStatus.actions";
import { withRouter } from "react-router-dom";

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      searchText: "",
      displayFullSearch: false,
      navMenu: [],
      websharestate: false,
    };
  }

  componentWillMount() {
    if (this.props.isLoggedIn === true) {
      this.props.getPersonalProfile(this.props.email);
      this.props.getNextStep(this.props.email);
    }
  }
  componentDidMount() {
    window.location.href.includes("/webShareLink")
      ? this.setState({ websharestate: true })
      : this.setState({ websharestate: false });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isLoggedIn !== this.props.isLoggedIn &&
      nextProps.isLoggedIn === true
    ) {
      this.props.getPersonalProfile(this.props.email);
      this.props.getNextStep(this.props.email);
    }
  }

  toggleFullSearch = (value) => {
    this.setState({ displayFullSearch: value });
  };

  search = () => {
    if (this.state.searchText) {
      this.props.search(this.state.searchText);
      this.props.history.push({ pathname: "/search" });
    }
  };

  onSearchTextChanged = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  buildNavMenu() {
    let list = [];

    if (this.props.profile.securityMatrix) {
      if (this.props.profile.securityMatrix.clientId) {
        list.push({
          text: "Manage My Profile",
          path: ROUTE_CLIENT_PROFILE_EDIT,
        });
        list.push({ text: "Manage My Content", path: ROUTE_MANAGE_MY_CONTENT });
        return list;
      }

      if (
        this.props.profile.securityMatrix.canManageOrganization === true &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({
          text:
            this.props.profile.securityMatrix.canManageMasterList === true
              ? "Manage Healthswim"
              : "Manage Organization",
          path: ROUTE_ORG_MANAGE_PROFILE,
        });
      }

      if (
        this.props.profile.securityMatrix.canManageOrganizationAdmins ===
          true &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({
          text:
            this.props.profile.securityMatrix.canManageMasterList === true
              ? "Manage Healthswim Admins"
              : "Manage Organization Admins",
          path: ROUTE_ORG_MANAGE_ADMIN,
        });
      }

      if (
        this.props.profile.securityMatrix.canManageDeptAdmins === true &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({
          text: "Manage Department Admins",
          path: ROUTE_ORG_MANAGE_DEPTADMIN,
        });
      }

      if (
        this.props.profile.securityMatrix.canManageLocation === true &&
        this.props.profile.securityMatrix.canManageMasterList !== true &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({
          text: "Manage Locations",
          path: ROUTE_ORG_MANAGE_LOCATION,
        });
      }

      if (
        this.props.profile.securityMatrix.canManageDeptAdmins === true &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({
          text: "Manage Departments",
          path: ROUTE_ORG_MANAGE_DEPARTMENTS,
        });
      }

      if (
        this.props.profile.securityMatrix.canInviteProviders === true &&
        this.props.profile.securityMatrix.canManageMasterList !== true &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({
          text: "Manage Providers",
          path: ROUTE_ORG_MANAGE_PROVIDERS,
        });
      }

      if (
        this.props.profile.securityMatrix.isProvider === true &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({ text: "Manage My Profile", path: ROUTE_EDIT_PROFILE });
      }

      if (
        this.props.profile.securityMatrix.isProvider === false &&
        this.props.profile.securityMatrix.isHealthswimAdmin === false
      ) {
        list.push({
          text: "Manage My Profile",
          path: ROUTE_ORG_MANAGE_MYPROFILE,
        });
      }
      {
        list.push({ text: "Manage Channels", path: ROUTE_MANAGE_CHANNELS });
      }
      list.push({ text: "Manage Content", path: ROUTE_MANAGE_MY_CONTENT });
      {
        this.props.profile.securityMatrix.isHealthswimAdmin === false &&
          list.push({ text: "Change Password", path: ROUTE_CHANGE_PASSWORD });
      }
      if (this.props.profile.securityMatrix.isProvider === true) {
        list.push({ text: "Disable Account", path: ROUTE_DISABLE_ACCOUNT });
        list.push({ text: "Delete Account", path: ROUTE_DELETE_ACCOUNT });
        list.push({ text: "Premium Account", path: "/premiumInfoPage" });
      }
      if (
        this.props.profile.securityMatrix.canManageMasterList === true ||
        this.props.profile.securityMatrix.isHealthswimAdmin === true
      ) {
        list.push({ text: "Manage Mailing", path: ROUTE_MAILING_LIST });
        list.push({ text: "Manage Lists", path: ROUTE_MANAGE_LISTS });
        list.push({ text: "Manage Accounts", path: ROUTE_MANAGE_ACCOUNTS });
      }
    }

    return list;
  }
  isOpen = () => {
    if (this.props.isLoggedIn) this.props.history.push("/dashboard");
  };
  render() {
    console.log(this.props.profile, "firstname");
    const fullName =
      this.props.profile?.firstName + " " + this.props.profile?.lastName;

    if (fullName) {
      localStorage.setItem("fullName", fullName);
    }
    let list = [];
    let navMenu;

    if (this.props.profile !== null) {
      if (this.props.nextStep && this.props.nextStep.name !== "DASHBOARD") {
        navMenu = (
          <Dropdown.Menu>
            <div className="dropdown-link-container">
              <button
                className="dropdown-item"
                role="button"
                onClick={this.props.logOut}
              >
                Sign Out
              </button>
            </div>
          </Dropdown.Menu>
        );
      } else {
        list = this.buildNavMenu();

        navMenu = (
          <Dropdown.Menu>
            <div className="dropdown-link-container">
              <Link
                className="dropdown-item"
                role="button"
                to={ROUTE_DASHBOARD}
              >
                Dashboard
              </Link>
            </div>
            {/* <Dropdown.Divider /> */}
            {list.map((item, i) => {
              return (
                <div key={`nav_${i}`}>
                  <div className="dropdown-link-container">
                    <Link
                      className="dropdown-item"
                      role="button"
                      to={item.path}
                    >
                      {item.text}
                    </Link>
                  </div>
                  {/* <Dropdown.Divider /> */}
                </div>
              );
            })}
            <div className="dropdown-link-container">
              <button
                className="dropdown-item"
                role="button"
                onClick={this.props.logOut}
                style={{ fontSize: "16px" }}
              >
                Sign Out
              </button>
            </div>
          </Dropdown.Menu>
        );
      }
    }

    return (
      <div className="first-navbar">
        <nav className="navbar navbar-expand-lg navbar-light ">
          {/* <a
            className="navbar-brand"
            style={{ marginLeft: "0%" }}
            // onClick={this.isOpen}
          > */}
          {/* <img src={symbol} alt="symbol" className="logo ml-lg-5" />
            <img src={healthswim} alt="healthswim" className="healthswimWord" /> */}
          {/* 
            <img src={logo} alt="symbol" className="logo ml-lg-5" />
          </a> */}

          {this.props.profile !== null &&
            (this.props.nextStep && this.props.nextStep.name !== "DASHBOARD" ? (
              <a
                className="navbar-brand"
                style={{ marginLeft: "0%" }}
                // onClick={this.isOpen}
              >
                <img src={logo} alt="symbol" className="logo ml-lg-5" />
              </a>
            ) : (
              <a
                className="navbar-brand"
                style={{ marginLeft: "0%" }}
                onClick={this.isOpen}
              >
                <img src={logo} alt="symbol" className="logo ml-lg-5" />
              </a>
            ))}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0" />

            {this.props.isLoggedIn ? (
              this.props.profile ? (
                <div className="profile-info-container">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      variant="none"
                      as="div"
                      id="dropdown-menu"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        {this.props.profile.profileImg ? (
                          <div
                            className="profile-img-container"
                            style={{
                              backgroundImage:
                                "url(" + this.props.profile.profileImg + ")",
                            }}
                          />
                        ) : (
                          <div className="no-profile-img" />
                        )}
                        {/* {ReactHtmlParser(this.props.item.authorShareText)} */}

                        {this.props.profile.firstName ||
                        this.props.profile.lastName ? (
                          <div>
                            <div className="profile_name_display">
                              {this.props.profile.firstName
                                ? this.props.profile.firstName
                                : "Your Name"}{" "}
                              {/* </div>
                            <div className="profile_name_display"> */}
                              {this.props.profile.lastName
                                ? this.props.profile.lastName
                                : null}
                              {this.props.profile &&
                                this.props.profile.degree &&
                                "," +
                                  " " +
                                  JSON.parse(
                                    this.props.profile &&
                                      this.props.profile.degree
                                  )[0].name}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {navMenu}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          {this.props.isLoggedIn ? (
            ""
          ) : (
            <div
              className="form-inline my-2 my-lg-0 sign-in-sign-up"
              style={{ padding: "185PX" }}
            >
              {this.state.websharestate ? (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "37px",
                    fontSize: "1.1rem",
                  }}
                >
                  {" "}
                  Already have Healthswim,{" "}
                  <a href={SHARE_LINK_LOGIN}> SIGN IN </a>
                  to see more! <br />
                  Don't have an account,
                  <a href={SHARE_LINK_REGISTER}> SIGN UP now! </a>
                </p>
              ) : (
                <>
                  {window.location.pathname === "/" ? (
                    <>
                      <Link className="" to={"/login"}>
                        SIGN IN
                      </Link>

                      <h4 style={{ padding: "1px", marginTop: "10px" }}>/</h4>
                      <Link className="" to={"/registerinfo"}>
                        SIGN UP
                      </Link>
                    </>
                  ) : window.location.pathname === "/registerinfo" ? (
                    <Link className="" to={"/login"}>
                      SIGN IN
                    </Link>
                  ) : (
                    window.location.pathname === "/login" && (
                      <Link className="" to={"/registerinfo"}>
                        SIGN UP
                      </Link>
                    )
                  )}
                </>
              )}
            </div>
          )}
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.isLoggedIn,
  email: state.login.email,
  profile: state.personalProfile.profile,
  nextStep: state.onboardingStatus.nextStep,
});

const mapDispatchToProps = {
  getPersonalProfile,
  logOut,
  getNextStep,
};

const Header = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
);

export default Header;
