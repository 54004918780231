import React from "react";
import "./sharePost.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Modal, Button } from "react-bootstrap";

class sharePostComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChannel: null,
    };

    this.sharePostTextRef = React.createRef();
  }

  shareChannel = () => {
    if (this.props.selectedChannel.length > 0) {
      this.props.callback({
        postId: this.props.currentShareId,
        channelId: this.props.selectedChannel[0].id,
        shareTitle: this.sharePostTextRef.current.value,
      });
      this.props.closeShareDialog();
    }
  };

  render() {
    return (
      <div style={{ top: "36%" }}>
        <Modal
          show={this.props.openShareModal}
          onHide={this.props.closeShareDialog}
          style={{ top: "36%" }}
        >
          <div style={{ overflow: "hidden" }}>
            <Modal.Header closeButton>
              <Modal.Title>Select Channel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                ref={this.sharePostTextRef}
                className="shareExsistingPostText"
                placeholder="Enter Text Here..."
                rows="7"
              />
              <Typeahead
                options={this.props.channels}
                labelKey="name"
                placeholder="Select Channel"
                name="channels"
                onChange={(selected) =>
                  this.props.updateSelectedChannel(selected)
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Button className="shareButton" onClick={this.shareChannel}>
                Share
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

const SharePost = sharePostComponent;

export default SharePost;
