import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OrgManageAdmin from "../components/orgManageAdmins/orgManageAdmins";
import {
  getOrganizationAdmins,
  removeOrganizationAdmin,
  GET_ORGANIZATION_ADMINS_REQUEST,
  DELETE_ORGANIZATION_ADMIN_ERROR
} from "../actions/organizationManageAdmins.action";

class OrgManageAdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: false
    };
  }

  componentWillMount = () => {
    this.props.getOrganizationAdmins();
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.state !== this.props.state &&
      nextProps.state === GET_ORGANIZATION_ADMINS_REQUEST
    ) {
      this.setState({ loadingData: true });
    } else {
      this.setState({ loadingData: false });
    }

    if (
      nextProps.state !== this.props.state &&
      nextProps.state === DELETE_ORGANIZATION_ADMIN_ERROR
    ) {
      this.props.getOrganizationAdmins();
    }
  };

  removeAdmin = adminId => {
    this.props.removeOrganizationAdmin(adminId);
  };

  render() {
    return (
      <div className="container-white-bg">
        <div className="container">
          <OrgManageAdmin
            admins={this.props.admins}
            loadingData={this.state.loadingData}
            removeAdmin={this.removeAdmin}
            createNewAdmin={this.createNewAdmin}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    admins: state.organizationAdmins.administrators,
    error: state.organizationAdmins.error,
    state: state.organizationAdmins.state
  };
};

const mapDispatchToProps = {
  getOrganizationAdmins,
  removeOrganizationAdmin
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrgManageAdminPage)
);
