import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../actions/login.actions";
import { ROUTE_LOGIN } from "./RouteConstants";
import { LOG_OUT } from "../actions/login.actions";
class LogOutPage extends Component {
  componentDidMount() {
    this.props.logOut();
  }
  componentDidUpdate() {
    if (this.props.state === LOG_OUT) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="container-bg">
        <div className="container">
          <div className="section-header">
            <p>Sign out successfully.</p>
            <p>
              Click <Link to={ROUTE_LOGIN}>here</Link> to login to Healthswim..!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.isLoggedIn,
  state: state.login.state,
});

const mapDispatchToProps = {
  logOut,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogOutPage)
);
