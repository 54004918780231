import auth0 from "auth0-js";
import Axios from "axios";
import { SHOW_ERROR } from "./errorDisplay.actions";
import tokenService from "../middleware/tokenService";
import apiMethods, { HOME_ROOT } from "../middleware/baseApiService";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_VERIFY = "LOGIN_VERIFY";
export const LOG_OUT = "LOG_OUT";
export const LOGIN_PASSWORD = "LOGIN_PASSWORD";
export const LOGIN_EMAIL = "LOGIN_EMAIL";
export const Cuuent_password = "Cuuent_password";
export const COMMON_LOGIN = "COMMON_LOGIN ";

let auth0Config = {};
if (process.env.NODE_ENV === "production") {
  auth0Config = {
    /*domain: "dev-qhmfvjjc.us.auth0.com",
    clientID: "jISEQQxbe8tz6AS5jCPHk69QYcBeAVsd",
    redirectUri: "https://healthswim.com/callback",
    audience: "https://dev-qhmfvjjc.us.auth0.com/api/v2/",
    responseType: "token id_token",
    scope: "openid profile email"*/

    //prod
    // domain: "dev-qhmfvjjc.us.auth0.com",
    // clientID: "jISEQQxbe8tz6AS5jCPHk69QYcBeAVsd",
    // redirectUri: "https://healthswimwebv3.z13.web.core.windows.net/callback",
    // audience: "https://dev-qhmfvjjc.us.auth0.com/api/v2/",
    // responseType: "token id_token",
    // scope: "openid profile email",

    //staging
    domain: "dev-y3rwk3-l.auth0.com",
    clientID: "7qg558qoDI5VPslAwJlGXThoTDwDsSbN",
    redirectUri:
      "https://healthswimstagingweb.z19.web.core.windows.net/callback",
    audience: "https://dev-y3rwk3-l.auth0.com/api/v2/",
    responseType: "token id_token",
    scope: "openid profile email",

    //production
    // domain: "dev-y3rwk3-l.auth0.com",
    // clientID: "7qg558qoDI5VPslAwJlGXThoTDwDsSbN",
    // redirectUri: "https://www.healthswim.com/callback",
    // audience: "https://dev-y3rwk3-l.auth0.com/api/v2/",
    // responseType: "token id_token",
    // scope: "openid profile email",
  };
} else {
  auth0Config = {
    /*domain: "dev-t9fyl2gy.us.auth0.com",
    clientID: "B50RuzoSaiZmc7YLCOD3EKW7Awfp5re3",
    redirectUri: "http://localhost:3000/callback",
    audience: "https://dev-t9fyl2gy.us.auth0.com/api/v2/",
    responseType: "token id_token",
    scope: "openid profile email"*/

    //staging
    // domain: "dev-y3rwk3-l.auth0.com",
    // clientID: "7qg558qoDI5VPslAwJlGXThoTDwDsSbN",
    // redirectUri: "http://localhost:3000/callback",
    // audience: "https://dev-y3rwk3-l.auth0.com/api/v2/",
    // responseType: "token id_token",
    // scope: "openid profile email"

    //prod
    // domain: "dev-qhmfvjjc.us.auth0.com",
    // clientID: "jISEQQxbe8tz6AS5jCPHk69QYcBeAVsd",
    // redirectUri: "http://localhost:3000/callback",
    // audience: "https://dev-qhmfvjjc.us.auth0.com/api/v2/",
    // responseType: "token id_token",
    // scope: "openid profile email",

    //staging
    domain: "dev-y3rwk3-l.auth0.com",
    clientID: "7qg558qoDI5VPslAwJlGXThoTDwDsSbN",
    redirectUri: "http://localhost:3000/callback",
    audience: "https://dev-y3rwk3-l.auth0.com/api/v2/",
    responseType: "token id_token",
    scope: "openid profile email",
  };
}

// export const requestLogin = () => (dispatch) => {
//   const webAuth = new auth0.WebAuth(auth0Config);
//   webAuth.authorize();
// };

export const verifyLogin = () => (dispatch) => {
  dispatch({ type: LOGIN_VERIFY });
  const webAuth = new auth0.WebAuth(auth0Config);
  webAuth.parseHash((err, authResult) => {
    if (err || typeof authResult == "undefined") {
      dispatch({ type: SHOW_ERROR, payload: `${err.errorDescription}` });
      dispatch({
        type: LOGIN_ERROR,
        payload: `[${err.error}][${err.errorDescription}]`,
      });
    } else if (
      typeof authResult !== "undefined" &&
      authResult.idTokenPayload["http://type"] === "CLIENT"
    ) {
      dispatch({
        type: SHOW_ERROR,
        payload: "Please Use Mobile App For Login.",
      });
      dispatch({
        type: LOGIN_ERROR,
        payload: "Please Use Mobile App For Login.",
      });
    } else {
      scheduleTokenRenewal();
      dispatch({ type: LOGIN_SUCCESS, payload: authResult });
    }
  });
};

export const scheduleTokenRenewal = () => {
  const tokenRenewalTimeInMS = 60 * 1000;

  setTimeout(() => {
    // Make sure user is still logged in.
    if (!tokenService.getToken()) {
      return;
    }

    const now = new Date().getTime();
    const tokenExpiry = parseInt(tokenService.getExpiry());

    if (tokenExpiry - now > tokenRenewalTimeInMS) {
      scheduleTokenRenewal();
      return;
    }

    const webAuth = new auth0.WebAuth(auth0Config);
    webAuth.renewAuth(auth0Config, (err, result) => {
      if (err) {
        return;
      }

      tokenService.setExpiry(result.expiresIn);
      tokenService.setToken(result.accessToken);

      scheduleTokenRenewal(result.expiresIn);
    });
  }, tokenRenewalTimeInMS);
};

if (tokenService.getToken() != null) {
  scheduleTokenRenewal();
}

export const logOut = () => (dispatch) => {
  window.localStorage.removeItem("login");
  localStorage.clear();
  window.location.reload(false);
  // window.location.href = "https://www.healthswim.com/";
  window.location.href = HOME_ROOT;
  // dispatch({ type: LOG_OUT, payload: null });
};

export const requestLogin = (registerRequestParams) => (dispatch, getState) => {
  dispatch({ type: LOGIN_REQUEST });
  apiMethods
    .post("SignIn/Login", {
      // email: registerRequestParams.username,
      email: "",
      password: registerRequestParams.password,
      mobile: registerRequestParams.mobile
        ? "+" + registerRequestParams.mobile
        : "",
      emailOrMobile: registerRequestParams.username,
    })
    .then((response) => {
      // dispatch({
      //   type: COMMON_LOGIN,
      //   payload: {
      //     userType: response.data.userType,
      //     email: response.data.email,
      //     password: response.data.password,
      //     mobile: response.data.mobile,
      //   },
      // });
      dispatch({
        type: LOGIN_EMAIL,
        // payload: registerRequestParams.username,
        payload: response.data.email,
      });
      dispatch({
        type: Cuuent_password,
        // payload: registerRequestParams.password,
        payload: response.data.password,
      });

      window.localStorage.setItem("username", response.data.email);
      window.localStorage.setItem("mobile", response.data.mobile);
      window.localStorage.setItem("password", response.data.password);
      window.localStorage.setItem("login", true);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          userType: response.data.userType,
          email: response.data.email,
          password: response.data.password,
          mobile: response.data.mobile,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: LOGIN_ERROR });
      // console.log(error);
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
      // dispatch({ type: REGISTER_ERROR, error: error.message });
    });
};
export const requestForgotPasswordLogin =
  (registerRequestParams) => (dispatch, getState) => {
    dispatch({ type: LOGIN_REQUEST });
    apiMethods
      .post("SignIn/ForgotPassword", {
        email: registerRequestParams.username,

        // mobile: registerRequestParams.mobile,
      })
      .then((response) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            userType: "",
            email: "",
            password: "",
            mobile: "",
          },
        });
        dispatch({ type: LOGIN_PASSWORD });
        dispatch({
          type: LOGIN_EMAIL,
          payload: registerRequestParams.username,
        });
        localStorage.setItem("mobile", registerRequestParams.mobile);
      })
      .catch((error) => {
        dispatch({ type: LOGIN_ERROR });
        dispatch({ type: SHOW_ERROR, payload: "Invalid user" });
        // dispatch({ type: REGISTER_ERROR, error: error.message });
      });
  };

export const requestForgotPasswordEmail =
  (registerRequestParams) => (dispatch, getState) => {
    dispatch({ type: LOGIN_REQUEST });
    apiMethods
      .post("SignIn/ChangePasswordUsingHash", {
        password: registerRequestParams.password,
        hash: registerRequestParams.hash,
        // mobile: registerRequestParams.mobile,
      })
      .then((response) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            userType: "",
            email: "",
            password: "",
            mobile: "",
          },
        });
        dispatch({ type: LOGIN_PASSWORD });
      })
      .catch((error) => {
        // console.log("error", error.data.message);
        alert("error.data.message");
        dispatch({ type: LOGIN_ERROR });
        dispatch({ type: SHOW_ERROR, payload: "Invalid user" });
        // dispatch({ type: REGISTER_ERROR, error: error.message });
      });
  };
