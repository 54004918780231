import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OrganizationUser from "../components/organizationUser/organizationUser";

class OrganizationUsersPage extends Component {
  render() {
    return (
      <div className="container-bg">
        <OrganizationUser />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganizationUsersPage)
);
