import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const GET_CONDITION_TREATED_SUCCESS = "GET_CONDITION_TREATED_SUCCESS";
export const GET_CONDITION_TREATED_ERROR = "GET_CONDITION_TREATED_ERROR";
export const GET_CONDITION_TREATED_PROGRESS = "GET_CONDITION_TREATED_PROGRESS";

export const getConditionsTreated = conditionsTreated => (dispatch, state) => {
  dispatch({ type: GET_CONDITION_TREATED_PROGRESS });
  apiMethods
    .get("CondtionsTreated")
    .then(response => {
      dispatch({ type: GET_CONDITION_TREATED_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
      dispatch({ type: GET_CONDITION_TREATED_ERROR, error: error.message });
    });
};
