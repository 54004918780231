import React from "react";
import Channel from "./channelRenderer";
import CreateNewChannel from "./createNewChannel";

export default class MyChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMyChannels: false,
      openCreateChannelDialog: false,
      showChannels: true,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    document.getElementById("Feed_div").scrollTop = 0;
    this.props.setTimeLineType("myChannels");
    this.props.setTimeLineCurrentChannel(
      this.props.myChannels.map((c) => c.id)
    );
    this.props.renderFeedForChannel(this.props.myChannels.map((c) => c.id));
    this.setState({ showMyChannels: true });
    this.setState({ showChannels: !this.state.showChannels });
  }

  render() {
    return (
      <div>
        <div className="d-flex">
          <div
            style={{ margin: "auto auto auto 20%" }}
            className="timelineFIlterOptions"
            onClick={() => this.handleClick()}
          >
            {this.props.timelineFilterType === "myChannels" ? (
              <span className="filter-active">my channels</span>
            ) : (
              <span>my channels</span>
            )}
          </div>

          <div style={{ cursor: "pointer", marginRight: "74px" }}>
            {this.state.showMyChannels || this.state.showChannels ? (
              <i
                className=" fa fa-angle-up"
                aria-hidden="true"
                onClick={() =>
                  this.setState({
                    showMyChannels: !this.state.showMyChannels,
                    showChannels: false,
                  })
                }
              />
            ) : (
              <i
                className="fa fa-angle-down"
                aria-hidden="true"
                onClick={() => {
                  this.props.setTimeLineCurrentChannel(
                    this.props.myChannels.map((c) => c.id)
                  );
                  this.props.setTimeLineType("myChannels");
                  this.props.renderFeedForChannel(
                    this.props.myChannels.map((c) => c.id)
                  );
                  this.setState({
                    showMyChannels: !this.state.showMyChannels,
                    showChannels: true,
                  });
                }}
              />
            )}
          </div>
        </div>

        {this.props.profile &&
        this.props.profile.securityMatrix &&
        this.props.profile.securityMatrix.canCreateChannels &&
        this.state.showChannels ? (
          <>
            <div>
              {this.props.myChannels && this.state.showChannels ? (
                <div className="channel-list">
                  {this.props.myChannels.map((item, i) => {
                    return (
                      <Channel
                        item={item}
                        isOwn={true}
                        index={i}
                        key={"MyChannel" + i}
                        renderFeedForChannel={this.props.renderFeedForChannel}
                        setTimeLineCurrentChannel={
                          this.props.setTimeLineCurrentChannel
                        }
                        updateChnannelStatus={this.props.updateChnannelStatus}
                        setTimeLineType={this.props.setTimeLineType}
                      />
                    );
                  })}
                </div>
              ) : null}
              {this.props.profile &&
              this.props.profile.securityMatrix &&
              this.props.profile.securityMatrix.canCreateChannels ? (
                <div
                  style={{ marginLeft: "25%", cursor: "pointer" }}
                  onClick={() =>
                    this.setState({ openCreateChannelDialog: true })
                  }
                >
                  + create new
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            {this.state.showMyChannels ? (
              <div>
                {this.props.myChannels && this.state.showMyChannels ? (
                  <div className="channel-list">
                    {this.props.myChannels.map((item, i) => {
                      return (
                        <Channel
                          item={item}
                          isOwn={true}
                          index={i}
                          key={"MyChannel" + i}
                          renderFeedForChannel={this.props.renderFeedForChannel}
                          setTimeLineCurrentChannel={
                            this.props.setTimeLineCurrentChannel
                          }
                          updateChnannelStatus={this.props.updateChnannelStatus}
                          setTimeLineType={this.props.setTimeLineType}
                        />
                      );
                    })}
                  </div>
                ) : null}
                {this.props.profile &&
                this.props.profile.securityMatrix &&
                this.props.profile.securityMatrix.canCreateChannels ? (
                  <div
                    style={{ marginLeft: "25%", cursor: "pointer" }}
                    onClick={() =>
                      this.setState({ openCreateChannelDialog: true })
                    }
                  >
                    + create new
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        )}

        {/* {this.state.showMyChannels ? (
          <div>
            {this.props.myChannels && this.state.showMyChannels ? (
              <div className="channel-list">
                {this.props.myChannels.map((item, i) => {
                  return (
                    <Channel
                      item={item}
                      isOwn={true}
                      index={i}
                      key={"MyChannel" + i}
                      renderFeedForChannel={this.props.renderFeedForChannel}
                      setTimeLineCurrentChannel={
                        this.props.setTimeLineCurrentChannel
                      }
                      updateChnannelStatus={this.props.updateChnannelStatus}
                      setTimeLineType={this.props.setTimeLineType}
                    />
                  );
                })}
              </div>
            ) : null}
            {this.props.profile &&
            this.props.profile.securityMatrix &&
            this.props.profile.securityMatrix.canCreateChannels ? (
              <div
                style={{ marginLeft: "25%", cursor: "pointer" }}
                onClick={() => this.setState({ openCreateChannelDialog: true })}
              >
                + create new
              </div>
            ) : null}
          </div>
        ) : null} */}

        <CreateNewChannel
          openCreateChannelDialog={this.state.openCreateChannelDialog}
          closeCreateChannelDialog={() =>
            this.setState({ openCreateChannelDialog: false })
          }
          createNewChannel={this.props.createNewChannel}
          myDepartments={this.props.myDepartments}
          profile={this.props.profile}
        />
      </div>
    );
  }
}
