import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const SAVE_TANDC_ACCEPT_SUCCESS = "SAVE_TANDC_ACCEPT_SUCCESS";
export const SAVE_TANDC_ACCEPT_ERROR = "SAVE_TANDC_ACCEPT_ERROR";
export const SAVE_TANDC_ACCEPT_IN_PROGRESS = "SAVE_TANDC_ACCEPT_IN_PROGRESS";

export function saveAccept() {
  return function (dispatch, state) {
    dispatch({ type: SAVE_TANDC_ACCEPT_IN_PROGRESS });
    apiMethods
      .post("provider/acceptterms ", {
        email: localStorage.getItem("username"),
        mobile: localStorage.getItem("mobile"),
      })
      .then((response) => {
        dispatch({ type: SAVE_TANDC_ACCEPT_SUCCESS });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: SHOW_ERROR, payload: error.data.message });
        dispatch({ type: SAVE_TANDC_ACCEPT_ERROR, error: error.data.message });
      });
  };
}
