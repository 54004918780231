import {
  SAVE_ORG_RPOFILE_SUCCESS,
  GET_ORG_PROFILE_SUCCESS,
  SAVE_ORG_LOGO_SUCCESS,
  SAVE_ORG_RPOFILE_IN_PROGRESS,
  GET_ORG_TYPE_SUCCESS,
  SAVE_ORG_RPOFILE_ERROR,
} from "../actions/organizationProfile.actions";

const intialState = {
  orgLogo: null,
  orgTypes: [],
  initialValues: {
    OrganisationName: "",
    Country: "",
    PrimaryAddress: "",
    City: "",
    State: "",
    zipCode: "",
    Phone: "",
    Fax: "",
    PrimaryLocationName: "",
    Type: [],
    speciality: [],
    CountryId: "",
    StateId: "",
    CityId: "",
  },
};

const organizationProfile = (state = intialState, action) => {
  switch (action.type) {
    case SAVE_ORG_RPOFILE_SUCCESS:
      console.log("runing");
      return {
        ...state,
        saveState: SAVE_ORG_RPOFILE_SUCCESS,
      };
    case SAVE_ORG_RPOFILE_ERROR:
      return {
        ...state,
        saveState: SAVE_ORG_RPOFILE_ERROR,
      };
    case GET_ORG_PROFILE_SUCCESS:
      return {
        ...state,
        initialValues: action.payload,
        orgLogo: action.payload.orgLogo,
      };
    case SAVE_ORG_LOGO_SUCCESS:
      return {
        ...state,
        orgLogo: action.payload,
      };
    case SAVE_ORG_RPOFILE_IN_PROGRESS:
      return {
        ...state,
        saveState: SAVE_ORG_RPOFILE_IN_PROGRESS,
      };
    case GET_ORG_TYPE_SUCCESS:
      return {
        ...state,
        orgTypes: action.payload,
      };
    default:
      return state;
  }
};

export default organizationProfile;
