import React, { Component } from "react";
//import { formatPhoneNumber } from "react-phone-number-input";

export default class OrgAdminCard extends Component {
  constructor(props) {
    super(props);

    this.renderPrimaryIndicator = this.renderPrimaryIndicator.bind(this);
    this.removeAdmin = this.removeAdmin.bind(this);
  }

  removeAdmin() {
    const displayName = `${
      this.props.orgAdmin.title ? this.props.orgAdmin.title : ""
    } ${this.props.orgAdmin.firstName ? this.props.orgAdmin.firstName : ""} ${
      this.props.orgAdmin.lastName ? this.props.orgAdmin.lastName : ""
    }`;

    if (
      window.confirm(
        `Are you sure you want to remove the '${displayName}' from admin role?`
      )
    ) {
      this.props.removeAdmin(this.props.orgAdmin.providerId);
    }
  }

  renderRemoveAdmin = () => {
    if (this.props.orgAdmin.isPrimary === false) {
      return (
        <i
          className="far fa-times-circle remove-admin"
          onClick={() => this.removeAdmin()}
        />
      );
    } else {
      return <i />;
    }
  };

  renderPrimaryIndicator() {
    if (this.props.orgAdmin.isPrimary) {
      return <i className="fas fa-circle primary-admin-indicator" />;
    } else {
      return <span />;
    }
  }

  render() {
    return (
      <div className="col-4 mt-3">
        <div className="rectangle-info-admin">
          {this.renderPrimaryIndicator()}

          <div className="row h-100">
            <div className="col-5 my-auto">
              {this.props.orgAdmin.profileImg ? (
                <div
                  className="admin-img-container"
                  style={{
                    backgroundImage: `url(${this.props.orgAdmin.profileImg})`
                  }}
                />
              ) : (
                <div className="user-pic" />
              )}
            </div>
            <div className="col-7" style={{ paddingRight: "25px" }}>
              <div className="user-name text-left">
                {this.props.orgAdmin.firstName}&nbsp;
                {this.props.orgAdmin.lastName}
              </div>
              <div className="loc-info-txt" title={this.props.orgAdmin.email}>
                {this.props.orgAdmin.email}
              </div>
              <div className="loc-info-txt">
                {this.props.orgAdmin.phone || ""}
              </div>
              {this.renderRemoveAdmin()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
