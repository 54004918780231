import React from "react";
//import { formatPhoneNumber } from "react-phone-number-input";

class SubscriptionCard extends React.Component {
  render() {
    return (
      <div
        className="col-4"
        style={{ cursor: "pointer", marginBottom: "10px" }}
        onClick={() =>
          this.props.showDetails(
            this.props.subscription.type,
            this.props.subscription.providerId ||
              this.props.subscription.organisationId
          )
        }
      >
        <div className="rectangle-info" style={{ minHeight: "110px" }}>
          <div className="loc-name text-left">
            {`${this.props.subscription.firstName || ""}${" "}${this.props
              .subscription.lastName || ""}`}
          </div>
          <div className="row">
            <div className="col-6">
              <div className="loc-clinic">{this.props.subscription.type}</div>
            </div>
            <div className="col-6" style={{ paddingLeft: "0px" }}>
              <div className="loc-info-txt">
                {this.props.subscription.contactNumber || ""}
              </div>
              <div className="loc-info-txt">
                {this.props.subscription.email || ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionCard;
