import { change } from "redux-form";
import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_VERIFY,
  LOG_OUT,
  LOGIN_PASSWORD,
  LOGIN_EMAIL,
  Cuuent_password,
  COMMON_LOGIN,
} from "../actions/login.actions";
import tokenService from "../middleware/tokenService";

const initialState = {
  isLoggedIn: false,

  showMessage: false,
  userToken: null,
  error: null,
  state: null,
  email: null,
  password: null,
  commonLogin: "",
  mobile: null,
};

initialState.userToken = tokenService.getToken();
initialState.isLoggedIn = window.localStorage.getItem("login");

const loginReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case LOGIN_REQUEST: {
      newState = {
        ...state,
        state: LOGIN_REQUEST,
        isLoggedIn: false,
        userToken: null,
        error: null,
      };
      break;
    }
    // case COMMON_LOGIN: {
    //   console.log("ccc", action.payload);
    //   newState = {
    //     ...state,
    //     state: COMMON_LOGIN,
    //     email: action.payload.email,
    //     commonLogin: action.payload.userType,
    //     password: action.payload.password,
    //     mobile: action.payload.mobile,
    //   };
    //   break;
    // }
    case LOGIN_EMAIL: {
      newState = {
        // ...state,
        state: LOGIN_EMAIL,
        isLoggedIn: false,
        userToken: null,
        error: null,
        email: action.payload,
      };
      break;
    }
    case LOGIN_ERROR: {
      newState = {
        ...state,
        state: LOGIN_ERROR,
        isLoggedIn: false,
        userToken: null,
        error: action.payload,
      };
      break;
    }
    case LOGIN_SUCCESS: {
      // tokenService.setToken(action.payload.accessToken);
      // const nowInMSecs = new Date().getTime();
      // const expiriesAt = nowInMSecs + action.payload.expiresIn * 1000;
      // tokenService.setExpiry(expiriesAt);
      newState = {
        ...state,
        state: LOGIN_SUCCESS,
        isLoggedIn: true,
        email: action.payload.email,
        userToken: null,
        error: null,
        commonLogin: action.payload.userType,
        password: action.payload.password,
        mobile: action.payload.mobile,
      };
      break;
    }
    case LOGIN_PASSWORD: {
      newState = {
        ...state,
        state: LOGIN_PASSWORD,
        isLoggedIn: false,
        showMessage: true,
        // email: action.payload.email,
        userToken: null,
        error: null,
      };
      break;
    }
    case Cuuent_password: {
      newState = {
        ...state,
        state: Cuuent_password,
        isLoggedIn: true,
        showMessage: true,
        password: action.payload,
        userToken: null,
        error: null,
      };
      break;
    }
    case LOGIN_VERIFY: {
      newState = {
        ...state,
        state: LOGIN_VERIFY,
        isLoggedIn: true,
        userToken: null,
        error: null,
      };
      break;
    }
    case LOG_OUT: {
      localStorage.clear();

      newState = {
        ...state,
        state: LOG_OUT,
        isLoggedIn: false,
        userToken: null,
        error: null,
      };

      break;
    }

    default: {
      newState = { ...state };
      break;
    }
  }
  return newState;
};

export default loginReducer;
