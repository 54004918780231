import React, { Component } from "react";
import "./mobileprivacyPolicy.css";
import tokenService from "../../middleware/tokenService";

export default class mobilePrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.props.saveAccept();
  }

  render() {
    return (
      <div className="privacy">
        <p>
          <span className="heading">Healthswim Online Privacy Notice</span>
        </p>
        <p>
          <span className="heading">Our Privacy Notice</span>
          <p>
            {" "}
            This Privacy Notice (“Privacy Notice”) discloses the privacy
            practices for Health Swim, LLC or any Site or mobile applications
            within the Healthswim network (the “Site”). Within this Privacy
            Notice, Health Swim, LLC might sometimes be referred to as
            “Healthswim,” “we,” “our,” or “us”; users of the Site – whether
            content providers, healthcare providers, or those individuals
            receiving their news stream – might be referred to as “you” or
            “your” or “their”.
          </p>
          <p>
            Healthswim believes in the importance of thoughtfully handling
            personal information and is committed to privacy practices that are
            transparent and compliant. This Privacy Notice sets out how
            Healthswim uses the personal information we collect and receive
            about you, the visitor to our Site. The following Privacy Notice
            applies only to information collected on Healthswim Sites or mobile
            applications where this Privacy Notice is posted.
          </p>
          <p>
            If you do not agree with the terms of this Privacy Notice, then do
            not visit the Site. The terms in this Privacy Notice are
            incorporated into the Terms of Use (“ToU”) for the Site and are
            subject to the limitations of those ToU including any provisions on
            liability and indemnity.
          </p>
        </p>
        <p>
          <span className="heading">Information That We Collect</span>
        </p>
        <p>
          The type of information we collect will depend on your interactions
          with Healthswim, typically during registration and use of our Service.
          It may include:
        </p>

        <p>
          <span className="heading">Contact and Log In Information. </span>Name,
          organization, title, email address, professional credentials,
          professional specialties, physical address(es), phone number, and
          similar contact info, user names and passwords.
        </p>

        <p>
          <span className="heading">Payment Information. </span>Credit card
          number or banking information and billing address.
        </p>

        <p>
          <span className="heading">Demographic Information. </span>Employment
          status, occupation, region. On occasion, Healthswim may collect other
          demographic information, such as gender, race and age.
        </p>

        <p>
          <span className="heading">Registration Information. </span>Personal
          information for healthcare provider registrations includes but is not
          limited to: log-in credentials (user name, password), name, postal
          address, e-mail address, NPI number, year of medical school
          graduation, areas of specialization, and telephone number. For
          individuals receiving a healthcare provider’s feed, we typically
          collect only log-in credentials and the identity of the provider feed
          or topic channels you select.
        </p>
        <p>
          <span className="heading">Usage and Geographic Data. </span>Healthswim
          collects personal information related to your use of our products,
          services and websites so that we can analyze and improve our Services
          and our engagement with you. Healthswim may use aggregated,
          non-personally identifiable usage data for external training and
          marketing purposes. In some instances, Healthswim may provide
          administrative functionality that allows you to opt out of the
          collection of some usage data.
        </p>
        <p>
          Usage data may include information about your computer's or mobile
          device's operating system and browser type, device type, details about
          how you are using our products (but not including the underlying
          data), view your Internet Protocol (IP) address, geographic areas
          derived from your IP address, networking connection data, Healthswim
          cookie information, file information, metadata, time stamped logs
          regarding access times and duration of visits, the websites you
          visited before coming to Healthswim websites, and other usage data
          relating to your activities on our Sites, including the pages you
          request. We may link this information to the personal information you
          provide for purposes described in this Privacy Notice, and may provide
          targeted product information back to users based on their user data.
        </p>
        <p>
          <span className="heading">Third Party Data. </span>We may receive your
          personal information from third party suppliers or partners. If you
          connect with Healthswim accounts on third party social networking
          sites, we may receive information about your social networking
          accounts, for example, your name, user name or display name, public
          profile, and email address. We may combine information you provide
          with data we collect automatically and with data we receive from third
          parties. Certain third parties, including analytics companies,
          advertisers and ad networks, may automatically collect information
          about you through our websites using cookies, Web beacons, and device
          identifiers, including personal information about your online
          activities over time and across different websites, devices, online
          channels and applications.
        </p>
        <p>
          We may also collect other types of information such as profession and
          professional interests as well as your communication preferences. We
          will store all personal information in a customer record. Registered
          users of the Site are able to control their communication preferences
          at any time by accessing the “My Account” page. We may combine
          information collected about you with information from third-party
          sources. For example, upon registration, some health care
          professionals will be matched against the publicly available NPI
          Registry to append and include their NPI number to their registration
          profiles. In addition, this Site participates in the Healthcare
          Communication Network<sup>SM</sup> (the "Network"), and as such has
          implemented various technologies for universal registration/log-in
          among all Network member Sites. If you would like to understand how
          this Site’s participation in the Network affects your privacy, please{" "}
          <a href="http://usersupport.dmdconnects.com/PrivacyPolicy.htm">
            click here.
          </a>
        </p>
        <p>
          <span className="heading">Anonymous Users.</span> We may collect
          information about your usage of Healthswim whether or not you have
          registered for Healthswim. We collect this information through the use
          of cookies, which are bits of information that a Site sends to your
          computer while you are viewing a webpage. These items may include:
          <ul>
            <li>The time and date of your request</li>
            <li>The internet address of your computer</li>
            <li>The browser and operating system you are using</li>
            <li>The webpage that you are viewing</li>
            <li>The Site that referred you to Healthswim</li>
          </ul>
        </p>
        <p>
          <span className="heading">Cookies.</span> Healthswim collects
          information through a variety of technical methods, including cookies.
          We collect information through such technology to make our Site more
          relevant and useful, and to deliver information about products and
          services to our users. Healthswim uses third-party marketing services,
          such as Google, to advertise on third-party websites across the
          Internet. These services use cookies to serve ads to you based on your
          past visits to Healthswim. You may opt out of Google’s use of cookies
          by visiting{" "}
          <a href="https://www.google.com/ads/preferences/" target="_blank">
            Google’s Ads Settings.{" "}
          </a>
          You may opt out of all of Healthswim’s marketing programs by emailing
          your name and National Provider Identifier (NPI) number to{" "}
          <a href="mailto:privacy@healthswim.com"> privacy@healthswim.com. </a>
          You may learn more about interest-based advertising and opt out of
          interest-based advertising from members of the{" "}
          <a href="http://optout.aboutads.info/">
            Digital Advertising Alliance
          </a>{" "}
          or the{" "}
          <a href="http://optout.networkadvertising.org/" target="_blank">
            Network Advertising Initiative
          </a>
          . In the event that you don’t want to be served ads that are tailored
          to you, you may “opt out” of many campaigns by going to{" "}
          <a href="https://www.aboutads.info/" target="_blank">
            https://www.aboutads.info
          </a>{" "}
          or{" "}
          <a href="https://preferences-mgr.truste.com/" target="_blank">
            https://preferences-mgr.truste.com
          </a>
          . If you decide to opt out, you will continue to be served ads, but
          they will not be served based on your personal Internet activity.
        </p>
        <p>
          The “Help” function in your web browser explains how to manage cookies
          while using the Internet. As previously noted, some services will not
          function properly if your browser does not accept cookies.
        </p>
        <p>
          <span className="heading"> Comments and Forums. </span>When you post a
          comment on Healthswim, your name or username will be displayed.
          Information that you post will be publicly available and may be used
          by us and third parties. However, we reserve the right not to post any
          comments with unsolicited information about medical devices or other
          products. At no time should Healthswim be used to provide medical
          advice to patients.
        </p>
        <p>
          <span className="heading">Mobile Applications (Apps).</span> To access
          mobile device applications (apps), you may or may not be required to
          register. We will collect information about you and your use of our
          apps in the same way we do on our Sites. This may or may not include
          urls that have been clicked on, time on Site, and information about
          the device that you were using when you accessed our Site.
        </p>
        <p>
          <span className="heading">Surveys & Polls. </span>Healthswim may
          request feedback regarding your use of our services through polls,
          educational surveys, and other types of market research. We may
          collect personal information about you when a response is necessary or
          to fulfill contests, honoraria, giveaways, etc. In surveys that
          involve an educational component, individuals’ personal information
          including name, NPI and specialty as well as their responses may be
          shared with grantors. In other instances, data are collected in
          aggregate form only.
        </p>
        <p>
          <span className="heading">Widgets.</span> Social media icons, widgets
          and other sharing buttons may be present on our Site. When you consent
          to using our Site, please be aware that these widgets may be connected
          with third-party sites, such as Facebook or Twitter. These sites track
          your sharing behaviors and identity (through your social media
          handles) by utilizing cookies. Your interactions with these Sites are
          administrated by the privacy policies of those third parties.
          Accessing content that is promoted by a third party publisher on
          Healthswim is covered by that publisher’s privacy notice, and the
          publisher may monitor usage using primarily anonymized data.
        </p>
        <p>
          <span className="heading">Do Not Track.</span>Currently our Site does
          not respond to Do Not Track signals from individual browser settings.
        </p>
        <p>
          <span className="heading"> Why we collect this information</span>
        </p>
        <p>
          We may use the personal information collected for many purposes,
          including delivering content, tracking, reporting, marketing,
          delivering ads and analyzing the traffic patterns on our Sites.
          Information we collect is aggregated into reports that enable us to
          monitor usage and security. Aggregated and individual usage statistics
          are distributed within Healthswim to improve Site or service
          performance and deliver relevant educational and promotional content.
          Healthswim may collect and share both aggregated and personally
          identifiable information tied to IP addresses or cookie information
          with third parties (including advertisers and institutional
          subscribers) interested in communicating with you about information or
          products relevant to your profession.
        </p>
        <p>
          Healthswim does not endorse and is not responsible for the privacy
          practices of any third-party sites links to which may be on our Site,
          although we make try to collaborate only with those who follow
          Internet best practices.
        </p>
        <p>
          We reserve the right to review the use of our Site by individual
          user’s accounts associated with 1) attempts to violate the security of
          our computer networks, 2) activity that degrades the performance of
          our Site, 3) activity that may be related to copyright violation, or
          4) if we are required to disclose this information by law.
        </p>
        <p>
          <span className="heading">Sharing of Personal Information</span>
        </p>
        <p>
          We work to keep your personal information confidential and secure, and
          do not sell your personal data. In some circumstances Healthswim may
          share your information with third parties, for example:
        </p>
        <p>
          We may share your personal information when we have your permission,
          including when you choose to post to any future community websites;
        </p>
        <p>
          We provide personal information to trusted partners who work on behalf
          of or with Healthswim to provide us with services. These companies may
          use your personal information to perform services and to help
          Healthswim communicate with you. We maintain contracts with these
          companies restricting their access, use and disclosure of personal
          information in compliance with this Privacy Notice and any legal
          obligations;
        </p>
        <p>
          If you connect to your third party accounts through our products, we
          will use that information to authenticate you, enumerate the data
          sources available to you, download any data you request us to, and
          download and refresh authentication tokens or persist authentication
          information such as user names and passwords as necessary to continue
          to connect to these data;
        </p>
        <p>
          We may share your personal information with our current or future
          affiliates, which may include parent and subsidiary companies, joint
          ventures, or other companies under common control, in which case we
          will require our affiliates to honor this Privacy Notice;
        </p>
        <p>
          We will disclose your personal information to comply with legal
          requirements, for example, in response to a court order or a subpoena.
          We also may disclose your personal information in response to a law
          enforcement agency's request, or where we believe it is necessary to
          investigate, verify, prevent, enforce compliance with, or take action
          regarding illegal or suspected illegal activities; suspected fraud;
          situations involving potential threats to the physical safety of any
          person; protection of the rights and property of Healthswim, our
          agents, customers, or others; violations or suspected violations of
          our agreements and policies; or as otherwise required or permitted by
          law or consistent with legal requirements;
        </p>
        <p>
          We transfer or disclose your personal information for corporate
          reasons. For example, to third parties in connection with or during
          negotiation of any merger, financing, acquisition, bankruptcy or
          similar transaction. We may also share personal information with our
          auditors, attorneys or other advisors in the connection with corporate
          functions; and
        </p>
        <p>
          Finally, we also share aggregated, anonymized or statistical
          information about you, including demographics data, with others for a
          variety of purposes, for example, for improving products and services
          for Healthswim and others.
        </p>
        <p>
          We may facilitate third party services or ways to share data through
          third parties, including social media platforms, websites,
          applications, and services through “plug-ins,” widgets, buttons, and
          other third party features on and connected with our websites,
          communications or products. Third parties whose services you use in
          connection with Healthswim or third parties whose websites we link to
          may have information practices that are different from ours. This
          Privacy Notice does not apply to the activities of third parties when
          they are collecting or using data for their own purpose or on behalf
          of others. We are not responsible for the activities of these third
          parties. We encourage you to review their privacy policies to
          understand how they use your information.
        </p>
        <p>
          <span className="heading">
            Controlling the Use of Your Personal Information
          </span>
        </p>
        <p>
          <span className="heading"> Unsubscribe/Right to Be Forgotten. </span>
          If at any time you wish to stop receiving marketing or content
          communications from us, you may unsubscribe at the bottom of your
          email(s) or contact Healthswim at{" "}
          <a href="mailto:privacy@healthswim.com">privacy@healthswim.com</a>.
        </p>
        <p>
          <span className="heading">Internal security measures</span>
        </p>
        <p>
          In order to support its business activities or services, which may
          include analyzing lists of personal information for behavioral trends
          and internal emailing of unencrypted personal information (among other
          actions), only authorized Healthswim employees, our service providers,
          or our clients’ authorized staff (who have contractually agreed to
          keep all information secure) may have access to your personal data.
          All Healthswim staff who have access are required to adhere to staff
          confidentiality agreements with regard to personal information that
          are held on file.
        </p>
        <p>
          <span className="heading">
            Contact Information for Privacy & Personal Data Inquiries
          </span>
        </p>
        <p>
          If you have any questions regarding the Healthswim Privacy Notice, or
          would like to exercise your rights regarding your privacy and data as
          it pertains to this Site, please contact us via:
        </p>
        <p>Postal mail:</p>
        <p>Health Swim, LLC </p>
        <p>Attn: Privacy Compliance</p>
        <p>19034 20th Ave NW Seattle WA 98177</p>
        <p>
          Email:{" "}
          <a href="mailto:privacy@healthswim.com">privacy@healthswim.com</a>
        </p>
        <p>
          <span className="heading">Conclusion</span>
        </p>
        <p>
          These statements convey the current Privacy Notice of Healthswim. We
          reserve the right to alter this Notice at any time without advance
          notification. Any changes to this Privacy Notice will be reflected on
          this webpage.{" "}
        </p>
      </div>
    );
  }
}
