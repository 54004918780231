import React, { Component } from "react";
import Modal from "react-modal";
import { Typeahead } from "react-bootstrap-typeahead";
import CloseButton from "react-bootstrap/CloseButton";
import { FiEdit } from "react-icons/fi";
import csc from "country-state-city";
import default_org_Logo from "../../img/profile_icon.jpeg";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "-20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid gray",
    maxWidth: "72%",
  },
};

export default class ShowOrgInformation extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      isEdit: true,
      stateId: "",

      initialValues: {
        id: "",
        orgName: "",
        country: "",
        primaryaddress: "",
        state: "",
        city: "",
        zipCode: "",
        phone: "",
        fax: "",
        primaryLocationName: "",
        type: [],
        speciality: [],
        profileLogo: "",
        logo: "",
        cityId: "",
      },
    };
  }
  componentDidMount() {
    if (this.props.subscriptionDetailsOrg) {
      this.setState({
        stateId: this.props.subscriptionDetailsOrg.stateId,

        initialValues: {
          id: this.props.subscriptionDetailsOrg.id,
          orgName: this.props.subscriptionDetailsOrg.organisationName,
          country: this.props.subscriptionDetailsOrg.country,
          primaryaddress: this.props.subscriptionDetailsOrg.primaryAddress,
          state: JSON.parse(this.props.subscriptionDetailsOrg.stateArray),
          city: JSON.parse(this.props.subscriptionDetailsOrg.cityArray),
          zipCode: this.props.subscriptionDetailsOrg.zipCode,
          phone: this.props.subscriptionDetailsOrg.phone,
          fax: this.props.subscriptionDetailsOrg.fax,
          primaryLocationName:
            this.props.subscriptionDetailsOrg.primaryLocationName,
          type: JSON.parse(this.props.subscriptionDetailsOrg.type),
          speciality: this.props.subscriptionDetailsOrg.specialities,
          countryId: this.props.subscriptionDetailsOrg.countryId,
          cityId: this.props.subscriptionDetailsOrg.cityId,
          profileLogo: this.props.subscriptionDetailsOrg.profileLogo,
        },
      });
    }
  }
  saveOrganizationLogo = (event) => {
    if (event.target.files[0]) {
      var file = URL.createObjectURL(event.target.files[0]);
      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      this.setState((prevState) => ({
        initialValues: {
          ...prevState.initialValues, // keep all other key-value pairs
          profileLogo: file, // update the value of specific key
          logo: formData,
        },
      }));
    }
  };
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      initialValues: {
        // object that we want to update
        ...prevState.initialValues, // keep all other key-value pairs
        [name]: value, // update the value of specific key
      },
    }));
  };
  selectedOptions = (name, selected) => {
    let temp = [];
    selected.map((x) => {
      temp.push(x);
    });
    if ((name = "state")) {
      this.setState({ stateId: selected[0] && selected[0].id });
    }

    this.setState((prevState) => ({
      initialValues: {
        // object that we want to update
        ...prevState.initialValues, // keep all other key-value pairs
        [name]: temp, // update the value of specific key
      },
    }));
  };
  selectedOptionsforcity = (name, selected) => {
    let temp = [];
    selected.map((x) => {
      temp.push(x);
    });

    this.setState((prevState) => ({
      initialValues: {
        // object that we want to update
        ...prevState.initialValues, // keep all other key-value pairs
        [name]: temp, // update the value of specific key
      },
    }));
  };
  saveOrgInfo = () => {
    this.props.submitOrgEditInfo(this.state.initialValues);
    this.setState({ isEdit: true });
  };
  render() {
    return (
      <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
        <CloseButton aria-label="Hide" onClick={this.props.onClose} />

        <div className="form-header">
          <h3> {this.state.initialValues.orgName}</h3>
        </div>
        <div className="form-container h-100">
          <div className="row">
            {this.state.isEdit === true && (
              <div className="col-4">
                {/* <div className="form-lText ">
              {!this.props.orgLogo
                ? `Uploading a logo is a good way to ensure that users know who
                  you and your providers are`
                : null}
            </div> */}
                <div className="d-flex">
                  {this.state.initialValues.profileLogo ? (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage:
                          "url(" + this.state.initialValues.profileLogo + ")",
                      }}
                    />
                  ) : (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage: "url(" + default_org_Logo + ")",
                        border: "2px solid black",
                      }}
                    />
                  )}
                </div>
                {/* <div className="form-group text-center mt-4">
              <span
                className="form-link-btn"
                style={{ cursor: "pointer" }}
                onClick={() => this.imageRef.current.click()}
              >
                Add a logo here
              </span>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={this.imageRef}
                onChange={this.props.saveOrganizationLogo}
              />
            </div> */}
              </div>
            )}
            {this.state.isEdit === false && (
              <div className="col-4">
                <div className="form-lText ">
                  {!this.state.initialValues.profileLogo
                    ? `Uploading a logo is a good way to ensure that users know who
                  you and your providers are`
                    : null}
                </div>
                <div className="d-flex">
                  {this.state.initialValues.profileLogo ? (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage:
                          "url(" + this.state.initialValues.profileLogo + ")",
                      }}
                    />
                  ) : (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage: "url(" + default_org_Logo + ")",
                        border: "2px solid black",
                      }}
                    />
                  )}
                </div>
                <div className="form-group text-center mt-4">
                  <span
                    className="form-link-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.imageRef.current.click()}
                  >
                    Add a logo here
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={this.imageRef}
                    // onChange={this.props.saveOrganizationLogo}
                    onChange={this.saveOrganizationLogo}
                  />
                </div>
              </div>
            )}
            <div className="col-8">
              {this.state.isEdit === true && (
                <form>
                  <div className="form-group form-lable">
                    <label className="form-lable">Organization name</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.orgName}
                    </span>
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Country</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.country}
                    </span>
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Primary address</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.primaryaddress}
                    </span>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-4">
                        <label className="form-lable">State</label>
                        <span className="form-control form-input">
                          {this.state.initialValues.state &&
                            this.state.initialValues.state.map(
                              (index, value) => {
                                return " " + index.name;
                              }
                            )}
                        </span>
                      </div>
                      <div className="col-sm-4">
                        <label className="form-lable">City</label>
                        <span className="form-control form-input">
                          {this.state.initialValues.city &&
                            this.state.initialValues.city.map(
                              (index, value) => {
                                return " " + index.name;
                              }
                            )}
                        </span>
                      </div>
                      <div className="col-sm-4">
                        <label className="form-lable">Zip Code</label>
                        <span className="form-control form-input">
                          {this.state.initialValues.zipCode}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="form-lable">Primary phone</label>
                        <span className="form-control form-input">
                          {this.state.initialValues.phone}
                        </span>
                      </div>
                      <div className="col-sm-6">
                        <label className="form-lable">Primary fax</label>
                        <span className="form-control form-input">
                          {this.state.initialValues.fax}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group form-lable">
                    <label className="form-lable">Primary location name</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.primaryLocationName}
                    </span>
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Type</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.type &&
                        this.state.initialValues.type.map((index, value) => {
                          return " " + index.name;
                        })}
                    </span>
                  </div>

                  <div className="form-group form-lable">
                    <label className="form-lable">Specialty</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.speciality &&
                        this.state.initialValues.speciality.map(
                          (index, value) => {
                            return index.name + ", ";
                          }
                        )}
                    </span>
                  </div>
                  <div className="mt-5 text-right">
                    <FiEdit
                      style={{ color: "#00c6ff" }}
                      size={30}
                      onClick={() => {
                        this.setState({ isEdit: false });
                      }}
                    />
                  </div>
                </form>
              )}
              {this.state.isEdit === false && (
                <form>
                  <div className="form-group form-lable">
                    <label className="form-lable">Organization name</label>

                    <input
                      type="text"
                      className="form-control form-input"
                      name="orgName"
                      value={this.state.initialValues.orgName}
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Country</label>

                    <input
                      type="text"
                      className="form-control form-input"
                      name="country"
                      value={this.state.initialValues.country}
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Primary address</label>

                    <input
                      type="text"
                      className="form-control form-input"
                      name="primaryaddress"
                      value={this.state.initialValues.primaryaddress}
                      onChange={this.handleInput}
                    />
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-4">
                        <label className="form-lable">State</label>
                        <Typeahead
                          options={csc.getStatesOfCountry(
                            this.props.subscriptionDetailsOrg.countryId
                          )}
                          selected={this.state.initialValues.state}
                          name="State"
                          labelKey="name"
                          onChange={(selected) => {
                            this.selectedOptions("state", selected);
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label className="form-lable">City</label>
                        {/* {console.log(
                          "csc.getCitiesOfState(this.state.stateId)",
                          csc.getCitiesOfState(this.state.stateId)
                        )} */}
                        <Typeahead
                          options={csc.getCitiesOfState(this.state.stateId)}
                          selected={this.state.initialValues.city}
                          name="City"
                          labelKey="name"
                          onChange={(selected) => {
                            this.selectedOptionsforcity("city", selected);
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label className="form-lable">Zip Code</label>

                        <input
                          type="text"
                          className="form-control form-input"
                          name="zipCode"
                          value={this.state.initialValues.zipCode}
                          onChange={this.handleInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="form-lable">Primary phone</label>

                        <input
                          type="text"
                          className="form-control form-input"
                          name="phone"
                          value={this.state.initialValues.phone}
                          onChange={this.handleInput}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-lable">Primary fax</label>

                        <input
                          type="text"
                          className="form-control form-input"
                          name="fax"
                          value={this.state.initialValues.fax}
                          onChange={this.handleInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group form-lable">
                    <label className="form-lable">Primary location name</label>

                    <input
                      type="text"
                      className="form-control form-input"
                      name="primaryLocationName"
                      value={this.state.initialValues.primaryLocationName}
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Type</label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      name="speclocationiality"
                      // onChange={setSingleSelections}
                      options={this.props.orgTypes}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.type}
                      onChange={(selected) => {
                        this.selectedOptionsforcity("type", selected);
                      }}
                      multiple
                    />
                  </div>

                  <div className="form-group form-lable">
                    <label className="form-lable">Specialty</label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      name="speclocationiality"
                      // onChange={setSingleSelections}
                      options={this.props.specalities}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.speciality}
                      onChange={(selected) => {
                        this.selectedOptionsforcity("speciality", selected);
                      }}
                      multiple
                    />
                  </div>
                  <div className="mt-5 text-right">
                    <button
                      style={{ background: "#00c6ff" }}
                      type="button"
                      className="btn form-btn"
                      id="btnLogin"
                      onClick={this.saveOrgInfo}
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
