import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import apiMethods from "../../middleware/baseApiService";
import { Link } from "react-router-dom";

export default class DeleteAccount extends Component {
  constructor(props) {
    super(props);

    // this.state = {};
    this.state = {
      show: false,
      isDisable: false,
    };
  }
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleRedirectToDashboard = () => {
    // Redirect to the dashboard route
    // window.location.href = "/dashboard";
    this.props.history.push("/dashboard");
  };

  handleShowAlert = () => {
    // toast.warning("Are you sure you want to cancel?", {
    //   position: toast.POSITION.TOP_RIGHT,
    //   autoClose: false, // Do not auto-close the alert
    //   closeButton: false, // Hide the close button
    //   closeOnClick: false, // Do not close when clicked
    //   draggable: false, // Do not allow dragging
    //   pauseOnHover: false, // Do not pause on hover
    //   onClick: this.handleRedirectToDashboard, // Redirect when clicked
    // });
  };
  deleteProfile = async (props) => {
    // this.props.showApiLoader();
    this.setState({ show: false, isDisable: true });
    var email1 = localStorage.getItem("username");
    apiMethods
      .post("SignIn/deleteProviderUsingEmail", { email: email1 })
      .then((res) => {
        // props.hideApiLoader();
        console.log(res.data.message);
        localStorage.removeItem("username");
        localStorage.removeItem("mobile");
        localStorage.removeItem("login");
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          closeButton: false,
        });
        setTimeout(() => {
          // this.props.history.push("/login");
          this.setState({ isDisable: false });
          window.location.href = "/login";
        }, 3000);
      })
      .catch((err) => {
        this.setState({ isDisable: false });
        console.log("deactivation failed", mobile);
      });
  };

  navigateToDisableAccount = () => {
    this.props.history.push("/disableAccount");
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your information will stay with us for 3 months and then it will be
            completely deleted.
            <br />
            Do you want to disable instead?{" "}
            {/* <Link to="/disableAccount">disable</Link> */}
            <button
              style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                color: "#fff",
                borderRadius: ".25rem",
                border: "1px solid transparent",
              }}
              onClick={this.navigateToDisableAccount}
            >
              Disable
            </button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.deleteProfile}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="container">
          <div className="form-rectangle">
            <div className="form-header">
              <h3>Delete Account</h3>
            </div>
            <div className="form-container h-100">
              <h2 className="disableAccountHeading">
                Are you sure you want to delete your account?
              </h2>

              <div className="disableAccountBtnContainer">
                <button
                  className="disableAccountBtn"
                  // onClick={this.deleteProfile}
                  onClick={this.handleShow}
                  disabled={this.state.isDisable}
                >
                  Yes
                </button>
                <button
                  className="disableAccountBtn disableAccountCancelBtn"
                  // onClick={this.handleShowAlert}
                  onClick={this.handleRedirectToDashboard}
                  disabled={this.state.isDisable}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}
