import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const GET_LIST_TYPE_SUCCESS = "GET_LIST_TYPE_SUCCESS";
export const ADD_NEW_LIST_TYPE_VALUE = "ADD_NEW_LIST_TYPE_VALUE";
export const REMOVE_LIST_TYPE_VALUE = "REMOVE_LIST_TYPE_VALUE";
export const RESET_STATE = "RESET_STATE";

export const getListTypeData = (type, id) => (dispatch) => {
  switch (type) {
    case "specialities":
      dispatch(getSpecialities());
      break;
    case "affliations":
      dispatch(getAffiliations());
      break;
    case "certifications":
      dispatch(getCertifications());
      break;
    case "conditionsTreated":
      dispatch(getConditionsTreated());
      break;
    case "degrees":
      dispatch(getDegrees());
      break;
    case "departments":
      dispatch(getDepartments());
      break;
    case "orgTypes":
      dispatch(getOrgTypes());
      break;
    case "specialityChannels":
      dispatch(getSpecialityChannels(id));
      break;
    default:
      break;
  }
};

export const addNewListTypevalue = (data, type, id) => (dispatch) => {
  switch (type) {
    case "specialities":
      dispatch(addNewSpeciality(data));
      break;
    case "affliations":
      dispatch(addNewAffliation(data));
      break;
    case "certifications":
      dispatch(addNewCertification(data));
      break;
    case "conditionsTreated":
      dispatch(addNewConditionTreated(data));
      break;
    case "degrees":
      dispatch(addNewDegree(data));
      break;
    case "departments":
      dispatch(addNewDepartment(data));
      break;
    case "orgTypes":
      dispatch(addNewOrgType(data));
      break;
    case "specialityChannels":
      dispatch(addNewTemplateChannel(data, id));
      break;
    default:
      break;
  }
};

export const removeListTypeValue =
  (index, type, id, specialityId) => (dispatch) => {
    switch (type) {
      case "specialities":
        dispatch(removeSpeciality(index, id));
        break;
      case "affliations":
        dispatch(removeAflliations(index, id));
        break;
      case "certifications":
        dispatch(removeCertifications(index, id));
        break;
      case "conditionsTreated":
        dispatch(removeConditonsTreated(index, id));
        break;
      case "degrees":
        dispatch(removeDegree(index, id));
        break;
      case "departments":
        dispatch(removeDepartment(index, id));
        break;
      case "orgTypes":
        dispatch(removeOrgType(index, id));
        break;
      case "specialityChannels":
        dispatch(removeTemplateChannel(index, id));
        break;
      default:
        break;
    }
  };

export const resetState = () => (dispatch) => {
  dispatch({ type: RESET_STATE });
};

const getSpecialities = () => (dispatch) => {
  return apiMethods
    .post("Speciality")
    .then((response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error && error.message });
    });
};

const getAffiliations = () => (dispatch) => {
  apiMethods
    .get("affliations")
    .then((response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    });
};

const getCertifications = () => (dispatch) => {
  apiMethods
    .get("certifications")
    .then((response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    });
};

const getConditionsTreated = () => (dispatch) => {
  apiMethods
    .get("CondtionsTreated")
    .then((response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};

const getDegrees = () => (dispatch) => {
  apiMethods
    .get("Degree")
    .then((response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};

const getDepartments = () => (dispatch) => {
  apiMethods.get("DepartmentList").then(
    (response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

const getOrgTypes = () => (dispatch) => {
  apiMethods.get("OrganisationType").then(
    (response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

const getSpecialityChannels = (id) => (dispatch) => {
  apiMethods.post(`Speciality/GetTemplateChannlesBySpeciality?id=${id}`).then(
    (response) => {
      dispatch({ type: GET_LIST_TYPE_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

const addNewSpeciality = (data) => (dispatch) => {
  apiMethods.post("Speciality/Create", { name: data }).then(
    (response) => {
      dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error || error.data.Name,
      });
    }
  );
};

const addNewAffliation = (data) => (dispatch) => {
  apiMethods.post("Affliations", { name: data }).then(
    (response) => {
      dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error || error.data.Name,
      });
    }
  );
};

const addNewCertification = (data) => (dispatch) => {
  apiMethods.post("Certifications", { name: data }).then(
    (response) => {
      dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error || error.data.Name,
      });
    }
  );
};

const addNewConditionTreated = (data) => (dispatch) => {
  apiMethods.post("CondtionsTreated", { name: data }).then(
    (response) => {
      dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error || error.data.Name,
      });
    }
  );
};

const addNewDegree = (data) => (dispatch) => {
  apiMethods.post("Degree", { name: data }).then(
    (response) => {
      dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error || error.data.Name,
      });
    }
  );
};

const addNewDepartment = (data) => (dispatch) => {
  apiMethods.post("DepartmentList", { name: data }).then(
    (response) => {
      dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error || error.data.Name,
      });
    }
  );
};

const addNewOrgType = (data) => (dispatch) => {
  apiMethods.post("OrganisationType", { name: data }).then(
    (response) => {
      dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error || error.data.Name,
      });
    }
  );
};

const addNewTemplateChannel = (data, id) => (dispatch) => {
  apiMethods
    .post("Channel/CreateTemplateChannel", { name: data, specilityId: id })
    .then(
      (response) => {
        dispatch({ type: ADD_NEW_LIST_TYPE_VALUE, payload: response.data });
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error || error.data.Name,
        });
      }
    );
};

const removeSpeciality = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("Speciality/Delete", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

const removeAflliations = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("Affliations/Delete", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

const removeCertifications = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("Certifications/Delete", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

const removeConditonsTreated = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("CondtionsTreated/Delete", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

const removeDegree = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("Degree/Delete", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

const removeDepartment = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("DepartmentList/Delete", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

const removeOrgType = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("OrganisationType/Delete", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

const removeTemplateChannel = (index, id) => (dispatch) => {
  apiMethods.deleteRequest("Channel/DeleteTemplateChannel", { id: id }).then(
    (response) => {
      dispatch({ type: REMOVE_LIST_TYPE_VALUE, payload: index });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};
