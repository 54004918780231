import React, { Component } from "react";
import "./orgManageDeptAdmin.css";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import DeptAdminCard from "./deptAdminCard";
import {
  DEPTADMIN_ADD_REQUEST,
  DEPTADMIN_ADD_SUCCESS
} from "../../actions/departmentAdmin.action";
import { Link } from "react-router-dom";
import { ROUTE_ORG_CREATE_DEPTADMIN } from "../../containers/RouteConstants";

export default class orgManageDeptAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDepartment: null,
      selectedAdminForDelete: null,
      isCleared: false
    };

    this.handleNewContentNameChange = this.handleNewContentNameChange.bind(
      this
    );
    this.onKeyPress = this.onKeyPress.bind(this);
    this.search = this.search.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  handleNewContentNameChange = function(event) {
    this.setState({ enteredContentName: event.target.value });
  };

  onKeyPress = function(event) {
    if (event.key === "Enter") {
      const copyOfContent = Object.assign([], this.state.content);
      copyOfContent.push({ name: this.state.enteredContentName, id: null });
      this.setState({ content: copyOfContent, enteredContentName: "" });
    }
  };

  search = function(searchText) {
    this.props.deptAdminSearch(searchText);
  };

  handleSelectionChange = function(user) {
    if (user && user.length > 0) {
      this.setState({ userId: user[0].id });
    }
  };

  handleSelection = function(dept) {
    if (dept && dept.length > 0) {
      this.setState({ selectedDepartment: dept[0] });
      this.setState({ deptId: dept[0].id });
      this.props.getDepatmentAdmins(dept[0].id);
    } else {
      this.setState({ selectedDepartment: null });
    }
  };

  handleAddClick = function() {
    if (this.state.userId && this.state.deptId) {
      this.props.addDepartmentAdmin(this.state.userId, this.state.deptId);
      this.setState({ isCleared: false });
    }
  };

  handleCardClick(deptAdminId) {
    this.props.getDeptAdminDetails(deptAdminId);
  }

  handleRemoveClick(adminId) {
    if (adminId && this.state.deptId) {
      this.setState({ selectedAdminForDelete: adminId });
      this.props.removeDeptAdmin(adminId, this.state.deptId);
    }
  }

  render() {
    if (
      this.props.addAdminState === DEPTADMIN_ADD_SUCCESS &&
      this.state.isCleared === false
    ) {
      setTimeout(() => this.refs.asyncAdminTypeahead.getInstance().clear(), 10);
      this.setState({ isCleared: true });
    }

    return (
      <div className="manage-form">
        <div className="row">
          <div className="col-12 text-center">
            <div className="user-oval" />
          </div>
        </div>
        <div
          className="row mtb-60 "
          style={{ display: "flex", marginTop: "30px" }}
        >
          <div
            className="col-4"
            style={{ margin: "auto", textAlign: "center" }}
          >
            <Link
              className="manage-form-label align-middle pull-middle"
              to={ROUTE_ORG_CREATE_DEPTADMIN}
            >
              + Create new
            </Link>
          </div>
        </div>

        <div className="row mt-5 align-items-center h-100">
          <div className="col-md-5 my-auto ">
            <div className="form-group form-lable">
              <Typeahead
                options={this.props.myDepartments}
                labelKey="departmentName"
                name="depatment"
                onChange={selected => this.handleSelection(selected)}
                placeholder="Select department"
              />
            </div>
          </div>
          <div className="col-5 my-auto">
            <div className="form-group">
              <div className="form-group">
                <AsyncTypeahead
                  labelKey="displayText"
                  minLength={3}
                  allowNew={false}
                  onSearch={args => this.search(args)}
                  isLoading={this.props.isLoading}
                  options={this.props.searchResults}
                  onChange={selected => this.handleSelectionChange(selected)}
                  placeholder="Search..."
                  ref="asyncAdminTypeahead"
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <button
              className="btn form-btn-sm btn-sm"
              id="btnAdd"
              onClick={this.handleAddClick}
            >
              Add
            </button>
            {this.props.addAdminState === DEPTADMIN_ADD_REQUEST ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#00c6ff" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          <div className="row mt-10  no-gutter row-eq-height">
            {this.state.selectedDepartment !== null
              ? this.props.departmentAdmins.map((item, i) => {
                  return (
                    <DeptAdminCard
                      deptAdmin={item}
                      showEditDeptAdmin={this.handleCardClick}
                      index={i}
                      key={`depAdmin_${i}`}
                      removeDeptAdmin={this.handleRemoveClick}
                      selectedAdminForDelete={this.selectedAdminForDelete}
                    />
                  );
                })
              : ""}
          </div>
        </div>
        {/* <div className="row mb-16  no-gutter">
                    <div className="col-12">
                        {this.props.deptAdmin ?
                            <EditDeptAdmin
                                departmentAdmin={this.props.deptAdmin}
                                show={this.props.showEditForm}
                                hideEditForm={this.props.hideEditForm}
                                departments={this.props.myDepartments}
                                selectedDepartment={this.state.selectedDepartment}
                            /> : null}
                    </div>
                </div> */}
      </div>
    );
  }
}
