import React, { Component } from "react";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import "./organizationUser.css";

export default class organizationUser extends Component {
  render() {
    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>LETS SET UP YOUR USERS…</h3>
            <p>
              If you’re not a one person show, set up your more providers and
              admins here.
            </p>
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText ">
                  Providers are typically practitioners and/or content creators.
                  Admins have the ability to create users and post on providers
                  behalf. Providers can also be admins.
                  <br /> <br />
                  <p>
                    If you have a pic of them this is the place to put it (don’t
                    worry, it can be added or changed their own later.)
                  </p>
                </div>
                <div className="user-oval" />
              </div>
              <div className="col-8">
                <div className="form-body">
                  <div className="form-group form-lable">
                    <label className="form-lable">First name</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Last name</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Phone</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Email</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Location phone</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Location</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group">
                    <label className="form-lable">Degree</label>
                    <Typeahead
                      options={["MBBS", "MD", "B"]}
                      placeholder=""
                      multiple
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-lable">Specialty</label>
                    <Typeahead
                      options={["Gastroenterology", "Norovirus", "B"]}
                      placeholder=""
                      multiple
                    />
                  </div>
                  <div className="row mt-5 no-gutter">
                    <div className="col-md-4" />
                    <div className="col-md-2">
                      <div className="user-icon" />
                    </div>
                    <div className="col-md-6 align-self-center">
                      <span className="form-link" style={{ cursor: "pointer" }}>
                        Create another user
                      </span>
                    </div>
                  </div>

                  <div className="mt-5 text-right">
                    <button
                      type="submit"
                      className="btn form-btn"
                      id="btnLogin"
                    >
                      FINISH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
