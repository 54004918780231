import React, { Component } from "react";
import RegisterComponent from "../components/register/register";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  RegisterRequestParams,
  requestRegister,
  REGISTER_SUCCESS,
} from "../actions/register.actions";
import { ROUTE_PATIENT_SIGNUP } from "./RouteConstants";

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
  }

  register(registerParams) {
    console.log("registerParams", registerParams);
    const registerRequest = new RegisterRequestParams(
      registerParams.username,
      registerParams.password,
      registerParams.registrationType,
      registerParams.mobile
    );
    this.props.requestRegister(registerRequest);
  }

  componentDidUpdate() {
    if (this.props.state === REGISTER_SUCCESS) {
      this.props.history.push("/register-success");
    }
  }

  redirectToAppDownloadPage = () =>
    this.props.history.push(ROUTE_PATIENT_SIGNUP);

  render() {
    return (
      <div className="container-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12">&nbsp;</div>
            <div className="col-md-6 col-sm-12">
              <RegisterComponent
                register={this.register}
                state={this.props.state}
                erorr={this.props.error}
                redirectToAppDownloadPage={this.redirectToAppDownloadPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToProps = (state) => ({
  state: state.register.state,
  erorr: state.register.error,
});

const mapDispatchToProps = {
  requestRegister: requestRegister,
};

export default withRouter(
  connect(mapPropsToProps, mapDispatchToProps)(RegisterPage)
);
