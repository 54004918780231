import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_ERROR
} from "../actions/search.action";

const initialState = {
  isLoading: false,
  results: [],
  error: null
};

const searchResultReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case SEARCH_REQUEST: {
      newState = { ...state, isLoading: true, error: null };
      break;
    }
    case SEARCH_ERROR: {
      newState = { ...state, isLoading: false, error: action.message };
      break;
    }
    case SEARCH_SUCCESS: {
      newState = {
        ...state,
        isLoading: false,
        results: action.payload,
        error: null
      };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }

  return newState;
};

export default searchResultReducer;
