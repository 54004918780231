import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationProfile from "../components/organizationProfile/organizationProfile";
import {
  saveOrganizationProfile,
  saveOrganizationProfileopen,
  setOrganizationLogo,
  SAVE_ORG_RPOFILE_SUCCESS,
  getOrganizationProfile,
  SAVE_ORG_RPOFILE_IN_PROGRESS,
  getOrganizationType,
} from "../actions/organizationProfile.actions";
import { getSpecialities } from "../actions/personalProfile.actions";
import { getPrevStep } from "../actions/onboardingStatus.actions";
import {
  ROUTE_ORG_PROFILE,
  ROUTE_ORG_ABOUTME,
  ROUTE_MANAGE_ACCOUNTS,
} from "./RouteConstants";
import OrganizationProfileOpen from "../components/organizationProfile/OrganizationProfileOpen";
import { getOrganisationList } from "../actions/orgManageAccounts.action";
class OrganizationProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentWillMount = () => {
    this.props.getSpecialities();
    this.props.getOrganizationProfile();
    this.props.getOrganizationType();
  };
  xyz = (data) => {};
  saveOrgProfile = (data) => {
    this.props.saveOrganizationProfile(data);
  };
  onClose = () => {
    this.props.isClose();
  };
  saveOrgProfiles = (data) => {
    this.props.saveOrganizationProfileopen(data);
    this.setState({
      isOpen: false,
    });
    this.props.getOrganisationList();
    this.onClose();
  };

  getPrevStep = () => {
    this.props.getPrevStep();
  };

  saveOrganizationLogo = (event) => {
    if (event.target.files[0]) {
      var file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      this.props.setOrganizationLogo(file, formData);
    }
  };

  // shouldComponentUpdate () {
  //   xyz = () => {
  //     this.setState({ isOpen: false });
  //   };
  // }
  render() {
    // if (
    //   this.props.prevStep !== null &&
    //   this.props.prevStep.name === "ABOUTME" &&
    //   this.state.isOpen
    // ) {
    //   return <Redirect to={ROUTE_ORG_ABOUTME} />;
    // }
    const isSaveInProgress =
      this.props.saveState === SAVE_ORG_RPOFILE_IN_PROGRESS;

    // if (
    //   this.props.saveState === SAVE_ORG_RPOFILE_SUCCESS &&
    //   this.state.isOpen
    // ) {
    //   return <Redirect to={"/next-step" + ROUTE_ORG_PROFILE} />;
    // }
    // if (
    //   this.props.saveState === SAVE_ORG_RPOFILE_SUCCESS &&
    //   this.props.openPage
    // ) {
    //   this.onClose();
    //   this.props.getOrganisationList();
    // }
    if (
      this.props.saveState === SAVE_ORG_RPOFILE_SUCCESS &&
      this.props.openPage
    ) {
      this.props.getOrganisationList();
    }
    return (
      <div>
        {/* <OrganizationProfile
          specalities={this.props.specalities}
          saveOrganizationProfile={this.saveOrgProfile}
          saveOrganizationLogo={this.saveOrganizationLogo}
          initialValues={this.props.initialValues}
          orgLogo={this.props.orgLogo}
          isSaveInProgress={isSaveInProgress}
          orgTypes={this.props.orgTypes}
          getPrevStep={this.getPrevStep}
        /> */}

        {this.props.openPage === true &&
        isSaveInProgress === false &&
        this.state.isOpen ? (
          <>
            <OrganizationProfileOpen
              specalities={this.props.specalities}
              saveOrganizationProfile={this.saveOrgProfiles}
              saveOrganizationLogo={this.saveOrganizationLogo}
              initialValues={this.props.initialValues}
              orgLogo={this.props.orgLogo}
              isSaveInProgress={isSaveInProgress}
              orgTypes={this.props.orgTypes}
              getPrevStep={this.getPrevStep}
              onClose={this.onClose}
            />
          </>
        ) : (
          this.props.openPage !== true &&
          this.props.openPage !== false && (
            <div className="container-bg">
              <OrganizationProfile
                specalities={this.props.specalities}
                saveOrganizationProfile={this.saveOrgProfile}
                saveOrganizationLogo={this.saveOrganizationLogo}
                initialValues={this.props.initialValues}
                orgLogo={this.props.orgLogo}
                isSaveInProgress={isSaveInProgress}
                orgTypes={this.props.orgTypes}
                getPrevStep={this.getPrevStep}
              />
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveState: state.organizationProfile.saveState,
  specalities: state.personalProfile.specialities,
  initialValues: state.organizationProfile.initialValues,
  orgLogo: state.organizationProfile.orgLogo,
  orgTypes: state.organizationProfile.orgTypes,
  prevStep: state.onboardingStatus.prevStep,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialities: () => dispatch(getSpecialities()),
  saveOrganizationProfile: (data) => dispatch(saveOrganizationProfile(data)),
  saveOrganizationProfileopen: (data) =>
    dispatch(saveOrganizationProfileopen(data)),
  setOrganizationLogo: (file, data) =>
    dispatch(setOrganizationLogo(file, data)),
  getOrganizationProfile: () => dispatch(getOrganizationProfile()),
  getOrganizationType: () => dispatch(getOrganizationType()),
  getPrevStep: () => dispatch(getPrevStep("?currentStep=ORGANIZATION-PROFILE")),
  getOrganisationList: () => dispatch(getOrganisationList()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationProfilePage)
);
