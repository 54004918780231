import React from "react";

class LinkAuthoring extends React.Component {
  render() {
    return (
      <div>
        <div
          className={!this.props.showPreview ? "show flex_display" : "hidden"}
        >
          <textarea
            type="text"
            name="linkUrl"
            placeholder="Paste link here..."
            value={this.props.linkUrl}
            onChange={this.props.inputChange}
            onBlur={() => this.props.getLinkPreview()}
            className="link_input"
            style={{ borderRadius: "10px" }}
          />
        </div>

        <div className={this.props.showPreview ? "show" : "hidden"}>
          <div className="newPostTextArea left_float">
            <div className="preview_container ">
              <div
                className="preview_close"
                onClick={() => this.props.closePreview(false)}
              >
                <i className="fa fa-times" aria-hidden="true" />
              </div>
              <div>
                <img
                  className="preview_image left_float"
                  alt=""
                  src={
                    this.props.previewData ? this.props.previewData.image : null
                  }
                />
                <div className="preview_title">
                  {this.props.previewData ? this.props.previewData.title : null}
                </div>
              </div>
              <div className="preview_desc">
                {this.props.previewData
                  ? this.props.previewData.descp.length > 130
                    ? this.props.previewData.descp.substring(0, 129) + "..."
                    : this.props.previewData.descp
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LinkAuthoring;
