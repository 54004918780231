import {
  SET_ORGANIZATIONLOCATION_IN_PROGRESS,
  SET_ORGANIZATIONLOCATION_SUCCESS,
  UPDATE_ADD_ANOTHER_LOCATION_STATUS,
  ADD_ANOTHER_LOCATION,
  UPDATE_SAVE_STATE_TO_NULL,
  GET_ORGANIZATION_LOCATIONS_SUCCESS,
  UPDATE_CURRENT_LOCATION_INDEX,
  SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS,
  SET_ORGANIZATIONLOCATION_ERROR
} from "../actions/organizationLocation.action";

const intialState = {
  saveLocationState: null,
  addAnotherLocation: false,
  resetForm: false,
  saveState: null,
  locations: [],
  currentLocationIndex: -1
};

const organizationLocation = (state = intialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONLOCATION_IN_PROGRESS:
      return {
        ...state,
        saveLocationState: SET_ORGANIZATIONLOCATION_IN_PROGRESS
      };
    case SET_ORGANIZATIONLOCATION_SUCCESS:
      return {
        ...state,
        saveLocationState: SET_ORGANIZATIONLOCATION_SUCCESS
      };
    case SET_ORGANIZATIONLOCATION_ERROR:
      return {
        ...state,
        saveLocationState: SET_ORGANIZATIONLOCATION_ERROR
      };
    case UPDATE_ADD_ANOTHER_LOCATION_STATUS:
      return {
        ...state,
        addAnotherLocation: action.payload
      };
    case ADD_ANOTHER_LOCATION:
      return {
        ...state,
        saveLocationState: ADD_ANOTHER_LOCATION
      };
    case UPDATE_SAVE_STATE_TO_NULL:
      return {
        ...state,
        saveLocationState: null
      };
    case GET_ORGANIZATION_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload
      };
    case UPDATE_CURRENT_LOCATION_INDEX:
      return {
        ...state,
        currentLocationIndex: action.payload
      };
    case SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS:
      return {
        ...state,
        saveLocationState: SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS
      };
    default:
      return state;
  }
};

export default organizationLocation;
