import React from "react";
import "./sharePost.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Modal, Button } from "react-bootstrap";
import share from "../../img/Share.png";
import social from "../../img/Family.png";
import copy from "../../img/copy.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Sharepopbox extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    toast.success("This is a success toast!", {
      position: toast.POSITION.TOP_RIGHT,
      // Other options as needed
    });
  };
  copyLink = (prop) => {
    // const { dispatch } = this.props;
    console.log("share", prop);
    navigator.clipboard
      .writeText(
        `${window.location.origin}/webShareLink/${prop.id}/${prop.channelId}`
      )
      .then(
        () => {
          toast("Link Copied!", {
            position: "top-right",
            autoClose: 5000,
          });
          // invoked if the data is copied
          // alert("Link Copied");
          // this.setState({
          //   isCopy: true,
          // });
          // dispatch(setWebShareLink(prop));
        },
        () => {
          // handle data copy error
          alert("Failed to Copy Link");
          // this.setState({
          //   isCopy: false,
          // });
        }
      );
  };
  render() {
    return (
      <div style={{ top: "36%" }}>
        <Modal
          show={this.props.openshareopopbox}
          onHide={this.props.closeSharePopBox}
          style={{ top: "36%", height: "300px" }}
        >
          <div style={{ overflow: "hidden" }}>
            <Modal.Header closeButton>
              <Modal.Title>Share</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div onClick={() => this.props.openShareDialog(this.props.shareId)}>
              <img src={share} alt="like" className="post_option_like" />
              Share on Channels
            </div> */}
              <div className="col ">
                <a
                  onClick={() => this.props.openShareDialog(this.props.shareId)}
                  style={{
                    fontSize: "21px",
                    fontWeight: " 500",
                    cursor: "pointer",
                  }}
                >
                  <img src={share} alt="like" className="post_option_likes" />{" "}
                  Share on Channels
                </a>
              </div>
              {/* <div onClick={() => this.props.openSocialMedia(this.props.shareId)}>
              Share on Social Media
            </div> */}

              <div className="col ">
                <a
                  onClick={() => this.props.openSocialMedia(this.props.shareId)}
                  style={{
                    fontSize: "21px",
                    fontWeight: " 500",
                    cursor: "pointer",
                  }}
                >
                  <img src={social} alt="like" className="post_option_likes" />{" "}
                  Share on Social Media
                </a>
              </div>
              <div className="col ">
                {/* <button
                  onClick={() => this.copyLink(this.props.shareId.id)}
                  style={{ border: "none", background: "none" }}
                > */}
                <a
                  onClick={() =>
                    this.copyLink({
                      id: this.props.shareId.id,
                      channelId: this.props.shareId.channelId,
                    })
                  }
                  style={{
                    fontSize: "21px",
                    fontWeight: " 500",
                    cursor: "pointer",
                  }}
                >
                  <img src={copy} alt="like" className="post_option_likes" />{" "}
                  Copy Link
                </a>

                <ToastContainer />
                {/* </button> */}
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Sharepopbox;
// export default connect()(Sharepopbox);
