import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const GET_AFFILIATIONS_SUCCESS = "GET_AFFILIATIONS_SUCCESS";
export const GET_AFFILIATIONS_ERROR = "GET_AFFILIATIONS_ERROR";
export const GET_AFFILIATIONS_PROGRESS = "GET_AFFILIATIONS_PROGRESS";

export const getAffiliations = affliations => (dispatch, state) => {
  dispatch({ type: GET_AFFILIATIONS_PROGRESS });
  apiMethods
    .get("affliations")
    .then(response => {
      dispatch({ type: GET_AFFILIATIONS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
      dispatch({ type: GET_AFFILIATIONS_ERROR, error: error });
    });
};
