import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import apiMethods from "../middleware/baseApiService";
import "./WebShareLink.css";
import { isMobile } from "react-device-detect";
import { isIOS } from "react-device-detect";
import apple_link from "../img/apple-app-store-icon.png";
import google_link from "../img/en_badge_web_generic.png";
import { Button } from "react-bootstrap";
import Header from "../components/header/header";
import parse from "react-html-parser";
class WebShareLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      imageUrl: null,
    };
  }
  componentDidMount() {
    let url = apiMethods.imageUrl();
    this.setState({ imageUrl: url });
    const id = this.props.match.params.id;
    const channelId = this.props.match.params.channelId;
    console.log("id", id, channelId);
    apiMethods
      .get(`PostShare/${id}/GetPostLink?channelId=${channelId}`)
      .then((response) => {
        // console.log(response);
        this.setState({ data: response });
      });
  }
  getFormatedDate = (dateString) => {
    var localDate = new Date(dateString);
    return `${localDate.toLocaleString("en-us", {
      month: "long",
    })}${" "}${localDate.toLocaleString("en-us", {
      day: "numeric",
    })}${this.getSuffix(
      localDate.toLocaleString("en-us", { day: "numeric" })
    )}, ${localDate.toLocaleString("en-us", { year: "numeric" })}`;
  };
  getSuffix = (n) => [, "st", "nd", "rd"][(n % 100 >> 3) ^ 1 && n % 10] || "th";

  render() {
    console.log(`${this.state.data}
    `);
    console.log("webshare44", window.location.pathname.split("/")[1]);
    console.log("webshare", isMobile);
    return (
      <>
        <div style={{ position: "sticky", top: "0" }}>
          {isMobile ? (
            <div
              style={{
                display: "flex",
                padding: "10px",
                justifyContent: "end",
                // position: "relative",
                // zIndex: "10",
                border: "1px solid beige",
                backgroundColor: "#f8f9fa",
              }}
            >
              {isIOS ? (
                <a
                  href="https://apps.apple.com/us/app/healthswim/id1529080934"
                  target="_blank"
                >
                  {/* <img
                    alt="apple-app-store-icon.png"
                    src={apple_link}
                    style={{
                      height: "82px",
                      marginTop: "3%",
                      marginLeft: "5%",
                    }}
                  /> */}
                  {/* <Button class="btn btn-info">Download App</Button> */}
                  <button
                    style={{
                      backgroundColor: "#00c6ff",
                      border: "#00c6ff",
                      borderRadius: "8px",
                      minHeight: "35px",
                      color: "#fff",
                    }}
                  >
                    Download App
                  </button>
                </a>
              ) : (
                <a
                  href="https://apps.apple.com/us/app/healthswim/id1529080934"
                  target="_blank"
                >
                  {/* <img
                    alt="apple-app-store-icon.png"
                    src={google_link}
                    style={{
                      height: "82px",
                      marginTop: "3%",
                      marginLeft: "5%",
                    }}
                  /> */}
                  {/* <Button class="btn btn-info">Download App</Button> */}
                  <button
                    style={{
                      backgroundColor: "#00c6ff",
                      border: "#00c6ff",
                      borderRadius: "8px",
                      minHeight: "35px",
                      color: "#fff",
                    }}
                  >
                    Open App
                  </button>
                </a>
              )}
            </div>
          ) : (
            <div
              style={{
                height: "40px",
                // position: "sticky",
                // top: "0",
                // background: "white",
                // zIndex: "10",
              }}
            >
              <Header />

              {/* <ErrorDisplay /> */}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3%",
          }}
        >
          {/* <div style={{height:"124px"}}>
      <div className="first-navbar">

      </div>

     </div> */}
          <div
            style={{
              // width: "40%",
              display: "flex",

              justifyContent: "center",
              // marginLeft: "28%",
              // marginTop: "2%",
              borderRadius: "32px",
              overflow: "hidden",
            }}
            className="mainContainer-link"
          >
            <div
              style={{
                // width: "40%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // marginLeft: "28%",
                // marginTop: "2%",
                //   border: "1px solid beige",
                width: "643px",
                borderRadius: "14px",
                //  border:"20px solid #3ea2bf",
                borderWidth: "52px",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(to right, #dff3fc, #effafe, #ade4f9, #97ddf7) 1",

                height: "684px",
                // boxShadow:"0px 3px 8px rgba(0,0,0,.4)"
              }}
              className="webShare-link"
            >
              {" "}
              <div
                className="post_Header_Container"
                style={{ margin: "30px 10px 32px" }}
              >
                <div
                  className="post_Header"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    {this.state.data.data && this.state.data.data.profileImg ? (
                      <div
                        className="profile-image"
                        style={{
                          backgroundImage:
                            "url(" + this.state.data.data.profileImg + ")",
                        }}
                      />
                    ) : (
                      <div className="no-profile-image" />
                    )}
                    <div className="post-set" style={{ width: "80%" }}>
                      <div
                        className="post_info"
                        style={{ flexDirection: "row" }}
                      >
                        {this.state.data.data &&
                          this.state.data.data.authorShareText}
                        {/* {ReactHtmlParser(this.props.item.authorShareText)} */}
                        {/* Eye */}
                      </div>

                      <div className="post_date" style={{ lineBreak: "auto" }}>
                        <span style={{ marginRight: "7px" }}>
                          {this.state.data.data &&
                            this.state.data.data.channelName}
                          {/* Eye */}
                        </span>
                      </div>

                      <div className="post-channel">
                        <span>
                          {this.state.data.data &&
                            this.state.data.data.createDate &&
                            this.getFormatedDate(
                              this.state.data.data.createDate
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="post_like"> */}
                  {/* {" "}
                  <i
                    className="fa fa-heart post_like_image"
                    aria-hidden="true"
                  />{" "}
                  {this.state.data.data && this.state.data.data.postLikeCount
                    ? this.state.data.data.postLikeCount
                    : 0}{" "} */}
                  {/* </div> */}
                </div>
                <div className="post_header_text">
                  {/* {this.props.item.postShareJson.shareTitle} */}
                  {this.state.data.data && this.state.data.data.shareView}
                </div>
                <div className="post_data_divider mt-3" />
              </div>
              <div
                className="post_desc_container"
                style={{ overflow: " hidden auto", margin: " 0 10px 32px" }}
              >
                {this.state.data.data &&
                this.state.data.data.postType === "Video" ? (
                  <div className="post_image_container">
                    <div className="post_desc_header">
                      {parse(this.state.data.data.title)}
                    </div>
                    <video controls style={{ width: "480px" }} preload="none">
                      <source src={this.state.data.data.postVideo} />
                    </video>
                  </div>
                ) : null}
                {this.state.data.data &&
                this.state.data.data.postType === "Image" ? (
                  <div className="post_image_container">
                    <div className="post_desc_header">
                      {parse(this.state.data.data.title)}
                    </div>
                    <img src={this.state.data.data.postImage} alt="Post" />
                  </div>
                ) : null}
                {this.state.data.data &&
                this.state.data.data.postType === "Document" ? (
                  <div
                    className="post_image_container"
                    style={{ height: "538px" }}
                  >
                    <div className="post_desc_header">
                      {parse(this.state.data.data.title)}
                    </div>
                    <div className="post_pdf_container">
                      {" "}
                      <a
                        href={this.state.data.data.postDocument}
                        className="post_document_link"
                      >
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={this.state.data.data.postDocument}
                            // plugins={[this.defaultLayoutPluginInstance]}
                          />
                        </Worker>
                      </a>
                    </div>
                  </div>
                ) : null}
                {this.state.data.data &&
                this.state.data.data.postType === "Link" ? (
                  <div>
                    {(this.state.data.data &&
                      this.state.data.data.metaData &&
                      this.state.data.data.metaData.image) !== "" &&
                      (this.state.data.data &&
                        this.state.data.data.metaData &&
                        this.state.data.data.metaData.url) !== "" && (
                        <div className="post_desc_image">
                          <a
                            className=""
                            href={this.state.data.data.metaData.url}
                            target="_blank"
                          >
                            <img
                              href="#"
                              // src={
                              //   this.state.validateImageUrl
                              //     ? this.props.item.metaDataValue.image
                              //     : `${this.state.imageUrl}def${Math.floor(
                              //         Math.random() * 70 + 1
                              //       )}.jpeg`
                              // }
                              src={this.state.data.data.metaData.image}
                              alt="doctor"
                            />
                          </a>
                        </div>
                      )}
                    {(this.state.data.data &&
                      this.state.data.data.metaData &&
                      this.state.data.data.metaData.image) !== "" &&
                      (this.state.data.data &&
                        this.state.data.data.metaData &&
                        this.state.data.data.metaData.url) === "" && (
                        <div className="post_desc_image">
                          <img
                            href="#"
                            src={this.state.data.data.metaData.image}
                            alt="doctor1"
                          />
                        </div>
                      )}

                    {(this.state.data.data &&
                      this.state.data.data.metaData &&
                      this.state.data.data.metaData.image) === "" && (
                      <div className="post_desc_image">
                        <img
                          href="#"
                          src={`${this.state.imageUrl}def${Math.floor(
                            Math.random() * 70 + 1
                          )}.jpeg`}
                          //images/def14.jpeg
                          // src="images/def1.jpeg"
                          alt="doctor"
                        />
                      </div>
                    )}
                    <div className="post_desc_header">
                      {this.state.data.data &&
                        this.state.data.data.metaData &&
                        parse(this.state.data.data.metaData.title)}
                    </div>
                    {
                      this.state.data.data &&
                        this.state.data.data.metaData &&
                        this.state.data.data.metaData.descp !==
                          "No Description found" && (
                          // this.props.item.metaDataValue.url !== "" && (
                          <div className="post_desc_data">
                            <div className="post_desc">
                              <a
                                className=""
                                href={this.state.data.data.metaData.url}
                                target="_blank"
                                style={{ textDecoration: "none" }}
                              >
                                <div className="post_desc_text">
                                  {this.state.data.data.metaData.descp}...
                                </div>
                              </a>
                            </div>
                          </div>
                        )
                      // )
                    }
                    {/* {this.props.item.metaDataValue.descp !== "No Description found" &&
                this.props.item.metaDataValue.url === "" && (
                  <div className="post_desc_data">
                    <div className="post_desc">
                      <div className="post_desc_text">
                        {this.props.item.metaDataValue.descp}...
                      </div>
                    </div>
                  </div>
                )} */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// export default WebShareLink;
// const mapStateToProps = function (state) {
//   console.log("st", state);
//   return {
//     WebShareId: state.webShareId,
//   };
// };
export default withRouter(WebShareLink);
