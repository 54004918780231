import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { Modal } from "react-bootstrap";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";

class ProviderModal extends React.Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        dialogClassName="modal-size"
      >
        <div className="manage-form-header">
          <div style={{ float: "right" }}>
            <button onClick={this.props.handleClose}>X</button>
          </div>
          <div className="manage-form-title align-middle">
            {`${this.props.subscriptionDetailsProvider.firstName}${" "}${
              this.props.subscriptionDetailsProvider.lastName
            }`}
          </div>
        </div>

        <div className="manage-form-container">
          <div className="row">
            <div className="col-12  d-flex">
              <div className="col-3">
                <div className="d-flex" style={{ marginTop: "25%" }}>
                  {this.props.subscriptionDetailsProvider.profileImg ? (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage:
                          "url(" +
                          this.props.subscriptionDetailsProvider.profileImg +
                          ")"
                      }}
                    />
                  ) : (
                    <div className="user-oval" />
                  )}
                </div>
              </div>
              <div className="form-body col-9" style={{ margin: "auto" }}>
                <Formik
                  ref={this.formik}
                  initialValues={{
                    firstName:
                      this.props.subscriptionDetailsProvider.firstName || "",
                    lastName:
                      this.props.subscriptionDetailsProvider.lastName || "",
                    email: this.props.subscriptionDetailsProvider.email || "",
                    phone:
                      this.props.subscriptionDetailsProvider.contactPhone || "",
                    degrees:
                      this.props.subscriptionDetailsProvider.degree &&
                      this.props.subscriptionDetailsProvider.degree[0] === "["
                        ? JSON.parse(
                            this.props.subscriptionDetailsProvider.degree
                          )
                        : [],
                    specialities:
                      this.props.subscriptionDetailsProvider.specialities || [],
                    npiNumber:
                      this.props.subscriptionDetailsProvider.npInumber || "",
                    certifications:
                      this.props.subscriptionDetailsProvider
                        .boardCertifications &&
                      this.props.subscriptionDetailsProvider
                        .boardCertifications[0] === "["
                        ? JSON.parse(
                            this.props.subscriptionDetailsProvider
                              .boardCertifications
                          )
                        : [],
                    affiliations:
                      this.props.subscriptionDetailsProvider.affiliations &&
                      this.props.subscriptionDetailsProvider.affiliations[0] ===
                        "["
                        ? JSON.parse(
                            this.props.subscriptionDetailsProvider.affiliations
                          )
                        : [],
                    conditionsTreated:
                      this.props.subscriptionDetailsProvider.conditionTreated &&
                      this.props.subscriptionDetailsProvider
                        .conditionTreated[0] === "["
                        ? JSON.parse(
                            this.props.subscriptionDetailsProvider
                              .conditionTreated
                          )
                        : []
                  }}
                  onSubmit={this.onSubmit}
                  render={this.renderForm}
                  enableReinitialize={true}
                />
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-3"></div>
              <div className="col-3">
                <button
                  className="btn form-btn-sm btn-sm"
                  id="btnActivate"
                  onClick={() => this.props.activateDeactivateAccount("?providerId=" + this.props.subscriptionDetailsProvider.id + "&isActive=" + !this.props.subscriptionDetailsProvider.isActive)}
                >
                  {this.props.subscriptionDetailsProvider.isActive ? "Deactivate" : "Activate" }
                </button>
              </div>
              <div className="col-3">
                <button
                  className="btn form-btn-sm btn-sm"
                  id="btnDelete"
                  onClick={() => this.props.deleteAccount("?providerId=" + this.props.subscriptionDetailsProvider.id)}
                >
                  Delete
                </button>
              </div>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderForm = props => {
    const { handleSubmit, values } = props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-sm-6">
                <label className="form-lable">First name</label>
                <Field
                  type="text"
                  value={values.firstName}
                  name="firstName"
                  className="form-control form-input"
                  disabled
                />
                <span className="error_text">
                  <ErrorMessage name="firstName" />
                </span>
              </div>
              <div className="col-sm-6">
                <label className="form-lable">Last name</label>
                <Field
                  type="text"
                  value={values.lastName}
                  name="lastName"
                  className="form-control form-input"
                  disabled
                />
                <span className="error_text">
                  <ErrorMessage name="lastName" />
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-sm-6">
                <label className="form-lable">Phone</label>
                <Field
                  className="form-control form-input"
                  name="phone"
                  disabled
                  value={values.phone}
                />
              </div>
              <div className="col-sm-6">
                <label className="form-lable">Email</label>
                <Field
                  type="text"
                  value={values.email}
                  name="email"
                  className="form-control form-input"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-12">
                <label className="form-lable">Degree</label>
                <Typeahead
                  clearButton
                  options={[]}
                  selected={values.degrees}
                  labelKey="name"
                  placeholder=""
                  name="degrees"
                  multiple
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-12">
                <label className="form-lable">Speciality</label>
                <Typeahead
                  options={[]}
                  placeholder=""
                  name="specialities"
                  selected={values.specialities}
                  labelKey="name"
                  multiple
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-12">
                <label className="form-lable">NPI number</label>
                <Field
                  type="text"
                  value={values.npiNumber}
                  name="npiNumber"
                  className="form-control form-input"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-12">
                <label className="form-lable">Board certifications</label>
                <Typeahead
                  options={[]}
                  selected={values.certifications}
                  placeholder=""
                  labelKey="name"
                  name="certifications"
                  multiple
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-12">
                <label className="form-lable">Affiliations</label>
                <Typeahead
                  options={[]}
                  selected={values.affiliations}
                  placeholder=""
                  name="affiliations"
                  labelKey="name"
                  multiple
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-12">
                <label className="form-lable">Conditions Treated</label>
                <Typeahead
                  options={[]}
                  selected={values.conditionsTreated}
                  placeholder=""
                  name="conditionsTreated"
                  labelKey="name"
                  multiple
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
}

export default ProviderModal;
