import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Route, BrowserRouter } from "react-router-dom";
import App from "./App";
import IndexPage from "./IndexPage";
import RegisterPage from "./RegisterPage";
import RegisterInfoPage from "./RegisterInfoPage";
import Header from "../components/header/header";
import Footer from "../components/Footer/footer";
import Sidebar from "../components/sideBar/sidebar";
import ProfessionalLifePage from "../containers/ProfessionalLifePage";
import ChannelSetupPage from "../containers/ChannelSetupPage";
import PublishContentPage from "../containers/PublishContentPage";
import FindContent from "../containers/FindContentPage";
import PrivacyPolicy from "../containers/PrivacyPolicyPage";
import PrivacyPolicys from "../components/privacyPolicy/privacypolicys";
import privacyPolicy from "../components/privacyPolicy/privacyPolicy";
import TermsAndCondition from "../containers/TermsAndConditionPage";
import Termsofuse from "../components/termsAndCondition/termsAndCondition";
import ErrorDisplay from "../components/errorDisplay/errorDisplay";
import DashboardPage from "../containers/DashboardPage";
import OrganizationProfile from "../containers/OrgnizationProfilePage";
import OrganizationLocation from "../containers/OrganizationLocationPage";
import LoginPage from "../containers/LoginPage";
import LoginCallbackPage from "../containers/LoginCallbackPage";
import ProfileEditPage from "../containers/ProfileEditPage";
import PersonalProfilePage from "../containers/PersonalProfilePage";
import ManageOrgAccountPage from "../containers/ManageOrgAccountPage";
import RegisterSuccessPage from "../containers/RegisterationSuccessPage";
import ChangePassword from "../components/changePassword";
import OnboardingControllerPage from "./OnboardingControllerPage";
import OrganizationUsersPage from "./OrganizationUsersPage";
import AboutMePage from "./AboutMePage";
import OrganizationDepartmentPage from "./organizationDepartmentPage";
import OrganizationInviteAdminPage from "./OrganizationInviteAdminPage";
import OrganizationInviteSubadminPage from "./OrganizationInviteSubadminPage";
import OrganizationInviteProviderPage from "./OrganizationInviteProviderPage";
import OrgManageLocationPage from "./OrgManageLocationPage";
import OrgManageDeptAdminPage from "./OrgManageDeptAdminPage";
import OrgManageProviderPage from "./OrgManageProviderPage";
import AcceptInvitePage from "./AcceptInvitePage";
import OrgManageMyProfilePage from "./OrgManageMyProfilePage";
import OrgManageProfilePage from "./OrgManageProfilePage";
import OrgManageDepartmentPage from "./OrgManageDepartmentPage";
import LogOutPage from "./LogOutPage";
import OrgLocationFromManage from "./orgLocationFromManagePage";
import OrgCreateProviderPage from "./OrgCreateProviderPage";
import ClientProfilePage from "./ClientProfilePage";
import SubscribeChannelPage from "./subscribeChannelPage";
import ManageMyContentPage from "./ManageMyContentPage";
import ClientProfileEditPage from "./clientProfileEditPage";
import PostSharePage from "./PostSharePage";
import ManageListsPage from "./OrgManageListsPage";
import MalilingListPage from "./orgMailingListPage";
import mobilePrivacyPolicy from "../components/privacyPolicy/mobileprivacyPolicy";
import mobileTermsAndCondition from "../components/termsAndCondition/mobiletermsAndCondition";
import termsAndConditionPage from "../components/termsAndCondition/termsAndConditionPage";
// TODO : Support different layouts in future - refer - https://gist.github.com/avinmathew/e82fe7e757b20cb337d5219e0ab8dc2c
import {
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_PERSONAL_PROFILE,
  ROUTE_PROFESSIONAL_INFO,
  ROUTE_CHANNEL_SETUP,
  ROUTE_FIND_CONTENT,
  ROUTE_PUBLISH_CONTENT,
  ROUTE_DASHBOARD,
  ROUTE_ORG_PROFILE,
  ROUTE_ORG_LOCATION,
  ROUTE_ORG_ABOUTME,
  ROUTE_ORG_USER,
  ROUTE_ORG_DEPARTMENT,
  ROUTE_ORG_INVITE_ADMIN,
  ROUTE_ORG_INVITE_SUBADMIN,
  ROUTE_ORG_INVITE_PROVIDER,
  ROUTE_ORG_MANAGE_LOCATION,
  ROUTE_ORG_MANAGE_DEPTADMIN,
  ROUTE_ORG_MANAGE_PROVIDERS,
  ROUTE_ACCEPT_INVITE,
  ROUTE_LOGIN,
  ROUTE_ORG_MANAGE_MYPROFILE,
  ROUTE_ORG_MANAGE_PROFILE,
  ROUTE_ORG_MANAGE_DEPARTMENTS,
  ROUTE_ORG_LOCATION_FROM_MANAGE,
  ROUTE_ORG_INVITE_PROVIDER_FROM_MANAGE,
  ROUTE_ORG_MANAGE_ADMIN,
  ROUTE_ORG_CREATE_DEPTADMIN,
  ROUTE_CLIENT_PROFILE,
  ROUTE_SUBSCRIBE_CHANNEL,
  ROUTE_MANAGE_MY_CONTENT,
  ROUTE_CLIENT_PROFILE_EDIT,
  ROUTE_ORG_CREATE_ADMIN,
  ROUTE_MANAGE_CHANNELS,
  ROUTE_POST_SHARE,
  ROUTE_MANAGE_LISTS,
  ROUTE_MAILING_LIST,
  ROUTE_MANAGE_ACCOUNTS,
  ROUTE_PATIENT_SIGNUP,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_MOBILE_PRIVACY,
  ROUTE_MOBILE_TERMS,
} from "./RouteConstants";
import { EnsureLoggedIn } from "../components/ensureLoggedIn/ensuredLoggedIn";
import OrgManageAdminPage from "./OrgManageAdminPage";
import OrgCreateAdminPage from "./OrgCreateAdminPage";
import OrgCreateDeptAdminPage from "./OrgCreateDeptAdminPage";
import ManageChannelsPage from "./ManageChannelsPage";
import ManageAccountPage from "./orgManageAccountPage";
import AppDownloadPage from "./appDownloadPage";
import ResendOtpPage from "./ResendOtpPage";
import Forgotpassword from "./Forgotpassword";
import CheckEmailNotification from "./CheckEmailNotification";
import EmailResetPassword from "./EmailResetPassword";
import SuccessResetPassword from "./SuccessResetPassword";
import Acceptinvitesuccepage from "../components/Acceptinvitesuccepage";
import WebShareLink from "./WebShareLink";
import DisableAccount from "../components/disableAccount/disableAcoount";
import DeleteAccount from "../components/deleteAccount/deleteAccount";
import LoginPagePopup from "./LoginPagePopup";
import RegisterInfoPagePopup from "./RegisterInfoPagePopup";
import PremiumPage from "./PremiumPage";
import OtpPage from "./OtpPage";
import PremiumInfoPage from "./PremiumInfoPage";

const Root = ({ store }) => (
  <Provider store={store}>
    <BrowserRouter>
      <>
        <Route
          exact
          path="/webShareLink/:id/:channelId"
          component={WebShareLink}
        />
        <div>
          {/* <Route exact path="/webShareLink/:id" component={WebShareLink} /> */}
          {window.location.pathname !== "/mobile-privacy" &&
          window.location.pathname !== "/mobile-terms" &&
          window.location.pathname !== "/reset-password" &&
          window.location.pathname !== "/success-reset-password" &&
          // window.location.pathname !== "/loginPage" &&
          window.location.pathname.split("/")[1] !== "webShareLink" ? (
            <div
              style={{
                height: "124px",
                // position: "sticky",
                // top: "0",
                // background: "white",
                // zIndex: "10",
              }}
            >
              <Header />

              <ErrorDisplay />
            </div>
          ) : null}
          <Route exact path="/reset-password" component={EmailResetPassword} />
          <Route
            exact
            path="/success-reset-password"
            component={SuccessResetPassword}
          />
          <Route
            exact
            path={ROUTE_MOBILE_PRIVACY}
            component={mobilePrivacyPolicy}
          />
          <Route
            exact
            path={ROUTE_MOBILE_TERMS}
            component={mobileTermsAndCondition}
          />
          <Route exact path="/" component={App} />
          <Route exact path="/index" component={IndexPage} />
          <Route
            exact
            path="/NWJwGyXSj8rCxnvOO4BFz3vERlXCHPfKyQJuPzEBE5c="
            component={RegisterPage}
          />
          <Route exact path="/registerinfo" component={RegisterInfoPage} />
          {/* <Route exact path="/registerinfo" component={RegisterPage} /> */}
          <Route
            exact
            path="/registerinfoPage"
            component={RegisterInfoPagePopup}
          />
          {/* <Route exact path="/register-success" component={OtpPage} /> */}
          <Route
            exact
            path="/register-success"
            component={RegisterSuccessPage}
          />
          <Route exact path="/register-resendotp" component={ResendOtpPage} />
          <Route
            exact
            path={ROUTE_PROFESSIONAL_INFO}
            component={ProfessionalLifePage}
          />
          <Route
            exact
            path={ROUTE_CHANNEL_SETUP}
            component={EnsureLoggedIn(ChannelSetupPage)}
          />
          <Route
            exact
            path={ROUTE_PUBLISH_CONTENT}
            component={PublishContentPage}
          />
          <Route
            exact
            path={ROUTE_FIND_CONTENT}
            component={EnsureLoggedIn(FindContent)}
          />
          <Route exact path={ROUTE_PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route
            exact
            path={ROUTE_TERMS_AND_CONDITIONS}
            component={EnsureLoggedIn(TermsAndCondition)}
          />
          <Route
            exact
            path="/termsofuse"
            component={EnsureLoggedIn(Termsofuse)}
          />
          <Route
            exact
            path={ROUTE_DASHBOARD}
            component={EnsureLoggedIn(DashboardPage)}
          />
          <Route exact path="/privacyPolicys" component={PrivacyPolicys} />
          <Route
            exact
            path="/privacyPolicy"
            component={EnsureLoggedIn(PrivacyPolicy)}
          />
          <Route
            exact
            path="/termsAndConditionPage"
            component={termsAndConditionPage}
          />
          <Route
            exact
            path={ROUTE_ORG_PROFILE}
            component={EnsureLoggedIn(OrganizationProfile)}
          />
          <Route
            exact
            path={ROUTE_ORG_LOCATION}
            component={EnsureLoggedIn(OrganizationLocation)}
          />
          <Route exact path={ROUTE_LOGIN} component={LoginPage} />
          <Route exact path="/forgotpassword" component={Forgotpassword} />
          <Route
            exact
            path="/forgot-password"
            component={CheckEmailNotification}
          />
          <Route exact path="/callback" component={LoginCallbackPage} />
          <Route
            exact
            path="/profile-edit"
            component={EnsureLoggedIn(ProfileEditPage)}
          />
          <Route
            exact
            path={ROUTE_PERSONAL_PROFILE}
            component={EnsureLoggedIn(PersonalProfilePage)}
          />
          <Route
            exact
            path={ROUTE_CHANGE_PASSWORD}
            component={EnsureLoggedIn(ChangePassword)}
          />
          <Route
            exact
            path="/manage-account"
            component={EnsureLoggedIn(ManageOrgAccountPage)}
          />
          <Route
            exact
            path="/acceptinvitesuccess"
            component={Acceptinvitesuccepage}
          />
          <Route
            exact
            path="/next-step/:completedStep"
            component={OnboardingControllerPage}
          />
          {/* <Route exact path="/webShareLink/:id" component={WebShareLink} /> */}
          <Route
            exact
            path={ROUTE_ORG_DEPARTMENT}
            component={OrganizationDepartmentPage}
          />
          <Route
            exact
            path={ROUTE_ORG_USER}
            component={EnsureLoggedIn(OrganizationUsersPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_ABOUTME}
            component={EnsureLoggedIn(AboutMePage)}
          />
          <Route
            exact
            path={ROUTE_ORG_INVITE_ADMIN}
            component={EnsureLoggedIn(OrganizationInviteAdminPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_INVITE_SUBADMIN}
            component={EnsureLoggedIn(OrganizationInviteSubadminPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_INVITE_PROVIDER}
            component={EnsureLoggedIn(OrganizationInviteProviderPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_MANAGE_LOCATION}
            component={EnsureLoggedIn(OrgManageLocationPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_MANAGE_DEPTADMIN}
            component={EnsureLoggedIn(OrgManageDeptAdminPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_MANAGE_PROVIDERS}
            component={EnsureLoggedIn(OrgManageProviderPage)}
          />
          <Route
            exact
            path={ROUTE_ACCEPT_INVITE}
            component={AcceptInvitePage}
          />
          <Route
            exact
            path={ROUTE_ORG_MANAGE_DEPARTMENTS}
            component={EnsureLoggedIn(OrgManageDepartmentPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_MANAGE_MYPROFILE}
            component={EnsureLoggedIn(OrgManageMyProfilePage)}
          />
          <Route
            exact
            path={ROUTE_ORG_MANAGE_PROFILE}
            component={EnsureLoggedIn(OrgManageProfilePage)}
          />
          <Route
            exact
            path="/disableAccount"
            component={EnsureLoggedIn(DisableAccount)}
          />
          <Route
            exact
            path="/deleteAccount"
            component={EnsureLoggedIn(DeleteAccount)}
          />
          <Route exact path="/logout" component={LogOutPage} />
          <Route
            exact
            path={ROUTE_ORG_LOCATION_FROM_MANAGE}
            component={EnsureLoggedIn(OrgLocationFromManage)}
          />
          <Route
            exact
            path={ROUTE_ORG_INVITE_PROVIDER_FROM_MANAGE}
            component={EnsureLoggedIn(OrgCreateProviderPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_MANAGE_ADMIN}
            component={EnsureLoggedIn(OrgManageAdminPage)}
          />
          <Route
            exact
            path={ROUTE_CLIENT_PROFILE}
            component={EnsureLoggedIn(ClientProfilePage)}
          />
          <Route
            exact
            path={ROUTE_SUBSCRIBE_CHANNEL}
            component={EnsureLoggedIn(SubscribeChannelPage)}
          />
          <Route
            exact
            path={ROUTE_MANAGE_MY_CONTENT}
            component={EnsureLoggedIn(ManageMyContentPage)}
          />
          <Route
            exact
            path={ROUTE_CLIENT_PROFILE_EDIT}
            component={EnsureLoggedIn(ClientProfileEditPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_CREATE_ADMIN}
            component={EnsureLoggedIn(OrgCreateAdminPage)}
          />
          <Route
            exact
            path={ROUTE_ORG_CREATE_DEPTADMIN}
            component={EnsureLoggedIn(OrgCreateDeptAdminPage)}
          />
          <Route
            exact
            path={ROUTE_MANAGE_CHANNELS}
            component={EnsureLoggedIn(ManageChannelsPage)}
          />
          <Route exact path={ROUTE_POST_SHARE} component={PostSharePage} />
          <Route
            exact
            path={ROUTE_MANAGE_LISTS}
            component={EnsureLoggedIn(ManageListsPage)}
          />
          <Route
            exact
            path={ROUTE_MAILING_LIST}
            component={EnsureLoggedIn(MalilingListPage)}
          />
          <Route
            exact
            path={ROUTE_MANAGE_ACCOUNTS}
            component={EnsureLoggedIn(ManageAccountPage)}
          />
          <Route
            exact
            path={ROUTE_PATIENT_SIGNUP}
            component={AppDownloadPage}
          />
          <Route exact path="/loginPage" component={LoginPagePopup} />
          <Route
            exact
            path="/PREMIUM-INFO"
            component={EnsureLoggedIn(PremiumPage)}
          />
          <Route
            exact
            path="/premiumInfoPage"
            component={EnsureLoggedIn(PremiumInfoPage)}
          />
          {window.location.pathname !== "/mobile-privacy" &&
          window.location.pathname !== "/mobile-terms" ? (
            <div>{/* <Footer /> */}</div>
          ) : null}
          {/* <DevTools /> */}
        </div>
      </>
    </BrowserRouter>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
