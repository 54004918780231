import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./index.css";
import { connect } from "react-redux";
import { changePassword } from "../../actions/changepassword.action";
import { showError } from "../../actions/errorDisplay.actions";
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.toggleShow = this.toggleShow.bind(this);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
    };
  }
  onSubmit = (values) => {
    if (this.props.password === values.currentPassword) {
      if (values.password === values.confirmPassword) {
        if (this.props.password === values.password) {
          this.props.showError("Current password and old password is same");
        } else {
          this.props.changePassword({
            password: values.password,
            email: localStorage.getItem("username"),
          });
          this.props.history.push("/dashboard");
        }
      } else {
        this.props.showError("Password and Confirm Password Should be same");
      }
    } else {
      this.props.showError("Please enter your correct current password");
    }
  };
  toggleShow = (field, value) => {
    this.setState({ showPassword: !value });
    this.setState({ showConfirmPassword: !value });
  };
  validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required"),
  });
  renderForm = (props) => {
    const { handleSubmit } = props;
    return (
      <form onSubmit={handleSubmit}>
        {console.log("this.props.email", this.props)}
        <div className="form-group form-lable">
          <label className="form-lable">Current Password</label>
          <Field
            type={this.state.showPassword ? "text" : "password"}
            name="currentPassword"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="password" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Password</label>
          <Field
            type={this.state.showPassword ? "text" : "password"}
            name="password"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="password" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Confirm Password</label>
          <Field
            type={this.state.showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            className="form-control form-input"
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="confirmPassword" />
          </span>
        </div>
        <div className="form-group form-lable">
          <input
            type="checkbox"
            id="vehicle1"
            name="vehicle1"
            value="Bike"
            onClick={() => {
              this.toggleShow("password", this.state.showPassword);
            }}
          ></input>
          <label className="form-lable" style={{ marginLeft: "10px" }}>
            Show Password
          </label>
        </div>
        <div className="mt-5 text-right">
          <button
            type="submit"
            className="btn form-btn"
            id="btnLogin"
            disabled={this.props.isSaveInProgress}
          >
            Save
            {this.props.isSaveInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };

  render() {
    let initialValues = {
      password: "",
      confirmPassword: "",
      currentPassword: "",
    };

    return (
      <div className="container" style={{ width: "54%" }}>
        <div className="form-rectangle">
          <div className="form-header">
            <h3>Change Password</h3>
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-12">
                <div className="form-body">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                    validationSchema={this.validationSchema}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(changePassword(data)),
  showError: (data) => dispatch(showError(data)),
});
const mapStateToProps = (state) => ({
  email: state.login.email,
  password: state.login.password,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
