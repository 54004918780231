import React from "react";
import ManageListsModal from "./manageListsModal";

class ManageLists extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>LIST TYPES</h3>
          </div>
          <div className="form-container h-100">
            <div className="form-body">
              {this.props.listTypes.map((item, index) => {
                return (
                  <div className="row">
                    <div className="col-8" style={{ display: "flex" }}>
                      <label
                        className="form-lable"
                        style={{ margin: "auto auto auto 0" }}
                      >
                        {item.name}
                      </label>
                    </div>
                    <div style={{ display: "flex" }} className="col-4">
                      <button
                        type="button"
                        className="btn form-btn"
                        onClick={() =>
                          this.props.openModal(item.value, item.name)
                        }
                        style={{ margin: "10px 0 0 auto" }}
                      >
                        Manage
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ManageListsModal
          show={this.props.show}
          onHide={this.props.handleClose}
          type={this.props.type}
          name={this.props.name}
          handleClose={this.props.handleClose}
          listTypeData={this.props.listTypeData}
          addNewListTypevalue={this.props.addNewListTypevalue}
          removeListTypeValue={this.props.removeListTypeValue}
          specialities={this.props.specialities}
          getListTypeData={this.props.getListTypeData}
          resetState={this.props.resetState}
        />
      </div>
    );
  }
}

export default ManageLists;
