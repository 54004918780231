import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AboutMe from "../components/aboutMe/aboutMe";
import {
  savePersonalProfile,
  setProfilePic,
  getPersonalProfile,
  SAVE_PERSONAL_INFO_IN_PROGRESS,
  updateProfileImg,
} from "../actions/personalProfile.actions";
import { updateOnBoardingStatus } from "../actions/onboardingStatus.actions";
import { getMyDepartments } from "../actions/department.action";

class OrgManageMyProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "edit",
    };
  }

  componentWillMount() {
    this.props.getPersonalProfile(this.props.email);
    this.props.getMyDepartments();
  }

  saveProfile = (data) => {
    this.props.savePersonalProfile(data);
  };

  saveProfieImg = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let formData = new FormData();
      let data = {};
      formData.append("file", event.target.files[0]);
      data["formData"] = formData;
      data["file"] = file;
      this.props.setProfilePic(data);
    }
  };

  render() {
    const isPersonalInfoSaveInProgress =
      this.props.saveState === SAVE_PERSONAL_INFO_IN_PROGRESS;

    // if (this.props.saveState === SAVE_PERSONAL_INFO_SUCCESS) {
    //     return <Redirect to={"/next-step" + ROUTE_ORG_ABOUTME} />;
    // }

    return (
      <div className="container-white-bg">
        <div className="container">
          <AboutMe
            saveProfile={this.saveProfile}
            saveProfileImg={this.saveProfieImg}
            profileImg={this.props.profileImg}
            personalProfile={this.props.personalProfile}
            isSaveInProgress={isPersonalInfoSaveInProgress}
            updateProfileImg={this.props.updateProfileImg}
            mode={this.state.mode}
            orgProfile={this.props.orgProfile}
            myDepartments={this.props.myDepartments}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveState: state.personalProfile.saveState,
  profileImg: state.personalProfile.profileImg,
  personalProfile: state.personalProfile.profile,
  orgProfile: state.personalProfile.orgProfile,
  myDepartments: state.departmentReducer.myDepartments,
  email: state.login.email,
});

const mapDispatchToProps = {
  savePersonalProfile,
  setProfilePic,
  updateOnBoardingStatus,
  getPersonalProfile,
  updateProfileImg,
  getMyDepartments,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrgManageMyProfilePage)
);
