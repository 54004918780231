import React, { Component } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Modal } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import csc from "country-state-city";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";

export default class orgaLocationFrm extends Component {
  constructor(props) {
    super(props);
    this.locationForm = React.createRef();
  }
  onSubmit = (values) => {
    if (values.Country.length === 1) {
      values.CountryId = values.Country[0].id;
      values.Country = values.Country[0].name;
    }
    if (values.State.length === 1) {
      values.StateId = values.State[0].id;
      values.State = values.State[0].name;
    }
    if (values.City.length === 1) {
      values.CityId = values.City[0].id;
      values.City = values.City[0].name;
    }
    var data = {};
    data["organisationId"] = this.props.location.organisationId;
    data["organisationLocationId"] = this.props.location.id;
    for (var key in values) {
      if (key !== "speciality" && key !== "Type") data[key] = values[key];
      if (key === "speciality")
        data["SpecialityIds"] = JSON.stringify(values["speciality"]);
      if (key === "Type") data["Type"] = JSON.stringify(values[key]);
    }
    this.props.updateOrganizationLocation(data);
    this.props.handleClose();
  };

  validationSchema = Yup.object().shape({
    Country: Yup.string().required("Country is required"),
    PrimaryAddress: Yup.string().required("Primary Address is required"),
    City: Yup.string().required("City is required"),
    State: Yup.string().required("State is required"),
    Zipcode: Yup.string().required("Zipcode is required"),
    Phone: Yup.string().required("Phone is required"),
    // .test("validPhoneNumber", "Phone number is invalid", value => {
    //   return isValidPhoneNumber(value);
    // }),
    LocationContactEmail: Yup.string()
      .email("Location Contact Email is not valid")
      .required("Location Contact Email is required"),
    PrimaryLocationName: Yup.string().required("Primary Location is required"),
    Type: Yup.array().min(1, "Type is required"),
    // Fax: Yup.string().required("Fax is required"),
    LocationContact: Yup.string().required("Location Contact is required"),
    speciality: Yup.array().min(1, "Speciality is required"),
  });

  renderForm = (props) => {
    const { handleSubmit, values } = props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <label className="form-lable">Location name</label>
              <Field
                type="text"
                value={values.PrimaryLocationName}
                name="PrimaryLocationName"
                className="form-control form-input"
              />
              <span className="error_text">
                <ErrorMessage name="PrimaryLocationName" />
              </span>
            </div>
            <div className="col-sm-6">
              <label className="form-lable">Street address</label>
              <Field
                type="text"
                value={values.PrimaryAddress}
                name="PrimaryAddress"
                className="form-control form-input"
              />
              <span className="error_text">
                <ErrorMessage name="PrimaryAddress" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <label className="form-lable">Country</label>
              <Typeahead
                id="basic-typeahead-single"
                options={csc.getAllCountries()}
                selected={[csc.getCountryById(values.CountryId)]}
                name="Country"
                labelKey="name"
                onChange={(selectedOptions) => {
                  if (selectedOptions.length === 1) {
                    props.setFieldValue("Country", selectedOptions[0].name);
                    props.setFieldTouched("Country", true, true);
                    props.setFieldValue("CountryId", selectedOptions[0].id);
                    // values.CountryId = selectedOptions[0].id;
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="Country" />
              </span>
            </div>
            <div className="col-sm-2">
              <label className="form-lable">State</label>
              <Typeahead
                id="basic-typeahead-single"
                options={csc.getStatesOfCountry(values.CountryId)}
                selected={[csc.getStateById(values.StateId)]}
                name="State"
                labelKey="name"
                onChange={(selectedOptions) => {
                  if (selectedOptions.length === 1) {
                    props.setFieldValue("State", selectedOptions[0].name);
                    props.setFieldTouched("State", true, true);
                    props.setFieldValue("StateId", selectedOptions[0].id);
                    // values.StateId = selectedOptions[0].id;
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="State" />
              </span>
            </div>
            <div className="col-sm-2">
              <label className="form-lable">Zip code</label>
              <Field
                type="text"
                value={values.Zipcode}
                name="Zipcode"
                className="form-control form-input"
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("Zipcode", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="Zipcode" />
              </span>
            </div>
            <div className="col-sm-2">
              <label className="form-lable">City</label>
              <Typeahead
                id="basic-typeahead-single"
                options={csc.getCitiesOfState(values.StateId)}
                selected={[csc.getCityById(values.CityId)]}
                name="City"
                labelKey="name"
                onChange={(selectedOptions) => {
                  if (selectedOptions.length === 1) {
                    props.setFieldValue("City", selectedOptions[0].name);
                    props.setFieldTouched("City", true, true);
                    props.setFieldValue("CityId", selectedOptions[0].id);
                    // values.CityId = selectedOptions[0].id;
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="City" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <label className="form-lable">Location phone</label>
              <Field
                className="form-control form-input"
                name="Phone"
                type="text"
                value={values.Phone}
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("Phone", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="Phone" />
              </span>
            </div>
            <div className="col-sm-6">
              <label className="form-lable">Location fax</label>
              <Field
                type="text"
                value={values.Fax}
                name="Fax"
                className="form-control form-input"
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("Fax", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="Fax" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <label className="form-lable">Location contact</label>
              <Field
                type="text"
                value={values.LocationContact}
                name="LocationContact"
                className="form-control form-input"
              />
              <span className="error_text">
                <ErrorMessage name="LocationContact" />
              </span>
            </div>
            <div className="col-sm-6">
              {" "}
              <label className="form-lable">Location contact email</label>
              <Field
                type="email"
                value={values.LocationContactEmail}
                name="LocationContactEmail"
                className="form-control form-input"
              />
              <span className="error_text">
                <ErrorMessage name="LocationContactEmail" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <label className="form-lable">Type</label>
              <Typeahead
                id="basic-typeahead-multiple"
                options={this.props.orgTypes}
                selected={values.Type}
                placeholder=""
                labelKey="name"
                name="Type"
                className="edit_location_typeahead"
                onChange={(selectedOptions) =>
                  props.setFieldValue("Type", selectedOptions)
                }
                ref={(ref) => (this._typeahead = ref)}
                multiple
                allowNew
              />
              <span className="error_text">
                <ErrorMessage name="Type" />
              </span>
            </div>
            <div className="col-sm-6">
              <label className="form-lable">Specialty</label>
              <Typeahead
                id="basic-typeahead-multiple"
                options={this.props.specialities}
                labelKey="name"
                placeholder=""
                selected={values.speciality}
                name="speciality"
                className="edit_location_typeahead"
                onChange={(selectedOptions) =>
                  props.setFieldValue("speciality", selectedOptions)
                }
                multiple
              />
              <span className="error_text">
                <ErrorMessage name="speciality" />
              </span>
            </div>
          </div>
        </div>

        <div className="mt-5 text-right">
          <span
            className="mr-5"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.props.deleteOrganizationLocation(this.props.location.id)
            }
          >
            {" "}
            Delete location
          </span>
          <button type="submit" className="btn form-btn" id="btnLogin">
            Done
          </button>
        </div>
      </form>
    );
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        dialogClassName="modal-size"
      >
        <div className="manage-form-header">
          <div style={{ float: "right" }}>
            <button onClick={this.props.handleClose}>X</button>
          </div>
          <div className="manage-form-title align-middle">
            {this.props.location.primaryLocationName}
            {/* <span className="edit-icon col" /> */}
          </div>
        </div>

        <div className="manage-form-container">
          <div className="row">
            <div className="col-12">
              <div className="form-body">
                <Formik
                  initialValues={{
                    Country: this.props.location.country || "",
                    CountryId: this.props.location.countryId || "",
                    PrimaryAddress: this.props.location.primaryAddress || "",
                    City: this.props.location.city || "",
                    CityId: this.props.location.cityId || "",
                    State: this.props.location.state || "",
                    StateId: this.props.location.stateId || "",
                    Zipcode: this.props.location.zipCode || "",
                    Phone: this.props.location.phone || "",
                    Fax: this.props.location.fax || "",
                    LocationContact: this.props.location.locationContact || "",
                    LocationContactEmail:
                      this.props.location.locationContactEmail || "",
                    PrimaryLocationName:
                      this.props.location.primaryLocationName || "",
                    Type: JSON.parse(this.props.location.type) || "",
                    speciality: this.props.location.specialities || [],
                  }}
                  onSubmit={this.onSubmit}
                  render={this.renderForm}
                  validationSchema={this.validationSchema}
                  enableReinitialize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
