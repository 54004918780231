import {
  PREMIUM_ACCOUNT_SUCCESS,
  PREMIUM_ACCOUNT_REQUEST,
  PREMIUM_ACCOUNT_ERROR,
} from "../actions/premium.action";

const initialState = {
  error: null,
  state: null,
  premium: null,
};

const premiumAccountReducer = (state = initialState, action) => {
  let newState = null;
  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case PREMIUM_ACCOUNT_REQUEST: {
      newState = { ...state, state: PREMIUM_ACCOUNT_REQUEST, error: null };
      break;
    }
    case PREMIUM_ACCOUNT_SUCCESS: {
      newState = {
        ...state,
        state: PREMIUM_ACCOUNT_SUCCESS,
        error: null,
        premium: action.payload,
      };
      break;
    }
    case PREMIUM_ACCOUNT_ERROR: {
      newState = {
        ...state,
        state: PREMIUM_ACCOUNT_ERROR,
        error: action.payload,
      };
      break;
    }
  }

  return newState;
};

export default premiumAccountReducer;
