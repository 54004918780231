import React from "react";

class AppDownloadPage extends React.Component {
  render() {
    return (
      <div className="container-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="card">
                <div className="manage-form-header">
                  <div className="manage-form-title">DownLoad Mobile App</div>
                </div>
                <div
                  className="card-body"
                  style={{ display: "flex", minHeight: "210px" }}
                >
                  <p className="form-lText" style={{ margin: "auto" }}>
                    Download Healthswim Mobile app from playStore for Andriod or
                    from appStore for Ios to signin as patient
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppDownloadPage;
