import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

class ManageListsModal extends React.Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.state = {
      showInput: false,
      selectedSpeciality: [],
    };
  }

  onSubmit = (values) => {
    if (this.props.type === "specialityChannels") {
      this.props.addNewListTypevalue(
        values.listTypeValue,
        this.props.type,
        values.specialities[0].id
      );
      this.formik.current.setFieldValue("listTypeValue", "", false);
    } else {
      this.props.addNewListTypevalue(values.listTypeValue, this.props.type);
      this.formik.current.resetForm();
    }
  };

  validationSchema = Yup.object().shape({
    listTypeValue: Yup.string().required(`value is required`),
  });

  renderForm = (props) => {
    const { handleSubmit } = props;
    return (
      <div>
        {this.loadlistTypeData()}
        <form onSubmit={handleSubmit}>
          <div className="row" style={{ marginLeft: 0 }}>
            <div
              className="form-group form-lable col-8"
              style={{ paddingLeft: 0 }}
            >
              <label className="form-lable">{this.props.name}</label>
              <Field
                type="text"
                name="listTypeValue"
                className="form-control form-input"
                placeholder="Enter new value"
              />
              <span className="error_text">
                <ErrorMessage name="listTypeValue" />
              </span>
            </div>
            <div style={{ display: "flex" }} className="col-4">
              <button
                type="submit"
                className="btn form-btn"
                style={{ margin: "auto", height: "40px" }}
              >
                create
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderFormSpecialityChannels = (props) => {
    const { handleSubmit } = props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div className="row" style={{ marginLeft: 0 }}>
            <div className="col-12" style={{ padding: 0 }}>
              <div
                className="form-group form-lable col-4"
                style={{ padding: 0 }}
              >
                <label className="form-lable">Specialty</label>
                <Typeahead
                  options={this.props.specialities}
                  labelKey="name"
                  placeholder="selectSpeciality"
                  name="specialities"
                  onChange={(selectedOptions) => {
                    props.setFieldValue("specialities", selectedOptions);
                    props.setFieldTouched("specialities", true, true);
                    props.setFieldValue("listTypeValue", "");
                    props.setFieldTouched("listTypeValue", false, false);
                    if (selectedOptions[0]) {
                      this.props.getListTypeData(
                        this.props.type,
                        selectedOptions[0].id
                      );
                      this.setState({
                        showInput: true,
                        selectedSpeciality: selectedOptions,
                      });
                    } else {
                      this.props.resetState();
                      this.setState({
                        showInput: false,
                        selectedSpeciality: [],
                      });
                    }
                  }}
                />
                <span className="error_text">
                  {" "}
                  <ErrorMessage name="specialities" />
                </span>
              </div>
            </div>
            {this.loadlistTypeData()}
            {this.state.showInput ? (
              <div className="container">
                <div className="row">
                  <div
                    className="form-group form-lable col-8"
                    style={{ paddingLeft: 0 }}
                  >
                    <label className="form-lable">{this.props.name}</label>
                    <Field
                      type="text"
                      name="listTypeValue"
                      className="form-control form-input"
                      placeholder="Enter new value"
                    />
                    <span className="error_text">
                      <ErrorMessage name="listTypeValue" />
                    </span>
                  </div>
                  <div style={{ display: "flex" }} className="col-4">
                    <button
                      type="submit"
                      className="btn form-btn"
                      style={{ margin: "auto", height: "40px" }}
                    >
                      create
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    );
  };

  loadlistTypeData = () => {
    return (
      <div
        className="row"
        style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
      >
        {this.props.listTypeData.length > 0 ? (
          <div className="col-12">
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {this.props.listTypeData.map((item, index) => {
                return (
                  <div
                    className="row"
                    style={{ height: "40px" }}
                    key={`listTypeData_${index}`}
                  >
                    <div
                      className="col-8"
                      style={{
                        display: "flex",
                        fontFamily: "proximaNova",
                        fontSize: "14px",
                      }}
                    >
                      <div style={{ margin: "auto auto auto 0" }}>
                        {item.name}
                      </div>
                    </div>
                    <div style={{ display: "flex" }} className="col-4">
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={() =>
                          this.props.removeListTypeValue(
                            index,
                            this.props.type,
                            item.id
                          )
                        }
                        style={{ margin: "auto", cursor: "pointer" }}
                        title="delete"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  handleClose = () => {
    this.setState({ showInput: false });
    this.props.handleClose();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        dialogClassName="modal-size"
      >
        <div className="manage-form-header">
          <div style={{ float: "right" }}>
            <button onClick={this.handleClose}>X</button>
          </div>
          <div className="manage-form-title align-middle">
            {this.props.name}
          </div>
        </div>
        <div className="manage-form-container" style={{ minHeight: "500px" }}>
          <div className="row">
            <div className="col-12">
              <div className="form-body">
                {this.props.type !== "specialityChannels" ? (
                  <Formik
                    ref={this.formik}
                    initialValues={{
                      listTypeValue: "",
                    }}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                    validationSchema={this.validationSchema}
                  />
                ) : (
                  <Formik
                    ref={this.formik}
                    initialValues={{
                      listTypeValue: "",
                      speciality: [],
                    }}
                    onSubmit={this.onSubmit}
                    render={this.renderFormSpecialityChannels}
                    validationSchema={this.validationSchema}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ManageListsModal;
