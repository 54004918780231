import React from "react";
import PreviewPageFoePDF from "./PreviewPageFoePDF";
import PDFViewer from "pdf-viewer-reactjs";
import CloseButton from "react-bootstrap/CloseButton";
class DocumentAuthoring extends React.Component {
  constructor(props) {
    super(props);
    this.uploadNewPostDocumentRef = React.createRef();
    this.state = { fileName: "", isOpen: false, pdfFile: null };
  }

  upload_file = (event) => {
    const fileType = ["application/pdf"];
    this.setState({ fileName: event.target.files[0].name });
    let selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          // setPdfFile(e.target.result);
          this.setState({ pdfFile: e.target.result });
          // setPdfFileError("");
        };
      } else {
        this.props.showError("Only pdf allow.");
        this.setState({ fileName: "" });
        this.setState({ pdfFile: null });
      }
    } else {
      this.props.showError("Please select your file");
    }
  };
  uploadDoc = () => {
    this.props.upload("Document");
    this.setState({ isOpen: true });
  };
  onClose = () => {
    this.setState({ isOpen: false });
  };
  reset = () => {
    this.setState({ fileName: "" });
    this.setState({ pdfFile: null });
  };

  render() {
    return (
      <div>
        <div className="flex_display">
          <input
            type="text"
            name="documentTitle"
            placeholder="Enter title here..."
            onChange={this.props.inputChange}
            className="upload_title_input form-control form-input"
          />
          <input
            type="file"
            style={{ display: "none" }}
            ref={this.uploadNewPostDocumentRef}
            className="upload_new_post_image form-control form-input"
            onChange={this.upload_file}
          />
        </div>
        <div style={{ textAlign: "center", marginBottom: "2%" }}>
          {this.state.fileName}
          {this.state.fileName && (
            <CloseButton
              aria-label="Hide"
              onClick={this.reset}
              style={{ position: "sticky", right: "4px" }}
            />
          )}
        </div>
        <div className="flex_display">
          <button
            type="button"
            className="btn upload-btn"
            id="btnVideoUpload"
            onClick={this.uploadDoc}
          >
            Upload Document Here
          </button>
        </div>

        {this.state.isOpen && this.state.pdfFile && (
          <PreviewPageFoePDF
            document={this.state.pdfFile}
            onClose={this.onClose}
          />
        )}
      </div>
    );
  }
}

export default DocumentAuthoring;
