import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";
import { getFeed } from "./timeLine.action";
import { setTimeLineType } from "../actions/timeLineFilter.action";
import Axios from "axios";

export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const UPDATE_SHOWPREVIEW = "UPDATE_SHOWPREVIEW";
export const GET_LINKPREVIEW_SUCCESS = "GET_LINKPREVIEW_SUCCESS";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_IN_PROGRESS = "CREATE_POST_IN_PROGRESS";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";
export const UPDATE_POST_TYPE = "UPDATE_POST_TYPE";
export const ADD_SELECTED_CHANNEL = "ADD_SELECTED_CHANNEL";
export const REMOVE_SELECTED_CHANNEL = "REMOVE_SELECTED_CHANNEL";
export const HANDLE_INPUT_CHANGE = "HANDLE_INPUT_CHANGE";
export const RESET_AUTHORING_UI = "RESET_AUTHORING_UI";
export const EMPTYFEED = "EMPTYFEED";

export const getChannels = () => (dispatch) => {
  apiMethods
    .post("Channel/Provider/MyChannels")
    .then((response) => {
      dispatch({ type: GET_CHANNELS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};

export const createPost = (data) => (dispatch) => {
  async function sendPushNotification(expoPushToken) {
    console.log("token", expoPushToken);
    const message = {
      to: expoPushToken,
      sound: "default",
      title: "Healthswim",
      body: "Pushnotification sucess",
      data: { someData: "goes here" },
    };
    console.log("newmessage", message);
    // await fetch("https://exp.host/--/api/v2/push/send", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Accept-encoding": "gzip, deflate",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(message),
    // });
    await Axios.post("https://exp.host/--/api/v2/push/send", {
      // body: JSON.stringify(message),
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "same-origin",
      message,
    })
      .then(function (response) {
        console.log("response", response);
      })
      .catch(function (error) {
        console.log("response", error);
      });
  }
  dispatch({ type: CREATE_POST_IN_PROGRESS });
  return apiMethods
    .post("Post", data)
    .then((response) => {
      if (
        data.postType === "Image" ||
        data.postType === "Document" ||
        data.postType === "Video"
      ) {
        dispatch(uploadForPost({ id: response.data.id, file: data.file }));
      } else dispatch({ type: CREATE_POST_SUCCESS });
      dispatch(updateShowPreview(false));
      dispatch({ type: RESET_AUTHORING_UI });
      //  "ExponentPushToken[TTgOI9M52OwXKO5H36SnlW]";
      sendPushNotification("ExponentPushToken[nY9wRZC0P1B4OLmdaPmTIU]");
    })
    .catch((errorResponse) => {
      let errorMessage = "";
      if (errorResponse && errorResponse.data && errorResponse.data.error) {
        errorMessage = errorResponse.data.error;
      }

      dispatch({ type: SHOW_ERROR, payload: errorResponse.data.message });
    });
};

export const uploadForPost = (data) => (dispatch) => {
  return apiMethods
    .post(`Post/UploadFile?postId=${data.id}`, data.file)
    .then((response) => {
      dispatch(setTimeLineType("all"));
      dispatch(emptyFeed());
      dispatch(getFeed({ all: true, from: 1, size: 10 }));
      dispatch({ type: CREATE_POST_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: "There is some error please uploade video again.",
      });
    });
};

export const getLinkPreview = (data) => (dispatch) => {
  return apiMethods
    .post(`LinkPreview`, data)
    .then((response) => {
      dispatch(updateShowPreview(true));
      dispatch({ type: GET_LINKPREVIEW_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};

export const updateShowPreview = (boolean) => ({
  type: UPDATE_SHOWPREVIEW,
  payload: boolean,
});

export const updatePostType = (data) => ({
  type: UPDATE_POST_TYPE,
  payload: data,
});

export const addSelectedChannel = (data) => ({
  type: ADD_SELECTED_CHANNEL,
  payload: data,
});

export const handleInputChange = (data) => ({
  type: HANDLE_INPUT_CHANGE,
  payload: data,
});

export const removeSelectedChannel = (data) => ({
  type: REMOVE_SELECTED_CHANNEL,
  payload: data,
});

export const emptyFeed = () => (dispatch) => {
  dispatch({ type: EMPTYFEED });
};
