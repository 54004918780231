import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  requestLogin,
  LOGIN_ERROR,
  LOG_OUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_PASSWORD,
} from "../actions/login.actions";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import logo from "../../img/thumbnail_hs_logo_horiz.png";
import logo from "../img/thumbnail_hs_logo_horiz.png";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import apiMethods, {
  HOME_ROOT,
  PATIENT_ROOT,
} from "../middleware/baseApiService";
import { Modal } from "react-bootstrap";
import "./LoginPagePopup.css";
import healthswim_home from "../img/heathswim_home.png";
import AppleAppStoreButton from "../img/AppleAppStoreButton.png";
import GooglePlayStoreButton from "../img/GooglePlayStoreButton.png";
import Hs_logo from "../img/HS_logo.png";
import Healthswim_home_page from "../components/Healthswim_home_page";

class LoginPagePopup extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    // this.toggleShow = this.toggleShow.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.state = {
      mobile: "",
    };
  }

  componentDidMount() {
    if (
      this.props.isLoggedIn === false &&
      (this.props.state === null ||
        this.props.state === LOGIN_ERROR ||
        this.props.state === LOG_OUT)
    ) {
      // this.props.requestLogin();
    }
  }
  componentDidUpdate() {
    if (this.props.isLoggedIn === true && this.props.state === LOGIN_SUCCESS) {
      if (this.props.commonLogin === "Client") {
        localStorage.clear();
        const userObject = {
          email: this.props.email,
          password: this.props.password,
          mobile: this.props.mobile ? this.props.mobile.substring(1) : "", //here we are removing + sign
        };
        const serializedObject = encodeURIComponent(JSON.stringify(userObject));
        window.location.href = `${PATIENT_ROOT}data=${serializedObject}`;
      } else {
        this.props.history.push("/next-step/login");
      }
    }
  }
  onSubmit = (values) => {
    if (values.username === "" && this.state.mobile === "") {
      alert("Please enter a valid Email address or Phone number");
      return;
    }
    this.props.requestLogin({
      username: values.username,
      password: values.password,
      mobile: this.state.mobile,
    });
  };
  handleClose = () => {
    // window.location.href =
    //   "https://healthswimstagwebsite.z19.web.core.windows.net";
    window.location.href = HOME_ROOT;
  };
  validationSchema = Yup.object().shape({
    username: Yup.string().required("Email or phone can't be blank"),
    // .email("Username should be email."),
    password: Yup.string()
      .required("Password can't be blank")
      .min(8, "Password should be minimum 8 characters long."),
  });
  handleRegister = () => {
    // <Redirect to={"registerinfoPage"} />;
    this.props.history.push("/registerinfoPage");
  };
  renderForm = (props) => {
    const { handleChange, handleSubmit, values } = props;
    const isRegistrationInProgress = this.props.state === LOGIN_REQUEST;

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="form-group form-lable">
            {/* <label className="form-lable">Email</label> */}
            <Field
              type="text"
              value={values.username}
              name="username"
              // className="form-control form-input1"
              style={{
                width: "100%",
                height: "3.25rem",
                borderRadius: "6px",
                padding: "13px 16px",
                fontSize: "19px",
                fontWeight: "400",
                border: "1px solid #dddfe2",
                outline: "none",
                fontFamily: "proximaNova",
              }}
              placeholder="Email address or phone number"
            />
            <span className="error_text">
              <ErrorMessage name="username" />
            </span>
          </div>

          <div className="form-group form-lable ">
            {/* <label className="form-lable">Password</label> */}

            <div className="input-group ">
              <Field
                type="password"
                value={values.password}
                name="password"
                // className="form-control form-input"
                style={{
                  width: "100%",
                  height: "3.25rem",
                  borderRadius: "6px",
                  padding: "13px 16px",
                  fontSize: "19px",
                  fontWeight: "400",
                  border: "1px solid #dddfe2",
                  outline: "none",
                  fontFamily: "proximaNova",
                }}
                placeholder="Password"
              />
            </div>
            <span className="error_text">
              <ErrorMessage name="password" />
            </span>
          </div>

          <button
            className="set-button"
            type="submit"
            disabled={isRegistrationInProgress}
            style={{
              backgroundColor: "#00d3ff",
              display: "block",
            }}
          >
            {" "}
            Log IN{" "}
            {isRegistrationInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </form>
        <div style={{ textAlign: "center", marginTop: "5px" }}>
          <Link to="/forgotpassword" className="set-password">
            Don't remember your password?
          </Link>
        </div>
        <hr></hr>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{
              backgroundColor: "#00d3ff",
              display: "block",
              // marginTop: "20px",
              width: "50%",
            }}
            className="set-button"
            onClick={() => this.handleRegister()}
          >
            {" "}
            Sign-Up{" "}
          </button>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div className="modal" style={{ width: "80%" }}>
          <Modal
            style={{ width: "100%" }}
            show={true}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-26w"
            onHide={this.handleClose}
          >
            <Modal.Header closeButton></Modal.Header>
            <div
              className="form-rectangle"
              style={{ width: "100%", minHeight: "345px" }}
            >
              <div className="form-container h-100">
                <div>
                  <Formik
                    initialValues={{
                      username: "",
                      password: "",
                      mobile: "",
                    }}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                    validationSchema={this.validationSchema}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <Healthswim_home_page />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  commonLogin: state.login.commonLogin,
  state: state.login.state,
  error: state.login.error,
  isLoggedIn: state.login.isLoggedIn,
  showMessage: state.login.showMessage,
  email: state.login.email,
  password: state.login.password,
  mobile: state.login.mobile,
});

const mapDispatchToProps = {
  requestLogin,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPagePopup)
);
