import React, { Component } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./organizationProfile.css";
import default_org_Logo from "../../img/profile_icon.jpeg";
import csc from "country-state-city";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";

export default class OrganizationProfile extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
  }

  onSubmit = (values) => {
    if (values.Country.length === 1) {
      values.CountryId = values.Country[0].id;
      values.Country = values.Country[0].name;
    }
    if (values.State.length === 1) {
      values.StateId = values.State[0].id;
      values.State = values.State[0].name;
    }
    if (values.City.length === 1) {
      values.CityId = values.City[0].id;
      values.City = values.City[0].name;
    }
    // var orgData = {};
    // for (var key in values) {
    //   if (key !== "City" && key !== "Type") orgData[key] = values[key];
    //   else {
    //     if (key === "City") {
    //       // var SpecialityIds = [];
    //       // values.speciality.forEach(element => {
    //       //   SpecialityIds.push(element.id);
    //       // });
    //       orgData["CityId"] = JSON.stringify(values["City"]);
    //     }
    //     if (key === "Type") {
    //       orgData["Type"] = JSON.stringify(values["Type"]);
    //     }
    //   }
    // }
    var orgData = {};
    for (var key in values) {
      if (key !== "speciality" && key !== "Type") orgData[key] = values[key];
      else {
        if (key === "speciality") {
          // var SpecialityIds = [];
          // values.speciality.forEach(element => {
          //   SpecialityIds.push(element.id);
          // });
          orgData["SpecialityId"] = JSON.stringify(values["speciality"]);
        }
        if (key === "Type") {
          orgData["Type"] = JSON.stringify(values["Type"]);
        }
      }
    }
    this.props.saveOrganizationProfile(orgData);
  };

  goLastStep = () => {
    this.props.getPrevStep();
    // this.props.history.goBack();
  };

  validationSchema = Yup.object().shape({
    City: Yup.string().required("City is required"),
    OrganisationName: Yup.string().required("Organization Name is required"),
    Country: Yup.string().required("Country is required"),
    // Fax: Yup.string().required("Fax is required"),
    Phone: Yup.string().required("Phone is required"),
    // .test("validPhoneNumber", "phone number is invalid", value => {
    //   return isValidPhoneNumber(value);
    // }),
    PrimaryAddress: Yup.string().required("Primary Address is required"),
    PrimaryLocationName: Yup.string().required("Primary Location is required"),
    State: Yup.string().required("State is required"),
    Type: Yup.array().min(1, "Type is required "),
    speciality: Yup.array().min(1, "Speciality is required"),
    zipCode: Yup.string().required("ZipCode is required"),
  });

  render() {
    return (
      <div className="container">
        <div className="form-rectangle">
          {this.props.mode !== "edit" ? (
            <div className="form-header">
              <h3>TELL US ABOUT YOUR COMPANY…</h3>
              <p>This is the stuff we HAVE to know.</p>
            </div>
          ) : (
            <div className="form-header">
              <div className="manage-form-title">
                Organization Profile
                {/* <span className="edit-icon col"></span> */}
              </div>
            </div>
          )}
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText ">
                  {!this.props.orgLogo
                    ? `Uploading a logo is a good way to ensure that users know who
                  you and your providers are`
                    : null}
                </div>
                <div className="d-flex">
                  {this.props.orgLogo ? (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage: "url(" + this.props.orgLogo + ")",
                      }}
                    />
                  ) : (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage: "url(" + default_org_Logo + ")",
                        border: "2px solid black",
                      }}
                    />
                  )}
                </div>
                <div className="form-group text-center mt-4">
                  <span
                    className="form-link-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.imageRef.current.click()}
                  >
                    Add a logo here
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={this.imageRef}
                    onChange={this.props.saveOrganizationLogo}
                  />
                </div>
              </div>
              <div className="col-8">
                <div className="form-body">
                  <Formik
                    initialValues={this.props.initialValues}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                    validationSchema={this.validationSchema}
                    enableReinitialize={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm = (props) => {
    let buttonText = this.props.mode !== "edit" ? "Next" : "Done";
    const { handleSubmit, values } = props;

    // filter allCountries and return only USA
    let allCountries = csc.getAllCountries();
    let usaCountries = allCountries.filter(
      (country) => country.name === "United States"
    );

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <label className="form-lable">Organization name</label>
          <Field
            type="text"
            value={values.OrganisationName}
            name="OrganisationName"
            className="form-control form-input"
            onChange={(e) => {
              var name = /^[a-z A-Z]+$/;
              if (e.target.value !== "" && e.target.value.match(name) === null)
                alert("Please input alphabetic characters only");
              else {
                props.setFieldValue("OrganisationName", e.target.value);
              }
            }}
          />
          <span className="error_text">
            <ErrorMessage name="OrganisationName" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Country</label>
          <Typeahead
            id="basic-typeahead-single"
            options={usaCountries}
            selected={[csc.getCountryById(values.CountryId)]}
            name="Country"
            labelKey="name"
            onChange={(selectedOptions) => {
              if (selectedOptions.length === 1) {
                props.setFieldValue("Country", selectedOptions[0].name);
                // props.setFieldTouched("Country", true, true);
                // values.CountryId = selectedOptions[0].id;
                props.setFieldValue("CountryId", selectedOptions[0].id);
              }
            }}
            onBlur={() => {
              props.setFieldTouched("Country", true, true);
            }}
          />
          <span className="error_text">
            <ErrorMessage name="Country" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Primary address</label>
          <Field
            type="text"
            value={values.PrimaryAddress}
            name="PrimaryAddress"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="PrimaryAddress" />
          </span>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-sm-4">
              <label className="form-lable">State</label>
              <Typeahead
                id="basic-typeahead-single"
                options={csc.getStatesOfCountry(values.CountryId)}
                selected={[csc.getStateById(values.StateId)]}
                name="State"
                labelKey="name"
                onChange={(selectedOptions) => {
                  if (selectedOptions.length === 1) {
                    props.setFieldValue("State", selectedOptions[0].name);
                    // props.setFieldTouched("State", true, true);
                    // values.StateId = selectedOptions[0].id;
                    props.setFieldValue("StateId", selectedOptions[0].id);
                  }
                }}
                onBlur={() => {
                  props.setFieldTouched("State", true, true);
                }}
              />
              <span className="error_text">
                <ErrorMessage name="State" />
              </span>
            </div>
            <div className="col-sm-4">
              <label className="form-lable">City</label>
              <Typeahead
                id="basic-typeahead-single"
                // options={csc.getCitiesOfState(values.StateId)}
                // selected={[csc.getCityById(values.CityId)]}
                // name="City"
                // labelKey="name"
                // onChange={(selectedOptions) => {
                //   if (selectedOptions.length === 1) {
                //     props.setFieldValue("City", selectedOptions);
                //     props.setFieldTouched("City", true, true);
                //     values.CityId = selectedOptions[0].id;
                //   }
                // }}
                // allowNew
                options={csc.getCitiesOfState(values.StateId)}
                selected={[csc.getCityById(values.CityId)]}
                // options={this.props.City}
                // selected={values.City}
                labelKey="name"
                name="City"
                onChange={(selectedOptions) => {
                  if (selectedOptions.length === 1) {
                    props.setFieldValue("City", selectedOptions[0].name);
                    // props.setFieldTouched("City", true, true);
                    // values.CityId = selectedOptions[0].id;
                    props.setFieldValue("CityId", selectedOptions[0].id);
                  }
                }}
                onBlur={() => {
                  props.setFieldTouched("City", true, true);
                }}
                ref={(ref) => (this._typeahead = ref)}
                allowNew
              />
              <span className="error_text">
                <ErrorMessage name="City" />
              </span>
            </div>
            <div className="col-sm-4">
              <label className="form-lable">Zip Code</label>
              <Field
                type="text"
                value={values.zipCode}
                name="zipCode"
                className="form-control form-input"
                style={{ marginTop: "2px" }}
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("zipCode", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="zipCode" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <label className="form-lable">
                Primary phone {props.errors.phone}
              </label>
              <Field
                className="form-control form-input"
                name="Phone"
                type="text"
                value={values.Phone}
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("Phone", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="Phone" />
              </span>
            </div>
            <div className="col-sm-6">
              <label className="form-lable">Primary fax</label>
              <Field
                type="text"
                value={values.Fax}
                name="Fax"
                className="form-control form-input"
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("Fax", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="Fax" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Primary location name</label>
          <Field
            type="text"
            value={values.PrimaryLocationName}
            name="PrimaryLocationName"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="PrimaryLocationName" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Type</label>
          <Typeahead
            id="basic-typeahead-multiple"
            options={this.props.orgTypes}
            selected={values.Type}
            placeholder=""
            labelKey="name"
            name="Type"
            onChange={(selectedOptions) => {
              props.setFieldValue("Type", selectedOptions);
              // props.setFieldTouched("Type", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("Type", true, true);
            }}
            ref={(ref) => (this._typeahead = ref)}
            multiple
            allowNew
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="Type" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Specialty</label>
          <Typeahead
            id="basic-typeahead-multiple"
            options={this.props.specalities}
            selected={values.speciality}
            placeholder=""
            labelKey="name"
            name="speciality"
            onChange={(selectedOptions) => {
              props.setFieldValue("speciality", selectedOptions);
              // props.setFieldTouched("speciality", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("speciality", true, true);
            }}
            multiple
            allowNew
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="speciality" />
          </span>
        </div>

        <div className="mt-5">
          {/* changes */}
          {/* {this.props.mode !== "edit" && (
            <button
              type="button"
              className="btn text-left form-btn"
              onClick={this.goLastStep}
            >
              Back
            </button>
          )} */}
          <button type="submit" className="btn pull-right form-btn">
            {buttonText}
            {this.props.isSaveInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#212529" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };
}
