import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getNextStep,
  updateOnBoardingStatus,
} from "../actions/onboardingStatus.actions";
import {
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_PERSONAL_PROFILE,
  ROUTE_PROFESSIONAL_INFO,
  ROUTE_CHANNEL_SETUP,
  ROUTE_FIND_CONTENT,
  ROUTE_PUBLISH_CONTENT,
  ROUTE_DASHBOARD,
  ROUTE_ORG_PROFILE,
  ROUTE_ORG_LOCATION,
  ROUTE_ORG_INVITE_ADMIN,
  ROUTE_ORG_INVITE_SUBADMIN,
  ROUTE_ORG_INVITE_PROVIDER,
  ROUTE_ORG_ABOUTME,
  ROUTE_ORG_DEPARTMENT,
  ROUTE_CLIENT_PROFILE,
  ROUTE_SUBSCRIBE_CHANNEL,
  ROUTE_PRIVACY_POLICY,
} from "./RouteConstants";

let stepNameAndPathMap = {
  PRIVACYPOLICY: ROUTE_PRIVACY_POLICY,
  TERMSANDCONDITIONS: ROUTE_TERMS_AND_CONDITIONS,
  "PERSONAL-PROFILE": ROUTE_PERSONAL_PROFILE,
  "PROFESSIONAL-INFO": ROUTE_PROFESSIONAL_INFO,
  "CHANNEL-SETUP": ROUTE_CHANNEL_SETUP,
  "FIND-CONTENT": ROUTE_FIND_CONTENT,
  "PUBLISH-CONTENT": ROUTE_PUBLISH_CONTENT,
  DASHBOARD: ROUTE_DASHBOARD,
  ADD_DOCTOR_ORG: "/",
  ORGANIZATION_ABOUTCOMPANY: "/",
  ORGANIZATION_MANAGEMENT: "/",
  ORGANIZATION_LOCATION: "/",
  ORGANIZATION_SETUPUSERS: "/",
  "ORGANIZATION-PROFILE": ROUTE_ORG_PROFILE,
  "ORGANIZATION-LOCATION": ROUTE_ORG_LOCATION,
  "ORGANIZATION-INVITE-ADMIN": ROUTE_ORG_INVITE_ADMIN,
  "ORGANIZATION-INVITE-SUBADMIN": ROUTE_ORG_INVITE_SUBADMIN,
  "ORGANIZATION-INVITE-PROVIDER": ROUTE_ORG_INVITE_PROVIDER,
  "ORGANIZATION-DEPARTMENTS": ROUTE_ORG_DEPARTMENT,
  ABOUTME: ROUTE_ORG_ABOUTME,
  "PERSONAL-INFO": ROUTE_CLIENT_PROFILE,
  "SUBSCRIBE-CHANNEL": ROUTE_SUBSCRIBE_CHANNEL,
  "PREMIUM-INFO": "/PREMIUM-INFO",
};

class OnboardingControllerPage extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    if (this.props.prevStep !== null) {
      const prevStepUrl = stepNameAndPathMap[this.props.prevStep.name];
      // {
      //   console.log("prevStepUrl", prevStepUrl, this.props.prevStep);
      // }
      return <Redirect to={prevStepUrl} />;
      // return this.props.history.push(prevStepUrl);
    }
    if (this.props.match.params.completedStep !== "login") {
      this.props.updateOnBoardingStatus(this.props.match.params.completedStep);
    } else {
      this.props.getNextStep(this.props.email);
    }
  }

  render() {
    if (
      this.props.nextStep == null ||
      (this.props.nextStep &&
        this.props.nextStep.name.toUpperCase() ===
          this.props.match.params.completedStep.toUpperCase())
    ) {
      if (this.props.roleError) {
        return <Redirect to={ROUTE_DASHBOARD} />;
      }

      return (
        <div className="container-bg">
          <div className="container">
            <div className="section-header">Determining next step..!</div>
          </div>
        </div>
      );
    }

    const nextStepUrl = stepNameAndPathMap[this.props.nextStep.name];
    // {
    //   console.log("nextStepUrl", nextStepUrl, this.props.nextStep);
    // }
    return <Redirect to={nextStepUrl} />;
  }
}

const mapStateToProps = (state) => ({
  nextStep: state.onboardingStatus.nextStep,
  prevStep: state.onboardingStatus.prevStep,
  roleError: state.onboardingStatus.roleError,
  email: state.login.email,
});

const mapDispatchToProps = {
  getNextStep,
  updateOnBoardingStatus,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardingControllerPage)
);
