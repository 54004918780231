import React from "react";
import { clearError } from "../../actions/errorDisplay.actions";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class ErrorDisplayComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.clearError();
    this.setState({ show: false });
    if (
      this.props.errorMessage ===
      "Your account is deactivated. Please contact admin to access your account"
    ) {
      this.props.history.push("/logout");
    }
  }

  render() {
    this.state.show = this.props.errorMessage ? true : false;

    return (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <div
          // className="modal-content"

          style={{ overflow: "hidden" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ margin: "auto", marginRight: "-7%" }}>
              Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ margin: "auto" }}>
            <p style={{ textAlign: "center", margin: "auto" }}>
              {this.props.errorMessage}
            </p>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "1px solid #ffffff" }}>
            <Button
              onClick={this.handleClose}
              style={{
                backgroundColor: "#00CCFF",
                borderColor: "transparent",
                margin: "auto",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  errorMessage: state.error.errorMessage,
});

const mapDispatchToProps = {
  clearError: clearError,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorDisplayComponent)
);
