import React, { Component } from "react";

import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
// import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { getPrevStep } from "../actions/onboardingStatus.actions";
import {
  PREMIUM_ACCOUNT_REQUEST,
  PREMIUM_ACCOUNT_SUCCESS,
  premiumaccount,
} from "../actions/premium.action";
import Premium from "../components/premium/premium";

class PremiumPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.onSkipForNow = this.onSkipForNow.bind(this);
  }

  submit(values) {
    this.props.premiumaccount(values);
    // console.log(values);
  }
  // getPrevStep = () => {
  //   this.props.getPrevStep("?currentStep=/PREMIUM-INFO");
  // };
  onSkipForNow() {
    this.props.history.push("/next-step" + "/PREMIUM-INFO");
    // console.log("skip");
  }
  render() {
    // if (
    //   this.props.prevStep !== null &&
    //   this.props.prevStep.name === "ORGANIZATION-INVITE-PROVIDER"
    // ) {
    //   return <Redirect to={ROUTE_ORG_INVITE_PROVIDER} />;
    // }
    const saveInProgress = this.props.state === PREMIUM_ACCOUNT_REQUEST;

    if (this.props.state === PREMIUM_ACCOUNT_SUCCESS) {
      return <Redirect to={"/next-step" + "/PREMIUM-INFO"} />;
    }

    return (
      <div>
        <Premium submit={this.submit} onSkipForNow={this.onSkipForNow} />
      </div>
    );
  }
}
const mapPropsToState = (state) => ({
  state: state.premiumAccount.state,
  // console.log(state.premiumAccount);
});
const mapDispatchToState = {
  getPrevStep,
  premiumaccount,
};
export default withRouter(
  connect(mapPropsToState, mapDispatchToState)(PremiumPage)
);
