import { SHOW_ERROR, CLEAR_ERROR } from "../actions/errorDisplay.actions";

const initialState = {
  errorMessage: "",
};

const errorReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case SHOW_ERROR: {
      newState = { ...initialState, errorMessage: action.payload };
      break;
    }
    case CLEAR_ERROR: {
      newState = { ...initialState, errorMessage: "" };
      break;
    }
    default: {
      newState = {
        errorMessage: state.errorMessage || initialState.errorMessage,
      };
      break;
    }
  }
  return newState;
};

export default errorReducer;
