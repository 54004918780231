import React, { Component } from "react";
import Modal from "react-modal";
import { Typeahead } from "react-bootstrap-typeahead";
import CloseButton from "react-bootstrap/CloseButton";

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import OrgCreateAdminPage from "../../containers/OrgCreateAdminPage";
import OrganizationInviteSubadmin from "../organizationInviteSubadmin/organizationInviteSubadmin";
import OrgCreateDeptAdminPage from "../../containers/OrgCreateDeptAdminPage";
import OrganizationInviteProvider from "../organizationInviteProvider/organizationInviteProvider";
import OrganizationInviteProviderPage from "../../containers/OrganizationInviteProviderPage";
import OrgnizationProfilePage from "../../containers/OrgnizationProfilePage";
import Addorganizationpopup from "./Addorganizationpopup";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "-20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid gray",
    width: "70%",
  },
};

export default class ManageAccountPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAnotherAdminAfterSave: false,
      value: true,
      openOrganization: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.addAnotherAdmin = this.addAnotherAdmin.bind(this);
    this.onSubmitWithAdd = this.onSubmitWithAdd.bind(this);
    this.formik = React.createRef();
    this.infiniteRef = React.createRef();
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    ContactPhone: Yup.string().required("Contact Phone is required"),
    departments: Yup.array().min(1, "Select departments for this admin"),
    // .test("validPhoneNumber", "ContactPhone number is invalid", value => {
    //   return isValidPhoneNumber(value);
    // })
  });
  open = () => {
    this.setState({
      openOrganization: true,
    });
  };
  onCloses = () => {
    this.setState({
      openOrganization: false,
    });
  };
  goLastStep = () => {
    this.props.getPrevStep();
  };
  closeProvider = (values) => {
    // this.setState({
    //   value: values,
    // });
  };
  onSubmit(values) {
    this.props.onSubmit(values, this.state.addAnotherAdminAfterSave);
  }

  onSubmitWithAdd() {
    this.setState({ addAnotherAdminAfterSave: true });
    this.formik.current.submitForm();
  }

  addOneAdmin = () => {
    this.setState({ addAnotherAdminAfterSave: false });
    this.formik.current.submitForm();
  };

  addAnotherAdmin(values) {
    this.setState({ addAnotherAdminAfterSave: false });
    this.formik.current.resetForm();
  }

  renderForm = (props) => {
    const { handleSubmit, values } = props;

    let buttonText = this.props.isBoardingOff === true ? "Done" : "Next";

    // return (
    //   <form onSubmit={handleSubmit}>
    //     <div className="form-group form-lable">
    //       <label className="form-lable">First name</label>
    //       <Field
    //         type="text"
    //         value={values.firstName}
    //         name="firstName"
    //         className="form-control form-input"
    //         onChange={(e) => {
    //           var name = /^[a-zA-Z]+$/;
    //           if (e.target.value !== "" && e.target.value.match(name) === null)
    //             alert("Please input alphabetic characters only");
    //           else {
    //             props.setFieldValue("firstName", e.target.value);
    //           }
    //         }}
    //       />
    //       <span className="error_text">
    //         <ErrorMessage name="firstName" />
    //       </span>
    //     </div>

    //     <div className="form-group form-lable">
    //       <label className="form-lable">Last name</label>
    //       <Field
    //         type="text"
    //         value={values.lastName}
    //         name="lastName"
    //         className="form-control form-input"
    //         onChange={(e) => {
    //           var name = /^[a-zA-Z]+$/;
    //           if (e.target.value !== "" && e.target.value.match(name) === null)
    //             alert("Please input alphabetic characters only");
    //           else {
    //             props.setFieldValue("lastName", e.target.value);
    //           }
    //         }}
    //       />
    //       <span className="error_text">
    //         <ErrorMessage name="lastName" />
    //       </span>
    //     </div>

    //     <div className="form-group form-lable">
    //       <label className="form-lable">Phone</label>
    //       <Field
    //         className="form-control form-input"
    //         name="ContactPhone"
    //         type="text"
    //         value={values.ContactPhone}
    //         onChange={(e) => {
    //           var number = /^[0-9]+$/;
    //           if (
    //             e.target.value !== "" &&
    //             e.target.value.match(number) === null
    //           )
    //             alert("Please input numeric characters only");
    //           else {
    //             props.setFieldValue("ContactPhone", e.target.value);
    //           }
    //         }}
    //       />
    //       <span className="error_text">
    //         <ErrorMessage name="ContactPhone" />
    //       </span>
    //     </div>

    //     <div className="form-group form-lable">
    //       <label className="form-lable">Email</label>
    //       <Field
    //         type="email"
    //         value={values.email}
    //         name="email"
    //         className="form-control form-input"
    //       />
    //       <span className="error_text">
    //         <ErrorMessage name="email" />
    //       </span>
    //     </div>
    //
    //     {console.log("typeOf", typeof this.state.showDepartment)}
    //     {/* {this.props.showDepartment == true && (
    //       <div className="form-group form-lable">
    //         <>
    //           <label className="form-lable">Departments</label>
    //           <Field
    //             // options={values.allDepartments}
    //             // selected={values.departments}
    //             // placeholder=""
    //             // name="departments"
    //             // labelKey="departmentName"
    //             // onChange={(selectedOptions) => {
    //             //   props.setFieldValue("departments", selectedOptions, false);
    //             // }}
    //             // multiple
    //             type="text"
    //             value={values.departments}
    //             name="departments"
    //             className="form-control form-input"
    //             onChange={(e) => {
    //               var name = /^[a-zA-Z]+$/;
    //               if (
    //                 e.target.value !== "" &&
    //                 e.target.value.match(name) === null
    //               )
    //                 alert("Please input alphabetic characters only");
    //               else {
    //                 props.setFieldValue("departments", e.target.value);
    //               }
    //             }}
    //           />
    //         </>

    //         <span className="error_text">
    //           <ErrorMessage name="departments" />
    //         </span>
    //       </div>
    //     )} */}

    //     <div
    //       className="row mt-5 no-gutter"
    //       style={{ marginLeft: "0px", marginRight: "0px" }}
    //     >
    //       <div style={{ margin: "auto 10px auto auto", display: "flex" }}>
    //         <div
    //           className="user-icon"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => this.onSubmitWithAdd()}
    //         />
    //       </div>
    //       <div
    //         style={{
    //           display: "flex",
    //           textAlign: "left",
    //           margin: "auto auto auto 0px",
    //         }}
    //       >
    //         <span
    //           className="form-link"
    //           onClick={() => this.onSubmitWithAdd()}
    //           style={{ height: "37px", lineHeight: "37px", cursor: "Pointer" }}
    //         >
    //           Save and add another admin
    //         </span>
    //       </div>
    //     </div>

    //     <div className="mt-5 text-right">
    //       {this.props.isBoardingOff !== true && (
    //         <button
    //           type="button"
    //           className="btn mr-3 pull-left form-btn"
    //           onClick={this.goLastStep}
    //         >
    //           Back
    //         </button>
    //       )}
    //       {this.props.canSkip ? (
    //         <span
    //           className="form-link mr-5"
    //           onClick={() => this.props.onSkipForNow()}
    //         >
    //           Skip for now
    //         </span>
    //       ) : null}
    //       <button
    //         type="button"
    //         className="btn form-btn"
    //         id="btnLogin"
    //         disabled={this.props.isSaveInProgress}
    //         onClick={this.addOneAdmin}
    //       >
    //         {buttonText}
    //         {this.props.isSaveInProgress === true ? (
    //           <span className="text-right pl-2">
    //             <i
    //               className="fa fa-circle-o-notch fa-spin"
    //               style={{ color: "#fff" }}
    //             />
    //           </span>
    //         ) : (
    //           ""
    //         )}
    //       </button>
    //     </div>
    //   </form>
    // );
  };
  render() {
    let initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      ContactPhone: "",
    };
    return (
      <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
        <CloseButton
          aria-label="Hide"
          onClick={this.props.onClose}
          style={{ position: "sticky", right: "4px" }}
        />
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "30px" }}>
            <div className="user-oval" />
            <label style={{ cursor: "pointer" }}>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={this.props.onFileUpload}
              />
              UPLOAD USING A SPREADSHEET
            </label>
            <br></br>
            <label> (Max. 20 records at a time)</label>
          </div>
        </div>
        <div className="row mtb-60 " style={{ display: "flex" }}>
          <div className="col-8" style={{ display: "flex", margin: "auto" }}>
            <div className="col-md-5 my-auto ">
              <div className="form-group form-lable">
                <Typeahead
                  options={this.props.orgList}
                  labelKey="name"
                  name="org_id"
                  onChange={(selected) => this.props.setOrgId(selected)}
                  placeholder="Select Organisation"
                />
              </div>
            </div>
            <div className="col-5 my-auto">
              <div className="form-group">
                <div className="form-group">
                  <Typeahead
                    labelKey="name"
                    name="role"
                    options={this.props.roleList}
                    onChange={(selected) => this.props.setRole(selected)}
                    placeholder="Select Role"
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <button
                className="btn form-btn-sm btn-sm"
                id="btnAdd"
                onClick={this.props.handleAddClick}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            fontFamily: "PTSansNarrow",
            fontSize: "20px",
            fontWeight: "bold",
            lineHeight: "26px",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          {" "}
          Add another Organisation{" "}
          <button className="form-btn-sms" onClick={this.open}>
            {" "}
            +
          </button>{" "}
        </div>
        <hr></hr>
        {/* <div className="col-8">
          <div className="form-body">
            <Formik
              ref={this.formik}
              initialValues={initialValues}
              onSubmit={this.onSubmit}
              render={this.renderForm}
              validationSchema={this.validationSchema}
              onReset={this.addAnotherAdmin}
            />
          </div>
        </div> */}

        {this.props.showAdmin == true ? (
          <OrgCreateAdminPage
            value={this.state.value}
            orgId={this.props.orgId}
          />
        ) : (
          ""
        )}
        {this.props.showDepartment == true ? (
          <OrgCreateDeptAdminPage
            value={this.state.value}
            orgId={this.props.orgId}
          />
        ) : (
          ""
        )}
        {this.props.showProvider == true && this.state.value ? (
          <OrganizationInviteProviderPage
            value={this.state.value}
            orgId={this.props.orgId}
            closeProvider={this.closeProvider}
          />
        ) : (
          ""
        )}
        {/* <OrgnizationProfilePage openPage={this.state.openOrganization} /> */}
        {this.state.openOrganization === true && (
          <Addorganizationpopup onClose={this.onCloses} />
        )}
      </Modal>
    );
  }
}
