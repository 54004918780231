import {
  GET_LIST_SUCCESS,
  GET_PROVIDER_USER,
  GET_DEP_USER,
  GET_SUBSCRIPTIONDETAILSORG_SUCCESS,
  GET_SUBSCRIPTIONDETAILSPROVIDER_SUCCESS,
  UPDATE_HAS_MORE_ORG,
  RESET_STATE_MANAGE_ACCOUNTS,
  GET_ORGANISATION_LIST_SUCCESS,
  ACTIVATE_DEACTIVATE_ACCOUNT_SUCCESS,
} from "../actions/orgManageAccounts.action";

const intialState = {
  orgList: [],
  roleList: [
    { id: "ROLE_ORGANIZATION_ADMIN", name: "Admin" },
    { id: "ROLE_ORGANIZATION_SUBADMIN", name: "Dept-Admin" },
    { id: "ROLE_ORGANIZATION_PHYSICIAN", name: "Provider" },
  ],
  subscriptionList: [],
  pageNumber: 1,
  totalPages: null,
  hasMore: true,
  subscriptionDetailsProvider: {
    type: "[]",
    degree: "[]",
    boardCertifications: "[]",
    affiliations: "[]",
    conditionTreated: "[]",
  },
  subscriptionDetailsOrg: { type: "[]" },
  providerList: [],
  depList: [],
};

const orgManageAccounts = (state = intialState, action) => {
  switch (action.type) {
    case GET_LIST_SUCCESS:
      if (action.payload.page === 1) {
        return {
          ...state,
          subscriptionList: [...action.payload.items],
          pageNumber: action.payload.page,
          totalPages: Math.ceil(action.payload.total / 10),
        };
      } else {
        return {
          ...state,
          subscriptionList: [
            ...state.subscriptionList,
            ...action.payload.items,
          ],
          pageNumber: action.payload.page,
          totalPages: Math.ceil(action.payload.total / 10),
        };
      }
    case GET_SUBSCRIPTIONDETAILSORG_SUCCESS:
      return { ...state, subscriptionDetailsOrg: action.payload };
    case GET_PROVIDER_USER:
      return { ...state, providerList: action.payload };
    case GET_DEP_USER:
      return { ...state, depList: action.payload };
    case GET_ORGANISATION_LIST_SUCCESS:
      return { ...state, orgList: action.payload };
    case ACTIVATE_DEACTIVATE_ACCOUNT_SUCCESS:
      if (action.payload.includes("organisationId")) {
        var id = action.payload.split("=")[1];
        var subscriptions = state.subscriptionList.filter(
          (item) => item.organisationId != id
        );
        return { ...state, subscriptionList: subscriptions };
      } else return { ...state };
    case GET_SUBSCRIPTIONDETAILSPROVIDER_SUCCESS:
      return { ...state, subscriptionDetailsProvider: action.payload };
    case UPDATE_HAS_MORE_ORG:
      return { ...state, hasMore: action.payload };
    case RESET_STATE_MANAGE_ACCOUNTS:
      return {
        ...state,
        subscriptionDetailsProvider: {
          type: "[]",
          degree: "[]",
          boardCertifications: "[]",
          affiliations: "[]",
          conditionTreated: "[]",
        },
        subscriptionDetailsOrg: { type: "[]" },
      };
    default:
      return state;
  }
};

export default orgManageAccounts;
