import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import "./organizationInviteProvider.css";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";

class OrganizationInviteProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addInviteAnotherProviderAfterSave: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.inviteAnotherProvider = this.inviteAnotherProvider.bind(this);
    this.onSubmitWithAdd = this.onSubmitWithAdd.bind(this);
    this.formik = React.createRef();
  }

  goLastStep = () => {
    this.props.getPrevStep();
  };

  onSubmitWithAdd() {
    this.setState({ addInviteAnotherProviderAfterSave: true });
    this.formik.current.submitForm();
  }

  onSubmitWithoutAdd = () => {
    this.setState({ addInviteAnotherProviderAfterSave: false });
    this.formik.current.submitForm();
  };

  inviteAnotherProvider() {
    this.setState({ addInviteAnotherProviderAfterSave: false });
    this.formik.current.resetForm();
    this._locationTypeahead.getInstance().clear();
    this._departmentTypeahead.getInstance().clear();
  }

  onSubmit(values) {
    this.props.inviteProvider(
      values,
      this.state.addInviteAnotherProviderAfterSave
    );
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    location: Yup.array().min(1, "Location is required"),
    department: Yup.array().min(1, "Department  is required"),
    degrees: Yup.array().min(1, "Degree is required"),
    specialities: Yup.array().min(1, "Specialities is required"),
    npiNumber: Yup.string()
      .min(10, "NPI number should be of 10 digits")
      .required("NPI number is required"),
    certifications: Yup.array().min(1, "Certification is required"),
    affiliations: Yup.array().min(1, "Affiliations is required"),
    conditionsTreated: Yup.array().min(1, "Conditions treated is required"),
    phone: Yup.string().required("Phone is required"),
    // .test("validPhoneNumber", "Phone number is invalid", value => {
    //   return isValidPhoneNumber(value);
    // }),
  });

  renderForm = (props) => {
    const { handleSubmit, values } = props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <label className="form-lable">First name</label>
          <Field
            type="text"
            value={values.firstName}
            name="firstName"
            className="form-control form-input"
            onChange={(e) => {
              var name = /^[a-zA-Z\s]*$/;
              if (e.target.value !== "" && e.target.value.match(name) === null)
                alert("Please input alphabetic characters only");
              else {
                props.setFieldValue("firstName", e.target.value);
              }
            }}
          />
          <span className="error_text">
            <ErrorMessage name="firstName" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Last name</label>
          <Field
            type="text"
            value={values.lastName}
            name="lastName"
            className="form-control form-input"
            onChange={(e) => {
              var name = /^[a-zA-Z\s]*$/;
              if (e.target.value !== "" && e.target.value.match(name) === null)
                alert("Please input alphabetic characters only");
              else {
                props.setFieldValue("lastName", e.target.value);
              }
            }}
          />
          <span className="error_text">
            <ErrorMessage name="lastName" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Phone</label>
          <Field
            className="form-control form-input"
            name="phone"
            type="text"
            value={values.phone}
            onChange={(e) => {
              var number = /^[0-9]+$/;
              if (
                e.target.value !== "" &&
                e.target.value.match(number) === null
              )
                alert("Please input numeric characters only");
              else {
                props.setFieldValue("phone", e.target.value);
              }
            }}
          />
          <span className="error_text">
            <ErrorMessage name="phone" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Email</label>
          <Field
            type="email"
            value={values.email}
            name="email"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="email" />
          </span>
        </div>

        <div className="form-group form-lable">
          <div className="row">
            <div className="col-6 single-typhead">
              <label className="form-lable">Location</label>

              <Typeahead
                id={"basic-typeahead-single"}
                options={this.props.locations}
                value={values.location}
                placeholder=""
                labelKey="primaryLocationName"
                name="location"
                className="singleSelect"
                ref={(ref) => (this._locationTypeahead = ref)}
                onChange={(selectedOptions) => {
                  props.setFieldValue("location", selectedOptions);
                  // props.setFieldTouched("location", true, true);
                }}
                onBlur={() => {
                  props.setFieldTouched("location", true, true);
                }}
                allowNew
              />
              <span className="error_text">
                <ErrorMessage name="location" />
              </span>
            </div>
            <div className="col-6 single-typhead">
              <label className="form-lable">Department</label>
              <Typeahead
                id={"basic-typeahead-single"}
                options={this.props.departments}
                labelKey="departmentName"
                value={values.department}
                placeholder=""
                name="department"
                ref={(ref) => (this._departmentTypeahead = ref)}
                className="singleSelect"
                onChange={(selectedOptions) => {
                  props.setFieldValue("department", selectedOptions);
                  // props.setFieldTouched("department", true, true);
                }}
                onBlur={() => {
                  props.setFieldTouched("department", true, true);
                }}
              />
              <span className="error_text">
                <ErrorMessage name="department" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Degree</label>
          <Typeahead
            id="basic-typeahead-multiple"
            options={values.allDegrees}
            selected={values.degrees}
            labelKey="name"
            placeholder=""
            name="degrees"
            onChange={(selectedOptions) => {
              props.setFieldValue("degrees", selectedOptions);
              // props.setFieldTouched("degrees", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("degrees", true, true);
            }}
            multiple
            allowNew
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="degrees" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Speciality</label>
          <Typeahead
            id="basic-typeahead-multiple"
            options={values.allSpecialities}
            placeholder=""
            name="specialities"
            selected={values.specialities}
            labelKey="name"
            onChange={(selectedOptions) => {
              props.setFieldValue("specialities", selectedOptions);
              // props.setFieldTouched("specialities", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("specialities", true, true);
            }}
            multiple
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="specialities" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">NPI number</label>
          <Field
            type="text"
            value={values.npiNumber}
            name="npiNumber"
            className="form-control form-input"
            onChange={(e) => {
              var number = /^[0-9]+$/;
              if (e.target.value.length > 10)
                alert("NPI number should be of 10 digits");
              else if (
                e.target.value !== "" &&
                e.target.value.match(number) === null
              )
                alert("Please input numeric characters only");
              else if (
                e.target.value.length === 10 &&
                parseInt(e.target.value) <= 0
              )
                alert("Please enter value greater than 0");
              else {
                props.setFieldValue("npiNumber", e.target.value);
              }
            }}
          />
          <span className="error_text">
            <ErrorMessage name="npiNumber" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Board certifications</label>
          <Typeahead
            id="basic-typeahead-multiple"
            options={values.allCertifications}
            selected={values.certifications}
            placeholder=""
            labelKey="name"
            name="certifications"
            onChange={(selectedOptions) => {
              props.setFieldValue("certifications", selectedOptions);
              // props.setFieldTouched("certifications", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("certifications", true, true);
            }}
            multiple
            allowNew
          />
          <span className="error_text">
            <ErrorMessage name="certifications" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Affiliations</label>
          <Typeahead
            id="basic-typeahead-multiple"
            options={values.allAffiliations}
            selected={values.affiliations}
            placeholder=""
            name="affiliations"
            labelKey="name"
            onChange={(selectedOptions) => {
              props.setFieldValue("affiliations", selectedOptions);
              // props.setFieldTouched("affiliations", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("affiliations", true, true);
            }}
            multiple
            allowNew
          />
          <span className="error_text">
            <ErrorMessage name="affiliations" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Conditions Treated</label>
          <Typeahead
            id="basic-typeahead-multiple"
            options={values.allConditionsTreated}
            selected={values.conditionsTreated}
            placeholder=""
            name="conditionsTreated"
            labelKey="name"
            onChange={(selectedOptions) => {
              props.setFieldValue("conditionsTreated", selectedOptions);
              // props.setFieldTouched("conditionsTreated", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("conditionsTreated", true, true);
            }}
            multiple
            allowNew
          />
          <span className="error_text">
            <ErrorMessage name="conditionsTreated" />
          </span>
        </div>
        <div
          className="row mt-5 no-gutter"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <div style={{ margin: "auto 10px auto auto", display: "flex" }}>
            <div
              className="user-icon"
              onClick={() => this.onSubmitWithAdd()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "left",
              margin: "auto auto auto 0px",
            }}
          >
            <span
              className="form-link"
              onClick={() => this.onSubmitWithAdd()}
              style={{ height: "37px", lineHeight: "37px" }}
            >
              Save and add another provider
            </span>
          </div>
        </div>

        <div className="mt-5 text-right">
          {/* chnages */}
          {/* {this.props.onBoarding === true && (
            <button
              type="button"
              className="btn mr-3 pull-left form-btn"
              onClick={this.goLastStep}
            >
              Back
            </button>
          )} */}
          {this.props.canSkip ? (
            <span
              className="form-link mr-5"
              onClick={() => this.props.onSkipForNow()}
            >
              Skip for now
            </span>
          ) : null}
          <button
            type="button"
            className="btn form-btn"
            id="btnLogin"
            onClick={this.onSubmitWithoutAdd}
            disabled={this.props.isSaveInProgress}
          >
            Next
            {this.props.isSaveInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };

  render() {
    let initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      location: [],
      department: [],
      degrees: [],
      specialities: [],
      npiNumber: [],
      certifications: [],
      affiliations: [],
      conditionsTreated: [],
      allDegrees: [],
      allCertifications: [],
      allConditionsTreated: [],
      allAffiliations: [],
      allSpecialities: [],
    };

    if (this.props.allDegrees) {
      initialValues.allDegrees = this.props.allDegrees;
    }

    if (this.props.allCertifications) {
      initialValues.allCertifications = this.props.allCertifications;
    }

    if (this.props.allConditionsTreated) {
      initialValues.allConditionsTreated = this.props.allConditionsTreated;
    }

    if (this.props.allAffiliations) {
      initialValues.allAffiliations = this.props.allAffiliations;
    }

    if (this.props.allSpecialities) {
      initialValues.allSpecialities = this.props.allSpecialities;
    }

    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>LETS SETUP YOUR PROVIDERS...</h3>
            <p>
              Providers are anyone in your organization like doctors, physician
              assistants, etc who will post content.
            </p>
          </div>

          <div className="form-container h-100" />
          <div className="row">
            <div className="col-4">
              <div className="form-lText ">
                When you set up a provider they will receive an email
                confirmation which will allow them to set up their own password.
              </div>
            </div>
            <div className="col-8">
              <div className="form-body">
                <Formik
                  innerRef={this.formik}
                  initialValues={initialValues}
                  onSubmit={this.onSubmit}
                  render={this.renderForm}
                  validationSchema={this.validationSchema}
                  onReset={this.addAnotherAdmin}
                  enableReinitialize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationInviteProvider;
