import {
  GET_CONDITION_TREATED_SUCCESS,
  GET_CONDITION_TREATED_ERROR,
  GET_CONDITION_TREATED_PROGRESS
} from "../actions/conditionsTreated.action";

const initialState = {
  conditionsTreated: [],
  error: null
};

const conditionsTreatedReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case GET_CONDITION_TREATED_PROGRESS: {
      newState = { ...state, conditionsTreated: [], error: null };
      break;
    }
    case GET_CONDITION_TREATED_SUCCESS: {
      newState = { ...state, conditionsTreated: action.payload, error: null };
      break;
    }
    case GET_CONDITION_TREATED_ERROR: {
      newState = { ...state, conditionsTreated: [], error: action.payload };
      break;
    }
  }

  return newState;
};

export default conditionsTreatedReducer;
