import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PostShare from "../components/postShare/postShare";
import { getPostShare } from "../actions/postShare.action";

class PostSharePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postId: this.props.match.params.id
    };

    this.renderPostShare = this.renderPostShare.bind(this);
  }

  componentDidMount() {
    this.props.getPostShare(this.state.postId);
  }

  renderPostShare = () => {
    if (this.props.post) {
      return <PostShare getState={this.props.state} post={this.props.post} />;
    } else {
      return <h1>Loading...!</h1>;
    }
  };

  render() {
    return (
      <div className="container-white-bg">
        <div className="container">{this.renderPostShare()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  state: state.postShareReducer.state,
  post: state.postShareReducer.post
});

const mapDispatchToProps = {
  getPostShare
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PostSharePage)
);
