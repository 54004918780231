export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const showError = errorMessage => dispatch => {
  dispatch({ type: SHOW_ERROR, payload: errorMessage });
};

export const clearError = () => dispatch => {
  dispatch({ type: CLEAR_ERROR });
};
