import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";
import { withRouter, Redirect } from "react-router-dom";
export const SAVE_ORG_RPOFILE_SUCCESS = "SAVE_ORG_RPOFILE_SUCCESS";
export const GET_ORG_PROFILE_SUCCESS = "GET_ORG_PROFILE_SUCCESS";
export const SAVE_ORG_LOGO_SUCCESS = "SAVE_ORG_LOGO_SUCCESS";
export const SAVE_ORG_RPOFILE_IN_PROGRESS = "SAVE_ORG_RPOFILE_IN_PROGRESS";
export const SAVE_ORG_RPOFILE_ERROR = "SAVE_ORG_RPOFILE_ERROR";

export const GET_ORG_TYPE_SUCCESS = "GET_ORG_TYPE_SUCCESS";

export const saveOrganizationProfile = (data) => (dispatch) => {
  dispatch({ type: SAVE_ORG_RPOFILE_IN_PROGRESS });
  return apiMethods
    .post("OrganisationProfile/Organisation", data)
    .then((response) => {
      dispatch({ type: SAVE_ORG_RPOFILE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SAVE_ORG_RPOFILE_ERROR });

      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};
export const saveOrganizationProfileopen = (data) => (dispatch) => {
  dispatch({ type: SAVE_ORG_RPOFILE_IN_PROGRESS });
  return apiMethods
    .post("OrganisationProfile/CreateOrganisationInfo", data)
    .then((response) => {
      dispatch({ type: SAVE_ORG_RPOFILE_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: SAVE_ORG_RPOFILE_ERROR });
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    });
};
export const setOrganizationLogo = (file, data) => (dispatch) => {
  return apiMethods
    .post("ProfilePic/OrganisationLogo", data)
    .then((response) => {
      dispatch({
        type: SAVE_ORG_LOGO_SUCCESS,
        payload: URL.createObjectURL(file),
      });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    });
};

export const getOrganizationProfile = () => (dispatch) => {
  return apiMethods
    .post("OrganisationProfile/OrganisationProfile")
    .then((response) => {
      if (response && response.data && response.data.organisationJson) {
        var orgProfile = {};
        orgProfile["OrganisationName"] =
          response.data.organisationJson["organisationName"] || "";
        orgProfile["Country"] = response.data.organisationJson["country"] || "";
        orgProfile["CountryId"] =
          response.data.organisationJson["countryId"] || "";
        orgProfile["PrimaryAddress"] =
          response.data.organisationJson["primaryAddress"] || "";
        orgProfile["City"] = response.data.organisationJson["city"] || "";
        orgProfile["CityId"] = response.data.organisationJson["cityId"] || "";
        orgProfile["State"] = response.data.organisationJson["state"] || "";
        orgProfile["StateId"] = response.data.organisationJson["stateId"] || "";
        orgProfile["Phone"] = response.data.organisationJson["phone"] || "";
        orgProfile["Fax"] = response.data.organisationJson["fax"] || "";
        orgProfile["PrimaryLocationName"] =
          response.data.organisationJson["primaryLocationName"] || "";
        orgProfile["Type"] =
          JSON.parse(response.data.organisationJson["type"]) || [];
        orgProfile["speciality"] =
          response.data.organisationJson["specialities"] || [];
        orgProfile["zipCode"] = response.data.organisationJson["zipCode"] || "";
        orgProfile["orgLogo"] =
          response.data.organisationJson["profileLogo"] || "";
        dispatch({ type: GET_ORG_PROFILE_SUCCESS, payload: orgProfile });
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};

export const getOrganizationType = () => (dispatch) => {
  apiMethods.get("OrganisationType").then(
    (response) => {
      dispatch({ type: GET_ORG_TYPE_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};
