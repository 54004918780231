import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  verifyLogin,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from "../actions/login.actions";

class LoginCallbackPage extends Component {
  // constructor(props) {
  //     super(props);
  // }

  componentDidMount() {
    if (this.props.isLoggedIn === false && this.props.state === null) {
      this.props.verifyLogin();
      return;
    }
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn === true && this.props.state === LOGIN_SUCCESS) {
      this.props.history.push("/next-step/login");
    }
  }

  render() {
    const showLoginLink =
      this.props.isLoggedIn === false && this.props.state === LOGIN_ERROR;
    if (this.props.isLoggedIn === false && this.props.state === LOGIN_ERROR) {
      setTimeout(() => {
        this.props.history.push("/logout");
      }, 2000);
    }

    return (
      <div className="container-bg">
        <div className="container">
          <div className={showLoginLink === false ? "visible" : "invisible"}>
            <div className="section-header">Verifying Login...!</div>
          </div>
          <div className={showLoginLink === false ? "invisible" : "visible"}>
            <div className="article-headline" style={{ textAlign: "center" }}>
              Error occurred while logging in <br />
              <Link to="/login">Login</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.login.state,
  error: state.login.error,
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = {
  verifyLogin,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginCallbackPage)
);
