import apiMethods from "../middleware/baseApiService";
import { ROLE_ORGANIZATION_ADMIN } from "../store/constants";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const ORG_INVITE_ADMIN_REQUEST = "ORG_INVITE_ADMIN_REQUEST";
export const ORG_INVITE_ADMIN_ERROR = "ORG_INVITE_ADMIN_ERROR";
export const ORG_INVITE_ADMIN_SUCCESS = "ORG_INVITE_ADMIN_SUCCESS";

export const inviteAdmin = inviteAdminParams => dispatch => {
  const inviteAdminRequest = {
    ...inviteAdminParams,
    userRole: ROLE_ORGANIZATION_ADMIN
  };

  dispatch({ type: ORG_INVITE_ADMIN_REQUEST });
  apiMethods
    .post("InviteUser", inviteAdminRequest)
    .then(response => {
      dispatch({ type: ORG_INVITE_ADMIN_SUCCESS, payload: response.data });
    })
    .catch(error => {
      let errorMessage = "Unknow error occurred while inviting user.";
      if (error && error.data && error.data.message) {
        errorMessage = error.data.message;
      }
      dispatch({ type: ORG_INVITE_ADMIN_ERROR, error: errorMessage });
      dispatch({ type: SHOW_ERROR, payload: errorMessage });
    });
};
