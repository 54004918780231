import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";

class OrganizationInviteSubadminField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addAnotherSubadminAfterSave: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.addAnotherSubadmin = this.addAnotherSubadmin.bind(this);
    this.onSubmitWithAdd = this.onSubmitWithAdd.bind(this);
    this.formik = React.createRef();
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    departments: Yup.array().min(1, "Select departments for this admin"),
    ContactPhone: Yup.string().required("Phone is required"),
    // .test("validPhoneNumber", "Phone number is invalid", value => {
    //   return isValidPhoneNumber(value);
    // }),
  });

  onSubmit(values) {
    this.props.onSubmit(values, this.state.addAnotherSubadminAfterSave);
  }

  goLastStep = () => {
    this.props.getPrevStep();
  };

  onSubmitWithAdd() {
    this.setState({ addAnotherSubadminAfterSave: true });
    this.formik.current.submitForm();
  }

  onSubmitWithoutAdd = () => {
    this.setState({ addAnotherSubadminAfterSave: false });
    this.formik.current.submitForm();
  };

  addAnotherSubadmin(values) {
    this.setState({ addAnotherSubadminAfterSave: false });
    this.formik.current.resetForm();
  }

  render() {
    let initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      ContactPhone: "",
      departments: [],
      allDepartments: [],
    };

    if (this.props.allDepartments) {
      initialValues.allDepartments = Object.assign(
        [],
        this.props.allDepartments
      );
    }

    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-container h-100" />
          <div className="col-12">
            <div
              style={{
                color: "#4a4a4a",
                fontFamily: "PTSansNarrow",
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: "26px",
                textAlign: "center",
              }}
            >
              When you set up department-admin they will receive an email
              confirmation which will allow them to set up their own password.
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-body">
                <Formik
                  innerRef={this.formik}
                  initialValues={initialValues}
                  onSubmit={this.onSubmit}
                  render={this.renderForm}
                  validationSchema={this.validationSchema}
                  // onReset={this.addAnotherSubadmin}
                  enableReinitialize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm = (props) => {
    const { handleSubmit, values } = props;
    let buttonText = this.props.isBoardingOff === true ? "Done" : "Next";

    return (
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div className="col-12 form-group form-lable">
              <label className="form-lable">First name</label>
              <Field
                type="text"
                value={values.firstName}
                name="firstName"
                className="form-control form-input"
                onChange={(e) => {
                  var name = /^[a-zA-Z]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(name) === null
                  )
                    alert("Please input alphabetic characters only");
                  else {
                    props.setFieldValue("firstName", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="firstName" />
              </span>
            </div>
            <div className="col-12 form-group form-lable">
              <label className="form-lable">Phone</label>
              <Field
                className="form-control form-input"
                name="ContactPhone"
                type="text"
                value={values.ContactPhone}
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("ContactPhone", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="ContactPhone" />
              </span>
            </div>
            <div
              className="col-12 form-group form-lable"
              // style={{ marginRight: "404px", marginLeft: "14px" }}
            >
              <label className="form-lable">Departments</label>
              <Typeahead
                className="form-control form-input"
                options={values.allDepartments}
                selected={values.departments}
                placeholder=""
                name="departments"
                labelKey="name"
                onChange={(selectedOptions) => {
                  props.setFieldValue("departments", selectedOptions, false);
                }}
                multiple
              />
              <span className="error_text">
                <ErrorMessage name="departments" />
              </span>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div className="col-12 form-group form-lable">
              <label className="form-lable">Last name</label>
              <Field
                type="text"
                value={values.lastName}
                name="lastName"
                className="form-control form-input"
                onChange={(e) => {
                  var name = /^[a-zA-Z]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(name) === null
                  )
                    alert("Please input alphabetic characters only");
                  else {
                    props.setFieldValue("lastName", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="lastName" />
              </span>
            </div>
            <div className="col-12 form-group form-lable">
              <label className="form-lable">Email</label>
              <Field
                type="email"
                value={values.email}
                name="email"
                className="form-control form-input"
              />
              <span className="error_text">
                <ErrorMessage name="email" />
              </span>
            </div>
          </div>
        </div>

        {/* <div className="form-group form-lable">
            <label className="form-lable">Phone</label>
            <Field
              className="form-control form-input"
              name="ContactPhone"
              type="text"
              value={values.ContactPhone}
              onChange={(e) => {
                var number = /^[0-9]+$/;
                if (
                  e.target.value !== "" &&
                  e.target.value.match(number) === null
                )
                  alert("Please input numeric characters only");
                else {
                  props.setFieldValue("ContactPhone", e.target.value);
                }
              }}
            />
            <span className="error_text">
              <ErrorMessage name="ContactPhone" />
            </span>
            <label className="form-lable">Email</label>
            <Field
              type="email"
              value={values.email}
              name="email"
              className="form-control form-input"
            />
            <span className="error_text">
              <ErrorMessage name="email" />
            </span>
          </div> */}

        {/* <div
          className="form-group form-lable"
          style={{ marginRight: "404px", marginLeft: "14px" }}
        >
          <label className="form-lable">Departments</label>
          <Typeahead
            options={values.allDepartments}
            selected={values.departments}
            placeholder=""
            name="departments"
            labelKey="name"
            onChange={(selectedOptions) => {
              props.setFieldValue("departments", selectedOptions, false);
            }}
            multiple
          />
          <span className="error_text">
            <ErrorMessage name="departments" />
          </span>
        </div> */}

        <div
          className="row mt-5 no-gutter"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <div style={{ margin: "auto 10px auto auto", display: "flex" }}>
            <div
              className="user-icon"
              onClick={() => this.onSubmitWithAdd()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "left",
              margin: "auto auto auto 0px",
            }}
          >
            <span
              className="form-link"
              onClick={() => this.onSubmitWithAdd()}
              style={{
                height: "37px",
                lineHeight: "37px",
                cursor: "pointer",
              }}
            >
              Save and add another department-admin
            </span>
          </div>
        </div>

        <div className="mt-5 text-right">
          {/* chnages */}
          {/* {this.props.isBoardingOff !== true && (
            <button
              type="button"
              className="btn mr-3 pull-left form-btn"
              onClick={this.goLastStep}
            >
              Back
            </button>
          )} */}
          {/* {this.props.canSkip ? (
            <span
              className="form-link mr-5"
              onClick={() => this.props.onSkipForNow()}
              style={{ cursor: "pointer" }}
            >
              Skip for now
            </span>
          ) : null} */}
          <button
            type="button"
            onClick={this.onSubmitWithoutAdd}
            className="btn form-btn"
            id="btnLogin"
            disabled={this.props.isSaveInProgress}
          >
            {buttonText}
            {this.props.isSaveInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };
}

export default OrganizationInviteSubadminField;
