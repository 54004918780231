import baseApiService from "../middleware/baseApiService";

export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_ERROR = "GET_DEPARTMENTS_ERROR";

export const getMyDepartments = (qString) => (dispatch) => {
  var url = "";
  if (qString === undefined) url = "Department/MyDepartments";
  else url = "Department/MyDepartments" + qString;
  dispatch({ type: GET_DEPARTMENTS_REQUEST });
  baseApiService
    .get(url)
    .then((response) => {
      dispatch({ type: GET_DEPARTMENTS_SUCCESS, payload: response.data });
      {
        console.log("this.props.departments", response.data);
      }
    })
    .catch((err) => {
      console.log("dispatch", err);
      dispatch({ type: GET_DEPARTMENTS_ERROR, error: err.data.message });
    });
};
