import React, { Component } from "react";
import RegisterComponent from "../components/registerinfo/registerinfo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import {
//   RegisterInfoRequestParams,
//   requestRegisterInfo,
//   REGISTERINFO_SUCCESS
// } from "../actions/registerinfo.actions";
import {
  RegisterRequestParams,
  requestRegister,
  REGISTER_SUCCESS,
} from "../actions/register.actions";
import { ROUTE_PATIENT_SIGNUP } from "./RouteConstants";
import RegisterInfoPopup from "../components/registerinfo/registerinfoPopup";
import { Modal } from "react-bootstrap";
import Healthswim_home_page from "../components/Healthswim_home_page";
import { HOME_ROOT } from "../middleware/baseApiService";

class RegisterInfoPage extends Component {
  constructor(props) {
    super(props);
    // this.registerinfo = this.registerinfo.bind(this);
    this.register = this.register.bind(this);
  }

  // registerinfo(registerInfoParams) {
  //   const registerInfoRequest = new RegisterInfoRequestParams(
  //     registerInfoParams.username,
  //     registerInfoParams.password,
  //     registerInfoParams.registrationType
  //   );
  //   this.props.requestRegisterInfo(registerInfoRequest);
  // }
  register(registerParams) {
    console.log("registerPa", registerParams);
    const registerRequest = new RegisterRequestParams(
      registerParams.username,
      registerParams.password,
      registerParams.registrationType,
      registerParams.mobile,
      registerParams.firstName,
      registerParams.lastName,
      registerParams.dateOfBirth,
      registerParams.gender
    );
    console.log("registerParams", registerRequest);
    this.props.requestRegister(registerRequest);
  }
  handleClose = () => {
    // window.location.href =
    //   "https://healthswimstagwebsite.z19.web.core.windows.net";
    window.location.href = HOME_ROOT;
  };
  componentDidUpdate() {
    // if (this.props.state === REGISTERINFO_SUCCESS) {
    //   this.props.history.push("/register-success");
    // }
    if (this.props.state === REGISTER_SUCCESS) {
      this.props.history.push("/register-success");
    }
  }

  redirectToAppDownloadPage = () =>
    this.props.history.push(ROUTE_PATIENT_SIGNUP);

  render() {
    return (
      <>
        <Modal
          style={{ width: "100%" }}
          show={true}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          dialogClassName="modal-35w"
          onHide={this.handleClose}
          scrollable={true}
        >
          <Modal.Header closeButton style={{ height: "67px" }}>
            <h3 style={{ color: "#fff" }}>SIGN UP</h3>
          </Modal.Header>
          <Modal.Body style={{ border: "none" }}>
            <div
              // className="form-rectangle"
              style={{ width: "100%", minHeight: "345px" }}
            >
              <div className=" h-100">
                <RegisterInfoPopup
                  register={this.register}
                  state={this.props.state}
                  erorr={this.props.error}
                  redirectToAppDownloadPage={this.redirectToAppDownloadPage}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Healthswim_home_page />
      </>
    );
  }
}

const mapPropsToProps = (state) => ({
  state: state.register.state,
  erorr: state.register.error,
});

const mapDispatchToProps = {
  // requestRegisterInfo: requestRegisterInfo
  requestRegister: requestRegister,
};

export default withRouter(
  connect(mapPropsToProps, mapDispatchToProps)(RegisterInfoPage)
);
