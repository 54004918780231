import React, { Component } from "react";
import "./channelSetup.css";
import { BULK_UPDATE_CHANNELS_IN_PROGRESS } from "../../actions/channelsetup.actions";

export default class ChannelSetup extends Component {
  constructor(props) {
    super(props);

    const copyOfInputChannels = Object.assign([], props.channels);
    this.state = {
      enteredChannelName: "",
      channels: copyOfInputChannels,
    };

    this.handleNewChannelNameChange =
      this.handleNewChannelNameChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.onHandleSkip = this.onHandleSkip.bind(this);
  }

  handleNewChannelNameChange = (event) => {
    this.setState({ enteredChannelName: event.target.value });
  };

  onKeyPress = (event) => {
    if (event.key === "Enter") {
      if (
        this.state.enteredChannelName.trim() !== "" &&
        !this.props.channels.find(
          (channel) => channel.name === this.state.enteredChannelName.trim()
        )
      ) {
        const copyOfExistingChannels = Object.assign([], this.props.channels);
        copyOfExistingChannels.push({
          name: this.state.enteredChannelName,
          id: null,
        });
        this.props.updateSpeclaityChannels(copyOfExistingChannels);
        this.setState({ enteredChannelName: "" });
      }
    }
  };

  handleRemoveClick = function (e) {
    var copyOfExistingChannels = Object.assign([], this.props.channels);
    copyOfExistingChannels = copyOfExistingChannels.filter(
      (el) => el.name !== e.target.id
    );
    this.props.updateSpeclaityChannels(copyOfExistingChannels);
  };

  onHandleSkip = function () {
    this.props.handleSkip();
  };

  render() {
    let buttonText = this.props.isBoardingOff === true ? "Done" : "Next";
    const channelList = this.props.channels.map((channel, i) => {
      return (
        <div className="pill mr-2" key={i} title={channel.name}>
          {channel.name}
          <button
            tabIndex="-1"
            aria-label="Remove"
            className="remove-btn"
            id={channel.name}
            onClick={this.handleRemoveClick}
          >
            x
          </button>
        </div>
      );
    });

    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>Lets setup some channels...</h3>
            <p>
              So your clients can easily search and find anything you publish.
            </p>
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText">
                  You can create a channel for any topic you like. Its ok if
                  similar or even same channels exist, each channel you create
                  will be identified as yours.
                </div>
                <div className="oval channel-oval" />
              </div>
              <div className="col-8">
                <div className="form-body">
                  <div className="form-group form-lable">
                    <label className="form-lable">
                      Type in any name you like either choose from the suggested
                      list or hit enter to create a completely new channel.
                      Create as many as you like.
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-input"
                        value={this.state.enteredChannelName}
                        onChange={this.handleNewChannelNameChange}
                        onKeyPress={this.onKeyPress}
                        placeholder="Channnel name"
                      />
                      {/* <div className="input-group-append">
                        <button className="input-icon" type="button">
                          <i className="fa fa-search" />
                        </button>
                      </div> */}
                    </div>
                    <div className="form-group form-lable mt-5">
                      <label className="form-lable">
                        We’ve created a few channels for you already based on
                        your profile. Delete any you don’t want or click ‘show
                        more’ to see even more.
                      </label>
                    </div>
                    <div className="list-container">{channelList}</div>

                    {this.props.channels.length > 0 ? (
                      <div className="d-flex">
                        <button
                          className="btn form-btn"
                          style={{ margin: "auto 0 auto auto" }}
                          onClick={() => this.props.updateSpeclaityChannels([])}
                        >
                          Remove all
                        </button>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-10 text-right">
                    {this.props.isBoardingOff === true ? (
                      ""
                    ) : (
                      <span
                        className="form-link  mr-5"
                        onClick={() => this.onHandleSkip()}
                        style={{ cursor: "pointer" }}
                      >
                        Skip for now
                      </span>
                    )}
                    <button
                      type="submit"
                      className="btn form-btn"
                      id="btnLogin"
                      onClick={this.props.bulkUpdate}
                    >
                      {buttonText}
                      {this.props.channelUpdateState ===
                      BULK_UPDATE_CHANNELS_IN_PROGRESS ? (
                        <span className="text-right pl-2">
                          <i
                            className="fa fa-circle-o-notch fa-spin"
                            style={{ color: "#fff" }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
