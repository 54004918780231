import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  REGISTER_SUCCESS,
  requestRegisterSuccess,
  REGISTER_REQUEST,
  requestResendOtpSuccess,
  LOGIN_EMAIL_ID,
} from "../actions/register.actions";

class OtpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // firstDigit: "",
      // secondDigit: "",
      // thirdDigit: "",
      // fourthDigit: "",
      // fifthDigit: "",
      // sixthDigit: "",
      // finalCode:""
      otp: Array(6).fill(""),
    };
  }
  componentDidUpdate() {
    if (this.props.state === REGISTER_SUCCESS) {
      // this.props.history.push("/login");
      this.props.history.push("/loginPage");
    }
  }
  handleChange = (e, index) => {
    // this.setState({ [e.target.name]: e.target.value });
    const value = e.target.value;
    if (value.match(/^[0-9]$/) || value === "") {
      const newOtp = [...this.state.otp];
      newOtp[index] = value;
      this.setState({ otp: newOtp });

      // Move focus to the next input field if the value is entered
      if (value !== "" && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && this.state.otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };
  handleSubmit = () => {
    const otpString = this.state.otp.join("");
    this.props.requestRegisterSuccess({
      otp: otpString,
      email: this.props.email,
    });
  };

  handleClick = () => {
    console.log("this.props.email", this.props.email);
    this.props.requestResendOtpSuccess({ email: this.props.email });
  };
  render() {
    console.log(this.state);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "-126px",
          position: "relative",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(196 239 235)",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            style={{
              height: "588px",
              width: "20%",
              // backgroundColor: "darkgray",
              // backgroundImage: "linear-gradient(skyblue, white)",
              borderRadius: "40px",
            }}
          >
            <div
              style={{
                backgroundImage: "linear-gradient(skyblue, white)",
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
                height: "360px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "40px 0px 20px 0px",
                }}
              >
                <h6>Account Verification</h6>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "95%",
                  backgroundColor: "#b3cce7",
                  margin: "auto",
                }}
              >
                <h8 style={{ fontWeight: "500", fontSize: "small" }}>
                  A 6-digit verification code has been sent to your email and/or
                  mobile phone, please enter here
                </h8>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "40px",
                }}
              >
                <div style={{ width: "10%", height: "45px" }}>
                  <input
                    maxlength="1"
                    id={`otp-input-${0}`}
                    type="text"
                    onChange={(e) => this.handleChange(e, 0)}
                    onKeyDown={(e) => this.handleKeyDown(e, 0)}
                    // name="firstDigit"
                    style={{
                      width: "100%",
                      height: "45px",
                      outline: "none",
                      borderRadius: "4px",
                      border: "none",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  />
                </div>
                <div style={{ width: "10%", height: "45px" }}>
                  <input
                    maxlength="1"
                    id={`otp-input-${1}`}
                    type="text"
                    onChange={(e) => this.handleChange(e, 1)}
                    onKeyDown={(e) => this.handleKeyDown(e, 1)}
                    // name="secondDigit"
                    style={{
                      width: "100%",
                      height: "45px",
                      outline: "none",
                      borderRadius: "4px",
                      border: "none",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  />
                </div>
                <div style={{ width: "10%", height: "45px" }}>
                  <input
                    maxlength="1"
                    onChange={(e) => this.handleChange(e, 2)}
                    onKeyDown={(e) => this.handleKeyDown(e, 2)}
                    id={`otp-input-${2}`}
                    type="text"
                    // name="thirdDigit"
                    style={{
                      width: "100%",
                      height: "45px",
                      outline: "none",
                      borderRadius: "4px",
                      border: "none",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  />
                </div>{" "}
                <h2>-</h2>
                <div style={{ width: "10%", height: "45px" }}>
                  <input
                    maxlength="1"
                    onChange={(e) => this.handleChange(e, 3)}
                    onKeyDown={(e) => this.handleKeyDown(e, 3)}
                    id={`otp-input-${3}`}
                    type="text"
                    // name="fourthDigit"
                    style={{
                      width: "100%",
                      height: "45px",
                      outline: "none",
                      borderRadius: "4px",
                      border: "none",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  />
                </div>
                <div style={{ width: "10%", height: "45px" }}>
                  <input
                    maxlength="1"
                    onChange={(e) => this.handleChange(e, 4)}
                    onKeyDown={(e) => this.handleKeyDown(e, 4)}
                    id={`otp-input-${4}`}
                    type="text"
                    // name="fifthDigit"
                    style={{
                      width: "100%",
                      height: "45px",
                      outline: "none",
                      borderRadius: "4px",
                      border: "none",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  />
                </div>
                <div style={{ width: "10%", height: "45px" }}>
                  <input
                    maxlength="1"
                    onChange={(e) => this.handleChange(e, 5)}
                    onKeyDown={(e) => this.handleKeyDown(e, 5)}
                    id={`otp-input-${5}`}
                    type="text"
                    // name="sixthDigit"
                    style={{
                      width: "100%",
                      height: "45px",
                      outline: "none",
                      borderRadius: "4px",
                      border: "none",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{
                    border: "none",
                    height: "40px",
                    width: "85px",
                    outline: "none",
                    borderRadius: "5px",
                    backgroundColor: "#c3c0c0",
                    color: "white",
                  }}
                  disabled={this.state.otp.join("").length < 6}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <span>Did not receive the code?</span>{" "}
                <span
                  style={{ color: "#00c6ff", cursor: "pointer" }}
                  onClick={this.handleClick}
                >
                  Send again
                </span>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                backgroundColor: "#c3c0c0",
                gridTemplateColumns: "auto auto auto",
                // margin: "15px",
                padding: "12px 5px 12px 5px",
                borderBottomLeftRadius: "40px",
                borderBottomRightRadius: "40px",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                1
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                2
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                3
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                4
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                5
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                6
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                7
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                8
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                9
              </div>
              <div
                style={{
                  // backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                0
              </div>
              <div
                style={{
                  // backgroundColor: "white",
                  border: "5px solid #c3c0c0",
                  height: "50px",
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "8px",
                  fontWeight: "600",
                }}
              >
                x
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  state: state.register.state,
  erorr: state.register.error,
  email: state.register.email,
});
const mapDispatchToProps = {
  requestRegisterSuccess,
  requestResendOtpSuccess,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtpPage)
);
