import {
  GET_DEGREES_SUCCESS,
  GET_SPECIALITIES_SUCCESS,
  SAVE_PERSONAL_INFO_ERROR,
  SAVE_PERSONAL_INFO_IN_PROGRESS,
  SAVE_PERSONAL_INFO_SUCCESS,
  SAVE_PROFILE_IMG_SUCCESS,
  GET_PERSONAL_PROFILE_ERROR,
  GET_PERSONAL_PROFILE_SUCCESS,
  GET_PERSONAL_PROFILE_REQUEST,
  UPDATE_PROFILE_IMG_SUCCESS,
  ORGANIZATION_PROFILE_SUCCESS,
  GET_SUPER_ADMIN,
} from "../actions/personalProfile.actions";

const intialState = {
  specialities: [],
  degrees: [],
  saveState: null,
  error: null,
  profileImg: null,
  profile: null,
  getProfileState: null,
  orgProfile: null,
  isHealthswimAdmin: false,
};

const personalProfile = (state = intialState, action) => {
  switch (action.type) {
    case GET_SPECIALITIES_SUCCESS:
      return { ...state, specialities: action.payload };
    case GET_DEGREES_SUCCESS:
      return { ...state, degrees: action.payload };
    case SAVE_PERSONAL_INFO_IN_PROGRESS: {
      return {
        ...state,
        saveState: SAVE_PERSONAL_INFO_IN_PROGRESS,
        error: null,
      };
    }
    case SAVE_PERSONAL_INFO_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        saveState: SAVE_PERSONAL_INFO_SUCCESS,
        error: null,
      };
    }
    case SAVE_PERSONAL_INFO_ERROR: {
      return {
        ...state,
        saveState: SAVE_PERSONAL_INFO_ERROR,
        error: action.payload,
      };
    }
    case SAVE_PROFILE_IMG_SUCCESS:
      return {
        ...state,
        profileImg: URL.createObjectURL(action.payload),
      };

    case GET_PERSONAL_PROFILE_REQUEST: {
      return {
        ...state,
        getProfileState: GET_PERSONAL_PROFILE_REQUEST,
      };
    }

    case GET_PERSONAL_PROFILE_SUCCESS: {
      return {
        ...state,
        getProfileState: GET_PERSONAL_PROFILE_SUCCESS,
        profile: action.payload,
        profileImg: action.payload.profileImg,
      };
    }
    case GET_SUPER_ADMIN: {
      return {
        ...state,
        getProfileState: GET_SUPER_ADMIN,
        isHealthswimAdmin: action.payload,
      };
    }
    case GET_PERSONAL_PROFILE_ERROR: {
      return {
        ...state,
        getProfileState: GET_PERSONAL_PROFILE_ERROR,
        error: action.error,
      };
    }
    case UPDATE_PROFILE_IMG_SUCCESS: {
      return {
        ...state,
        profileImg: action.payload,
      };
    }
    case ORGANIZATION_PROFILE_SUCCESS: {
      return {
        ...state,
        orgProfile: action.payload,
      };
    }
    default:
      return state;
  }
};

export default personalProfile;
