import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter, Redirect } from "react-router-dom";
import {
  saveProfessionalInfo,
  SAVE_PROFESSIONAL_INFO_IN_PROGRESS,
  getProfessionalInfo,
} from "../actions/professionalInfo.actions";
import { getCertifications } from "../actions/certifications.action";
import { getAffiliations } from "../actions/affiliations.action";
import { getConditionsTreated } from "../actions/conditionsTreated.action";
import ProfessionalLife from "../components/professionalLife/professionalLife";
import { ROUTE_DASHBOARD } from "./RouteConstants";
import {
  getDegrees,
  savePersonalProfile,
  setProfilePic,
  getSpecialities,
  getPersonalProfile,
  SAVE_PERSONAL_INFO_IN_PROGRESS,
  updateProfileImg,
} from "../actions/personalProfile.actions";
import PersonalProfile from "../components/personalInfo/personalInfo";

class ProfileEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "edit",
    };

    this.saveProfessionalInfo = this.saveProfessionalInfo.bind(this);
    this.savePersonalInfo = this.savePersonalInfo.bind(this);
    this.saveProfieImg = this.saveProfieImg.bind(this);
  }

  componentWillMount() {
    this.props.getCertifications();
    this.props.getAffiliations();
    this.props.getConditionsTreated();
    this.props.getProfessionalInfo();
    this.props.getDegrees();
    this.props.getSpecialities();

    this.props.getPersonalProfile(this.props.email);
  }

  saveProfessionalInfo(professionalInfo) {
    this.props.saveProfessionalInfo(professionalInfo);
  }

  savePersonalInfo(personalInfo) {
    this.props.savePersonalProfile(personalInfo);
  }

  saveProfieImg(event) {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let formData = new FormData();
      let data = {};
      formData.append("file", event.target.files[0]);
      data["formData"] = formData;
      data["file"] = file;
      this.props.setProfilePic(data);
    }
  }

  render() {
    const isProfessionInfoSaveInProgress =
      this.props.saveProfessionalInfoState ===
      SAVE_PROFESSIONAL_INFO_IN_PROGRESS;

    const isPersonalInfoSaveInProgress =
      this.props.savePersonalInfoState === SAVE_PERSONAL_INFO_IN_PROGRESS;

    if (isProfessionInfoSaveInProgress || isPersonalInfoSaveInProgress) {
      return <Redirect to={ROUTE_DASHBOARD} />;
    }

    return (
      <div className="container-bg">
        <PersonalProfile
          mode={this.state.mode}
          specialities={this.props.specialities}
          saveProfile={this.savePersonalInfo}
          saveProfileImg={this.saveProfieImg}
          profileImg={this.props.profileImg}
          degrees={this.props.degrees}
          isSaveInProgress={isPersonalInfoSaveInProgress}
          personalProfile={this.props.personalProfile}
          updateProfileImg={this.props.updateProfileImg}
        />
        <div className="mt-5">
          <ProfessionalLife
            mode={this.state.mode}
            certifications={this.props.certifications}
            affiliations={this.props.affiliations}
            conditionsTreated={this.props.conditionsTreated}
            saveProfessionalInfo={this.saveProfessionalInfo}
            isSaveInProgress={isProfessionInfoSaveInProgress}
            handleSkip={this.handleSkip}
            professionalInfo={this.props.professionalInfo}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveProfessionalInfoState: state.professionalInfoReducer.saveState,
  error: state.professionalInfoReducer.error,
  certifications: state.certifications.certificates,
  affiliations: state.affiliations.affiliations,
  conditionsTreated: state.conditionsTreated.conditionsTreated,
  professionalInfo: state.professionalInfoReducer.professionalInfo,
  savePersonalInfoState: state.personalProfile.saveState,
  specialities: state.personalProfile.specialities,
  degrees: state.personalProfile.degrees,
  profileImg: state.personalProfile.profileImg,
  personalProfile: state.personalProfile.profile,
  email: state.login.email,
});

const mapDispatchToProps = {
  saveProfessionalInfo,
  getCertifications,
  getConditionsTreated,
  getAffiliations,
  getProfessionalInfo,
  getDegrees,
  getSpecialities,
  savePersonalProfile,
  setProfilePic,
  getPersonalProfile,
  updateProfileImg,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileEditPage)
);
