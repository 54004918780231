import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const GET_CERTIFICATES_SUCCESS = "GET_CERTIFICATES_SUCCESS";
export const GET_CERTIFICATES_ERROR = "GET_CERTIFICATES_ERROR";
export const GET_CERTIFICATES_PROGRESS = "GET_CERTIFICATES_PROGRESS";

export const getCertifications = certificates => (dispatch, state) => {
  dispatch({ type: GET_CERTIFICATES_PROGRESS });
  apiMethods
    .get("certifications")
    .then(response => {
      dispatch({ type: GET_CERTIFICATES_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
      dispatch({ type: GET_CERTIFICATES_ERROR, error: error });
    });
};
