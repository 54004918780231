import React, { Component } from "react";
import RegisterComponent from "../components/registerinfo/registerinfo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import {
//   RegisterInfoRequestParams,
//   requestRegisterInfo,
//   REGISTERINFO_SUCCESS
// } from "../actions/registerinfo.actions";
import {
  RegisterRequestParams,
  requestRegister,
  REGISTER_SUCCESS,
} from "../actions/register.actions";
import { ROUTE_PATIENT_SIGNUP } from "./RouteConstants";

class RegisterInfoPage extends Component {
  constructor(props) {
    super(props);
    // this.registerinfo = this.registerinfo.bind(this);
    this.register = this.register.bind(this);
  }

  // registerinfo(registerInfoParams) {
  //   const registerInfoRequest = new RegisterInfoRequestParams(
  //     registerInfoParams.username,
  //     registerInfoParams.password,
  //     registerInfoParams.registrationType
  //   );
  //   this.props.requestRegisterInfo(registerInfoRequest);
  // }
  register(registerParams) {
    console.log("registerParams", registerParams);
    const registerRequest = new RegisterRequestParams(
      registerParams.username,
      registerParams.password,
      registerParams.registrationType,
      registerParams.mobile
    );
    this.props.requestRegister(registerRequest);
  }

  componentDidUpdate() {
    // if (this.props.state === REGISTERINFO_SUCCESS) {
    //   this.props.history.push("/register-success");
    // }
    if (this.props.state === REGISTER_SUCCESS) {
      this.props.history.push("/register-success");
    }
  }

  redirectToAppDownloadPage = () =>
    this.props.history.push(ROUTE_PATIENT_SIGNUP);

  render() {
    return (
      <div className="container-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12">&nbsp;</div>
            <div className="col-md-6 col-sm-12">
              <RegisterComponent
                register={this.register}
                state={this.props.state}
                erorr={this.props.error}
                redirectToAppDownloadPage={this.redirectToAppDownloadPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToProps = (state) => ({
  state: state.register.state,
  erorr: state.register.error,
});

const mapDispatchToProps = {
  // requestRegisterInfo: requestRegisterInfo
  requestRegister: requestRegister,
};

export default withRouter(
  connect(mapPropsToProps, mapDispatchToProps)(RegisterInfoPage)
);
