import React, { Component } from "react";
import "./orgManageLocation.css";
import EditLocation from "./editLocation";
import LocationCard from "./locationCard";

export default class OrgManageLocation extends Component {
  render() {
    return (
      <div className="manage-form">
        <div className="row">
          <div className="col-12 text-center">
            <div className="oval location-oval" />
          </div>
        </div>
        <div
          className="row mtb-60 "
          style={{ display: "flex", marginTop: "30px" }}
        >
          <div className="col-4" style={{ margin: "auto" }}>
            <div
              style={{ cursor: "pointer" }}
              className="manage-form-label"
              onClick={this.props.createNewLocation}
            >
              + Create new
            </div>
          </div>
        </div>

        <div
          className="manage-form-container"
          style={{
            overflow: "auto",
          }}
        >
          <div
            className="row mb-16  no-gutter"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            {this.props.locations.length > 0 ? (
              this.props.locations.map((location, i) => {
                return (
                  <LocationCard
                    key={`location_${i}`}
                    location={location}
                    index={i}
                    updateCurrentLocationIndex={
                      this.props.updateCurrentLocationIndex
                    }
                  />
                );
              })
            ) : (
              <div
                style={{
                  margin: "auto",
                  fontFamily: "proximaNova",
                  fontSize: "30px",
                }}
              >
                No locations Found
              </div>
            )}
          </div>
        </div>

        <div className="row mb-16  no-gutter">
          <div className="col-12">
            {this.props.locations[this.props.currentLocationIndex] ? (
              <EditLocation
                location={this.props.locations[this.props.currentLocationIndex]}
                deleteOrganizationLocation={
                  this.props.deleteOrganizationLocation
                }
                updateOrganizationLocation={
                  this.props.updateOrganizationLocation
                }
                specialities={this.props.specialities}
                show={this.props.show}
                handleClose={this.props.handleClose}
                orgTypes={this.props.orgTypes}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
