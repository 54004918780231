import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseButton from "react-bootstrap/CloseButton";
import PDFViewer from "pdf-viewer-reactjs";

import { Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "-20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid gray",
    width: "50%",
  },
};
function PreviewPageFoePDF(props) {
  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const { ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: "#357edd",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={props.onClick}
                  >
                    Zoom out
                  </button>
                )}
              </ZoomOut>
            </div>
            ...
          </div>
        );
      }}
    </Toolbar>
  );
  const defaultLayoutPluginInstance = renderToolbar();

  return (
    <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
      <div className="popBox">
        <h5>Share a document</h5>
        <CloseButton
          aria-label="Hide"
          onClick={props.onClose}
          // style={{ position: "sticky", right: "4px" }}
        />
      </div>

      {/* {
        "https://healthswimstagingcontent.blob.core.windows.net/res-container/e2de49ce-e0b0-4f84-ba58-1846bdeb89a1.pdf"
      }
      {props.document && (
        <PDFViewer
          document={{
            url: "https://healthswimstagingcontent.blob.core.windows.net/res-container/e2de49ce-e0b0-4f84-ba58-1846bdeb89a1.pdf",
          }}
        />
      )} */}
      {/* {props.document && (
        <iframe
          title="pdf"
          width="100%"
          height="600px"
          src={props.document}
        ></iframe>
      )} */}

      {/* {props.document && (
        <object
          type="application/pdf"
          data={props.document}
          width="250"
          height="200"
        ></object>
      )} */}
      <div>
        {props.document && (
          <>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <Viewer
                fileUrl={props.document}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </>
        )}
      </div>
      <div className="setBottom">
        {" "}
        <button
          onClick={props.onClose}
          className="btn"
          style={{ backgroundColor: "skyblue", marginTop: "10px" }}
        >
          Done
        </button>
      </div>
    </Modal>
  );
}

export default PreviewPageFoePDF;
