import React from "react";
import logo from "../../src/img/lock.png";
import logo1 from "../img/thumbnail_hs_logo_horiz.png";
function SuccessResetPassword() {
  return (
    <div>
      {" "}
      <img
        src={logo1}
        alt="symbol"
        className="logo-set"
        style={{ marginTop: "55px" }}
      />
      <div className="form-rectangle set-box">
        <div>
          {" "}
          <img src={logo} alt="symbol" className="success-img" />
          <div className="center-message">Password Changed</div>
          <div style={{ textAlign: "center" }}>
            Please log in with the new password.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessResetPassword;
