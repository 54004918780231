import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationDepartment from "../components/organizationDepartment/organizationDepartment";
import {
  createDepartments,
  getDepartmentList,
  CREATEDEPARTMENTS_SUCCESS,
  getOrganizationDepartments,
  addNewDepartment,
  removeDepartmentFromList,
} from "../actions/organizationDepartment.action";
import { ROUTE_ORG_DEPARTMENT, ROUTE_ORG_LOCATION } from "./RouteConstants";
import { getPrevStep } from "../actions/onboardingStatus.actions";

const mapStateToProps = (state) => {
  return {
    createDepartmentsStatus:
      state.organizationDepartment.createDepartmentsStatus,
    canSkip: state.onboardingStatus.canSkip,
    departmentList: state.organizationDepartment.departmentList,
    departments: state.organizationDepartment.departments,
    prevStep: state.onboardingStatus.prevStep,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDepartments: (data) => dispatch(createDepartments(data)),
    getDepartmentList: () => dispatch(getDepartmentList()),
    getOrganizationDepartments: () => dispatch(getOrganizationDepartments()),
    removeDepartmentFromList: (index) =>
      dispatch(removeDepartmentFromList(index)),
    addNewDepartment: (data) => dispatch(addNewDepartment(data)),
    getPrevStep: () =>
      dispatch(getPrevStep("?currentStep=ORGANIZATION-DEPARTMENTS")),
  };
};

class OrganizationDepartmentPage extends React.Component {
  componentDidMount() {
    this.props.getDepartmentList();
    this.props.getOrganizationDepartments();
  }

  addNewDepartment = (name) => {
    this.props.addNewDepartment({ departmentName: name, remove: true });
  };

  removeDepartment = (index) => {
    this.props.removeDepartmentFromList(index);
  };

  getPrevStep = () => {
    this.props.getPrevStep();
  };

  onSkipForNow = () =>
    this.props.history.push(`/next-step${ROUTE_ORG_DEPARTMENT}`);

  render() {
    if (
      this.props.prevStep !== null &&
      this.props.prevStep.name === "ORGANIZATION-LOCATION"
    ) {
      return <Redirect to={ROUTE_ORG_LOCATION} />;
    }
    if (this.props.createDepartmentsStatus === CREATEDEPARTMENTS_SUCCESS) {
      return <Redirect to={`/next-step${ROUTE_ORG_DEPARTMENT}`} />;
    }

    return (
      <div className="container-bg">
        <OrganizationDepartment
          departments={this.props.departments}
          addNewDepartment={this.addNewDepartment}
          removeDepartment={this.removeDepartment}
          createDepartments={this.props.createDepartments}
          canSkip={this.props.canSkip}
          onSkipForNow={this.onSkipForNow}
          departmentList={this.props.departmentList}
          getPrevStep={this.getPrevStep}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationDepartmentPage)
);
