import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ClientProfile from "../components/clientProfile/clientProfile.js";
import {
  setClientProfile,
  SET_CLIENT_PROFILE_IN_PROGRESS
} from "../actions/clientProfile.action";
import { setProfilePic } from "../actions/personalProfile.actions";

class ClientProfileEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "edit"
    };
  }

  saveProfieImg = event => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let formData = new FormData();
      let data = {};
      formData.append("file", event.target.files[0]);
      data["formData"] = formData;
      data["file"] = file;
      this.props.setProfilePic(data);
    }
  };

  render() {
    const isSaveInProgress =
      this.props.setProfileState === SET_CLIENT_PROFILE_IN_PROGRESS;

    return (
      <div>
        <ClientProfile
          mode={this.state.mode}
          setClientProfile={this.props.setClientProfile}
          setProfileState={this.props.setProfileState}
          isSaveInProgress={isSaveInProgress}
          profile={this.props.profile}
          saveProfileImg={this.saveProfieImg}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  setProfileState: state.ClientProfile.setProfileState,
  profile: state.personalProfile.profile
});

const mapDispatchToProps = dispatch => ({
  setClientProfile: data => dispatch(setClientProfile(data)),
  setProfilePic: data => dispatch(setProfilePic(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClientProfileEditPage)
);
