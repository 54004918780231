import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class CreateNewChannelComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      department: [],
      errorMsg: "",
    };

    this.channelTextBoxRef = React.createRef();
    this.CreateNewChannel = this.CreateNewChannel.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  CreateNewChannel() {
    if (this.channelTextBoxRef.current.value) {
      if (
        this.props.profile.securityMatrix.accessibleDepartments &&
        this.props.profile.securityMatrix.accessibleDepartments.length > 0 &&
        this.state.department.length === 0
      ) {
        this.setState({ errorMsg: "Please select department" });
        return;
      }
      this.props.createNewChannel({
        name: this.channelTextBoxRef.current.value,
        departmentId: this.state.department ? this.state.department.id : null,
      });
      this.setState({ department: [], errorMsg: "" });
      this.props.closeCreateChannelDialog();
    }
  }

  handleSelection(department) {
    if (department.length > 0 && this.state.department !== department[0]) {
      this.setState({ department: department[0], errorMsg: "" });
    } else {
      if (
        this.props.profile.securityMatrix.accessibleDepartments &&
        this.props.profile.securityMatrix.accessibleDepartments.length > 0 &&
        department.length === 0
      ) {
        this.setState({ department: [], errorMsg: "Please select department" });
      }
    }
  }

  render() {
    return (
      <div style={{ top: "36%" }}>
        <Modal
          show={this.props.openCreateChannelDialog}
          onHide={this.props.closeCreateChannelDialog}
          style={{ top: "36%" }}
        >
          <div style={{ overflow: "hidden" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ margin: "auto", marginRight: "-7%" }}>
                Create new channel
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.profile.securityMatrix.isProvider === true ||
              (this.props.profile.securityMatrix.accessibleDepartments &&
                this.props.profile.securityMatrix.accessibleDepartments.length >
                  0) ? (
                <div className="form-group form-lable">
                  {/* <Typeahead
                  options={this.props.myDepartments}
                  labelKey="departmentName"
                  name="depatment"
                  onChange={(selected) => {
                    this.handleSelection(selected);
                  }}
                  placeholder="Select department"
                /> */}
                  <span className="error_text">{this.state.errorMsg}</span>
                </div>
              ) : (
                ""
              )}
              <div className="form-group form-lable">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Enter channel name..."
                  ref={this.channelTextBoxRef}
                />
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "1px solid #ffffff" }}>
              <Button
                className="btn form-btn-sm"
                onClick={this.CreateNewChannel}
                style={{
                  margin: "auto",
                }}
              >
                Create
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  myDepartments: state.departmentReducer.myDepartments,
});

// const mapDispatchToProps = {
//   getMyDepartments
// }

const CreateNewChannel = CreateNewChannelComponent;

export default withRouter(connect(mapStateToProps, "")(CreateNewChannel));
