import {
  GET_LIST_TYPE_SUCCESS,
  ADD_NEW_LIST_TYPE_VALUE,
  REMOVE_LIST_TYPE_VALUE,
  RESET_STATE
} from "../actions/orgmanageLists.action";

const intialState = {
  listTypeData: []
};

const orgManageLists = (state = intialState, action) => {
  switch (action.type) {
    case GET_LIST_TYPE_SUCCESS:
      return { ...state, listTypeData: action.payload };
    case ADD_NEW_LIST_TYPE_VALUE:
      return {
        ...state,
        listTypeData: [...state.listTypeData, action.payload]
      };
    case REMOVE_LIST_TYPE_VALUE:
      return {
        ...state,
        listTypeData: [
          ...state.listTypeData.slice(0, action.payload),
          ...state.listTypeData.slice(action.payload + 1)
        ]
      };
    case RESET_STATE:
      return { ...state, listTypeData: [] };
    default:
      return state;
  }
};

export default orgManageLists;
