import React from "react";
//import { formatPhoneNumber } from "react-phone-number-input";

class LocationCard extends React.Component {
  render() {
    return (
      <div
        className="col-4"
        style={{ cursor: "pointer" }}
        onClick={() => this.props.updateCurrentLocationIndex(this.props.index)}
      >
        <div className="rectangle-info">
          <div className="loc-name text-left">
            {this.props.location.primaryLocationName || ""}
          </div>
          <div className="row">
            <div className="col-6">
              <div
                title={
                  (this.props.location.type &&
                    JSON.parse(this.props.location.type)[0].name) ||
                  ""
                }
                className="loc-clinic d-flex"
              >
                <span style={{ margin: "auto" }}>
                  {(this.props.location.type &&
                    JSON.parse(this.props.location.type)[0].name) ||
                    ""}
                </span>
              </div>
            </div>
            <div className="col-6" style={{ paddingLeft: "0px" }}>
              <div className="loc-info-txt">
                {this.props.location.locationContact || ""}
              </div>
              <div className="loc-info-txt">
                {this.props.location.locationContactEmail || ""}
              </div>
              <div className="loc-info-txt">
                {this.props.location.phone || ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationCard;
