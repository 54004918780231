import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";
import { getPersonalProfile } from "./personalProfile.actions";

export const SET_CLIENT_PROFILE_IN_PROGRESS = "SET_CLIENT_PROFILE_IN_PROGRESS";
export const SET_CLIENT_PROFILE_SUCCESS = "SET_CLIENT_PROFILE_SUCCESS";
export const SET_CLIENT_PROFILE_ERROR = "SET_CLIENT_PROFILE_ERROR";

export const setClientProfile = data => dispatch => {
  dispatch({ type: SET_CLIENT_PROFILE_IN_PROGRESS });
  apiMethods.post("Profile/Client", data).then(
    response => {
      dispatch({ type: SET_CLIENT_PROFILE_SUCCESS });
      dispatch(getPersonalProfile());
    },
    error => {
      dispatch({ type: SET_CLIENT_PROFILE_ERROR });
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};
