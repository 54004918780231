import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ShareNewPost from "../components/shareNewPost/shareNewPost";
import Timeline from "../components/tImeline/Timeline";
import {
  getMyChannels,
  getSubscribedChannels,
  updateChnannelStatus,
  createNewChannel,
  unsubscribeChannel,
} from "../actions/channel.action";
import { getPersonalProfile } from "../actions/personalProfile.actions";
import {
  getFeed,
  toggleLikeForPost,
  savePost,
  removePost,
  sharePost,
  deletePost,
  getDeletedPosts,
} from "../actions/timeLine.action";
import {
  createPost,
  getLinkPreview,
  updateShowPreview,
  updatePostType,
  addSelectedChannel,
  handleInputChange,
  emptyFeed,
} from "../actions/publishContent.actions";
import {
  setTimeLineType,
  setTimeLineCurrentChannel,
} from "../actions/timeLineFilter.action";
import { getMyDepartments } from "../actions/department.action";

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.getFeed = this.getFeed.bind(this);
    this.loadMyChannels = this.loadMyChannels.bind(this);

    this.shareNewPostRef = React.createRef();
    this.state = {
      disableCreateButton: false,
      channelId: "",
    };
  }

  componentDidMount() {
    this.props.getSubscribedChannels();
    this.props.getMyDepartments();
    this.props.getPersonalProfile(this.props.email);
    if (this.props.profile && !this.props.profile.securityMatrix.clientId) {
      this.loadMyChannels();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.profile &&
      this.props.profile !== nextProps.profile &&
      !nextProps.profile.securityMatrix.clientId
    ) {
      this.loadMyChannels();
    }
  }

  loadMyChannels() {
    this.props.getMyChannels();
  }

  getFeed(feedCriteria) {
    this.props.getFeed(feedCriteria);
  }
  createNewChannel = (data) => {
    this.props.createNewChannel(data);
    this.props.getMyChannels();
    this.setState({ channelId: localStorage.getItem("id") });
  };
  getPreview = () => {
    if (this.props.linkUrl.trim() !== "")
      this.props.getLinkPreview({ url: this.props.linkUrl });
  };

  sharepost = (data) => {
    this.props.sharePost(data).then((response) => {
      if (this.props.timelineType === "all") {
        this.props.getFeed({
          all: true,
          from: 1,
          size: 10,
        });
      }
    });
  };

  createPost = (newPostdata) => {
    this.setState({ disableCreateButton: true });
    if (newPostdata.postType === "Link") {
      this.props.getLinkPreview({ url: newPostdata.link }).then((response) => {
        newPostdata["subject"] = this.props.previewData.title;
        newPostdata["content"] = this.props.previewData.descp;
        newPostdata["url"] = this.props.previewData.url;
        newPostdata["metaDataValue"] = JSON.stringify(this.props.previewData);
        this.props.createPost(newPostdata).then((response) => {
          this.props.setTimeLineType("all");
          this.props.emptyFeed();
          this.props.getFeed({ all: true, from: 1, size: 10 });
          this.loadMyChannels();
          this.shareNewPostRef &&
            this.shareNewPostRef.current &&
            this.shareNewPostRef.current._typeahead.getInstance().clear();
          this.setState({ disableCreateButton: false });
        });
      });
    } else
      this.props.createPost(newPostdata).then((response) => {
        // this.shareNewPostRef.current._typeahead.getInstance().clear();
        this.setState({ disableCreateButton: false });
      });
  };

  render() {
    return (
      <div
        className="shareNewPost"
        style={{ width: "1523px", marginLeft: "auto", marginRight: "auto" }}
      >
        {this.props.profile && !this.props.profile.securityMatrix.clientId ? (
          <ShareNewPost
            ref={this.shareNewPostRef}
            myChannels={this.props.myChannels}
            showPreview={this.props.showPreview}
            previewData={this.props.previewData}
            closePreview={this.props.updateShowPreview}
            linkUrl={this.props.linkUrl}
            inputChange={this.props.handleInputChange}
            getLinkPreview={this.getPreview}
            documentTitle={this.props.documentTitle}
            videoTitle={this.props.videoTitle}
            imageTitle={this.props.imageTitle}
            postType={this.props.postType}
            updatePostType={this.props.updatePostType}
            handleSelectedOption={this.props.addSelectedChannel}
            selectedchannels={this.props.selectedchannels}
            createPost={this.createPost}
            newPostTextBox={this.props.newPostTextBox}
            isFeatured={this.props.isFeatured}
            disableCreateButton={this.state.disableCreateButton}
            loadMyChannels={this.loadMyChannels}
            newChannelId={this.state.channelId}
            createNewChannel={this.createNewChannel}
          />
        ) : null}
        <Timeline
          myChannels={this.props.myChannels}
          subscribedChannels={this.props.subscribedChannels}
          getFeedForTimeLine={this.getFeed}
          feed={this.props.feed}
          timelineFilterType={this.props.timelineType}
          setTimeLineType={this.props.setTimeLineType}
          totalPages={this.props.totalPages}
          pageNumber={this.props.pageNumber}
          hasMore={this.props.hasMore}
          toggleLikeForPost={this.props.toggleLikeForPost}
          savePost={this.props.savePost}
          setTimeLineCurrentChannel={this.props.setTimeLineCurrentChannel}
          currentFeedChannel={this.props.currentFeedChannel}
          removePost={this.props.removePost}
          sharePost={this.sharepost}
          updateChnannelStatus={this.props.updateChnannelStatus}
          createNewChannel={this.props.createNewChannel}
          unsubscribeChannel={this.props.unsubscribeChannel}
          profile={this.props.profile}
          myDepartments={this.props.myDepartments}
          deletePost={this.props.deletePost}
          getDeletedPosts={this.props.getDeletedPosts}
          success={this.props.success}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  myChannels: state.channelReducer.channels.sort((c1, c2) => {
    if (c1.name.toLowerCase() < c2.name.toLowerCase()) return -1;
    if (c1.name.toLowerCase() > c2.name.toLowerCase()) return 1;
    return 0;
  }),
  subscribedChannels: state.channelReducer.subscribedChannels,
  feed: state.timeLineReducer.feed,
  pageNumber: state.timeLineReducer.pageNumber,
  success: state.timeLineReducer.success,
  totalPages: state.timeLineReducer.totalPages,
  hasMore: state.timeLineReducer.hasMore,
  showPreview: state.publishContent.showPreview,
  previewData: state.publishContent.previewData,
  createPostState: state.publishContent.createPostState,
  postType: state.publishContent.postType,
  selectedchannels: state.publishContent.selectedchannels,
  imageTitle: state.publishContent.imageTitle,
  videoTitle: state.publishContent.videoTitle,
  documentTitle: state.publishContent.documentTitle,
  newPostTextBox: state.publishContent.newPostTextBox,
  isFeatured: state.publishContent.isFeatured,
  linkUrl: state.publishContent.linkUrl,
  timelineType: state.filterReducer.type,
  currentFeedChannel: state.filterReducer.currentFeedChannel,
  profile: state.personalProfile.profile,
  myDepartments: state.departmentReducer.myDepartments,
  newChannelId: state.channelData.newChannelId,
});

const mapDispatchToProps = (dispatch) => ({
  getMyChannels: () => dispatch(getMyChannels()),
  getSubscribedChannels: () => dispatch(getSubscribedChannels()),
  getFeed: (data) => dispatch(getFeed(data)),
  getLinkPreview: (data) => dispatch(getLinkPreview(data)),
  updateShowPreview: (data) => dispatch(updateShowPreview(data)),
  createPost: (data) => dispatch(createPost(data)),
  updatePostType: (data) => dispatch(updatePostType(data)),
  addSelectedChannel: (data) => dispatch(addSelectedChannel(data)),
  handleInputChange: (data) => dispatch(handleInputChange(data)),
  setTimeLineType: (data) => dispatch(setTimeLineType(data)),
  toggleLikeForPost: (data) => dispatch(toggleLikeForPost(data)),
  savePost: (data) => dispatch(savePost(data)),
  setTimeLineCurrentChannel: (data) =>
    dispatch(setTimeLineCurrentChannel(data)),
  removePost: (data) => dispatch(removePost(data)),
  sharePost: (data) => dispatch(sharePost(data)),
  updateChnannelStatus: (data) => dispatch(updateChnannelStatus(data)),
  createNewChannel: (data) => dispatch(createNewChannel(data)),
  unsubscribeChannel: (data) => dispatch(unsubscribeChannel(data)),
  getMyDepartments: () => dispatch(getMyDepartments()),
  deletePost: (data) => dispatch(deletePost(data)),
  getDeletedPosts: (data) => dispatch(getDeletedPosts(data)),
  emptyFeed: () => dispatch(emptyFeed()),
  getPersonalProfile: (data) => dispatch(getPersonalProfile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
);
