import {
  GET_PROVIDERS_FOR_ORG_SUCCESS,
  UPDATE_CURRENT_PROVIDER_INDEX
} from "../actions/organizationManageProvider.actions";

const intialState = {
  organizationProviders: [],
  currentProviderIndex: 0
};

const organizationManageProvider = (state = intialState, action) => {
  switch (action.type) {
    case GET_PROVIDERS_FOR_ORG_SUCCESS:
      return {
        ...state,
        organizationProviders: action.payload
      };
    case UPDATE_CURRENT_PROVIDER_INDEX:
      return {
        ...state,
        currentProviderIndex: action.payload
      };
    default:
      return state;
  }
};

export default organizationManageProvider;
