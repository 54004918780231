import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter, Redirect } from "react-router-dom";
import ChannelSetup from "../components/channelSetup/editChannel";
import {
  getSpecialityChannels,
  updateSpecialityChannels,
  channelBulkUpdate,
  BULK_UPDATE_CHANNELS_IN_PROGRESS,
  removeChannel,
} from "../actions/channelsetup.actions";
import { getMyChannels, updateChannels } from "../actions/channel.action";
import { ROUTE_CHANNEL_SETUP } from "./RouteConstants";
import { ROUTE_DASHBOARD } from "./RouteConstants";

class ManageChannelsPage extends Component {
  componentWillMount() {
    this.props.getSpecialityChannels();
    this.props.getMyChannels();
  }

  updateSpeclaityChannels = (data) => {
    this.props.updateChannels(data);
  };
  removeChannel = (data) => {
    this.props.removeChannel(data);
  };

  bulkUpdate = () => {
    var channelArr = this.props.myChannels.map((el) => el.name);

    this.props.channelBulkUpdate({ name: channelArr });
  };

  handleSkip = () =>
    this.props.history.push("/next-step" + ROUTE_CHANNEL_SETUP);

  render() {
    if (this.props.state === BULK_UPDATE_CHANNELS_IN_PROGRESS) {
      return <Redirect to={ROUTE_DASHBOARD} />;
    }

    return (
      <div className="container-bg">
        <ChannelSetup
          channels={this.props.myChannels}
          updateSpeclaityChannels={this.updateSpeclaityChannels}
          bulkUpdate={this.bulkUpdate}
          handleSkip={this.handleSkip}
          channelUpdateState={this.props.channelUpdateState}
          isBoardingOff={true}
          removeChannel={this.removeChannel}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialityChannels: state.channelSetup.specialityChannels,
  channelUpdateState: state.channelSetup.channelUpdateState,
  state: state.channelSetup.state,
  myChannels: state.channelReducer.channels.sort((c1, c2) => {
    if (c1.name.toLowerCase() < c2.name.toLowerCase()) return -1;
    if (c1.name.toLowerCase() > c2.name.toLowerCase()) return 1;
    return 0;
  }),
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialityChannels: () => dispatch(getSpecialityChannels()),
  getMyChannels: () => dispatch(getMyChannels()),
  updateSpecialityChannels: (data) => dispatch(updateSpecialityChannels(data)),
  updateChannels: (data) => dispatch(updateChannels(data)),
  channelBulkUpdate: (data) => dispatch(channelBulkUpdate(data)),
  removeChannel: (data) => dispatch(removeChannel(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageChannelsPage)
);
