import React from "react";
import attach from "../../img/attach.svg";
import image from "../../img/image.svg";
import video from "../../img/video.svg";
import link from "../../img/link.svg";
import "./publishContent.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import content from "../../img/content.svg";
import ImageAuthoring from "../imageAuthoring";
import VideoAuthoring from "../videoAuthoring";
import DocumentAuthoring from "../documentAuthoring";
import LinkAuthoring from "../linkAuthoring";
import { CREATE_POST_IN_PROGRESS } from "../../actions/publishContent.actions";

class PublishContent extends React.Component {
  constructor(props) {
    super(props);
    this.newPostTextAreaRef = React.createRef();
    this.imageAuthorRef = React.createRef();
    this.videoAuthorRef = React.createRef();
    this.docAuthorRef = React.createRef();
  }

  // handleKeydown = event => {
  //   if (event.key === "Enter") {
  //     this.props.handleEnteredValue(event.target.value);
  //     this._typeahead.getInstance().clear();
  //   }
  // };

  handleSelectedOption = (option) => {
    if (!(this.props.selectedchannels.indexOf(option[0]) > -1))
      this.props.handleSelectedOption([
        ...this.props.selectedchannels,
        ...option,
      ]);
    this._typeahead.getInstance().clear();
  };

  uploadForPost = (value) => {
    switch (value) {
      case "Document":
        this.docAuthorRef.current.uploadNewPostDocumentRef.current.click();
        break;
      case "Video":
        this.videoAuthorRef.current.uploadNewPostVideoRef.current.click();
        break;
      case "Image":
        this.imageAuthorRef.current.uploadNewPostImageRef.current.click();
        break;
      default:
        break;
    }
  };

  goLastStep = () => {
    this.props.getPrevStep();
  };

  publish = () => {
    if (this.props.selectedchannels.length > 0) {
      var newPostData = {};

      if (this.props.postType === "Image") {
        if (
          this.imageAuthorRef.current.uploadNewPostImageRef.current.files[0]
        ) {
          let formData = new FormData();
          formData.append(
            "file",
            this.imageAuthorRef.current.uploadNewPostImageRef.current.files[0]
          );
          newPostData["subject"] = "subject";
          newPostData["title"] = this.props.imageTitle;
          newPostData["shareView"] = this.newPostTextAreaRef.current.value;
          newPostData["channelIds"] = this.props.selectedchannels.map(
            (el) => el.id
          );
          newPostData["postType"] = this.props.postType;
          newPostData["file"] = formData;
        } else return;
      }

      if (this.props.postType === "Document") {
        if (
          this.docAuthorRef.current.uploadNewPostDocumentRef.current.files[0]
        ) {
          let formData = new FormData();
          formData.append(
            "file",
            this.docAuthorRef.current.uploadNewPostDocumentRef.current.files[0]
          );
          newPostData["subject"] = "subject";
          newPostData["title"] = this.props.documentTitle;
          newPostData["shareView"] = this.newPostTextAreaRef.current.value;
          newPostData["postType"] = this.props.postType;
          newPostData["channelIds"] = this.props.selectedchannels.map(
            (el) => el.id
          );
          newPostData["file"] = formData;
        } else return;
      }

      if (this.props.postType === "Video") {
        if (
          this.videoAuthorRef.current.uploadNewPostVideoRef.current.files[0]
        ) {
          let formData = new FormData();
          formData.append(
            "file",
            this.videoAuthorRef.current.uploadNewPostVideoRef.current.files[0]
          );
          newPostData["subject"] = "subject";
          newPostData["title"] = this.props.videoTitle;
          newPostData["shareView"] = this.newPostTextAreaRef.current.value;
          newPostData["postType"] = this.props.postType;
          newPostData["channelIds"] = this.props.selectedchannels.map(
            (el) => el.id
          );
          newPostData["file"] = formData;
        } else return;
      }

      if (this.props.postType === "Link") {
        if (this.props.linkUrl) {
          newPostData["channelIds"] = this.props.selectedchannels.map(
            (el) => el.id
          );
          newPostData["postType"] = this.props.postType;
          newPostData["link"] = this.props.linkUrl;
          newPostData["shareView"] = this.newPostTextAreaRef.current.value;
        } else return;
      }

      this.props.createPost(newPostData);
    }
  };

  render() {
    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>LET'S PUBLISH SOMETHING...</h3>
            <p>
              The best way to start on Healthswim is to let the world know
              you're here.
            </p>
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText ">
                  You can post anything at anytime - comments, articles,
                  documents, images and video. Choose one or more channels to
                  publish it to and your post will appear for users who follow
                  that you or that channel.
                </div>
                <div className="oval flex_display">
                  <img src={content} alt="content" className="content_icon" />
                </div>
              </div>
              <div className="col-8">
                <div className="form-body">
                  <textarea
                    name="newPostTextBox"
                    placeholder="Type Your Post here:"
                    className={"link_input show"}
                    rows="5"
                    cols="40"
                    ref={this.newPostTextAreaRef}
                    style={{ borderRadius: "10px" }}
                  />

                  {this.props.postType === "Link" ? (
                    <LinkAuthoring
                      inputChange={this.props.inputChange}
                      getLinkPreview={this.props.getLinkPreview}
                      showPreview={this.props.showPreview}
                      previewData={this.props.previewData}
                      closePreview={this.props.closePreview}
                      linkUrl={this.props.linkUrl}
                    />
                  ) : null}

                  {this.props.postType === "Image" ? (
                    <ImageAuthoring
                      ref={this.imageAuthorRef}
                      inputChange={this.props.inputChange}
                      upload={this.uploadForPost}
                    />
                  ) : null}

                  {this.props.postType === "Video" ? (
                    <VideoAuthoring
                      ref={this.videoAuthorRef}
                      inputChange={this.props.inputChange}
                      upload={this.uploadForPost}
                    />
                  ) : null}

                  {this.props.postType === "Document" ? (
                    <DocumentAuthoring
                      ref={this.docAuthorRef}
                      inputChange={this.props.inputChange}
                      upload={this.uploadForPost}
                    />
                  ) : null}

                  <p className="attach_text">
                    Add an attachment, image, video or link:
                  </p>
                  <div className="row posts_options_wrapper">
                    <div className="col d-flex">
                      <div
                        className={
                          this.props.postType === "Document"
                            ? "connect post-type selectedBorder"
                            : "connect post-type "
                        }
                        onClick={() => this.props.updatePostType("Document")}
                      >
                        <img src={attach} alt="doc" title="document" />
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div
                        className={
                          this.props.postType === "Image"
                            ? "connect post-type selectedBorder"
                            : "connect post-type "
                        }
                        style={{ marginLeft: "15%" }}
                        onClick={() => this.props.updatePostType("Image")}
                      >
                        <img src={image} alt="imagePost" title="imagePost" />
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div
                        className={
                          this.props.postType === "Video"
                            ? "connect post-type selectedBorder"
                            : "connect post-type "
                        }
                        style={{ marginLeft: "30%" }}
                        onClick={() => this.props.updatePostType("Video")}
                      >
                        <img src={video} alt="video" title="video" />
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className={
                          this.props.postType === "Link"
                            ? "connect post-type float-right auto_margin selectedBorder"
                            : "connect post-type float-right auto_margin"
                        }
                        onClick={() => this.props.updatePostType("Link")}
                      >
                        <img src={link} alt="link" title="link" />
                      </div>
                    </div>
                  </div>
                  <p className="type_text">
                    Type in any name you like either choose from the pop up list
                  </p>
                  <Typeahead
                    options={this.props.options}
                    labelKey="name"
                    placeholder="Choose channels.."
                    multiple
                    ref={(ref) => (this._typeahead = ref)}
                    onChange={this.handleSelectedOption}
                  />
                  <div
                    className="row"
                    style={{
                      marginTop: "1rem",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    {this.props.selectedchannels &&
                      this.props.selectedchannels.map((item, i) => {
                        return (
                          <div
                            className=""
                            key={`selectedChannel_${i}`}
                            style={{ maxWidth: "100%" }}
                            title={item.name}
                          >
                            <div className="selected-option rbt-token rbt-token-removeable">
                              {item.name}
                              <button
                                aria-label="Remove"
                                className="close rbt-close rbt-token-remove-button"
                                type="button"
                              >
                                <span
                                  aria-hidden="true"
                                  onClick={() => this.props.removeChannel(i)}
                                >
                                  ×
                                </span>
                                <span className="sr-only">Remove</span>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {/* <div className="flex_display schedule_wrapper">
                    <div className="flex_display m-auto">
                      <span>Schedule when you want it to post:</span>
                      <div className="post-type">
                        <img
                          src={calendar}
                          alt="calendar"
                          className="m-auto"
                          style={{ width: "33px", height: "33px" }}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="mt-10">
                    {/* changes */}
                    {/* {this.props.isBoardingOff !== true && (
                      <button
                        type="button"
                        className="btn mr-3 pull-left form-btn"
                        onClick={this.goLastStep}
                      >
                        Back
                      </button>
                    )} */}
                    <button
                      type="submit"
                      className="btn form-btn  float-right publish-button"
                      id="btnLogin"
                      onClick={this.publish}
                      disabled={this.props.disablePostButton}
                    >
                      <span style={{ lineHeight: "40px" }}>POST</span>
                      {this.props.createPostState ===
                      CREATE_POST_IN_PROGRESS ? (
                        <span className="text-right pl-2">
                          <i
                            className="fa fa-circle-o-notch fa-spin"
                            style={{ color: "#fff" }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                    {this.props.canSkip ? (
                      <span
                        className="form-link  float-right mr-5"
                        onClick={this.props.handleSkip}
                        style={{ cursor: "pointer" }}
                      >
                        Skip for now
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PublishContent;
