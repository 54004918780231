import React from "react";
import SubsrciptionCard from "./subscriptionCard";
import InfiniteScroll from "react-infinite-scroller";
import OrgModal from "./orgModal";
import ProviderModal from "./providerModal";
import { Typeahead } from "react-bootstrap-typeahead";
import Axios from "axios";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import ShowUserInformations from "./ShowUserInformation";
import ShowOrgInformation from "./ShowOrgInformation";
class ManageAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.infiniteRef = React.createRef();
    this.state = {
      data: [],
      showInput: false,
      password: "",
      id: "",
      openUserInfo: false,
      userId: "",
      openOrgInfo: false,
      firstName: "",
      lastName: "",
      userRole: "",
      email: "",
      isEdit: true,
      user_Id: "",
      location: "",
    };
  }

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };
  submitPassword = (id) => {
    console.log("runing password");
    this.props.changePassword(this.state.password, id);

    this.setState({ showInput: false, id: "" });
  };
  onClose = () => {
    this.setState({ openUserInfo: false, openOrgInfo: false });
  };
  openUserPersonalinfo = (id) => {
    this.setState({ openUserInfo: true, userId: id });
    this.setState({
      firstName: this.props.subscriptionDetailsOrg.members[id].firstName,
      lastName: this.props.subscriptionDetailsOrg.members[id].lastName,
      userRole: this.props.subscriptionDetailsOrg.members[id].userRole,
      email: this.props.subscriptionDetailsOrg.members[id].email,
      user_Id: this.props.subscriptionDetailsOrg.members[id].id,
      location: this.props.subscriptionDetailsOrg.members[id].location,
    });
    if (this.props.subscriptionDetailsOrg.members[id].userRole === "Provider") {
      this.props.getProviderInfo(
        this.props.subscriptionDetailsOrg.members[id].id
      );
    } else if (
      this.props.subscriptionDetailsOrg.members[id].userRole ===
      "DepartmentAdmin"
    ) {
      this.props.getDepAdminInfo(
        this.props.subscriptionDetailsOrg.members[id].id
      );
    } else if (
      this.props.subscriptionDetailsOrg.members[id].userRole ===
      "PrimaryOrganizationAdmin"
    ) {
      this.setState({ data: this.props.subscriptionDetailsOrg.members[id] });
    }
  };
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("handleInput", name, value);
    this.setState({ [name]: value });
  };
  closeEdit = (info) => {
    this.setState({ isEdit: !this.state.isEdit });
    this.props.submitUserInformation(info);
  };
  submitOrgEditInfo = (info) => {
    this.setState({ isEdit: !this.state.isEdit });
    this.props.submitOrgInformation(info);
  };
  saveProviderInfo = (info) => {
    this.setState({ isEdit: !this.state.isEdit });
    this.props.submitProviderInformation(info);
  };
  saveSuperAdminInfo = (info) => {
    this.setState({ isEdit: !this.state.isEdit });
    this.props.submitSuperAdminInformation(info);
  };
  saveOrganizationLogo = (event) => {
    if (event.target.files[0]) {
      var file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      this.props.setOrganizationLogo(file, formData);
    }
  };

  render() {
    return (
      <div className="manage-form">
        {/* <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "30px" }}>
            <div className="user-oval" />
            <label style={{ cursor: "pointer" }}>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={this.props.onFileUpload}
              />
              UPLOAD USING A SPREADSHEET
            </label>
            <br></br>
            <label> (Max. 20 records at a time)</label>
          </div>
        </div>
        <div className="row mtb-60 " style={{ display: "flex" }}>
          <div className="col-8" style={{ display: "flex", margin: "auto" }}>
            <div className="col-md-5 my-auto ">
              <div className="form-group form-lable">
                <Typeahead
                  options={this.props.orgList}
                  labelKey="name"
                  name="org_id"
                  onChange={(selected) => this.props.setOrgId(selected)}
                  placeholder="Select Organisation"
                />
              </div>
            </div>
            <div className="col-5 my-auto">
              <div className="form-group">
                <div className="form-group">
                  <Typeahead
                    labelKey="name"
                    name="role"
                    options={this.props.roleList}
                    onChange={(selected) => this.props.setRole(selected)}
                    placeholder="Select Role"
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <button
                className="btn form-btn-sm btn-sm"
                id="btnAdd"
                onClick={this.props.handleAddClick}
              >
                Add
              </button>
            </div>
          </div>
        </div> */}
        <hr></hr>
        <div className="row mtb-60 " style={{ display: "flex" }}>
          <div className="col-6" style={{ display: "flex", margin: "auto" }}>
            <div style={{ width: "100%" }}>
              <input
                type="text"
                className="form-control form-input"
                style={{ margin: "auto" }}
                placeholder="search..."
                onChange={this.props.updateSearchtext}
                onKeyPress={this.props.keyPress}
              />
              <i
                className="fa fa-search"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "3%",
                  transform: "rotate(90deg)",
                  fontSize: "24px",
                  color: "#00C6FF",
                  cursor: "pointer",
                }}
                onClick={this.props.getListDetails}
              />
            </div>
          </div>
        </div>
        <div
          className="manage-form-container"
          style={{
            overflow: "auto",
          }}
        >
          <div className="col-12">
            <div
              className="mb-16  no-gutter"
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                height: "300px",
                overflow: "auto",
              }}
              id="MangeAccounts_div"
            >
              <InfiniteScroll
                pageStart={1}
                loadMore={this.props.loadFunc}
                hasMore={this.props.hasMore}
                initialLoad={false}
                // loader={<div>Loading ...</div>}
                useWindow={false}
                style={{ width: "100%" }}
                className="row"
              >
                {this.props.subscriptionList.length > 0 ? (
                  this.props.subscriptionList.map((subscription, i) => {
                    return (
                      <SubsrciptionCard
                        key={`location_${i}`}
                        subscription={subscription}
                        index={i}
                        showDetails={this.props.showDetails}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{
                      margin: "auto",
                      fontFamily: "proximaNova",
                      fontSize: "30px",
                    }}
                  >
                    No subscriptions Found
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
        <div className="row mb-16  no-gutter">
          <div className="col-12">
            <OrgModal
              show={this.props.show}
              handleClose={this.props.handleClose}
              subscriptionDetailsOrg={this.props.subscriptionDetailsOrg}
              activateDeactivateAccount={this.props.activateDeactivateAccount}
              deleteAccount={this.props.deleteAccount}
            />
          </div>
        </div>
        <div className="row mb-16  no-gutter">
          <div className="col-12">
            <ProviderModal
              show={this.props.showProviderModal}
              handleClose={this.props.handleClose}
              subscriptionDetailsProvider={
                this.props.subscriptionDetailsProvider
              }
              activateDeactivateAccount={this.props.activateDeactivateAccount}
              deleteAccount={this.props.deleteAccount}
            />
          </div>
        </div>
        {/* <div
          style={{ textAlign: "center", height: "200px", paddingTop: "100px" }}
        >
          <button className="change_button" onClick={this.props.handleOnClick}>
            + Create New
          </button>
        </div> */}

        {this.props.subscriptionList.length > 0 ? (
          <>
            <div
              style={{
                textAlign: "center",
                height: "200px",
                paddingTop: "100px",
              }}
            >
              <button
                className="change_button"
                onClick={this.props.handleOnClick}
              >
                + Create New
              </button>
            </div>
            <div
              style={{
                borderTop: "3px solid",
                borderLeft: "3px solid",
                borderRight: "3px solid",
              }}
            >
              {this.props.isShowList === false &&
                this.props.orgList &&
                this.props.orgList.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "3px solid black",
                      height: "40px",
                      padding: "0px 105px 0 14px",
                      backgroundColor: "#305496",
                    }}
                  >
                    <div style={{ color: "white" }}> {item.name}</div>
                    <button
                      style={{
                        backcgroundColor: "white",
                        border: "none",
                        borderRadious: "5px",
                        color: "#305496",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        this.props.showDetails("Organisation", item.id);
                      }}
                    >
                      Expand
                    </button>
                  </div>
                ))}

              <table style={{ width: "100%" }}>
                {this.props.isShowList && this.state.openUserInfo === false && (
                  <thead>
                    <tr
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "3px solid black",
                        height: "40px",
                        padding: "0px 105px 0 14px",
                        backgroundColor: "#305496",
                      }}
                    >
                      <th style={{ color: "white", width: "238px" }}>
                        {" "}
                        <IoArrowBackCircleSharp
                          onClick={this.props.isBack}
                          size={30}
                        />
                        {this.props.subscriptionDetailsOrg.organisationName}
                      </th>
                      <th style={{ color: "white", width: "538px" }}>
                        {" "}
                        Organisation
                      </th>

                      <th style={{ width: "90px" }}>
                        <button
                          style={{
                            backcgroundColor: "white",
                            border: "none",
                            borderRadious: "5px",
                            color: "#305496",
                            fontWeight: "500",
                          }}
                          onClick={() =>
                            this.props.activateDeactivateAccount(
                              "?organisationId=" +
                                this.props.subscriptionDetailsOrg.id +
                                "&isActive=" +
                                !this.props.subscriptionDetailsOrg.isActive
                            )
                          }
                        >
                          {this.props.subscriptionDetailsOrg.isActive
                            ? "Deactivate"
                            : "Activate"}
                        </button>
                      </th>
                      <th style={{ width: "90px" }}>
                        <button
                          style={{
                            backcgroundColor: "white",
                            border: "none",
                            borderRadious: "5px",
                            color: "#305496",
                            fontWeight: "500",
                          }}
                          onClick={() =>
                            this.props.deleteAccount(
                              "?organisationId=" +
                                this.props.subscriptionDetailsOrg.id
                            )
                          }
                        >
                          Delete
                        </button>
                      </th>
                      <th style={{ width: "230px" }}>
                        <button
                          style={{
                            backcgroundColor: "white",
                            border: "none",
                            borderRadious: "5px",
                            color: "#305496",
                            fontWeight: "500",
                          }}
                        >
                          Change Password
                        </button>
                      </th>
                      <th>
                        <button
                          style={{
                            backcgroundColor: "white",
                            border: "none",
                            borderRadious: "5px",
                            color: "#305496",
                            fontWeight: "500",
                          }}
                          onClick={() => {
                            this.setState({ openOrgInfo: true });
                          }}
                        >
                          Expand
                        </button>
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  <tr>
                    {this.props.isShowList &&
                      this.state.openUserInfo === false &&
                      this.props.subscriptionDetailsOrg &&
                      this.props.subscriptionDetailsOrg.members &&
                      this.props.subscriptionDetailsOrg.members.map(
                        (item, i) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderBottom: "3px solid black",
                              height: "40px",
                              padding: "0px 105px 0 14px",
                              backgroundColor: "#305496",
                            }}
                          >
                            <td style={{ color: "white", width: "238px" }}>
                              {" "}
                              {item.firstName} {item.lastName}
                            </td>
                            <td style={{ width: "538px" }}>
                              <td
                                style={{
                                  color: "white",
                                  paddingRight: "15px",
                                }}
                              >
                                {" "}
                                {item.userRole}
                              </td>
                              <td style={{ color: "white" }}> {item.email}</td>
                            </td>
                            <td style={{ width: "90px" }}>
                              {item.userRole !== "PrimaryOrganizationAdmin" && (
                                <button
                                  style={{
                                    backcgroundColor: "white",
                                    border: "none",
                                    borderRadious: "5px",
                                    color: "#305496",
                                    fontWeight: "500",
                                  }}
                                  onClick={() =>
                                    this.props.activateDeactivateAccount(
                                      "?providerId=" +
                                        item.id +
                                        "&isActive=" +
                                        !item.isActive
                                    )
                                  }
                                >
                                  {item.isActive ? "Deactivate" : "Activate"}
                                </button>
                              )}
                            </td>
                            <td style={{ width: "90px" }}>
                              {item.userRole !== "PrimaryOrganizationAdmin" && (
                                <button
                                  style={{
                                    backcgroundColor: "white",
                                    border: "none",
                                    borderRadious: "5px",
                                    color: "#305496",
                                    fontWeight: "500",
                                  }}
                                  onClick={() =>
                                    this.props.deleteAccount(
                                      "?providerId=" + item.id
                                    )
                                  }
                                >
                                  Delete
                                </button>
                              )}
                            </td>

                            <td style={{ width: "230px" }}>
                              {this.state.id !== item.id && (
                                <button
                                  style={{
                                    backcgroundColor: "white",
                                    border: "none",
                                    borderRadious: "5px",
                                    color: "#305496",
                                    fontWeight: "500",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      showInput: true,
                                      id: item.id,
                                    });
                                  }}
                                >
                                  Change Password
                                </button>
                              )}
                              {this.state.showInput === true &&
                                this.state.id === item.id && (
                                  <>
                                    <td
                                      style={{
                                        color: "white",
                                        // paddingRight: "15px",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        onChange={this.onChangePassword}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        style={{
                                          backcgroundColor: "white",
                                          border: "none",
                                          borderRadious: "5px",
                                          color: "#305496",
                                          fontWeight: "500",
                                          // marginLeft: "10px",
                                        }}
                                        onClick={() => {
                                          this.submitPassword(item.id);
                                        }}
                                      >
                                        Save
                                      </button>
                                    </td>
                                  </>
                                )}
                            </td>

                            <td>
                              <button
                                style={{
                                  backcgroundColor: "white",
                                  border: "none",
                                  borderRadious: "5px",
                                  color: "#305496",
                                  fontWeight: "500",
                                }}
                                onClick={() => {
                                  this.openUserPersonalinfo(i);
                                }}
                              >
                                Expand
                              </button>
                            </td>
                          </div>
                        )
                      )}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            style={{
              margin: "auto",
              fontFamily: "proximaNova",
              fontSize: "30px",
              textAlign: "center",
            }}
          >
            No subscriptions Found
          </div>
        )}
        {/* <div
          style={{
            borderTop: "3px solid",
            borderLeft: "3px solid",
            borderRight: "3px solid",
          }}
        >
          {this.props.isShowList === false &&
            this.props.orgList &&
            this.props.orgList.map((item) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "3px solid black",
                  height: "40px",
                  padding: "0px 105px 0 14px",
                  backgroundColor: "#305496",
                }}
              >
                <div style={{ color: "white" }}> {item.name}</div>
                <button
                  style={{
                    backcgroundColor: "white",
                    border: "none",
                    borderRadious: "5px",
                    color: "#305496",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    this.props.showDetails("Organisation", item.id);
                  }}
                >
                  Expand
                </button>
              </div>
            ))}

          <table style={{ width: "100%" }}>
            {this.props.isShowList && this.state.openUserInfo === false && (
              <thead>
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "3px solid black",
                    height: "40px",
                    padding: "0px 105px 0 14px",
                    backgroundColor: "#305496",
                  }}
                >
                  <th style={{ color: "white", width: "238px" }}>
                    {" "}
                    <IoArrowBackCircleSharp
                      onClick={this.props.isBack}
                      size={30}
                    />
                    {this.props.subscriptionDetailsOrg.organisationName}
                  </th>
                  <th style={{ color: "white", width: "538px" }}>
                    {" "}
                    Organisation
                  </th>

                  <th style={{ width: "90px" }}>
                    <button
                      style={{
                        backcgroundColor: "white",
                        border: "none",
                        borderRadious: "5px",
                        color: "#305496",
                        fontWeight: "500",
                      }}
                      onClick={() =>
                        this.props.activateDeactivateAccount(
                          "?organisationId=" +
                            this.props.subscriptionDetailsOrg.id +
                            "&isActive=" +
                            !this.props.subscriptionDetailsOrg.isActive
                        )
                      }
                    >
                      {this.props.subscriptionDetailsOrg.isActive
                        ? "Deactivate"
                        : "Activate"}
                    </button>
                  </th>
                  <th style={{ width: "90px" }}>
                    <button
                      style={{
                        backcgroundColor: "white",
                        border: "none",
                        borderRadious: "5px",
                        color: "#305496",
                        fontWeight: "500",
                      }}
                      onClick={() =>
                        this.props.deleteAccount(
                          "?organisationId=" +
                            this.props.subscriptionDetailsOrg.id
                        )
                      }
                    >
                      Delete
                    </button>
                  </th>
                  <th style={{ width: "230px" }}>
                    <button
                      style={{
                        backcgroundColor: "white",
                        border: "none",
                        borderRadious: "5px",
                        color: "#305496",
                        fontWeight: "500",
                      }}
                    >
                      Change Password
                    </button>
                  </th>
                  <th>
                    <button
                      style={{
                        backcgroundColor: "white",
                        border: "none",
                        borderRadious: "5px",
                        color: "#305496",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        this.setState({ openOrgInfo: true });
                      }}
                    >
                      Expand
                    </button>
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              <tr>
                {this.props.isShowList &&
                  this.state.openUserInfo === false &&
                  this.props.subscriptionDetailsOrg &&
                  this.props.subscriptionDetailsOrg.members &&
                  this.props.subscriptionDetailsOrg.members.map((item, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "3px solid black",
                        height: "40px",
                        padding: "0px 105px 0 14px",
                        backgroundColor: "#305496",
                      }}
                    >
                      <td style={{ color: "white", width: "238px" }}>
                        {" "}
                        {item.firstName} {item.lastName}
                      </td>
                      <td style={{ width: "538px" }}>
                        <td style={{ color: "white", paddingRight: "15px" }}>
                          {" "}
                          {item.userRole}
                        </td>
                        <td style={{ color: "white" }}> {item.email}</td>
                      </td>
                      <td style={{ width: "90px" }}>
                        {item.userRole !== "PrimaryOrganizationAdmin" && (
                          <button
                            style={{
                              backcgroundColor: "white",
                              border: "none",
                              borderRadious: "5px",
                              color: "#305496",
                              fontWeight: "500",
                            }}
                            onClick={() =>
                              this.props.activateDeactivateAccount(
                                "?providerId=" +
                                  item.id +
                                  "&isActive=" +
                                  !item.isActive
                              )
                            }
                          >
                            {item.isActive ? "Deactivate" : "Activate"}
                          </button>
                        )}
                      </td>
                      <td style={{ width: "90px" }}>
                        {item.userRole !== "PrimaryOrganizationAdmin" && (
                          <button
                            style={{
                              backcgroundColor: "white",
                              border: "none",
                              borderRadious: "5px",
                              color: "#305496",
                              fontWeight: "500",
                            }}
                            onClick={() =>
                              this.props.deleteAccount("?providerId=" + item.id)
                            }
                          >
                            Delete
                          </button>
                        )}
                      </td>

                      <td style={{ width: "230px" }}>
                        {this.state.id !== item.id && (
                          <button
                            style={{
                              backcgroundColor: "white",
                              border: "none",
                              borderRadious: "5px",
                              color: "#305496",
                              fontWeight: "500",
                            }}
                            onClick={() => {
                              this.setState({ showInput: true, id: item.id });
                            }}
                          >
                            Change Password
                          </button>
                        )}
                        {this.state.showInput === true &&
                          this.state.id === item.id && (
                            <>
                              <td
                                style={{ color: "white", paddingRight: "15px" }}
                              >
                                <input
                                  type="text"
                                  onChange={this.onChangePassword}
                                />
                              </td>
                              <td>
                                <button
                                  style={{
                                    backcgroundColor: "white",
                                    border: "none",
                                    borderRadious: "5px",
                                    color: "#305496",
                                    fontWeight: "500",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => {
                                    this.submitPassword(item.id);
                                  }}
                                >
                                  Save
                                </button>
                              </td>
                            </>
                          )}
                      </td>

                      <td>
                        <button
                          style={{
                            backcgroundColor: "white",
                            border: "none",
                            borderRadious: "5px",
                            color: "#305496",
                            fontWeight: "500",
                          }}
                          onClick={() => {
                            this.openUserPersonalinfo(i);
                          }}
                        >
                          Expand
                        </button>
                      </td>
                    </div>
                  ))}
              </tr>
            </tbody>
          </table>
        </div> */}

        {this.state.openUserInfo &&
          this.props.providerList &&
          this.props.providerList.id &&
          this.state.userRole === "Provider" && (
            <>
              <ShowUserInformations
                onClose={this.onClose}
                userIndex={this.state.userId}
                subscriptionDetailsOrg={this.props.subscriptionDetailsOrg}
                submitUserEditInfo={this.closeEdit}
                saveProviderInfo={this.saveProviderInfo}
                saveSuperAdminInfo={this.saveSuperAdminInfo}
                allDegrees={this.props.allDegrees}
                allSpecialities={this.props.allSpecialities}
                allAffiliations={this.props.allAffiliations}
                allCertifications={this.props.allCertifications}
                allConditionsTreated={this.props.allConditionsTreated}
                departments={this.props.departments}
                locations={this.props.locations}
                providerList={this.props.providerList}
                depList={this.props.depList}
              />
            </>
          )}
        {this.state.openUserInfo &&
          this.props.depList &&
          this.props.depList.id &&
          this.state.userRole === "DepartmentAdmin" && (
            <>
              <ShowUserInformations
                onClose={this.onClose}
                userIndex={this.state.userId}
                subscriptionDetailsOrg={this.props.subscriptionDetailsOrg}
                submitUserEditInfo={this.closeEdit}
                saveProviderInfo={this.saveProviderInfo}
                saveSuperAdminInfo={this.saveSuperAdminInfo}
                allDegrees={this.props.allDegrees}
                allSpecialities={this.props.allSpecialities}
                allAffiliations={this.props.allAffiliations}
                allCertifications={this.props.allCertifications}
                allConditionsTreated={this.props.allConditionsTreated}
                departments={this.props.departments}
                locations={this.props.locations}
                providerList={this.props.depList}
                depList={this.props.depList}
              />
            </>
          )}

        {this.state.openUserInfo &&
          this.state.data &&
          this.state.data.id &&
          this.state.userRole === "PrimaryOrganizationAdmin" && (
            <>
              <ShowUserInformations
                onClose={this.onClose}
                userIndex={this.state.userId}
                subscriptionDetailsOrg={this.props.subscriptionDetailsOrg}
                submitUserEditInfo={this.closeEdit}
                saveProviderInfo={this.saveProviderInfo}
                saveSuperAdminInfo={this.saveSuperAdminInfo}
                allDegrees={this.props.allDegrees}
                allSpecialities={this.props.allSpecialities}
                allAffiliations={this.props.allAffiliations}
                allCertifications={this.props.allCertifications}
                allConditionsTreated={this.props.allConditionsTreated}
                departments={this.props.departments}
                locations={this.props.locations}
                providerList={this.state.data}
              />
            </>
          )}
        {this.state.openOrgInfo && (
          <ShowOrgInformation
            onClose={this.onClose}
            subscriptionDetailsOrg={this.props.subscriptionDetailsOrg}
            specalities={this.props.specalities}
            orgTypes={this.props.orgTypes}
            submitOrgEditInfo={this.submitOrgEditInfo}
            saveOrganizationLogo={this.saveOrganizationLogo}
          />
        )}
        <div className="takeSomespace" style={{ height: "120px" }}></div>
      </div>
    );
  }
}

export default ManageAccounts;
