import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationInviteAdmin from "../components/organizationInviteAdmin/organizationInviteAdmin";
import { ROUTE_ORG_INVITE_ADMIN, ROUTE_ORG_PROFILE } from "./RouteConstants";
import {
  inviteAdmin,
  ORG_INVITE_ADMIN_SUCCESS,
  ORG_INVITE_ADMIN_ERROR,
} from "../actions/organizationInviteAdmin.action";
import { getPrevStep } from "../actions/onboardingStatus.actions";
import ManageAccountPopUp from "../components/orgManageAccounts/ManageAccountPopUp";

class OrganizationInviteAdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addAnotherAdmin: false,
      saveInProgress: false,
    };

    this.inviteAdmin = this.inviteAdmin.bind(this);
    this.formRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_ADMIN_SUCCESS &&
      this.state.addAnotherAdmin === true
    ) {
      this.setState({ saveInProgress: false });
      this.formRef.current.addAnotherAdmin();
    }

    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_ADMIN_ERROR
    ) {
      this.setState({ saveInProgress: false, addAnotherAdmin: false });
    }
  }

  inviteAdmin(values, addAnotherAdmin) {
    this.setState({ addAnotherAdmin: addAnotherAdmin, saveInProgress: true });
    this.props.inviteAdmin(values);
  }

  getPrevStep = () => {
    this.props.getPrevStep();
  };

  render() {
    if (
      this.props.prevStep !== null &&
      this.props.prevStep.name === "ORGANIZATION-PROFILE"
    ) {
      return <Redirect to={ROUTE_ORG_PROFILE} />;
    }
    {
      console.log(
        "this.props.organizationInviteAdminStatus",
        this.props.organizationInviteAdminStatus
      );
    }
    if (
      this.props.organizationInviteAdminStatus === ORG_INVITE_ADMIN_SUCCESS &&
      this.state.addAnotherAdmin === false
    ) {
      return <Redirect to={"/next-step" + ROUTE_ORG_INVITE_ADMIN} />;
    }

    return (
      <div className="container-bg">
        <div className="container">
          <OrganizationInviteAdmin
            ref={this.formRef}
            onSubmit={this.inviteAdmin}
            isSaveInProgress={this.state.saveInProgress}
            getPrevStep={this.getPrevStep}
          />
          {/* <ManageAccountPopUp
            ref={this.formRef}
            onSubmit={this.inviteAdmin}
            isSaveInProgress={this.state.saveInProgress}
            getPrevStep={this.getPrevStep}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizationInviteAdminStatus: state.organizationInviteAdmin.state,
  organizationInviteAdminError: state.organizationInviteAdmin.error,
  prevStep: state.onboardingStatus.prevStep,
});

const mapDispatchToProps = (dispatch) => ({
  inviteAdmin: (data) => dispatch(inviteAdmin(data)),
  getPrevStep: () =>
    dispatch(getPrevStep("?currentStep=ORGANIZATION-INVITE-ADMIN")),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationInviteAdminPage)
);
