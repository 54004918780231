import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ManageAccount from "../components/manageAccount/manageAccount";

class ManageOrgAccountPage extends Component {
  render() {
    return (
      <div className="container-bg">
        <ManageAccount />
      </div>
    );
  }
}

const mapPropsToState = state => ({});

const mapDispatchToState = state => ({});

export default withRouter(
  connect(
    mapPropsToState,
    mapDispatchToState
  )(ManageOrgAccountPage)
);
