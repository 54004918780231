import React from "react";
import "./sidebar.css";
import profile from "../../img/xperson_4.jpg.pagespeed.ic.zElXCyQBvP.jpeg";
import { withRouter } from "react-router-dom";
import DashboardPage from "../../containers/DashboardPage";
import google_link from "../../img/en_badge_web_generic.png";
import apple_link from "../../img/apple-app-store-icon.png";
import "../Footer/footer.css";
import "../../index.css";
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        class="flex-container"
        style={{ display: "flex", flexDirection: "coloumn" }}
      >
        <aside>
          {/* <div class="sidebar-header">
        <div class ="profile">
        <img src={profile} alt="Image" class="img-fluid" data-pagespeed-url-hash="1626978661" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" style={{borderRadius:"50%",width:"80px",margin:"0 auto 20px auto"}}/>
        <p class = "profile-sidebar-user-name center">Craig David , MD </p>
        <span class="gray-darkish">Gastroenterology Seattle, WA</span>
        </div>
               
            </div> */}
          {/* <div class="nav-item-divider"></div> */}
          <div>
            <div
              className="row"
              style={{ backgroundColor: "#00c7ff", margin: "0" }}
            >
              <div className="col-md-12 hidden" style={{ margin: "2% 0%" }}>
                <div className="col-md-6 col-xs-12" style={{ float: "left" }}>
                  <img
                    className="google_link"
                    alt="en_badge_web_generic.png"
                    src={google_link}
                  />
                </div>
                <div className="col-md-6 col-xs-12" style={{ float: "right" }}>
                  <img
                    alt="apple-app-store-icon.png"
                    src={apple_link}
                    style={{
                      height: "82px",
                      marginTop: "3%",
                      marginLeft: "5%",
                    }}
                  />
                </div>
              </div>
              <div
                className="col-md-12"
                style={{ marginTop: "10%", marginBottom: "2%" }}
              >
                <h1
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <span>
                    <span style={{ fontSize: "20px" }}>
                      <span
                        style={{
                          fontFamily: "DIN Neuzeit Grotesk LT W01 BdCn",
                        }}
                      >
                        <a href="/privacypolicy" className="link">
                          Privacy Policy
                        </a>
                        &nbsp; •&nbsp;&nbsp;
                        <a href="/termsandconditions" className="link">
                          Terms of Use{" "}
                        </a>
                        •&nbsp; 2020 ©Healthswim
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </aside>
        <div />
        {/* <DashboardPage/> */}
        {/* <main>
        <div class="content"> main </div>
        
      </main> */}
      </div>

      /* <div class="wrapper">
      
        <nav id="sidebar">
            <div class="sidebar-header">
                <h3>Bootstrap Sidebar</h3>
            </div>

            <ul class="list-unstyled components">
                <p>Dummy Heading</p>
                <li class="active">
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Home</a>
                    <ul class="collapse list-unstyled" id="homeSubmenu">
                        <li>
                            <a href="#">Home 1</a>
                        </li>
                        <li>
                            <a href="#">Home 2</a>
                        </li>
                        <li>
                            <a href="#">Home 3</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">About</a>
                </li>
                <li>
                    <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Pages</a>
                    <ul class="collapse list-unstyled" id="pageSubmenu">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">Portfolio</a>
                </li>
                <li>
                    <a href="#">Contact</a>
                </li>
            </ul>

            <ul class="list-unstyled CTAs">
                <li>
                    <a href="https://bootstrapious.com/tutorial/files/sidebar.zip" class="download">Download source</a>
                </li>
                <li>
                    <a href="https://bootstrapious.com/p/bootstrap-sidebar" class="article">Back to article</a>
                </li>
            </ul>
        </nav>

      
     
    </div> */
    );
  }
}

export default withRouter(Sidebar);
