import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import PublishContent from "../components/publishContent/publishContent";
import {
  getChannels,
  createPost,
  uploadForPost,
  getLinkPreview,
  updateShowPreview,
  CREATE_POST_SUCCESS,
  updatePostType,
  addSelectedChannel,
  handleInputChange,
  removeSelectedChannel
} from "../actions/publishContent.actions";
import { ROUTE_PUBLISH_CONTENT,ROUTE_FIND_CONTENT } from "./RouteConstants";
import { getPrevStep } from "../actions/onboardingStatus.actions";

const mapStateToProps = state => {
  return {
    channels: state.publishContent.channels,
    showPreview: state.publishContent.showPreview,
    previewData: state.publishContent.previewData,
    createPostState: state.publishContent.createPostState,
    postType: state.publishContent.postType,
    selectedchannels: state.publishContent.selectedchannels,
    imageTitle: state.publishContent.imageTitle,
    videoTitle: state.publishContent.videoTitle,
    documentTitle: state.publishContent.documentTitle,
    newPostTextBox: state.publishContent.newPostTextBox,
    isFeatured: state.publishContent.isFeatured,
    linkUrl: state.publishContent.linkUrl,
    canSkip: state.onboardingStatus.canSkip,
    prevStep: state.onboardingStatus.prevStep,
  };
};

const mapDispatchToProps = dispatch => ({
  getChannels: () => dispatch(getChannels()),
  createPost: data => dispatch(createPost(data)),
  uploadForPost: data => dispatch(uploadForPost(data)),
  getLinkPreview: data => dispatch(getLinkPreview(data)),
  updateShowPreview: data => dispatch(updateShowPreview(data)),
  updatePostType: data => dispatch(updatePostType(data)),
  addSelectedChannel: data => dispatch(addSelectedChannel(data)),
  handleInputChange: data => dispatch(handleInputChange(data)),
  removeSelectedChannel: data => dispatch(removeSelectedChannel(data)),
  getPrevStep: () => dispatch(getPrevStep("?currentStep=PUBLISH-CONTENT"))
});

class PublishContentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disablePostButton: false
    };
    this.publishContentRef = React.createRef();
  }
  componentWillMount() {
    this.props.getChannels();
  }

  getPreview = () => {
    if (this.props.linkUrl.trim() !== "")
      this.props.getLinkPreview({ url: this.props.linkUrl });
  };

  handleSkip = () =>
    this.props.history.push("/next-step" + ROUTE_PUBLISH_CONTENT);

  // handleEnteredValue = value =>
  //   this.props.addSelectedChannel([{ name: value }]);

  createPost = newPostdata => {
    this.setState({ disablePostButton: true });
    if (newPostdata.postType === "Link") {
      this.props.getLinkPreview({ url: newPostdata.link }).then(
        response => {
          newPostdata["subject"] = this.props.previewData.title;
          newPostdata["content"] = this.props.previewData.descp;
          newPostdata["url"] = this.props.previewData.url;
          newPostdata["metaDataValue"] = JSON.stringify(this.props.previewData);
          this.props.createPost(newPostdata);
        },
        error => {
          this.setState({ disablePostButton: false });
        }
      );
    } else this.props.createPost(newPostdata);
  };

  getPrevStep = () =>{
    this.props.getPrevStep();
  }

  render() {
    if(this.props.prevStep !== null && this.props.prevStep.name === "FIND-CONTENT")
    {
      return <Redirect to={ROUTE_FIND_CONTENT} />;
    }
    if (this.props.createPostState === CREATE_POST_SUCCESS) {
      return <Redirect to={"/next-step" + ROUTE_PUBLISH_CONTENT} />;
    }

    return (
      <div className="container-bg">
        <PublishContent
          options={this.props.channels}
          createPost={this.createPost}
          ref={this.publishContentRef}
          getLinkPreview={this.getPreview}
          showPreview={this.props.showPreview}
          previewData={this.props.previewData}
          closePreview={this.props.updateShowPreview}
          linkUrl={this.props.linkUrl}
          inputChange={this.props.handleInputChange}
          documentTitle={this.props.documentTitle}
          videoTitle={this.props.videoTitle}
          imageTitle={this.props.imageTitle}
          postType={this.props.postType}
          updatePostType={this.props.updatePostType}
          selectedchannels={this.props.selectedchannels}
          handleSelectedOption={this.props.addSelectedChannel}
          removeChannel={this.props.removeSelectedChannel}
          handleEnteredValue={this.handleEnteredValue}
          handleSkip={this.handleSkip}
          newPostTextBox={this.props.newPostTextBox}
          isFeatured={this.props.isFeatured}
          canSkip={this.props.canSkip}
          createPostState={this.props.createPostState}
          disablePostButton={this.state.disablePostButton}
          getPrevStep={this.getPrevStep}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublishContentPage)
);
