import {
  GET_POSTSHARE_SUCCESS,
  GET_POSTSHARE_REQUEST,
  GET_POSTSHARE_ERROR
} from "../actions/postShare.action";

const intialState = {
  state: null,
  post: null,
  error: null
};

const postShare = (state = intialState, action) => {
  switch (action.type) {
    case GET_POSTSHARE_REQUEST: {
      return {
        ...state,
        state: GET_POSTSHARE_REQUEST
      };
    }
    case GET_POSTSHARE_SUCCESS: {
      return {
        post: action.payload,
        state: GET_POSTSHARE_SUCCESS,
        error: null
      };
    }
    case GET_POSTSHARE_ERROR: {
      return {
        post: action.payload,
        state: GET_POSTSHARE_ERROR,
        error: action.payload
      };
    }
    default:
      return state;
  }
};

export default postShare;
