import React from "react";
import OrgManageDepartments from "../components/orgManageDepartments/orgManageDepartments";
import {
  getOrganizationDepartments,
  createDepartments,
  getDepartmentList
} from "../actions/organizationDepartment.action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class OrgManageDepartmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.orgMangeDeptRef = React.createRef();
    this.state = {
      deptText: ""
    };
  }

  componentDidMount = () => {
    this.props.getOrganizationDepartments();
    this.props.getDepartmentList();
  };

  updateDeptText = value => {
    if (typeof value === "object" && value[0]) {
      this.setState({ deptText: value[0].name });
    }

    if (typeof value === "string") {
      this.setState({ deptText: value });
    }
  };

  createNewDepartment = () => {
    if (this.state.deptText.trim() !== "") {
      this.props.createDepartments({ name: [this.state.deptText] });
      this.orgMangeDeptRef.current._typeahead.getInstance().clear();
    }
  };

  render() {
    return (
      <div className="container-white-bg">
        <div className="container">
          <OrgManageDepartments
            ref={this.orgMangeDeptRef}
            departments={this.props.departments}
            createDepartmentRef={this.props.createDepartmentRef}
            createNewDepartment={this.createNewDepartment}
            departmentList={this.props.departmentList}
            updateDeptText={this.updateDeptText}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    departments: state.organizationDepartment.departments,
    departmentList: state.organizationDepartment.departmentList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrganizationDepartments: () => dispatch(getOrganizationDepartments()),
    createDepartments: data => dispatch(createDepartments(data)),
    getDepartmentList: () => dispatch(getDepartmentList())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrgManageDepartmentPage)
);
