import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  requestForgotPasswordLogin,
  LOGIN_ERROR,
  LOG_OUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from "../actions/login.actions";
import LoginPage from "./LoginPage";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import logo from "../../img/thumbnail_hs_logo_horiz.png";
import logo from "../img/thumbnail_hs_logo_horiz.png";
import CheckEmailNotification from "./CheckEmailNotification";

class Forgotpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      username: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    // this.toggleShow = this.toggleShow.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  componentDidMount() {
    if (
      this.props.isLoggedIn === false &&
      (this.props.state === null ||
        this.props.state === LOGIN_ERROR ||
        this.props.state === LOG_OUT)
    ) {
      // this.props.requestLogin();
    }
  }
  onSubmit = (values) => {
    this.setState({
      isOpen: true,
      username: values.username,
    });
    this.props.requestForgotPasswordLogin({
      username: values.username,
      password: values.password,
      mobile: values.mobile,
    });
  };
  componentDidUpdate() {
    if (this.props.isLoggedIn === true && this.props.state === LOGIN_SUCCESS) {
      this.props.history.push({
        pathname: "/forgot-password",
        state: this.state.username,
      });
    }
  }

  validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Email can't be blank")
      .email("Username should be email."),
  });

  renderForm = (props) => {
    const { handleChange, handleSubmit, values } = props;
    const isRegistrationInProgress = this.props.state === LOGIN_REQUEST;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <div>
            Please enter your email address. We will send you an email to reset
            your password.
          </div>
          <label className="form-lable">Email</label>
          <Field
            type="text"
            value={values.username}
            name="username"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="username" />
          </span>
        </div>

        {/* <div className="form-group form-lable">
          <label className="form-lable">Mobile Number</label>
          <Field
            type="text"
            value={values.mobile}
            name="mobile"
            className="form-control form-input"
          />
        </div> */}

        <button
          className="set-button"
          type="submit"
          disabled={isRegistrationInProgress}
          style={{
            backgroundColor: "#00d3ff",
            display: "block",
          }}
        >
          Get reset link
          {isRegistrationInProgress === true ? (
            <span className="text-right pl-2">
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: "#fff" }}
              />
            </span>
          ) : (
            ""
          )}
        </button>
      </form>
    );
  };

  render() {
    return (
      <div>
        <div
          className="form-rectangle"
          style={{ width: "23%", minHeight: "349px" }}
        >
          <div className="form-headers">
            <img src={logo} alt="symbol" className="logo-set" />
            <div className="center-text">Reset your password</div>
          </div>
          <div className="form-container h-100">
            <div>
              <Formik
                initialValues={{
                  username: "",
                }}
                onSubmit={this.onSubmit}
                render={this.renderForm}
                validationSchema={this.validationSchema}
              />
            </div>
            {console.log("check", this.state.isOpen)}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          Never mind!{" "}
          {/* <Link to="/login" style={{ color: "black", fontWeight: "500" }}>
            {" "}
            Take me back to login
          </Link> */}
          <Link to="/loginPage" style={{ color: "black", fontWeight: "500" }}>
            {" "}
            Take me back to login
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.login.state,
  error: state.login.error,
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = {
  requestForgotPasswordLogin,
  CheckEmailNotification,
  LoginPage,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Forgotpassword)
);
