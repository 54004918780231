import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrganizationProfile from "../components/organizationProfile/organizationProfile";
import {
  saveOrganizationProfile,
  setOrganizationLogo,
  getOrganizationProfile,
  SAVE_ORG_RPOFILE_IN_PROGRESS,
} from "../actions/organizationProfile.actions";
import { getSpecialities } from "../actions/personalProfile.actions";
import { getOrganizationType } from "../actions/organizationProfile.actions";

class OrgManageProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "edit",
    };
  }

  componentWillMount = () => {
    this.props.getSpecialities();
    this.props.getOrganizationProfile();
  };

  saveOrgProfile = (data) => {
    this.props.saveOrganizationProfile(data);
  };

  saveOrganizationLogo = (event) => {
    if (event.target.files[0]) {
      var file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      this.props.setOrganizationLogo(file, formData);
    }
  };

  render() {
    const isSaveInProgress =
      this.props.saveState === SAVE_ORG_RPOFILE_IN_PROGRESS;

    return (
      <div className="container-white-bg">
        <div className="container">
          <OrganizationProfile
            specalities={this.props.specalities}
            saveOrganizationProfile={this.saveOrgProfile}
            saveOrganizationLogo={this.saveOrganizationLogo}
            initialValues={this.props.initialValues}
            orgLogo={this.props.orgLogo}
            isSaveInProgress={isSaveInProgress}
            mode={this.state.mode}
            orgTypes={this.props.orgTypes}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveState: state.organizationProfile.saveState,
  specalities: state.personalProfile.specialities,
  initialValues: state.organizationProfile.initialValues,
  orgLogo: state.organizationProfile.orgLogo,
  orgTypes: state.organizationProfile.orgTypes,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialities: () => dispatch(getSpecialities()),
  saveOrganizationProfile: (data) => dispatch(saveOrganizationProfile(data)),
  setOrganizationLogo: (file, data) =>
    dispatch(setOrganizationLogo(file, data)),
  getOrganizationProfile: () => dispatch(getOrganizationProfile()),
  getOrganizationType: () => dispatch(getOrganizationType()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrgManageProfilePage)
);
