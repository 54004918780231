import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { ROUTE_LOGIN } from "../../containers/RouteConstants";
import { connect } from "react-redux";
import { getPersonalProfile } from "../../actions/personalProfile.actions";
export const EnsureLoggedIn = (WrappedComponent) => {
  class EnsuredLoggedInComponent extends React.Component {
    componentDidMount() {
      this.props.getPersonalProfile(this.props.email);
    }
    render() {
      // console.log("this.props.isLoggedIn", this.props.isLoggedIn);
      // if (window.localStorage.getItem("login") !== true) {
      //   return <Redirect to={ROUTE_LOGIN} />;
      // }
      if (
        this.props.isLoggedIn === false ||
        !window.localStorage.getItem("login")
      ) {
        return <Redirect to={ROUTE_LOGIN} />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    isLoggedIn: state.login.isLoggedIn,
  });
  const mapDispatchToProps = (dispatch) => ({
    getPersonalProfile: (data) => dispatch(getPersonalProfile(data)),
  });
  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EnsuredLoggedInComponent)
  );
};
