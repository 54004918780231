import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";
export const SAVE_PROFESSIONAL_INFO_SUCCESS = "SAVE_PROFESSIONAL_INFO_SUCCESS";
export const SAVE_PROFESSIONAL_INFO_ERROR = "SAVE_PROFESSIONAL_INFO_ERROR";
export const SAVE_PROFESSIONAL_INFO_IN_PROGRESS =
  "SAVE_PROFESSIONAL_INFO_IN_PROGRESS";

export const GET_PROFESSIONAL_INFO_SUCCESS = "GET_PROFESSIONAL_INFO_SUCCESS";

export const saveProfessionalInfo = (professionalInfo) => (dispatch, state) => {
  dispatch({ type: SAVE_PROFESSIONAL_INFO_IN_PROGRESS });
  apiMethods
    .post("ProviderProfessionalProfile/ProviderProfessional", {
      nPInumber: professionalInfo.npiNumber,
      affiliations: JSON.stringify(professionalInfo.affiliations),
      boardCertifications: JSON.stringify(professionalInfo.boardCertifications),
      conditionTreated: JSON.stringify(professionalInfo.conditionsTreated),
    })
    .then((response) => {
      dispatch({
        type: SAVE_PROFESSIONAL_INFO_SUCCESS,
        payload: professionalInfo,
      });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
      dispatch({ type: SAVE_PROFESSIONAL_INFO_ERROR, error: error.message });
    });
};

export const getProfessionalInfo = () => (dispatch) => {
  return apiMethods
    .post("ProviderProfessionalProfile/Provider")
    .then((response) => {
      let professionalInfo = response.data.providerJson;
      professionalInfo.affiliations = professionalInfo.affiliations
        ? JSON.parse(professionalInfo.affiliations)
        : [];
      professionalInfo.boardCertifications =
        professionalInfo.boardCertifications
          ? JSON.parse(professionalInfo.boardCertifications)
          : [];
      professionalInfo.conditionTreated = professionalInfo.conditionTreated
        ? JSON.parse(professionalInfo.conditionTreated)
        : [];
      dispatch({
        type: GET_PROFESSIONAL_INFO_SUCCESS,
        payload: professionalInfo,
      });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error && error.message });
    });
};
