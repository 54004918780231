import baseApiService from "../middleware/baseApiService";

export const DEPTADMIN_SEARCH_REQUEST = "DEPTADMIN_SEARCH_REQUEST";
export const DEPTADMIN_SEARCH_SUCCESS = "DEPTADMIN_SEARCH_SUCCESS";
export const DEPTADMIN_SEARCH_ERROR = "DEPTADMIN_SEARCH_ERROR";

export const DEPTADMIN_ADD_REQUEST = "DEPTADMIN_ADD_REQUEST";
export const DEPTADMIN_ADD_SUCCESS = "DEPTADMIN_ADD_SUCCESS";
export const DEPTADMIN_ADD_ERROR = "DEPTADMIN_ADD_ERROR";

export const GET_DEPTADMINS_REQUEST = "GET_DEPTADMINS_REQUEST";
export const GET_DEPTADMINS_SUCCESS = "GET_DEPTADMINS_SUCCESS";
export const GET_DEPTADMINS_ERROR = "GET_DEPTADMINS_ERROR";

export const GET_DEPTADMIN_REQUEST = "GET_DEPTADMIN_REQUEST";
export const GET_DEPTADMIN_SUCCESS = "GET_DEPTADMIN_SUCCESS";
export const GET_DEPTADMIN_ERROR = "GET_DEPTADMIN_ERROR";

export const REMOVE_DEPTADMIN_REQUEST = "REMOVE_DEPTADMIN_REQUEST";
export const REMOVE_DEPTADMIN_SUCCESS = "REMOVE_DEPTADMIN_SUCCESS";
export const REMOVE_DEPTADMIN_ERROR = "REMOVE_DEPTADMIN_ERROR";

export const deptAdminSearch = (searchText) => (dispatch) => {
  dispatch({ type: DEPTADMIN_SEARCH_REQUEST });
  baseApiService
    .post(`Provider/search?SearchText=${searchText}`)
    .then((response) => {
      dispatch({ type: DEPTADMIN_SEARCH_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: DEPTADMIN_SEARCH_ERROR, error: err.message });
    });
};

export const addDeptAdmin = (providerId, deptId) => (dispatch) => {
  dispatch({ type: DEPTADMIN_ADD_REQUEST });
  baseApiService
    .post("DepartmentAdmin/add", {
      providerId: providerId,
      departmentId: deptId,
    })
    .then((response) => {
      dispatch({ type: DEPTADMIN_ADD_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: DEPTADMIN_ADD_ERROR, error: err.message });
    });
};

export const getDeptAdmins = (deptId) => (dispatch) => {
  dispatch({ type: GET_DEPTADMINS_REQUEST });
  baseApiService
    .get("DepartmentAdmin/Get?deptId=" + deptId)
    .then((response) => {
      dispatch({ type: GET_DEPTADMINS_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: GET_DEPTADMINS_ERROR, error: err.message });
    });
};

export const getDeptAdmin = (id) => (dispatch) => {
  dispatch({ type: GET_DEPTADMIN_REQUEST });
  baseApiService
    .get(`DepartmentAdmin/GetForId?deptAdminId=${id}`)
    .then((response) => {
      dispatch({ type: GET_DEPTADMIN_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: GET_DEPTADMIN_ERROR, error: err.message });
    });
};

export const removeDeptAdmin = (providerId, deptId) => (dispatch) => {
  dispatch({ type: REMOVE_DEPTADMIN_REQUEST, data: providerId });
  baseApiService
    .deleteRequest(
      `DepartmentAdmin/Delete?providerId=${providerId}&departmentId=${deptId}`
    )
    .then((response) => {
      dispatch({ type: REMOVE_DEPTADMIN_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: REMOVE_DEPTADMIN_ERROR, error: err.message });
    });
};
