import React, { Component } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./personalInfo.css";
import profile_icon from "../../img/adminUser.png";
import apiMethods from "../../middleware/baseApiService";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";
import { showError } from "../../actions/errorDisplay.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
class PersonalProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode,
      dummyProfileImg: profile_icon,
    };
    this.newChannel = this.newChannel.bind(this);
    this.imageRef = React.createRef();
  }
  newChannel = async (data) => {
    const promise = new Promise((resolve, reject) => {
      apiMethods
        .post("Speciality/Create", { name: data.name })
        .then((response) => {
          resolve(response.data.id);
        })
        .catch((error) => {
          this.props.showError(error.data.message);
          // console.log("errorp", error);
        });
    });

    let result = await promise;

    return result;
  };
  onSubmit = (values) => {
    var userData = {};
    for (var key in values) {
      if (key !== "degree" && key !== "speciality") userData[key] = values[key];
      if (key === "degree") userData["degree"] = JSON.stringify(values.degree);
      if (key === "specialities") {
        userData["specialityIds"] = values.specialities.map((el) => {
          if (typeof el.id === "number") {
            return el.id;
          } else if (typeof el.id !== "number") {
            const run = async (el) => {
              const result = await this.newChannel(el);

              // return result;
              userData["specialityIds"] = [
                ...userData.specialityIds,
                result,
              ].filter(function (el) {
                return el != null;
              });
              setTimeout(this.props.saveProfile(userData), 10000);
            };
            run(el);
          }
        });
        let sum = 0;
        for (var i = 0; i < values.specialities; i++) {
          if (typeof values.specialities[i] !== "number") {
            sum++;
          }
        }
        console.log("checksum", sum);
        if (sum === 0) {
          setTimeout(this.props.saveProfile(userData), 10000);
        }
        // console.log("typeof", typeof SpecialityIds[0]);

        // if (typeof SpecialityIds[0] === "string") {
        //   SpecialityIds[0] = this.props.specialities.length;
        //   console.log("SpecialityIds", SpecialityIds);
        //   userData["specialityIds"] = SpecialityIds;
        // } else {
        //   userData["specialityIds"] = SpecialityIds;
        // }
      }
    }
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    contactPhone: Yup.string().required("Contact phone number is required"),
    // .test("validPhoneNumber", "Contact phone number invalid", value => {
    //   return isValidPhoneNumber(value);
    // }),
    organisationName: Yup.string().required("Organisation name  is required"),
    location: Yup.string().required("Location is required"),
    degree: Yup.array().min(1, "Degree is required"),
    specialities: Yup.array().min(1, "Speciality is required"),
  });

  getProfileImage = () => {
    this.imageRef.current.click();
  };

  profileImageUploaded = (event) => {};

  renderForm = (props) => {
    const { handleSubmit, values } = props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <label className="form-lable">First name</label>
          <Field
            type="text"
            name="firstName"
            className="form-control form-input"
            onChange={(e) => {
              var name = /^[a-zA-Z\s]*$/;
              if (e.target.value !== "" && e.target.value.match(name) === null)
                alert("Please input alphabetic characters only");
              else {
                props.setFieldValue("firstName", e.target.value);
              }
            }}
          />
          <span className="error_text">
            <ErrorMessage name="firstName" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Last name</label>
          <Field
            type="text"
            name="lastName"
            className="form-control form-input"
            onChange={(e) => {
              var name = /^[a-zA-Z\s]*$/;
              if (e.target.value !== "" && e.target.value.match(name) === null)
                alert("Please input alphabetic characters only");
              else {
                props.setFieldValue("lastName", e.target.value);
              }
            }}
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="lastName" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Phone</label>
          <Field
            className="form-control form-input"
            name="contactPhone"
            type="text"
            value={values.contactPhone}
            onChange={(e) => {
              var name = /^[0-9]+$/;
              if (e.target.value !== "" && e.target.value.match(name) === null)
                alert("Please input numeric characters only");
              else {
                props.setFieldValue("contactPhone", e.target.value);
              }
            }}
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="contactPhone" />
          </span>
        </div>
        <div className="form-group form-lable">
          <div className="row">
            <div className="col-sm-6">
              <label className="form-lable">Organization</label>
              <Field
                type="text"
                name="organisationName"
                className="form-control form-input"
                onChange={(e) => {
                  var name = /^[a-z A-Z]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(name) === null
                  )
                    alert("Please input alphabetic characters only");
                  else {
                    props.setFieldValue("organisationName", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                {" "}
                <ErrorMessage name="organisationName" />
              </span>
            </div>
            <div className="col-sm-6">
              <label className="form-lable">Location</label>
              <Field
                type="text"
                name="location"
                className="form-control form-input"
              />
              <span className="error_text">
                {" "}
                <ErrorMessage name="location" />
              </span>
            </div>
          </div>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Degree</label>
          <Typeahead
            options={values.allDegrees}
            selected={values.degree}
            placeholder=""
            name="degree"
            labelKey="name"
            onChange={(selectedOptions) => {
              props.setFieldValue("degree", selectedOptions);
              // props.setFieldTouched("degree", true, true);
            }}
            allowNew
            multiple
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="degree" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Specialty</label>

          <Typeahead
            options={values.allSpecialities}
            selected={values.specialities}
            labelKey="name"
            placeholder=""
            name="specialities"
            onChange={(selectedOptions) => {
              props.setFieldValue("specialities", selectedOptions);
              // props.setFieldTouched("specialities", true, true);
            }}
            onBlur={() => {
              props.setFieldTouched("specialities", true, true);
            }}
            multiple
            allowNew
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="specialities" />
          </span>
        </div>
        <div className="mt-5 text-right">
          {this.state.mode === "edit" ? (
            <button
              type="submit"
              className="btn form-btn"
              id="btnLogin"
              disabled={this.props.isSaveInProgress}
            >
              Save
              {this.props.isSaveInProgress === true ? (
                <span className="text-right pl-2">
                  <i
                    className="fa fa-circle-o-notch fa-spin"
                    style={{ color: "#fff" }}
                  />
                </span>
              ) : (
                ""
              )}
            </button>
          ) : (
            <button
              type="submit"
              className="btn form-btn"
              id="btnLogin"
              disabled={this.props.isSaveInProgress}
            >
              Next
              {this.props.isSaveInProgress === true ? (
                <span className="text-right pl-2">
                  <i
                    className="fa fa-circle-o-notch fa-spin  "
                    style={{ color: "#fff" }}
                  />
                </span>
              ) : (
                ""
              )}
            </button>
          )}
        </div>
      </form>
    );
  };

  render() {
    var title =
      this.state.mode === "edit"
        ? "Personal PROFILE"
        : "TELL US ABOUT YOURSELF…";

    let initialValues = {
      firstName: "",
      lastName: "",
      contactPhone: "",
      organisationName: "",
      location: "",
      degree: [],
      specialities: [],
      allDegrees: [],
      allSpecialities: [],
    };

    if (this.props.personalProfile) {
      initialValues.firstName = this.props.personalProfile.firstName
        ? this.props.personalProfile.firstName
        : "";
      initialValues.lastName = this.props.personalProfile.lastName
        ? this.props.personalProfile.lastName
        : "";
      initialValues.contactPhone = this.props.personalProfile.contactPhone
        ? this.props.personalProfile.contactPhone
        : "";

      initialValues.organisationName = this.props.personalProfile
        .organisationName
        ? this.props.personalProfile.organisationName
        : "";

      initialValues.location = this.props.personalProfile.location
        ? this.props.personalProfile.location
        : "";
      initialValues.degree = this.props.personalProfile.degree
        ? JSON.parse(this.props.personalProfile.degree)
        : [];
      initialValues.specialities = Object.assign(
        [],
        this.props.personalProfile.specialities
      );
    }

    initialValues.allDegrees = this.props.degrees ? this.props.degrees : [];
    initialValues.allSpecialities = this.props.specialities;

    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>{title}</h3>
            {this.state.mode === "edit" ? (
              ""
            ) : (
              <p>This is the stuff we HAVE to know.</p>
            )}
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText ">
                  {this.props.mode === "edit"
                    ? null
                    : `Users typically trust content more when they can see the 
                    provider who posted it`}
                </div>
                {this.props.profileImg == null ? (
                  <div className="user-oval" />
                ) : (
                  <div
                    style={{
                      backgroundImage: "url(" + this.props.profileImg + ")",
                    }}
                    className="org_logo"
                  />
                )}

                {this.state.mode === "edit" ? (
                  <div className="form-group text-center mt-4">
                    <span
                      className="form-link-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.imageRef.current.click()}
                    >
                      Change pic
                    </span>
                    {/* <a className="form-link-btn">Change password</a> */}
                  </div>
                ) : (
                  <div className="form-group text-center mt-4">
                    <span
                      className="form-link-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.imageRef.current.click()}
                    >
                      Add a profile pic here
                    </span>
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={this.imageRef}
                  onChange={this.props.saveProfileImg}
                />
              </div>
              <div className="col-8">
                <div className="form-body">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                    validationSchema={this.validationSchema}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showError,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersonalProfile)
);
