import React, { Component } from "react";
import { Formik, Field, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { REGISTER_REQUEST } from "../../actions/register.actions";
import eye from "../../img/eye.png";
import info from "../../img/info_icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RegisterationSuccessPage from "../../containers/RegisterationSuccessPage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import "./register.css";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment/moment";

export default class RegisterInfoPopup extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleSubmitIsChecked = this.handleSubmitIsChecked.bind(this);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      isOpen: false,
      mobile: "",
      isChecked: false,
      isTermsPrivacy: false,
      showModel: false,
      checkNumber: "",
      errorPhone: "",
      selectedMonth: moment().month() + 1,
      selectedDay: moment().date(),
      selectedYear: moment().year(),
      gender: "",
      custom: "",
      errorGender: false,
      isCheckedConstent: false,
    };
    this.years = [];
    this.currentYear = moment().year();
    for (let year = this.currentYear; year >= this.currentYear - 100; year--) {
      this.years.push(year);
    }
    this.months = moment.months();
  }
  daysInMonth = () => {
    return moment(
      `${this.state.selectedYear}-${this.state.selectedMonth}`,
      "YYYY-MM"
    ).daysInMonth();
  };

  days = () => {
    const numDays = this.daysInMonth();
    return Array.from({ length: numDays }, (_, i) => i + 1);
  };
  onSubmit = (values) => {
    console.log("sub", values);
    if (this.state.checkNumber.length !== 10) {
      this.setState({ errorPhone: "Mobile number is required" });
      return;
    }
    if (
      this.state.isTermsPrivacy === false ||
      this.state.isCheckedConstent === false
    ) {
      this.setState({ showModel: true });
      return;
    }
    if (this.state.gender === "") {
      this.setState({ errorGender: true });
      return;
    }
    this.props.register({
      username: values.username,
      password: values.password,
      confirmPassword: values.confirmPassword,
      registrationType: "CLIENT",
      mobile: this.state.mobile,
      gender: this.state.gender,
      dateOfBirth: `${this.state.selectedDay}-${this.state.selectedMonth}-${this.state.selectedYear}`,
      firstName: values.firstName,
      lastName: values.lastName,
    });
    this.setState({ errorPhone: "" });
  };

  toggleShow = (field, value) => {
    if (field === "password") this.setState({ showPassword: !value });
    else {
      this.setState({ showConfirmPassword: !value });
    }
  };
  handleSubmitIsChecked = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  handleSubmitIsTermsPrivacy = () => {
    this.setState({
      isTermsPrivacy: !this.state.isTermsPrivacy,
    });
  };
  handleSubmitIsAge = () => {
    this.setState({
      isCheckedConstent: !this.state.isCheckedConstent,
    });
  };
  handleClose = () => {
    this.setState({
      showModel: false,
    });
  };

  handlePhonechange = (value, country) => {
    this.setState({ mobile: value });
    if (country.countryCode === "ca" || country.countryCode === "us") {
      this.setState({ checkNumber: value.substring(1) });
      if (value.substring(1).length === 10) {
        this.setState({ errorPhone: "" });
      } else {
        this.setState({ errorPhone: "Mobile number is required" });
      }
    } else if (country.countryCode === "in" || country.countryCode === "gb") {
      this.setState({ checkNumber: value.substring(2) });
      if (value.substring(2).length === 10) {
        this.setState({ errorPhone: "" });
      } else {
        this.setState({ errorPhone: "Mobile number is required" });
      }
    }
  };
  validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .email("Username should be email."),

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters long."),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "Password and confirm password do not match."
      ),
    // gender: Yup.string().required(),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    // registrationType: Yup.string().required("Registration type is required"),
  });
  onChangeGender = (e) => {
    if (e.target.value === "Male" || e.target.value === "Female") {
      this.setState({ gender: e.target.value });
      this.setState({ custom: "" });
    }
    this.setState({ gender: e.target.value });
    this.setState({ errorGender: false });
  };
  handleCustom = (e) => {
    if (this.state.gender === "Male" || this.state.gender === "Female") {
      this.setState({ gender: "" });
    }
    this.setState({ custom: e.target.value });
  };
  renderForm = (props) => {
    const {
      handleChange,
      handleSubmit,
      values,
      errors,
      touched,
      isSubmitting,
    } = props;

    const isRegistrationInProgress = this.props.state === REGISTER_REQUEST;

    return (
      <form onSubmit={handleSubmit}>
        <div
          className="form-group "
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        >
          <div style={{ width: "49%", borderRadius: "5px" }}>
            <Field
              id="firstName"
              type="text"
              value={values.firstName}
              name="firstName"
              className="form-popup"
              placeholder="First name"
              style={{
                border:
                  errors.firstName && touched.firstName ? "1px solid red" : "",
              }}
            />
          </div>
          <div style={{ width: "49%" }}>
            <Field
              id="lastName"
              type="text"
              value={values.lastName}
              name="lastName"
              className="form-popup"
              placeholder="Last name"
              style={{
                border:
                  errors.lastName && touched.lastName ? "1px solid red" : "",
              }}
            />
          </div>
        </div>
        <div className="form-group " style={{ marginBottom: ".5rem" }}>
          <Field
            id="username"
            type="text"
            value={values.username}
            name="username"
            className="form-popup"
            placeholder="Email"
            style={{
              border:
                errors.username && touched.username ? "1px solid red" : "",
            }}
          />
        </div>

        <div className="form-group " style={{ marginBottom: ".5rem" }}>
          {/* <label className="form-lable">Password</label> */}
          {/* <OverlayTrigger
            placemeKnt="right"
            overlay={
              <Tooltip id="ph_help">
                Password should be minimum 8 characters long.
              </Tooltip>
            }
          >
            <img
              src={info}
              alt="Show"
              className="pull-right"
              style={{ width: "4%", marginRight: "3%" }}
            ></img>
          </OverlayTrigger> */}
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
              border:
                errors.password && touched.password
                  ? "1px solid red"
                  : "1px solid #dddfe2",
              borderRadius: "6px",
            }}
          >
            <Field
              type={this.state.showPassword ? "text" : "password"}
              value={values.password}
              name="password"
              className="form-popup"
              placeholder="Password"
              style={{
                border: "none",
              }}
            />
            <span style={{ textAlign: "center" }}>
              <img
                src={eye}
                alt="Show"
                style={{ width: "50%" }}
                onClick={() => {
                  this.toggleShow("password", this.state.showPassword);
                }}
              />
            </span>
          </div>
        </div>

        <div className="form-group  " style={{ marginBottom: ".5rem" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
              border:
                errors.confirmPassword && touched.confirmPassword
                  ? "1px solid red"
                  : "1px solid #dddfe2",
              borderRadius: "6px",
            }}
          >
            <Field
              type={this.state.showConfirmPassword ? "text" : "password"}
              value={values.confirmPassword}
              name="confirmPassword"
              className="form-popup"
              placeholder="Confirm Password"
              style={{
                border: "none",
              }}
            />
            <span style={{ textAlign: "center" }}>
              <img
                src={eye}
                alt="Show"
                style={{ width: "50%" }}
                onClick={() => {
                  this.toggleShow(
                    "confirm_password",
                    this.state.showConfirmPassword
                  );
                }}
              />
            </span>
          </div>
        </div>

        <div
          className="form-group "
          style={{ height: "2.4rem", marginBottom: ".5rem" }}
        >
          <PhoneInput
            type="text"
            value={this.state.mobile}
            onChange={(value, country) =>
              this.handlePhonechange(value, country)
            }
            name="mobile"
            country={"us"}
            onlyCountries={["us"]}
            style={{
              width: "100%",
              border: this.state.errorPhone && "1px solid red",
              borderRadius: "5px",
            }}
            inputStyle={{
              width: "100%",
            }}
            inputProps={{ required: true }}
            rules={{ required: true }}
            className="input-phone-number"
          />

          {/* <span className="error_text">{this.state.errorPhone}</span> */}
        </div>
        <label className="lable-popup">Birthday</label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "31%", borderRadius: "5px" }}>
            <select
              type="text"
              value={this.state.selectedDay}
              onChange={(e) =>
                this.setState({ selectedDay: parseInt(e.target.value) })
              }
              className="form-popup"
              style={{ height: "35px", padding: "0px 10px" }}
            >
              {this.days().map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
            <span className="error_text">
              {/* <ErrorMessage name="username" /> */}
            </span>
          </div>
          <div style={{ width: "31%" }}>
            <select
              type="text"
              value={this.state.selectedMonth}
              onChange={(e) =>
                this.setState({ selectedMonth: parseInt(e.target.value) })
              }
              className="form-popup"
              style={{ height: "35px", padding: "0px 10px" }}
            >
              {" "}
              {this.months.map((option, index) => (
                <option key={index} value={index + 1}>
                  {option}
                </option>
              ))}
            </select>
            <span className="error_text">
              {/* <ErrorMessage name="username" /> */}
            </span>
          </div>
          <div style={{ width: "31%", borderRadius: "5px" }}>
            <select
              type="text"
              value={this.state.selectedYear}
              onChange={(e) =>
                this.setState({ selectedYear: parseInt(e.target.value) })
              }
              className="form-popup"
              style={{ height: "35px", padding: "0px 10px" }}
            >
              {this.years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <span className="error_text">
              {/* <ErrorMessage name="username" /> */}
            </span>
          </div>
        </div>
        <label className="lable-popup">Gender</label>
        <div
          className="form-group "
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2px",
            height: "35px",
          }}
        >
          <div
            className="form-popup"
            style={{
              width: "31%",
              display: "flex",
              padding: "8px",
              justifyContent: "space-between",
              border: this.state.errorGender && "1px solid red",
              height: "35px",
            }}
          >
            <div>
              <label>Female</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                value={"Female"}
                name="gender"
                className="form-popup"
                onChange={(e) => this.onChangeGender(e)}
                checked={this.state.gender === "Female"}
              />
            </div>
          </div>
          <div
            className="form-popup"
            style={{
              width: "31%",
              display: "flex",
              padding: "8px",
              justifyContent: "space-between",
              border: this.state.errorGender && "1px solid red",
              height: "35px",
            }}
          >
            <div>
              <label>Male</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                value={"Male"}
                name="gender"
                className="form-popup"
                onChange={(e) => this.onChangeGender(e)}
                checked={this.state.gender === "Male"}
              />
            </div>
          </div>
          <div
            className="form-popup"
            style={{
              width: "31%",
              display: "flex",
              padding: "8px",
              justifyContent: "space-between",
              border: this.state.errorGender && "1px solid red",
              height: "35px",
            }}
          >
            <div>
              <label>Custom</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                value={"Custom"}
                className="form-popup"
                onChange={(e) => {
                  this.handleCustom(e);
                }}
                checked={this.state.custom === "Custom"}
              />
            </div>
          </div>
        </div>
        {this.state.custom === "Custom" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                height: "35px",
              }}
            >
              <div
                className="form-popup"
                style={{
                  width: "37%",
                  display: "flex",
                  padding: "5px",
                  border: this.state.errorGender && "1px solid red",
                  height: "35px",
                  justifyContent: "space-between",
                  lineHeight: "20px",
                }}
              >
                <div>
                  <label>Prefer not to say</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    value={"Prefer not to say"}
                    name="gender"
                    className="form-popup"
                    onChange={(e) => this.onChangeGender(e)}
                    checked={this.state.gender === "Prefer not to say"}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "60%",
                  borderRadius: "5px",
                  border: this.state.errorGender && "1px solid red",
                  height: "35px",
                }}
              >
                <select
                  type="text"
                  className="form-popup"
                  style={{ padding: "0px 10px", height: "35px" }}
                  onChange={(e) => this.onChangeGender(e)}
                  name="gender"
                >
                  <option value="" disabled selected>
                    Select your pronoun
                  </option>
                  <option value={"She: Wish her a happy birthday!"}>
                    She: "Wish her a happy birthday!"
                  </option>
                  <option value={"He: Wish him a happy birthday!"}>
                    He: "Wish him a happy birthday!"
                  </option>
                  <option value={"They: Wish them a happy birthday!"}>
                    They: "Wish them a happy birthday!"
                  </option>
                </select>
              </div>
            </div>
            {/* <div className="form-group ">
              <label className="lable-popup">
                Your pronoun is visible to everyone.
              </label>
              <Field
                type="text"
                value={values.genderOptional}
                name="genderOptional"
                // className="form-control form-input"
                className="form-popup"
                placeholder="Gender (optional)"
              />
            </div> */}
          </>
        )}
        <div className="row">
          <div className="offset-md-3 col-md-9"></div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        >
          <input
            type="checkbox"
            id="inProgress"
            onChange={this.handleSubmitIsChecked}
            checked={this.state.isChecked}
            data-mssg="In Progress"
          />
          <label for="inProgress" />
          <div
            style={{
              color: "#4A4A4A",
              fontSize: "12px",
              fontFamily: "inherit",
              marginLeft: "10px",
            }}
          >
            I agree to receive SMS messages from Healthswim (including OTP).
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <input
            type="checkbox"
            id="inProgress"
            onChange={this.handleSubmitIsTermsPrivacy}
            checked={this.state.isTermsPrivacy}
            data-mssg="In Progress"
          />
          <label for="inProgress" />
          <div
            style={{
              color: "#4A4A4A",
              fontSize: "12px",
              fontFamily: "inherit",
              marginLeft: "10px",
            }}
          >
            I agree to the{" "}
            <Link to="/termsAndConditionPage" target="_blank">
              Terms and Conditions
            </Link>{" "}
            and the{" "}
            <Link to="/privacyPolicys" target="_blank">
              Privacy policy
            </Link>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <input
            type="checkbox"
            id="inProgress"
            onChange={this.handleSubmitIsAge}
            checked={this.state.isCheckedConstent}
            data-mssg="In Progress"
          />
          <label for="inProgress" />
          <div
            style={{
              color: "#4A4A4A",
              fontSize: "12px",
              fontFamily: "inherit",
              marginLeft: "10px",
            }}
          >
            I certify that I am 18 years of age or older.
          </div>
        </div>
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
            marginTop: "10px",
            height: "37px",
          }}
        >
          <button
            type="submit"
            style={{
              backgroundColor: "#00d3ff",
              display: "block",
              width: "50%",
              fontSize: "18px",
              fontWeight: "bold",
              padding: "0px",
              borderRadius: "6px",
              textTransform: "none",
            }}
            className="set-button"
            id="btnLogin"
          >
            Sign Up
            {/* <span className="text-right pl-2">
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: "#fff" }}
              />
            </span> */}
            {/* {isRegistrationInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )} */}
          </button>
        </div>
      </form>
    );
  };

  render() {
    console.log(this.state);
    return (
      <>
        <Modal
          show={this.state.showModel}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
            <Modal.Title>MESSAGE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", margin: "auto" }}>
              {!this.state.isTermsPrivacy
                ? "Please accept Terms of service and Privacy policy."
                : "Please select age criteria."}
            </p>
          </Modal.Body>
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                backgroundColor: "#00CCFF",
                borderColor: "transparent",
                margin: "auto",
              }}
              onClick={this.handleClose}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <div>
          <div className=" h-100">
            <div>
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                  registrationType: "",
                  confirmPassword: "",
                  mobile: "",
                  // picked: "",
                  // genderOptional: "",
                  firstName: "",
                  lastName: "",
                  // gender: "",
                }}
                onSubmit={this.onSubmit}
                render={this.renderForm}
                validationSchema={this.validationSchema}
              />
            </div>
          </div>
          <div
            style={{
              color: "#4A4A4A",
              fontSize: "12px",
              fontFamily: "inherit",
              marginBottom: "10px",
            }}
          >
            {/* If you are a patient, please visit the Apple app store or Google
            play store to download the Healthswim app */}
          </div>
          <div
            style={{
              color: "#4A4A4A",
              fontSize: "12px",
              fontFamily: "inherit",
            }}
          >
            {/* If you are a healthcare provider and want to help your patients with
            seamless transmission of credible medical information, please email
            us at */}
            If you are a provider, please email us at{" "}
            <a href="mailto:info@healthswim.com" target="_blank">
              info@healthswim.com
            </a>{" "}
            to set up your account
            {/* . We will help in setting up your account. */}
          </div>
        </div>
      </>
    );
  }
}
