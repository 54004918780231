import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { Modal } from "react-bootstrap";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";

class editProvider extends React.Component {
  constructor(props) {
    super(props);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.inviteAnotherProvider = this.inviteAnotherProvider.bind(this);
    this.formik = React.createRef();
  }

  // inviteAnotherProvider() {
  //   this.setState({ addInviteAnotherProviderAfterSave: false });
  //   this.formik.current.resetForm();
  //   this._locationTypeahead.getInstance().clear();
  //   this._departmentTypeahead.getInstance().clear();
  // }

  // onSubmit(values) {
  //   // this.props.inviteProvider(
  //   //   values,
  //   //   this.state.addInviteAnotherProviderAfterSave
  //   // );
  //   console.log(values);
  // }

  // validationSchema = Yup.object().shape({
  //   firstName: Yup.string().required("First name is required"),
  //   lastName: Yup.string().required("Last name is required"),
  //   phone: Yup.string().required("Phone is required"),
  //   email: Yup.string()
  //     .email()
  //     .required("Email is required"),
  //   location: Yup.array().min(1, "location is required"),
  //   department: Yup.array().min(1, "Department is required"),
  //   degrees: Yup.array().min(1, "Degree is required"),
  //   specialities: Yup.array().min(1, "Specialities is required"),
  //   npiNumber: Yup.string().required("NPI number is required"),
  //   certifications: Yup.array().min(1, "Certification is required"),
  //   affiliations: Yup.array().min(1, "Affiliations is required"),
  //   conditionsTreated: Yup.array().min(1, "Conditions treated is required")
  // });

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        dialogClassName="modal-size"
      >
        <div className="manage-form-header">
          <div style={{ float: "right" }}>
            <button onClick={this.props.handleClose}>X</button>
          </div>
          <div className="manage-form-title align-middle">
            {`${this.props.provider.firstName}${" "}${
              this.props.provider.lastName
            }`}
          </div>
        </div>

        <div className="manage-form-container">
          <div className="row">
            <div className="col-12">
              <div className="form-body">
                <Formik
                  ref={this.formik}
                  initialValues={{
                    firstName: this.props.provider.firstName || "",
                    lastName: this.props.provider.lastName || "",
                    email: this.props.provider.email || "",
                    phone: this.props.provider.contactPhone || "",
                    location: [this.props.provider.location || ""],
                    department: [this.props.provider.departmentName || ""],
                    degrees: JSON.parse(this.props.provider.degree) || [],
                    specialities: this.props.provider.specialities || [],
                    npiNumber: this.props.provider.npInumber || "",
                    certifications:
                      JSON.parse(this.props.provider.boardCertifications) || [],
                    affiliations:
                      JSON.parse(this.props.provider.affiliations) || [],
                    conditionsTreated:
                      JSON.parse(this.props.provider.conditionTreated) || []
                  }}
                  onSubmit={this.onSubmit}
                  render={this.renderForm}
                  validationSchema={this.validationSchema}
                  onReset={this.addAnotherAdmin}
                  enableReinitialize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderForm = props => {
    const { handleSubmit, values } = props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-sm-6">
              <label className="form-lable">First name</label>
              <Field
                type="text"
                value={values.firstName}
                name="firstName"
                className="form-control form-input"
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="firstName" />
              </span>
            </div>
            <div className="col-sm-6">
              <label className="form-lable">Last name</label>
              <Field
                type="text"
                value={values.lastName}
                name="lastName"
                className="form-control form-input"
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="lastName" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-sm-6">
              <label className="form-lable">Phone</label>
              <Field
                className="form-control form-input"
                name="phone"
                value={values.phone}
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="phone" />
              </span>
            </div>
            <div className="col-sm-6">
              <label className="form-lable">Email</label>
              <Field
                type="text"
                value={values.email}
                name="email"
                className="form-control form-input"
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="email" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-6">
              <label className="form-lable">Location</label>
              <Typeahead
                options={this.props.locations}
                selected={values.location}
                placeholder=""
                labelKey="primaryLocationName"
                name="location"
                className="singleSelect"
                ref={ref => (this._locationTypeahead = ref)}
                onChange={selectedOptions =>
                  props.setFieldValue("location", selectedOptions)
                }
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="location" />
              </span>
            </div>
            <div className="col-6">
              <label className="form-lable">Department</label>
              <Typeahead
                options={this.props.departments}
                labelKey="departmentName"
                value={values.department}
                placeholder=""
                name="department"
                ref={ref => (this._departmentTypeahead = ref)}
                className="singleSelect"
                onChange={selectedOptions =>
                  props.setFieldValue("department", selectedOptions)
                }
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="department" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-12">
              <label className="form-lable">Degree</label>
              <Typeahead
                clearButton
                options={this.props.degrees}
                selected={values.degrees}
                labelKey="name"
                placeholder=""
                name="degrees"
                onChange={selectedOptions => {
                  props.setFieldValue("degrees", selectedOptions);
                }}
                multiple
                disabled
              />
              <span className="error_text">
                {" "}
                <ErrorMessage name="degrees" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-12">
              <label className="form-lable">Speciality</label>
              <Typeahead
                clearButton
                options={this.props.specialities}
                placeholder=""
                name="specialities"
                selected={values.specialities}
                labelKey="name"
                onChange={selectedOptions => {
                  let namesOfSelected = [];
                  if (selectedOptions) {
                    namesOfSelected = selectedOptions.map(c => c);
                  }
                  props.setFieldValue("specialities", namesOfSelected, false);
                }}
                multiple
                disabled
              />
              <span className="error_text">
                {" "}
                <ErrorMessage name="specialities" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-12">
              <label className="form-lable">NPI number</label>
              <Field
                type="text"
                value={values.npiNumber}
                name="npiNumber"
                className="form-control form-input"
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="npiNumber" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-12">
              <label className="form-lable">Board certifications</label>
              <Typeahead
                clearButton
                options={this.props.certifications}
                selected={values.certifications}
                placeholder=""
                labelKey="name"
                name="certifications"
                onChange={selectedOptions => {
                  props.setFieldValue("certifications", selectedOptions);
                }}
                multiple
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="certifications" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-12">
              <label className="form-lable">Affiliations</label>
              <Typeahead
                clearButton
                options={this.props.affiliations}
                selected={values.affiliations}
                placeholder=""
                name="affiliations"
                labelKey="name"
                onChange={selectedOptions => {
                  props.setFieldValue("affiliations", selectedOptions);
                }}
                multiple
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="affiliations" />
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row no-gutter">
            <div className="col-12">
              <label className="form-lable">Conditions Treated</label>
              <Typeahead
                clearButton
                options={this.props.conditionsTreated}
                selected={values.conditionsTreated}
                placeholder=""
                name="conditionsTreated"
                labelKey="name"
                onChange={selectedOptions => {
                  props.setFieldValue("conditionsTreated", selectedOptions);
                }}
                multiple
                disabled
              />
              <span className="error_text">
                <ErrorMessage name="conditionsTreated" />
              </span>
            </div>
          </div>
        </div>

        {/* <div className="mt-5 text-right">
          <button
            type="submit"
            className="btn form-btn"
            id="btnLogin"
            disabled={this.props.isSaveInProgress}
          >
            Done
            {this.props.isSaveInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div> */}
      </form>
    );
  };
}

export default editProvider;
