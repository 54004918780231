import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrgManageDeptAdmin from "../components/orgManageDeptAdmin/orgManageDeptAdmin";
import {
  deptAdminSearch,
  addDeptAdmin,
  getDeptAdmins,
  getDeptAdmin,
  removeDeptAdmin,
  DEPTADMIN_ADD_SUCCESS,
  REMOVE_DEPTADMIN_ERROR,
} from "../actions/departmentAdmin.action";

import { getMyDepartments } from "../actions/department.action";

class OrgManageDeptAdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditForm: false,
      savingAddedDeptAdmin: false,
      selectedDepartment: null,
    };

    this.deptAdminSearch = this.deptAdminSearch.bind(this);
    this.addDepartmentAdmin = this.addDepartmentAdmin.bind(this);
    this.getDepatmentAdmins = this.getDepatmentAdmins.bind(this);
    this.getDeptAdminDetails = this.getDeptAdminDetails.bind(this);
    this.hideEditForm = this.hideEditForm.bind(this);
  }

  componentDidMount() {
    //this.props.getDeptAdmins();
    this.props.getMyDepartments();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.removeDeptAdminState !== nextProps.removeDeptAdminState &&
      nextProps.removeDeptAdminState === REMOVE_DEPTADMIN_ERROR
    ) {
      this.props.getDeptAdmins(this.state.selectedDepartment);
    }

    if (
      this.props.addAdminState !== nextProps.addAdminState &&
      nextProps.addAdminState === DEPTADMIN_ADD_SUCCESS
    ) {
      this.props.getDeptAdmins(this.state.selectedDepartment);
    }
  }

  deptAdminSearch(searchText) {
    this.props.deptAdminSearch(searchText);
  }

  addDepartmentAdmin = function (userid, deptId) {
    if (userid && deptId) {
      this.props.addDeptAdmin(userid, deptId);
    }
  };

  getDeptAdminDetails(id) {
    this.props.getDeptAdmin(id);
    this.setState({ showEditForm: true });
  }

  getDepatmentAdmins(deptId) {
    if (deptId) {
      this.setState({ selectedDepartment: deptId });
      this.props.getDeptAdmins(deptId);
    }
  }

  hideEditForm() {
    this.setState({ showEditForm: false });
  }

  render() {
    // const isDeleteInProgress =
    // this.props.removeDeptAdminState === REMOVE_DEPTADMIN_REQUEST;

    return (
      <div className="container-white-bg">
        <div className="container">
          <OrgManageDeptAdmin
            departmentAdmins={this.props.departmentAdmins}
            searchResults={this.props.searchResults}
            isLoading={this.props.isLoading}
            deptAdminSearch={this.deptAdminSearch}
            addDepartmentAdmin={this.addDepartmentAdmin}
            deptAdmin={this.props.deptAdmin}
            getDeptAdminState={this.props.getDeptAdminState}
            myDepartments={this.props.myDepartments}
            getDepatmentAdmins={this.getDepatmentAdmins}
            getDeptAdminDetails={this.getDeptAdminDetails}
            showEditForm={this.state.showEditForm}
            hideEditForm={this.hideEditForm}
            addAdminState={this.props.addAdminState}
            removeDeptAdmin={this.props.removeDeptAdmin}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.deptAdminReducer.isLoading,
  searchResults: state.deptAdminReducer.searchResults,
  departmentAdmins: state.deptAdminReducer.deptAdmins,
  deptAdmin: state.deptAdminReducer.deptAdmin,
  getDeptAdminState: state.deptAdminReducer.getDeptAdminState,
  myDepartments: state.departmentReducer.myDepartments,
  addAdminState: state.deptAdminReducer.saveState,
  removeDeptAdminState: state.deptAdminReducer.removeDeptAdminState,
});

const mapDispatchToProps = {
  deptAdminSearch,
  addDeptAdmin,
  getDeptAdmins,
  getMyDepartments,
  getDeptAdmin,
  removeDeptAdmin,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrgManageDeptAdminPage)
);
