import React from "react";
import Switch from "react-switch";

class Channel extends React.Component {
  handleClick = () => {
    if (!this.props.isOwn)
      this.props.unsubscribeChannel(this.props.item.channelId);
  };

  setTimeLineType = () => {
    if (this.props.isOwn) this.props.setTimeLineType("myChannels");
    else this.props.setTimeLineType(this.props.node.name);
  };

  render() {
    return (
      <div>
        <div className="d-flex" style={{ marginLeft: "8%", cursor: "pointer" }}>
          <div className="channel_name_container d-flex">
            <div
              onClick={() => {
                this.setTimeLineType();
                this.props.setTimeLineCurrentChannel([
                  this.props.item.id || this.props.item.channelId,
                ]);
                this.props.renderFeedForChannel([
                  this.props.item.id || this.props.item.channelId,
                ]);
              }}
              className="channel_name"
              title={this.props.item.name}
            >
              {" "}
              {this.props.item.name}
            </div>
            {!this.props.isOwn && !this.props.item.canNotUnsubscribe ? (
              <div
                onClick={this.handleClick}
                style={{
                  cursor: "pointer",
                  margin: "auto 7px auto auto",
                }}
                title="unsubscribe"
              >
                {" "}
                x{" "}
              </div>
            ) : null}
          </div>
          {this.props.isOwn ? (
            <div title="active/inactive" style={{ marginRight: "111px" }}>
              <Switch
                onChange={() =>
                  this.props.updateChnannelStatus({
                    channelId: this.props.item.id,
                    isActive: !this.props.item.isActive,
                    index: this.props.index,
                  })
                }
                checked={this.props.item.isActive}
                height={10}
                width={20}
                uncheckedIcon={false}
                checkedIcon={false}
                id="normal-switch"
                onColor="#9ce7fd"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Channel;
