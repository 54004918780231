import apiMethods from "../middleware/baseApiService";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";

export const loadSearchResults = (searchText) => (dispatch) => {
  dispatch({ type: SEARCH_REQUEST });
  apiMethods
    .post(`channel/Search1?SearchText=${searchText}`)
    .then((response) => {
      dispatch({ type: SEARCH_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: SEARCH_ERROR, error: err.message });
    });
};
