import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_PROVIDER_USER = " GET_PROVIDER_USER";
export const GET_DEP_USER = "GET_DEP_USER";
export const GET_SUBSCRIPTIONDETAILSORG_SUCCESS =
  "GET_SUBSCRIPTIONDETAILSORG_SUCCESS";
export const GET_SUBSCRIPTIONDETAILSPROVIDER_SUCCESS =
  "GET_SUBSCRIPTIONDETAILSPROVIDER_SUCCESS";
export const UPDATE_HAS_MORE_ORG = "UPDATE_HAS_MORE_ORG";
export const RESET_STATE_MANAGE_ACCOUNTS = "RESET_STATE_MANAGE_ACCOUNTS";
export const GET_ORGANISATION_LIST_SUCCESS = "GET_ORGANISATION_LIST_SUCCESS";
export const ACTIVATE_DEACTIVATE_ACCOUNT_SUCCESS =
  "ACTIVATE_DEACTIVATE_ACCOUNT_SUCCESS";

export const getList = (pageNo, searchText) => (dispatch) => {
  var url = "";
  if (searchText && searchText.trim() !== "") {
    url = `Subscription/Search?from=${pageNo}&size=10&SearchText=${searchText}`;
  } else {
    url = `Subscription/Search?from=${pageNo}&size=10`;
  }
  apiMethods.get(url).then(
    (response) => {
      dispatch({ type: GET_LIST_SUCCESS, payload: response.data });
      if (
        response.data.page === Math.ceil(response.data.total / 10) ||
        Math.ceil(response.data.total / 10) === 0
      )
        dispatch({ type: UPDATE_HAS_MORE_ORG, payload: false });
      else dispatch({ type: UPDATE_HAS_MORE_ORG, payload: true });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};
export const getProviderInfo = (id) => (dispatch) => {
  apiMethods.get(`Subscription/GetDetailsOfUser_Provider?id=${id}`).then(
    (response) => {
      dispatch({
        type: GET_PROVIDER_USER,
        payload: response.data.providerJsonNew,
      });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

export const getDepAdminInfo = (id) => (dispatch) => {
  apiMethods.get(`Subscription/GetDetailsOfUser_DepartmentAdmin?id=${id}`).then(
    (response) => {
      dispatch({
        type: GET_DEP_USER,
        payload: response.data,
      });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};
export const getOrganisationList = () => (dispatch) => {
  apiMethods.post("OrganisationProfile/GetAllOrganisation").then(
    (response) => {
      dispatch({ type: GET_ORGANISATION_LIST_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

export const activateDeactivateAccount = (qString) => (dispatch) => {
  apiMethods.get("Subscription/ActivateOrDeactive" + qString).then(
    (response) => {
      dispatch({ type: RESET_STATE_MANAGE_ACCOUNTS });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

export const deleteAccount = (qString) => (dispatch) => {
  apiMethods.get("Subscription/Delete" + qString).then(
    (response) => {
      dispatch({ type: ACTIVATE_DEACTIVATE_ACCOUNT_SUCCESS, payload: qString });
      getOrganisationList()(dispatch);
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

export const changePassword = (value, id) => (dispatch) => {
  apiMethods
    .post("ChangePassword/Change", {
      id: id,
      newPassword: value,
    })
    .then(
      (response) => {
        dispatch({ type: SHOW_ERROR, payload: "Password Change Succefly" });
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error,
        });
      }
    );
};
export const submitUserInformation = (info) => (dispatch) => {
  apiMethods
    .post("EditUser/EditAdmin", {
      id: info.id,
      email: info.email,
      phone: info.phone,
      firstName: info.firstName,
      lastName: info.lastName,
      // location: info.location,
    })
    .then(
      (response) => {
        dispatch({
          type: SHOW_ERROR,
          payload: "User Information Update Succefly",
        });
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error,
        });
      }
    );
};
//submitSuperAdminInformation

export const submitSuperAdminInformation = (info) => (dispatch) => {
  apiMethods
    .post("EditUser/EditDepartmentAdmin", {
      id: info.id,
      email: info.email,
      phone: info.phone,
      firstName: info.firstName,
      lastName: info.lastName,
      // location: info.location,
    })
    .then(
      (response) => {
        dispatch({
          type: SHOW_ERROR,
          payload: "User Information Update Succefly",
        });
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error,
        });
      }
    );
};
export const submitProviderInformation = (info) => (dispatch) => {
  apiMethods
    .post("EditUser/EditProvider", {
      Id: info.id,
      FirstName: info.firstName,
      LastName: info.lastName,
      Phone: info.phone,
      Email: info.email,
      Location: info.location[0].primaryLocationName,
      Degree: JSON.stringify(info.degree),
      NPINumber: info.nPInumber,
      SpecialityId: info.speciality.map((s) => s.id).toString(),
      Affiliations: JSON.stringify(info.affiliations),
      Boardcertifications: JSON.stringify(info.board),
      ConditionsTreated: JSON.stringify(info.conditions),
    })
    .then(
      (response) => {
        dispatch({
          type: SHOW_ERROR,
          payload: "User Information Update Succefly",
        });
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error,
        });
      }
    );
};
export const editOrglogo = (info) => (dispatch) => {
  // console.log("datachecking", info.logo);
  apiMethods
    .post(
      "ProfilePic/EditOrganisationLogo?OrganisationId=" + info.id,

      info.logo
    )
    .then(
      (response) => {
        // dispatch({
        //   type: SHOW_ERROR,
        //   payload: "Organisation Information Update Succefly",
        // });
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error,
        });
      }
    );
};
export const submitOrgInformation = (info) => (dispatch) => {
  apiMethods
    .post("OrganisationProfile/EditOrganisationInfo", {
      // id: info.id,
      // organisationName: info.orgName,
      // country: info.country,
      // countryId: info.countryId,
      // primaryAddress: info.primaryAddress,
      // city: info.city[0].name,
      // cityId: info.city[0].id,
      // state: info.state[0].name,
      // stateId: info.state[0].id,
      // zipCode: info.zipCode,
      // phone: info.phone,
      // fax: info.fax,
      // primaryLocationName: info.primaryLocationName,
      // type: JSON.stringify(info.type),

      organisationId: info.id.toString(),
      organisationName: info.orgName,
      country: info.country,
      countryId: info.countryId,
      primaryAddress: info.primaryaddress,
      city: info.city[0].name,
      cityId: info.city[0].id.toString(),
      state: info.state[0].name,
      stateId: info.state[0].id.toString(),
      zipCode: info.zipCode,
      phone: info.phone,
      fax: info.fax,
      primaryLocationName: info.primaryLocationName,
      type: JSON.stringify(info.type),
      specialityId: JSON.stringify(info.speciality),
      // profileLogo: info.logo,
    })
    .then(
      (response) => {
        dispatch({
          type: SHOW_ERROR,
          payload: "Organisation Information Update Succefly",
        });
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error,
        });
      }
    );
};
export const getSubscriptionDetails = (type, id) => (dispatch) => {
  apiMethods.get(`Subscription/GetDeatilsOfUser?id=${id}&Type=${type}`).then(
    (response) => {
      if (type === "Organisation")
        dispatch({
          type: GET_SUBSCRIPTIONDETAILSORG_SUCCESS,
          payload: response.data.organisationJson,
        });
      else
        dispatch({
          type: GET_SUBSCRIPTIONDETAILSPROVIDER_SUCCESS,
          payload: response.data.providerJson,
        });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};

export const resetState = () => (dispatch) => {
  dispatch({ type: RESET_STATE_MANAGE_ACCOUNTS });
};

export const uploadSpreadsheet = (data) => (dispatch) => {
  apiMethods.post("Subscription/UploadFiles", data).then(
    (response) => {
      dispatch({ type: RESET_STATE_MANAGE_ACCOUNTS });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message || error.data.error,
      });
    }
  );
};
