export const SET_FILTER_TYPE_SUCCESS = "SET_FILTER_TYPE_SUCCESS";
export const SET_CURRENT_FEED_CHANNEL = "SET_CURRENT_FEED_CHANNEL";

export const setTimeLineType = type => dispatch => {
  dispatch({ type: SET_FILTER_TYPE_SUCCESS, payload: type });
};

export const setTimeLineCurrentChannel = data => dispatch => {
  dispatch({ type: SET_CURRENT_FEED_CHANNEL, payload: data });
};
