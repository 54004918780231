import apiMethods from "../middleware/baseApiService";
import { ROLE_ORGANIZATION_PHYSICIAN } from "../store/constants";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const ORG_INVITE_PROVIDER_REQUEST = "ORG_INVITE_PROVIDER_REQUEST";
export const ORG_INVITE_PROVIDER_ERROR = "ORG_INVITE_PROVIDER_ERROR";
export const ORG_INVITE_PROVIDER_SUCCESS = "ORG_INVITE_PROVIDER_SUCCESS";

export const UPDATE_SAVE_STATE_TO_NULL_PROVIDER =
  "UPDATE_SAVE_STATE_TO_NULL_PROVIDER";

export const inviteProvider = (inviteProviderParams) => (dispatch) => {
  const inviteProviderRequest = {
    ...inviteProviderParams,
    degree: JSON.stringify(inviteProviderParams.degrees),
    boardCertifications: JSON.stringify(inviteProviderParams.certifications),
    affiliations: JSON.stringify(inviteProviderParams.affiliations),
    conditionTreated: JSON.stringify(inviteProviderParams.conditionsTreated),
    departmentIds: inviteProviderParams.department[0].id.toString(),
    contactPhone: inviteProviderParams.phone,
    specilaityIds: inviteProviderParams.specialities
      .map((s) => s.id)
      .toString(),
    locationId: inviteProviderParams.location[0].id
      .toString()
      .replace(/\D/g, ""),
    userRole: ROLE_ORGANIZATION_PHYSICIAN,
  };
  dispatch({ type: ORG_INVITE_PROVIDER_REQUEST });
  apiMethods
    .post("InviteUser", inviteProviderRequest)
    .then((response) => {
      dispatch({ type: ORG_INVITE_PROVIDER_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      let errorMessage = "Unknow error occurred while inviting provider.";
      if (error && error.data && error.data.message) {
        errorMessage = error.data.message;
      }

      dispatch({ type: SHOW_ERROR, payload: errorMessage });
      dispatch({ type: ORG_INVITE_PROVIDER_ERROR, error: errorMessage });
    });
};

export const updateSaveStateToNull = () => (dispatch) => {
  dispatch({ type: UPDATE_SAVE_STATE_TO_NULL_PROVIDER });
};
