import {
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
} from "../actions/department.action";

const initialState = {
  myDepartments: [],
  getState: null,
  error: null,
};

const departmentReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case GET_DEPARTMENTS_REQUEST: {
      newState = { ...state, getState: GET_DEPARTMENTS_REQUEST, error: null };
      break;
    }
    case GET_DEPARTMENTS_SUCCESS: {
      newState = {
        ...state,
        getState: GET_DEPARTMENTS_SUCCESS,
        myDepartments: action.payload,
        error: null,
      };
      break;
    }
    case GET_DEPARTMENTS_ERROR: {
      newState = {
        ...state,
        getState: GET_DEPARTMENTS_ERROR,
        error: action.message,
      };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }

  return newState;
};

export default departmentReducer;
