import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";

class ClientProfile extends React.Component {
  constructor(props) {
    super(props);

    this.imageRef = React.createRef();
  }

  onSubmit = values => {
    this.props.setClientProfile(values);
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    location: Yup.string().required("Location is required"),
    zipCode: Yup.string().required("ZipCode is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date().required("Date of birth is required"),
    phone: Yup.string().required("Phone number is required")
      // .test("validPhoneNumber", "phone number is invalid", value => {
      //   return isValidPhoneNumber(value);
      // }),
  });

  getMaxDate = () => {
    return `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`;
  };

  renderForm = props => {
    const { handleSubmit, values } = props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <label className="form-lable">First name</label>
          <Field
            type="text"
            name="firstName"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="firstName" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Last name</label>
          <Field
            type="text"
            name="lastName"
            className="form-control form-input"
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="lastName" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Primary Phone</label>
          <Field
            className="form-control form-input"
            name="phone"
            type="number"
            value={values.phone}
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="phone" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Date of birth</label>
          <Field
            type="date"
            name="dob"
            className="form-control form-input"
            max={this.getMaxDate()}
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="dob" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Gender</label>
          <Field
            type="text"
            name="gender"
            className="form-control form-input"
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="gender" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">ZipCode</label>
          <Field
            type="text"
            name="zipCode"
            className="form-control form-input"
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="zipCode" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Location</label>
          <Field
            type="text"
            name="location"
            className="form-control form-input"
          />
          <span className="error_text">
            {" "}
            <ErrorMessage name="location" />
          </span>
        </div>
        <div className="mt-5 text-right">
          {this.props.mode === "edit" ? (
            <button
              type="submit"
              className="btn form-btn"
              id="btnLogin"
              disabled={this.props.isSaveInProgress}
            >
              Save
              {this.props.isSaveInProgress === true ? (
                <span className="text-right pl-2">
                  <i
                    className="fa fa-circle-o-notch fa-spin"
                    style={{ color: "#fff" }}
                  />
                </span>
              ) : (
                ""
              )}
            </button>
          ) : (
            <button
              type="submit"
              className="btn form-btn"
              id="btnLogin"
              disabled={this.props.isSaveInProgress}
            >
              Next
              {this.props.isSaveInProgress === true ? (
                <span className="text-right pl-2">
                  <i
                    className="fa fa-circle-o-notch fa-spin  "
                    style={{ color: "#fff" }}
                  />
                </span>
              ) : (
                ""
              )}
            </button>
          )}
        </div>
      </form>
    );
  };

  render() {
    const initialValues = {
      firstName: "",
      lastName: "",
      dob: "",
      zipCode: "",
      location: "",
      phone: "",
      gender: ""
    };
    if (this.props.profile) {
      initialValues["firstName"] = this.props.profile.firstName || "";
      initialValues["lastName"] = this.props.profile.lastName || "";
      initialValues["zipCode"] = this.props.profile.zipCode || "";
      initialValues["location"] = this.props.profile.location || "";
      initialValues["phone"] = this.props.profile.phone || "";
      initialValues["gender"] = this.props.profile.gender || "";
      initialValues["dob"] =
        (this.props.profile.dob && this.props.profile.dob.split("T")[0]) || "";
    }

    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>
              {this.props.mode === "edit"
                ? "PERSONAL PROFILE"
                : "TELL US ABOUT YOURSELF..."}
            </h3>
            {this.props.mode === "edit" ? (
              ""
            ) : (
              <p>This is the stuff we HAVE to know.</p>
            )}
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText " />
                {this.props.profile && this.props.profile.profileImg == null ? (
                  // <div className="oval">
                  //     <img
                  //         src={profile_icon}
                  //         alt="profile"
                  //         className="dummyProfile_img" />
                  // </div>
                  <div className="user-oval" />
                ) : this.props.profile ? (
                  <div
                    style={{
                      backgroundImage:
                        "url(" + this.props.profile.profileImg + ")"
                    }}
                    className="org_logo"
                  />
                ) : null}

                {this.props.mode === "edit" ? (
                  <div className="form-group text-center mt-4">
                    <span
                      className="form-link-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.imageRef.current.click()}
                    >
                      Change pic
                    </span>
                    {/* <a className="form-link-btn">Change password</a> */}
                  </div>
                ) : (
                  <div className="form-group text-center mt-4">
                    <span
                      className="form-link-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.imageRef.current.click()}
                    >
                      Add a profile pic here
                    </span>
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={this.imageRef}
                  onChange={this.props.saveProfileImg}
                />
              </div>
              <div className="col-8">
                <div className="form-body">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                    validationSchema={this.validationSchema}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientProfile;
