import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";
import { ROLE_ORGANIZATION_ADMIN } from "../store/constants";

export const GET_ORGANIZATION_ADMINS_REQUEST =
  "GET_ORGANIZATION_ADMINS_REQUEST";
export const GET_ORGANIZATION_ADMINS_SUCCESS =
  "GET_ORGANIZATION_ADMINS_SUCCESS";
export const GET_ORGANIZATION_ADMINS_ERROR = "GET_ORGANIZATION_ADMINS_ERROR";
export const DELETE_ORGANIZATION_ADMIN_REQUEST =
  "DELETE_ORGANIZATION_ADMIN_REQUEST";
export const DELETE_ORGANIZATION_ADMIN_SUCCESS =
  "DELETE_ORGANIZATION_ADMIN_SUCCESS";
export const DELETE_ORGANIZATION_ADMIN_ERROR =
  "DELETE_ORGANIZATION_ADMIN_ERROR";

export const getOrganizationAdmins = () => (dispatch) => {
  dispatch({ type: GET_ORGANIZATION_ADMINS_REQUEST });
  apiMethods
    .post("OrganisationAdmin")
    .then((response) => {
      dispatch({ type: GET_ORGANIZATION_ADMINS_SUCCESS, data: response.data });
    })
    .catch((error) => {
      let errorMessage = "Unknow error occurred while inviting user.";
      if (error && error.data && error.data.message) {
        errorMessage = error.data.message;
      }

      dispatch({ type: SHOW_ERROR, payload: errorMessage });
      dispatch({ type: GET_ORGANIZATION_ADMINS_ERROR, error: errorMessage });
    });
};

export const removeOrganizationAdmin = (providerId) => (dispatch) => {
  dispatch({ type: DELETE_ORGANIZATION_ADMIN_REQUEST, data: providerId });
  apiMethods
    .deleteRequest(
      `OrganisationAdmin?ProviderId=${providerId}&roleName=${ROLE_ORGANIZATION_ADMIN}`
    )
    .then((response) => {
      dispatch({
        type: DELETE_ORGANIZATION_ADMIN_SUCCESS,
        data: response.data,
      });
    })
    .catch((error) => {
      let errorMessage = "Unknow error occurred while inviting user.";
      if (error && error.data && error.data.message) {
        errorMessage = error.data.message;
      }

      dispatch({ type: SHOW_ERROR, payload: errorMessage });
      dispatch({ type: DELETE_ORGANIZATION_ADMIN_ERROR, error: errorMessage });
    });
};
