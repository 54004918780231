import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";
import { logOut } from "./login.actions";
import { useHistory } from "react-router-dom";
export const GET_INVITATION_DETAILS_REQUEST = "GET_INVITATION_DETAILS_REQUEST";
export const GET_INVITATION_DETAILS_SUCCESS = "GET_INVITATION_DETAILS_SUCCESS";
export const GET_INVITATION_DETAILS_ERROR = "GET_INVITATION_DETAILS_ERROR";

export const ACCEPT_INVITATION_REQUEST = "GET_ACCEPT_INVITATION_REQUEST";
export const ACCEPT_INVITATION_SUCCESS = "GET_ACCEPT_INVITATION_SUCCESS";
export const ACCEPT_INVITATION_ERROR = "GET_ACCEPT_INVITATION_ERROR";

export const getInvitationDetails = (invitationCode) => (dispatch) => {
  dispatch({ type: GET_INVITATION_DETAILS_REQUEST, payload: invitationCode });
  apiMethods
    .get(`AccecptInvite?inviteUserCode=${invitationCode}`)
    .then((response) => {
      dispatch({
        type: GET_INVITATION_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error });
      dispatch({ type: GET_INVITATION_DETAILS_ERROR, payload: error });
    });
};

export const acceptInvitation = (acceptInviteParams) => (dispatch) => {
  const request = {
    inviteUserId: acceptInviteParams.inviteUserId,
    desiredPassword: acceptInviteParams.password,
  };

  dispatch({ type: ACCEPT_INVITATION_REQUEST });

  apiMethods
    .post("AccecptInvite", request)
    .then((response) => {
      // dispatch(logOut());
      dispatch({ type: ACCEPT_INVITATION_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
      dispatch({ type: ACCEPT_INVITATION_ERROR, payload: error });
    });
};
