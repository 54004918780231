import {
  GET_CHANNELS_PROGRESS,
  GET_CHANNELS_SUCCESS,
  GET_CHANNELS_ERROR,
  GET_SUBSCRIBED_CHANNELS_SUCCESS,
  GET_SUBSCRIBED_CHANNELS_PROGRESS,
  GET_SUBSCRIBED_CHANNELS_ERROR,
  UPDATE_CHANNEL_STATUS_SUCCESS,
  CREATE_NEW_CHANNEL_SUCCESS,
  UNSUBSCRIBE_CHANNEL_SUCCESS,
  Current_NEW_CHANNEL_SUCCESS,
  GET_NEW_CHANNEL_DATA,
  UPDATE_CHANNELS,
} from "../actions/channel.action";

const initialState = {
  channels: [],
  subscribedChannels: [],
  error: null,
  newChannelId: [],
};

const channelReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case GET_CHANNELS_PROGRESS: {
      newState = { ...state, channels: [], error: null };
      break;
    }
    case GET_CHANNELS_SUCCESS: {
      newState = {
        ...state,
        channels: action.payload,
        newChannelId: action.payload.id,
        error: null,
      };
      break;
    }
    case GET_CHANNELS_ERROR: {
      newState = { ...state, channels: [], error: action.payload };
      break;
    }
    case GET_SUBSCRIBED_CHANNELS_PROGRESS: {
      newState = { ...state, subscribedChannels: [], error: null };
      break;
    }
    case GET_SUBSCRIBED_CHANNELS_SUCCESS: {
      newState = {
        ...state,
        subscribedChannels: action.payload.nodes,
        error: null,
      };
      break;
    }

    case GET_SUBSCRIBED_CHANNELS_ERROR: {
      newState = {
        ...state,
        subscribedChannels: [],
        error: action.payload,
      };
      break;
    }
    case UPDATE_CHANNEL_STATUS_SUCCESS:
      newState = {
        ...state,

        channels: state.channels.map((item, index) => {
          if (index === action.payload.index)
            return Object.assign({}, state.channels[index], {
              isActive: action.payload.isActive,
            });
          else return item;
        }),
      };
      break;

    case GET_NEW_CHANNEL_DATA: {
      newState = {
        ...state,
        newChannelId: action.payload.id,
      };
      break;
    }
    case CREATE_NEW_CHANNEL_SUCCESS:
      newState = {
        ...state,
        channels: [...state.channels, action.payload].sort((c1, c2) => {
          if (c1.name.toLowerCase() < c2.name.toLowerCase()) return -1;
          if (c1.name.toLowerCase() > c2.name.toLowerCase()) return 1;
          return 0;
        }),
      };
      break;
    case UPDATE_CHANNELS:
      return {
        ...state,

        channels: action.payload,
        state: UPDATE_CHANNELS,
      };
    case UNSUBSCRIBE_CHANNEL_SUCCESS:
      newState = {
        ...state,
        subscribedChannels: state.subscribedChannels.map((subscription) => {
          if (subscription.childs.length > 0) {
            return Object.assign({}, subscription, {
              childs: subscription.childs.filter((element) => {
                return element.channelId !== action.payload;
              }),
            });
          } else return subscription;
        }),
      };
      break;
  }

  return newState;
};

export default channelReducer;
