import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import PrivacyPolicy from "../components/privacyPolicy/privacyPolicy";
import {
  saveAccept,
  SAVE_PP_ACCEPT_IN_PROGRESS,
  SAVE_PP_ACCEPT_SUCCESS
} from "../actions/privacyPolicy.action";
import { ROUTE_PRIVACY_POLICY } from "./RouteConstants";

class PrivacyPolicyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccepted: false
    };

    this.save = this.save.bind(this);
  }

  save() {
    this.props.saveAccept();
  }

  render() {
    if (this.props.saveState === SAVE_PP_ACCEPT_SUCCESS) {
      return <Redirect to={"/next-step" + ROUTE_PRIVACY_POLICY} />;
    }

    const isSaveInProgress =
      this.props.saveState === SAVE_PP_ACCEPT_IN_PROGRESS;

    return (
      <div className="container-bg">
        <PrivacyPolicy
          saveAccept={this.save}
          isSaveInProgress={isSaveInProgress}
        />
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    saveState: state.privacyPolicy.saveState
  };
};

// mapDispatchToProps can just be a plain object. It doesn’t have to be a function.
// rather call it “actionCreators”
const mapDispatchToProps = {
  saveAccept
  // if actions do need arguments, then just wrap those specific action creators in a function eg.
  // saveAccept: () => saveAccept(false),
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrivacyPolicyPage)
);
