import React from "react";
import "./orgManageDepartments.css";
import department_logo from "../../img/department.svg";
import DepartmentPill from "./departmentPill";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

class OrgManageDepartments extends React.Component {
  constructor(props) {
    super(props);
    this.createDepartmentRef = React.createRef();
  }

  render() {
    return (
      <div>
        <div className="manage-form">
          <div className="row">
            <div className="col-12 text-center">
              <div className="dept_image_div">
                <img src={department_logo} alt="dept" className="dept_img" />
              </div>
            </div>
          </div>
          <div
            className="row mtb-60 "
            style={{ display: "flex", marginTop: "30px" }}
          >
            <div
              className="col-4"
              style={{ margin: "auto", textAlign: "center" }}
            >
              <div
                className="manage-form-label"
                style={{ cursor: "pointer" }}
                onClick={this.props.createNewDepartment}
              >
                + Create new
              </div>
            </div>
          </div>

          <div className="row mtb-60">
            <div className="col-8" style={{ margin: "auto" }}>
              <p style={{ fontFamily: "proximaNova", fontSize: "14px" }}>
                Either choose from the suggested list or type a new name and
                click create new button to create a new department.
              </p>
              <Typeahead
                placeholder=""
                labelKey="name"
                options={this.props.departmentList}
                onChange={this.props.updateDeptText}
                onInputChange={this.props.updateDeptText}
                ref={ref => (this._typeahead = ref)}
              />
            </div>
          </div>

          <div>
            <div
              className="row mb-16  no-gutter"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <div className="col-8" style={{ margin: "auto" }}>
                {this.props.departments.length > 0
                  ? this.props.departments.map((dept, i) => {
                      return (
                        <DepartmentPill
                          department={dept}
                          index={i}
                          key={`OrgDept_${i}`}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrgManageDepartments;
