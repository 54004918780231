import React, { Component } from "react";
//import { formatPhoneNumber } from "react-phone-number-input";

export default class deptAdminCard extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.removeDeptAdmin = this.removeDeptAdmin.bind(this);
  }

  handleClick() {
    this.props.showEditDeptAdmin(this.props.deptAdmin.deptAdminId);
  }

  removeDeptAdmin() {
    const displayName = `${
      this.props.deptAdmin.title ? this.props.deptAdmin.title : ""
    } ${this.props.deptAdmin.firstName ? this.props.deptAdmin.firstName : ""} ${
      this.props.deptAdmin.lastName ? this.props.deptAdmin.lastName : ""
    }`;

    if (
      window.confirm(
        `Are you sure you want to remove the '${displayName}' from department admin ?`
      )
    ) {
      this.props.removeDeptAdmin(this.props.deptAdmin.providerId);
    }
  }

  render() {
    return (
      <div className="col-4 mt-3" onClick={this.handleClick}>
        <div className="rectangle-info-deptAdmin">
          <div className="row h-100">
            <div className="col-5 my-auto">
              {this.props.deptAdmin.profileImg ? (
                <div
                  className="admin-img-container"
                  style={{
                    backgroundImage: `url(${this.props.deptAdmin.profileImg})`
                  }}
                />
              ) : (
                <div className="user-pic" />
              )}
            </div>
            <div className="col-7">
              {/* {
                                this.props.isDeleteInProgress === true ?
                                    (<span className="remove-deptadmin-spinner"><i className="fa fa-circle-o-notch fa-spin" style={{ color: "blue" }} /></span>) : ("")
                            } */}
              <i
                className="far fa-times-circle remove-deptadmin"
                onClick={() => this.removeDeptAdmin()}
              />

              <div className="user-name text-left">
                {this.props.deptAdmin.firstName}&nbsp;
                {this.props.deptAdmin.lastName}
              </div>
              <div className="loc-info-txt" title={this.props.deptAdmin.email}>
                {this.props.deptAdmin.email}
              </div>
              <div className="loc-info-txt">
                {this.props.deptAdmin.phone || ""}
              </div>
              <div className="loc-info-txt">
                {this.props.deptAdmin.location}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
