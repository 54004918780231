import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PersonalProfile from "../components/personalInfo/personalInfo";
import {
  getDegrees,
  savePersonalProfile,
  setProfilePic,
  getSpecialities,
  getPersonalProfile,
  SAVE_PERSONAL_INFO_IN_PROGRESS,
  SAVE_PERSONAL_INFO_SUCCESS,
  updateProfileImg,
} from "../actions/personalProfile.actions";
import { updateOnBoardingStatus } from "../actions/onboardingStatus.actions";
import { ROUTE_PERSONAL_PROFILE } from "./RouteConstants";

class PersonalProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "new",
    };
  }

  componentWillMount() {
    this.props.getDegrees();
    this.props.getSpecialities();
    this.props.getPersonalProfile(this.props.email);
  }

  saveProfile = (data) => {
    this.props.savePersonalProfile(data);
  };

  saveProfieImg = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let formData = new FormData();
      let data = {};
      formData.append("file", event.target.files[0]);
      data["formData"] = formData;
      data["file"] = file;
      this.props.setProfilePic(data);
    }
  };

  render() {
    const isPersonalInfoSaveInProgress =
      this.props.saveState === SAVE_PERSONAL_INFO_IN_PROGRESS;

    if (this.props.saveState === SAVE_PERSONAL_INFO_SUCCESS) {
      return <Redirect to={"/next-step" + ROUTE_PERSONAL_PROFILE} />;
    }

    return (
      <div className="container-bg">
        <PersonalProfile
          mode={this.state.mode}
          specialities={this.props.specialities}
          saveProfile={this.saveProfile}
          saveProfileImg={this.saveProfieImg}
          profileImg={this.props.profileImg}
          degrees={this.props.degrees}
          personalProfile={this.props.personalProfile}
          isSaveInProgress={isPersonalInfoSaveInProgress}
          updateProfileImg={this.props.updateProfileImg}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialities: state.personalProfile.specialities,
  degrees: state.personalProfile.degrees,
  saveState: state.personalProfile.saveState,
  profileImg: state.personalProfile.profileImg,
  personalProfile: state.personalProfile.profile,
  email: state.login.email,
});

const mapDispatchToProps = (dispatch) => ({
  getDegrees: () => dispatch(getDegrees()),
  getSpecialities: () => dispatch(getSpecialities()),
  savePersonalProfile: (data) => dispatch(savePersonalProfile(data)),
  setProfilePic: (data) => dispatch(setProfilePic(data)),
  updateOnBoardingStatus: (data) => dispatch(updateOnBoardingStatus(data)),
  getPersonalProfile: (data) => dispatch(getPersonalProfile()),
  updateProfileImg,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersonalProfilePage)
);
