import React from "react";
import Channel from "./channelRenderer";

export default class SubscribedChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChannels: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (this.props.node.childs.length === 0) {
      this.props.setTimeLineCurrentChannel([this.props.node.channelId]);
      this.props.renderFeedForChannel([this.props.node.channelId]);
    } else {
      this.props.setTimeLineCurrentChannel(
        this.props.node.childs.map((c) => c.channelId)
      );
      this.props.renderFeedForChannel(
        this.props.node.childs.map((c) => c.channelId)
      );
      this.setState({
        showChannels: true,
      });
    }
    this.props.setTimeLineType(this.props.node.name);
  }

  render() {
    return (
      <div
        style={{
          marginLeft:
            this.props.node.organisationId && !this.props.node.departmentId
              ? 0
              : "15px",
        }}
      >
        <div className="d-flex" title={this.props.node.name}>
          <div
            className="timelineFIlterOptions"
            style={{ margin: "auto auto auto 20%" }}
            onClick={() => this.handleClick()}
          >
            {this.props.timelineFilterType === this.props.node.name ? (
              <span
                className="filter-active"
                style={{
                  fontSize:
                    this.props.node.organisationId &&
                    !this.props.node.departmentId
                      ? "20px"
                      : "15px",
                }}
              >
                {" "}
                {this.props.node.name}
              </span>
            ) : (
              <span
                style={{
                  fontSize:
                    this.props.node.organisationId &&
                    !this.props.node.departmentId
                      ? "20px"
                      : "15px",
                }}
              >
                {" "}
                {this.props.node.name}
              </span>
            )}
          </div>

          <div style={{ cursor: "pointer", marginRight: "71px" }}>
            {this.state.showChannels && this.props.node.childs.length > 0 ? (
              <i
                className="fa fa-angle-up"
                aria-hidden="true"
                onClick={() =>
                  this.setState({
                    showChannels: !this.state.showChannels,
                  })
                }
              />
            ) : this.props.node.childs.length > 0 ? (
              <i
                className="fa fa-angle-down"
                aria-hidden="true"
                onClick={() => {
                  this.props.setTimeLineCurrentChannel(
                    this.props.node.childs.map((c) => c.channelId)
                  );
                  this.props.setTimeLineType(this.props.node.name);
                  this.props.renderFeedForChannel(
                    this.props.node.childs.map((c) => c.channelId)
                  );
                  this.setState({ showChannels: !this.state.showChannels });
                }}
              />
            ) : null}
          </div>
        </div>
        {this.state.showChannels && this.props.node.childs ? (
          <div>
            {this.props.node.childs.map((child, i) => {
              return (
                <div
                  style={{ marginLeft: "20%" }}
                  key={"subscribedChannel" + this.props.providerName + i}
                >
                  <Channel
                    item={child}
                    isOwn={false}
                    renderFeedForChannel={this.props.renderFeedForChannel}
                    setTimeLineCurrentChannel={
                      this.props.setTimeLineCurrentChannel
                    }
                    unsubscribeChannel={this.props.unsubscribeChannel}
                    setTimeLineType={this.props.setTimeLineType}
                    node={this.props.node}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
