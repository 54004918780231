import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";
export const REGISTERINFO_SUCCESS = "REGISTERINFO_SUCCESS";
export const REGISTERINFO_ERROR = "REGISTERINFO_ERROR";
export const REGISTERINFO_REQUEST = "REGISTERINFO_REQUEST";
export const REGISTERINFO_VERIFY = "REGISTERINFO_VERIFY";

export class RegisterInfoRequestParams {
  constructor(username, password, registrationType) {
    this.username = username;
    this.password = password;
    this.registrationType = registrationType;
  }

  getUsername() {
    return this.username;
  }

  getPassword() {
    return this.password;
  }

  getRegistrationType() {
    return this.registrationType;
  }
}

export const requestRegisterInfo =
  (registerInfoRequestParams) => (dispatch, getState) => {
    dispatch({ type: REGISTERINFO_REQUEST });

    apiMethods
      .post("SignUp/Register", {
        email: registerInfoRequestParams.username,
        password: registerInfoRequestParams.password,
        registrationType: registerInfoRequestParams.registrationType,
      })
      .then((response) => {
        dispatch({ type: REGISTERINFO_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: SHOW_ERROR, payload: error.data.message });
        dispatch({ type: REGISTERINFO_ERROR, error: error.message });
      });
  };
