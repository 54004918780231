import React from "react";
import "./shareNewPost.css";
import apiMethods from "../../middleware/baseApiService";
import { showError } from "../../actions/errorDisplay.actions";

import attach from "../../img/attach_blue.svg";
import image from "../../img/image_blue.svg";
import video from "../../img/video_blue.svg";
import link from "../../img/link_blue.svg";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import ImageAuthoring from "../imageAuthoring";
import VideoAuthoring from "../videoAuthoring";
import DocumentAuthoring from "../documentAuthoring";
import LinkAuthoring from "../linkAuthoring";
// import { createNewChannel } from "../../actions/channel.action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { number, string } from "prop-types";
const fullName = localStorage.getItem("fullName");
class ShareNewPostComponent extends React.Component {
  constructor(props) {
    super(props);
    this.newPostTextAreaRef = React.createRef();
    this.imageAuthorRef = React.createRef();
    this.videoAuthorRef = React.createRef();
    this.docAuthorRef = React.createRef();
    this.newChannel = this.newChannel.bind(this);

    this.state = {
      align: "justify",
      isPremiumCheckded: false,
      isFreeChecked: true,
      isPostPremium: 0,
    };
  }

  handleSubmitIsChecked = (checkboxNumber) => {
    console.log("isChecked", this.state);
    this.setState({ isPostPremium: checkboxNumber });
    if (checkboxNumber === 0) {
      this.setState({ isPremiumCheckded: false });
      this.setState({ isFreeChecked: !this.state.isFreeChecked });
    } else if (checkboxNumber === 1) {
      this.setState({ isFreeChecked: false });
      this.setState({ isPremiumCheckded: !this.state.isPremiumCheckded });
    }
  };

  uploadForPost = (value) => {
    switch (value) {
      case "Document":
        this.docAuthorRef.current.uploadNewPostDocumentRef.current.click();
        break;
      case "Video":
        this.videoAuthorRef.current.uploadNewPostVideoRef.current.click();
        break;
      case "Image":
        this.imageAuthorRef.current.uploadNewPostImageRef.current.click();
        break;
      default:
        break;
    }
  };
  showError = (text) => {
    this.props.showError(text);
  };
  newChannel = async (data) => {
    const promise = new Promise((resolve, reject) => {
      apiMethods
        .post("Channel", { name: data.name })
        .then((response) => {
          resolve(response.data.id);
        })
        .catch((error) => {
          this.props.showError(error.data.message);
        });
    });

    let result = await promise;

    return result;
  };
  ///
  shareNewPost = () => {
    if (this.props.isHealthswimAdmin !== true) {
      if (this.props.selectedchannels.length > 0) {
        var newPostData = {};

        if (this.props.postType === "Image") {
          if (
            this.imageAuthorRef.current.uploadNewPostImageRef.current.files[0]
          ) {
            let formData = new FormData();
            formData.append(
              "file",
              this.imageAuthorRef.current.uploadNewPostImageRef.current.files[0]
            );

            newPostData["subject"] = "subject";
            newPostData["title"] = this.props.imageTitle;
            newPostData["shareView"] = this.props.newPostTextBox;
            newPostData["isFeatured"] = this.props.isFeatured === "true";
            newPostData["authorShareText"] = fullName;
            newPostData["postType"] = this.props.postType;
            newPostData["file"] = formData;
            newPostData["isPremium"] = this.state.isPostPremium;
            const value = async () => {
              let newData = await this.props.selectedchannels.map((el) => {
                newPostData["ChannelName"] = el.name;
                if (typeof el.id !== "number") {
                  const result = this.newChannel(el);
                  newPostData["ChannelName"] = el.name;
                  result.then((a) => {
                    newPostData["channelIds"] = [a];
                    this.props.createPost(newPostData);
                  });
                } else if (typeof el.id === "number") {
                  newPostData["channelIds"] = [el.id];
                }
              });
              return newData;
            };
            let ans = value();
            ans.then((a) => {
              this.props.createPost(newPostData);

              return a;
            });

            this._typeahead.getInstance().clear();
          } else {
            alert("Please upload image for create post");
            this._typeahead.getInstance().clear();
            return false;
          }
        }

        if (this.props.postType === "Document") {
          if (
            this.docAuthorRef.current.uploadNewPostDocumentRef.current.files[0]
          ) {
            let formData = new FormData();
            formData.append(
              "file",
              this.docAuthorRef.current.uploadNewPostDocumentRef.current
                .files[0]
            );

            newPostData["subject"] = "subject";
            newPostData["title"] = this.props.documentTitle;
            newPostData["shareView"] = this.props.newPostTextBox;
            newPostData["isFeatured"] = this.props.isFeatured === "true";
            newPostData["postType"] = this.props.postType;
            newPostData["authorShareText"] = fullName;
            newPostData["file"] = formData;
            newPostData["isPremium"] = this.state.isPostPremium;
            const value = async () => {
              let newData = await this.props.selectedchannels.map((el) => {
                newPostData["ChannelName"] = el.name;
                if (typeof el.id !== "number") {
                  const result = this.newChannel(el);
                  newPostData["ChannelName"] = el.name;
                  result.then((a) => {
                    newPostData["channelIds"] = [a];
                    this.props.createPost(newPostData);
                  });
                } else if (typeof el.id === "number") {
                  newPostData["channelIds"] = [el.id];
                }
              });
              return newData;
            };
            let ans = value();
            ans.then((a) => {
              this.props.createPost(newPostData);

              return a;
            });
            this._typeahead.getInstance().clear();
          } else {
            alert("Please upload document for create post");
            this._typeahead.getInstance().clear();
            return false;
          }
        }

        if (this.props.postType === "Video") {
          if (
            this.videoAuthorRef.current.uploadNewPostVideoRef.current.files[0]
          ) {
            let formData = new FormData();
            formData.append(
              "file",
              this.videoAuthorRef.current.uploadNewPostVideoRef.current.files[0]
            );

            newPostData["subject"] = "subject";
            newPostData["title"] = this.props.videoTitle;
            newPostData["shareView"] = this.props.newPostTextBox;
            newPostData["isFeatured"] = this.props.isFeatured === "true";
            newPostData["postType"] = this.props.postType;
            newPostData["authorShareText"] = fullName;
            newPostData["file"] = formData;
            newPostData["isPremium"] = this.state.isPostPremium;
            const value = async () => {
              let newData = await this.props.selectedchannels.map((el) => {
                newPostData["ChannelName"] = el.name;
                if (typeof el.id !== "number") {
                  const result = this.newChannel(el);
                  newPostData["ChannelName"] = el.name;
                  result.then((a) => {
                    newPostData["channelIds"] = [a];
                    this.props.createPost(newPostData);
                  });
                } else if (typeof el.id === "number") {
                  newPostData["channelIds"] = [el.id];
                }
              });
              return newData;
            };
            let ans = value();
            ans.then((a) => {
              this.props.createPost(newPostData);

              return a;
            });
            this._typeahead.getInstance().clear();
          } else {
            alert("Please upload video for create post");
            this._typeahead.getInstance().clear();
            return false;
          }
        }

        if (this.props.postType === "Link") {
          if (this.props.linkUrl) {
            newPostData["postType"] = this.props.postType;
            newPostData["link"] = this.props.linkUrl;
            newPostData["shareView"] = this.props.newPostTextBox;
            newPostData["isFeatured"] = this.props.isFeatured === "true";
            newPostData["authorShareText"] = fullName;
            newPostData["isPremium"] = this.state.isPostPremium;
            console.log(
              this.props.selectedchannels,
              "this.props.selectedchannels"
            );
            const value = async () => {
              newPostData["channelIds"] = await this.props.selectedchannels.map(
                (el) => {
                  newPostData["ChannelName"] = el.name;
                  if (typeof el.id !== "number") {
                    const run = async (el) => {
                      const result = await this.newChannel(el);
                      newPostData["ChannelName"] = el.name;
                      newPostData["channelIds"] = [
                        ...newPostData.channelIds,
                        result,
                      ].filter(function (el) {
                        return el != null;
                      });
                    };
                    run(el);
                  } else if (typeof el.id === "number") {
                    return el.id;
                  }
                }
              );
              console.log(newPostData, "newPostDatanewPostData");
              this.props.createPost(newPostData);
            };
            value();

            this._typeahead.getInstance().clear();
          } else {
            alert("Please enter link Url for create post");
            return false;
            this._typeahead.getInstance().clear();
          }
        }

        // this.props.createPost(newPostData);
      } else {
        alert("Please select the channel");
        this._typeahead.getInstance().clear();
      }
    } else {
      this.props.showError("SuperAdmin can not post");
    }
  };

  render() {
    console.log("fullName", this.state);
    return (
      <div
        className="container-fluid new-post-container"
        // style={{ marginTop: "-93px" }}
      >
        <div style={{ marginBottom: "33px" }}>
          <div className="row">
            <div className=" col-3 my-auto">
              <div className="shareNewPostText">
                <div style={{ marginLeft: "26%", textAlign: "left" }}>
                  Share
                </div>
              </div>
            </div>
            <div className="col-5 pl-0">
              <form>
                <div>
                  <textarea
                    name="newPostTextBox"
                    placeholder="Share something with the world today..."
                    className={"newPostTextBox show"}
                    rows="2"
                    cols="40"
                    ref={this.newPostTextAreaRef}
                    value={this.props.newPostTextBox}
                    onChange={this.props.inputChange}
                  />

                  {this.props.postType === "Link" ? (
                    <LinkAuthoring
                      inputChange={this.props.inputChange}
                      getLinkPreview={this.props.getLinkPreview}
                      showPreview={this.props.showPreview}
                      previewData={this.props.previewData}
                      closePreview={this.props.closePreview}
                      linkUrl={this.props.linkUrl}
                    />
                  ) : null}

                  {this.props.postType === "Image" ? (
                    <ImageAuthoring
                      ref={this.imageAuthorRef}
                      inputChange={this.props.inputChange}
                      upload={this.uploadForPost}
                    />
                  ) : null}

                  {this.props.postType === "Document" ? (
                    <DocumentAuthoring
                      ref={this.docAuthorRef}
                      inputChange={this.props.inputChange}
                      upload={this.uploadForPost}
                      showError={this.showError}
                    />
                  ) : null}

                  {this.props.postType === "Video" ? (
                    <VideoAuthoring
                      ref={this.videoAuthorRef}
                      inputChange={this.props.inputChange}
                      upload={this.uploadForPost}
                    />
                  ) : null}
                </div>
              </form>
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "start",
                  marginLeft: "18px",
                }}
              >
                <div style={{ padding: "10px" }}>
                  <input
                    type="checkbox"
                    // id="inProgress"
                    onChange={() => this.handleSubmitIsChecked(0)}
                    checked={this.state.isFreeChecked}
                    data-mssg="In Progress"
                  />
                  <label for="inProgress" />
                  <span
                    style={{
                      color: "#4A4A4A",
                      fontSize: "14px",
                      fontFamily: "proximanova",
                      marginLeft: "10px",
                    }}
                  >
                    Free
                  </span>
                </div>
                <div style={{ padding: "10px" }}>
                  <input
                    type="checkbox"
                    // id="inProgress"
                    onChange={() => this.handleSubmitIsChecked(1)}
                    checked={this.state.isPremiumCheckded}
                    data-mssg="In Progress"
                  />
                  <label for="inProgress" />
                  <span
                    style={{
                      color: "#4A4A4A",
                      fontSize: "14px",
                      fontFamily: "proximanova",
                      marginLeft: "10px",
                    }}
                  >
                    Premium
                  </span>
                </div>
              </div>
            </div>

            <div className="col-4 pl-0">
              <div className="add-attachment-rectangle">
                <div className="mb-4 addOptionsText">Add...</div>
                <div className="row" style={{ marginTop: "-23px" }}>
                  <div className="col-3  my-auto">
                    <center>
                      <div
                        className={
                          this.props.postType === "Document"
                            ? "newPostOptionsDiv auto_margin selectedBorder"
                            : "newPostOptionsDiv auto_margin"
                        }
                        onClick={() => this.props.updatePostType("Document")}
                      >
                        <img
                          src={attach}
                          alt="attach"
                          title="Document"
                          className="newPostOptionsIcon"
                        />
                      </div>
                      <input
                        type="file"
                        ref={this.uploadNewPostDocumentRef}
                        className="upload_new_post_image"
                      />
                    </center>
                  </div>
                  <div className="col-3">
                    <center>
                      <div
                        className={
                          this.props.postType === "Image"
                            ? "newPostOptionsDiv auto_margin selectedBorder"
                            : "newPostOptionsDiv auto_margin"
                        }
                        onClick={() => this.props.updatePostType("Image")}
                      >
                        {" "}
                        <img
                          src={image}
                          alt="uploadImg"
                          title="Image"
                          className="newPostOptionsIcon_Image"
                        />
                      </div>
                      <input
                        type="file"
                        ref={this.uploadNewPostImageRef}
                        className="upload_new_post_image"
                        accept="image/*"
                      />
                    </center>
                  </div>
                  <div className="col-3">
                    <center>
                      <div
                        className={
                          this.props.postType === "Video"
                            ? "newPostOptionsDiv auto_margin selectedBorder"
                            : "newPostOptionsDiv auto_margin"
                        }
                        onClick={() => this.props.updatePostType("Video")}
                      >
                        <img
                          src={video}
                          alt="video"
                          title="Video"
                          className="newPostOptionsIcon_Video"
                        />
                      </div>
                      <input
                        type="file"
                        ref={this.uploadNewPostVideoRef}
                        className="upload_new_post_image"
                        accept="video/*"
                      />
                    </center>
                  </div>
                  <div className="col-3">
                    <center>
                      <div onClick={() => this.props.updatePostType("Link")}>
                        <div
                          className={
                            this.props.postType === "Link"
                              ? "newPostOptionsDiv auto_margin selectedBorder"
                              : "newPostOptionsDiv auto_margin"
                          }
                        >
                          <img
                            src={link}
                            alt="link"
                            title="Link"
                            className="newPostOptionsIcon_Link"
                          />
                        </div>
                      </div>
                    </center>
                  </div>
                </div>

                <div className="postcreate_typeahead ">
                  <Typeahead
                    // options={
                    //   this.props.myChannels &&
                    //   this.props.myChannels.filter((item) => {
                    //     return item.isActive;
                    //   })
                    // }
                    options={this.props.myChannels}
                    align={this.state.align}
                    labelKey="name"
                    multiple
                    allowNew
                    placeholder="Select channel..."
                    // className="createPost_typeahead"
                    id="basic-example"
                    ref={(ref) => (this._typeahead = ref)}
                    onChange={this.props.handleSelectedOption}
                    onFocus={(event) => (event.target.placeholder = "")}
                    onBlur={(event) => {
                      if (this.props.selectedchannels.length === 0)
                        event.target.placeholder = "Select channel...";
                    }}
                  />
                </div>

                <div className="row mt-3">
                  <div className="col-6 ">
                    {/* <div className="pull-left">
                      <input
                        id="featured_box"
                        name="isFeatured"
                        type="checkbox"
                        onChange={this.props.inputChange}
                        checked={this.props.isFeatured === "true"}
                        value={this.props.isFeatured === "true" ? false : true}
                      />
                      <label
                        htmlFor="featured_box"
                        style={{ color: "white", marginLeft: "0.5rem" }}
                      >
                        make it featured
                      </label>
                    </div> */}
                  </div>

                  <div className="col-6 ">
                    <button
                      type="submit"
                      className="btn form-btn pull-right"
                      id="btnLogin"
                      onClick={this.shareNewPost}
                      disabled={this.props.disableCreateButton}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <span style={{ fontSize: "14px" }}>
                        CREATE
                        {this.props.disableCreateButton ? (
                          <span className="text-right pl-2">
                            <i
                              className="fa fa-circle-o-notch fa-spin  "
                              // style={{ color: "#fff" }}
                              style={{ color: "#007bff" }}
                            />
                          </span>
                        ) : null}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  myChannels: state.channelReducer.channels.sort((c1, c2) => {
    if (c1.name.toLowerCase() < c2.name.toLowerCase()) return -1;
    if (c1.name.toLowerCase() > c2.name.toLowerCase()) return 1;
    return 0;
  }),
  isHealthswimAdmin: state.personalProfile.isHealthswimAdmin,
});
const mapDispatchToProps = {
  showError,
};
const ShareNewPost = ShareNewPostComponent;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShareNewPost)
);
