import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_ERROR = "GET_CHANNELS_ERROR";
export const GET_CHANNELS_PROGRESS = "GET_CHANNELS_PROGRESS";
export const UPDATE_CHANNELS = "UPDATE_CHANNELS";
export const UPDATE_CHANNEL_STATUS_SUCCESS = "UPDATE_CHANNEL_STATUS_SUCCESS";

export const CREATE_NEW_CHANNEL_SUCCESS = "CREATE_NEW_CHANNEL_SUCCESS";
export const GET_NEW_CHANNEL_DATA = "GET_NEW_CHANNEL_DATA";
export const UNSUBSCRIBE_CHANNEL_SUCCESS = "UNSUBSCRIBE_CHANNEL_SUCCES";
export const Current_NEW_CHANNEL_SUCCESS = "Current_NEW_CHANNEL_SUCCESS";
export const getMyChannels = () => (dispatch, state) => {
  dispatch({ type: GET_CHANNELS_PROGRESS });
  apiMethods
    .post("Channel/Provider/MyChannels")
    .then((response) => {
      dispatch({ type: GET_CHANNELS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_CHANNELS_ERROR, error: error });
    });
};

export const GET_SUBSCRIBED_CHANNELS_SUCCESS =
  "GET_SUBSCRIBED_CHANNELS_SUCCESS";
export const GET_SUBSCRIBED_CHANNELS_ERROR = "GET_SUBSCRIBED_CHANNELS_ERROR";
export const GET_SUBSCRIBED_CHANNELS_PROGRESS =
  "GET_SUBSCRIBED_CHANNELS_PROGRESS";

export const getSubscribedChannels = () => (dispatch, state) => {
  dispatch({ type: GET_SUBSCRIBED_CHANNELS_PROGRESS });
  apiMethods
    .post("Channel/Subscribed")
    .then(function (response) {
      dispatch({
        type: GET_SUBSCRIBED_CHANNELS_SUCCESS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: SHOW_ERROR,
        payload: error,
      });
      dispatch({
        type: GET_SUBSCRIBED_CHANNELS_ERROR,
        error: error,
      });
    });
};

export const updateChnannelStatus = (data) => (dispatch) => {
  apiMethods.post("Channel/UpdateStatus", data).then(
    (response) => {
      dispatch({ type: UPDATE_CHANNEL_STATUS_SUCCESS, payload: data });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        error: error,
      });
    }
  );
};

export const createNewChannel = (data) => (dispatch) => {
  apiMethods.post("Channel", data).then(
    (response) => {
      dispatch({ type: CREATE_NEW_CHANNEL_SUCCESS, payload: response.data });
      dispatch({ type: GET_NEW_CHANNEL_DATA, payload: response.data });
      localStorage.setItem("id", response.data.id);
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        payload: error.data.message,
      });
    }
  );
};

export const unsubscribeChannel = (id) => (dispatch) => {
  apiMethods.deleteRequest(`FeedSubscription?channelId=${id}`).then(
    (response) => {
      dispatch({ type: UNSUBSCRIBE_CHANNEL_SUCCESS, payload: id });
    },
    (error) => {
      dispatch({
        type: SHOW_ERROR,
        error: error,
      });
    }
  );
};
export const updateChannels = (data) => ({
  type: UPDATE_CHANNELS,
  payload: data,
});
