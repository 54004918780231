import {
  GET_INVITATION_DETAILS_REQUEST,
  GET_INVITATION_DETAILS_SUCCESS,
  GET_INVITATION_DETAILS_ERROR,
  ACCEPT_INVITATION_REQUEST,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_ERROR,
} from "../actions/acceptInvite.action";

const initialState = {
  invitationCode: null,
  invitationDetails: null,
  error: null,
  state: null,
};

const acceptInviteReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case GET_INVITATION_DETAILS_REQUEST: {
      newState = {
        ...state,
        state: action.type,
        error: null,
        invitationCode: action.payload,
      };
      break;
    }
    case GET_INVITATION_DETAILS_SUCCESS: {
      newState = {
        ...state,
        state: action.type,
        error: null,
        invitationDetails: action.payload,
      };
      break;
    }
    case GET_INVITATION_DETAILS_ERROR: {
      newState = {
        ...state,
        state: action.type,
        error: action.payload,
        invitationDetails: null,
      };
      break;
    }
    case ACCEPT_INVITATION_REQUEST: {
      newState = { ...state, state: action.type, error: null };
      break;
    }
    case ACCEPT_INVITATION_SUCCESS: {
      newState = { ...state, state: action.type, error: null };

      break;
    }
    case ACCEPT_INVITATION_ERROR: {
      newState = { ...state, state: action.type, error: action.payload };
      break;
    }
  }

  return newState;
};

export default acceptInviteReducer;
