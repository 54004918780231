const getToken = () => {
  return localStorage.getItem("bearer_token");
};

const setToken = token => {
  localStorage.setItem("bearer_token", token);
};

const setExpiry = time => {
  localStorage.setItem("token_expiry", time);
};

const getExpiry = () => {
  return localStorage.getItem("token_expiry");
};

const clear = () => {
  localStorage.clear();
};

const tokenService = {
  getToken,
  setToken,
  getExpiry,
  setExpiry,
  clear
};

function clearTokenIfExpired() {
  const expiryFromStorage = getExpiry();

  if (expiryFromStorage) {
    const numExpiryFromStorage = parseInt(expiryFromStorage);
    const now = new Date().getTime();
    if (now > numExpiryFromStorage) {
      clear();
    }
  }
}

clearTokenIfExpired();

export default tokenService;
