import React, { Component } from "react";
import "./disableAccount.css";
import { toast, ToastContainer } from "react-toastify";
import apiMethods from "../../middleware/baseApiService";
import { Modal, Button } from "react-bootstrap";

export default class DisableAccount extends Component {
  constructor(props) {
    super(props);

    // this.state = {};
    this.state = {
      show: false,
      isDisable: false,
    };
  }
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleRedirectToDashboard = () => {
    // Redirect to the dashboard route
    // window.location.href = "/dashboard";
    this.props.history.push("/dashboard");
  };

  // handleShowAlert = () => {
  //   toast.warning(
  //     <div>
  //       Are you sure you want to cancel?{" "}
  //       <button
  //         type="button"
  //         className="btn btn-primary"
  //         onClick={this.handleRedirectToDashboard}
  //       >
  //         yes
  //       </button>
  //     </div>,
  //     {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: false, // Do not auto-close the alert
  //       closeButton: false, // Hide the close button
  //       closeOnClick: false, // Do not close when clicked
  //       draggable: false, // Do not allow dragging
  //       pauseOnHover: false, // Do not pause on hover
  //       // onClick: this.handleRedirectToDashboard, // Redirect when clicked
  //     }
  //   );
  // };
  deactivateProfile = async (props) => {
    // this.props.showApiLoader();
    this.setState({ show: false, isDisable: true });
    var email1 = localStorage.getItem("username");
    apiMethods
      .post("SignIn/DeactivateAccountUsingEmail", { email: email1 })
      .then((res) => {
        // props.hideApiLoader();
        console.log(res.data.message);
        localStorage.removeItem("username");
        localStorage.removeItem("mobile");
        localStorage.removeItem("login");
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          closeButton: false,
        });
        setTimeout(() => {
          // this.props.history.push("/login");
          this.setState({ isDisable: false });
          window.location.href = "/login";
        }, 3000);
      })
      .catch((err) => {
        this.setState({ isDisable: false });
        console.log("deactivation failed", mobile);
      });
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please confirm your decision to proceed with disabling your account.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.deactivateProfile}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="container">
          <div className="form-rectangle">
            <div className="form-header">
              <h3>Disable Account</h3>
            </div>
            <div className="form-container h-100">
              <h2 className="disableAccountHeading">
                Are you sure you want to disable your account?
              </h2>

              <div className="disableAccountBtnContainer">
                <button
                  className="disableAccountBtn"
                  // onClick={this.deactivateProfile}
                  onClick={this.handleShow}
                  disabled={this.state.isDisable}
                >
                  Yes
                </button>
                <button
                  className="disableAccountBtn disableAccountCancelBtn"
                  onClick={this.handleRedirectToDashboard}
                  disabled={this.state.isDisable}
                >
                  Cancel
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
