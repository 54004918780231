import React from "react";
import document from "../../img/document.png";
import like from "../../img/like.svg";
import share from "../../img/share.svg";
import "./individualPost.css";
import SharePost from "../sharePost/sharePost";
import ReactHtmlParser from "react-html-parser";
import { Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import {
  deletePost,
  DELETE_POST_SUCCESS_MESSAGE,
} from "../../actions/timeLine.action";
import Sharepopbox from "../sharePost/Sharepopbox";
import SocialMediaShare from "../sharePost/SocialMediaShare";
import apiMethods from "../../middleware/baseApiService";
import Axios from "axios";
import WebShareLink from "../../containers/WebShareLink";
import parse from "react-html-parser";
class IndividualPostComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openShareModal: false,
      currentShareId: null,
      shareId: null,
      selectedChannel: null,
      isOpen: false,
      numPages: 1,
      pageNumber: 1,
      page: 1,
      isDelete: false,
      openshareopopbox: false,
      openSocialMediaPopbox: false,
      shareSocialMediaId: null,
      imageUrl: "",
      validateImageUrl: true,
      placeHolderImage: "",
    };
    this.sharePostRef = React.createRef();
  }

  // onError = () => {
  //   console.log("place", "placeHolder");
  //   this.setState({
  //     placeHolderImage: `${this.state.imageUrl}def${Math.floor(
  //       Math.random() * 70 + 1
  //     )}.jpeg`,
  //   });
  // };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages: numPages });
  };
  togglePostLikeStatus = (item, bool) => {
    let data = {};
    data["postShareId"] = item.postShareJson.id;
    data["isLike"] = bool;
    data["index"] = this.props.index;
    this.props.toggleLikeForPost(data);
  };
  componentDidMount() {
    let url = apiMethods.imageUrl();
    this.setState({ imageUrl: url });
    // console.log("imageyrl", apiMethods.imageUrl());
    console.log("image", this.props.item.metaDataValue.image);
    // if (this.props.item.metaDataValue.image) {
    //   fetch(this.props.item.metaDataValue.image)
    //     .then((res) => {
    //       if (res.status === 404) {
    //         console.log("urlll", "404");
    //         this.setState({ validateImageUrl: false });
    //       } else {
    //         console.log("urlll", "200");
    //         this.setState({ validateImageUrl: true });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("urlll", error);
    //       this.setState({ validateImageUrl: true });
    //     });
    // Axios({
    //   method: "post",
    //   url: this.props.item.metaDataValue.image,
    //   headers: {
    //     // Authorization: `Bearer ` + tokenService.getToken(),
    //     "access-control-allow-origin": "*",
    //     "Access-Control-Allow-Headers": "Content-Type, Authorization",
    //     "Access-Control-Allow-Methods": "*",
    //   },
    // })
    //   .then((res) => {
    //     if (res.status === 404) {
    //       console.log("urlll", "404");
    //       this.setState({ validateImageUrl: false });
    //     } else {
    //       console.log("urlll", "200");
    //       this.setState({ validateImageUrl: true });
    //     }
    //   })
    //   .catch((error) => {
    //     this.setState({ validateImageUrl: true });
    //   });
    // }
  }
  removePost = (item) => {
    this.props.removePost({
      postShareId: item.postShareJson.id,
      index: this.props.index,
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.state === DELETE_POST_SUCCESS_MESSAGE) {
      this.props.loadMore();
    }
  }
  deletePost = (item) => {
    this.props.deletePost({
      postShareId: item.postShareJson.id,
      index: this.props.index,
    });
    this.setState({ isDelete: true });
  };

  openPopup = () => {
    this.setState({ isOpen: true });
  };
  onClose = () => {
    this.setState({ isOpen: false });
  };
  openShareDialog = (item) => {
    if (!this.props.profile.securityMatrix.clientId)
      this.setState({
        currentShareId: item.id,
        openShareModal: true,
        openshareopopbox: false,
      });
  };

  closeShareDialog = () => {
    this.setState({ currentShareId: null, openShareModal: false });
  };
  openSharePopBox = (item) => {
    if (!this.props.profile.securityMatrix.clientId)
      this.setState({ shareId: item, openshareopopbox: true });
  };
  closeSharePopBox = () => {
    this.setState({ openshareopopbox: false });
  };
  openSocialMedia = (item) => {
    this.setState({
      openSocialMediaPopbox: true,
      openshareopopbox: false,
      shareSocialMediaId: item,
    });
  };
  closeSocialMediaPopBox = () => {
    this.setState({ openSocialMediaPopbox: false });
  };
  sharePostToChannel = (data) => {
    this.props.sharePost(data);
  };

  savePost = (item) => {
    this.props.savePost({
      postShareId: item.postShareJson.id,
      index: this.props.index,
    });
  };

  onFacebookShare = (postShareId) => {
    var shareRootUrl = "http://localhost:58530";

    if (process.env.NODE_ENV === "production") {
      shareRootUrl = "https://hsapiv2.azurewebsites.net";
    }

    window.FB.ui(
      {
        method: "share",
        display: "popup",
        href: `${shareRootUrl}/publicpostshare?externalpostshareid=${postShareId}`,
      },
      function (response) {}
    );
  };

  getFormatedDate = (dateString) => {
    var localDate = new Date(dateString);
    return `${localDate.toLocaleString("en-us", {
      month: "long",
    })}${" "}${localDate.toLocaleString("en-us", {
      day: "numeric",
    })}${this.getSuffix(
      localDate.toLocaleString("en-us", { day: "numeric" })
    )}, ${localDate.toLocaleString("en-us", { year: "numeric" })}`;
  };

  getSuffix = (n) => [, "st", "nd", "rd"][(n % 100 >> 3) ^ 1 && n % 10] || "th";

  updateSelectedChannel = (channel) =>
    this.setState({ selectedChannel: channel });

  renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const { ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: "#357edd",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={props.onClick}
                  >
                    Zoom out
                  </button>
                )}
              </ZoomOut>
            </div>
            ...
          </div>
        );
      }}
    </Toolbar>
  );
  defaultLayoutPluginInstance = this.renderToolbar();

  render() {
    console.log(this.props.item.postShareJson, "postlikeCOunt");
    return (
      <div>
        <div className="post_Header_Container">
          <div className="post_Header">
            {this.props.item.postShareJson.shareProvider.profileImg ? (
              <div
                className="profile-image"
                style={{
                  backgroundImage:
                    "url(" +
                    this.props.item.postShareJson.shareProvider.profileImg +
                    ")",
                }}
              />
            ) : (
              <div className="no-profile-image" />
            )}
            <div className="post-set" style={{ width: "68%" }}>
              <div className="post_info" style={{ flexDirection: "row" }}>
                {ReactHtmlParser(this.props.item.authorShareText)}
              </div>

              <div className="post_date" style={{ lineBreak: "auto" }}>
                <span style={{ marginRight: "7px" }}>
                  {this.props.item.channelName}
                </span>
              </div>

              <div className="post-channel">
                <span>{this.getFormatedDate(this.props.item.createDate)}</span>
              </div>
            </div>
            <div className="post_like">
              {" "}
              <i
                className="fa fa-heart post_like_image"
                aria-hidden="true"
              />{" "}
              {this.props.item.postShareJson.postLikeCount
                ? this.props.item.postShareJson.postLikeCount
                : 0}{" "}
              {/* Like(s) */}
            </div>
          </div>
          <div className="post_header_text">
            {this.props.item.postShareJson.shareTitle}
          </div>
          <div className="post_data_divider mt-3" />
        </div>

        <div
          className="post_desc_container"
          style={{ overflow: " hidden auto" }}
        >
          {this.props.item.postType === "Video" ? (
            <div className="post_image_container">
              <div className="post_desc_header">{this.props.item.title}</div>
              <video controls style={{ width: "480px" }} preload="none">
                <source src={this.props.item.postVideo} />
              </video>
            </div>
          ) : null}
          {this.props.item.postType === "Image" ? (
            <div className="post_image_container">
              <div className="post_desc_header">{this.props.item.title}</div>
              <img
                src={
                  this.props.item.postImage ||
                  `${this.state.imageUrl}def${Math.floor(
                    Math.random() * 70 + 1
                  )}.jpeg`
                }
                alt="Post"
              />
            </div>
          ) : null}
          {this.props.item.postType === "Document" ? (
            <div className="post_image_container" style={{ height: "538px" }}>
              <div className="post_desc_header">{this.props.item.title}</div>
              <div className="post_pdf_container">
                {" "}
                <a
                  href={this.props.item.postDocument}
                  className="post_document_link"
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={this.props.item.postDocument}
                      plugins={[this.defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </a>
              </div>
            </div>
          ) : null}
          {this.props.item.postType === "Link" ? (
            <div>
              {this.props.item.metaDataValue.image !== "" &&
                this.props.item.metaDataValue.url !== "" && (
                  <div className="post_desc_image">
                    <a
                      className=""
                      href={this.props.item.metaDataValue.url}
                      target="_blank"
                    >
                      <img
                        href="#"
                        src={
                          this.state.validateImageUrl
                            ? this.props.item.metaDataValue.image
                            : `${this.state.imageUrl}def${Math.floor(
                                Math.random() * 70 + 1
                              )}.jpeg`
                        }
                        // src={
                        //   this.props.item.metaDataValue.image ||
                        //   this.state.placeHolderImage
                        // }
                        // onError={this.onError}
                        alt="doctor"
                      />
                    </a>
                  </div>
                )}
              {this.props.item.metaDataValue.image !== "" &&
                this.props.item.metaDataValue.url === "" && (
                  <div className="post_desc_image">
                    <img
                      href="#"
                      src={this.props.item.metaDataValue.image}
                      alt="doctor"
                    />
                  </div>
                )}

              {this.props.item.metaDataValue.image === "" && (
                <div className="post_desc_image">
                  <img
                    href="#"
                    src={`${this.state.imageUrl}def${Math.floor(
                      Math.random() * 70 + 1
                    )}.jpeg`}
                    //images/def14.jpeg
                    // src="images/def1.jpeg"
                    alt="doctor"
                  />
                </div>
              )}
              <div className="post_desc_header">
                {parse(this.props.item.metaDataValue.title)}
              </div>
              {this.props.item.metaDataValue.descp !== "No Description found" &&
                this.props.item.metaDataValue.url !== "" && (
                  <div className="post_desc_data">
                    <div className="post_desc">
                      <a
                        className=""
                        href={this.props.item.metaDataValue.url}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="post_desc_text">
                          {parse(this.props.item.metaDataValue.descp)}...
                        </div>
                      </a>
                    </div>
                  </div>
                )}
              {this.props.item.metaDataValue.descp !== "No Description found" &&
                this.props.item.metaDataValue.url === "" && (
                  <div className="post_desc_data">
                    <div className="post_desc">
                      <div className="post_desc_text">
                        {this.props.item.metaDataValue.descp}...
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ) : null}
        </div>
        <div className="post_data_divider mt-4" />

        <div className="post-option mt-3">
          {!this.props.item.isPostDeleted && !this.state.isDelete ? (
            <div className="row">
              <div className="col text-center">
                {this.props.item.saved ? (
                  this.props.timelineFilterType === "savedPost" ? (
                    <a onClick={() => this.removePost(this.props.item)}>
                      <i className="fa fa-minus-circle" aria-hidden="true" />{" "}
                      Remove
                    </a>
                  ) : (
                    <a>
                      <i className="fa fa-check-circle" aria-hidden="true" />{" "}
                      Saved
                    </a>
                  )
                ) : (
                  <a onClick={() => this.savePost(this.props.item)}>
                    <i className="fa fa-plus-circle" aria-hidden="true" /> Save
                  </a>
                )}
              </div>
              <div className="col text-center">
                <div>
                  {!this.props.item.liked ? (
                    <a
                      onClick={() =>
                        this.togglePostLikeStatus(this.props.item, true)
                      }
                    >
                      <img src={like} alt="like" className="post_option_like" />{" "}
                      <span>Like</span>
                    </a>
                  ) : (
                    <a
                      onClick={() =>
                        this.togglePostLikeStatus(this.props.item, false)
                      }
                    >
                      {" "}
                      <i className="fa fa-heart" style={{ color: "#00C6FF" }}>
                        {" "}
                        <span style={{ fontFamily: "proximaNova" }}>Liked</span>
                      </i>
                    </a>
                  )}
                </div>
              </div>
              <div className="col text-center">
                <a onClick={() => this.openSharePopBox(this.props.item)}>
                  <img src={share} alt="like" className="post_option_like" />{" "}
                  Share
                </a>
              </div>

              {this.props.item.canDeletePost ? (
                <div className="col text-center">
                  <a onClick={() => this.deletePost(this.props.item)}>
                    <i className="fa fa-trash" aria-hidden="true" /> Delete
                  </a>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="post_option_deleted_container">
              <i className="fa fa-trash" aria-hidden="true" /> Deleted
            </div>
          )}
        </div>
        <SharePost
          onRef={(ref) => (this.sharePostRef = ref)}
          channels={this.props.channels}
          callback={this.sharePostToChannel}
          currentShareId={this.state.currentShareId}
          openShareModal={this.state.openShareModal}
          closeShareDialog={this.closeShareDialog}
          selectedChannel={this.state.selectedChannel}
          updateSelectedChannel={this.updateSelectedChannel}
        />
        <Sharepopbox
          openshareopopbox={this.state.openshareopopbox}
          shareId={this.state.shareId}
          closeSharePopBox={this.closeSharePopBox}
          openShareDialog={this.openShareDialog}
          openSocialMedia={this.openSocialMedia}
        />
        <SocialMediaShare
          openSocialMediaPopbox={this.state.openSocialMediaPopbox}
          closeSocialMediaPopBox={this.closeSocialMediaPopBox}
          shareSocialMediaId={this.state.shareId}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  success: state.timeLineReducer.success,
  state: state.timeLineReducer.state,
});
const mapDispatchToProps = (dispatch) => ({
  deletePost: (data) => dispatch(deletePost(data)),
});
const IndividualPost = IndividualPostComponent;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndividualPost)
);
