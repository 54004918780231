import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationInviteProvider from "../components/organizationInviteProvider/organizationInviteProvider";
import { getCertifications } from "../actions/certifications.action";
import { getConditionsTreated } from "../actions/conditionsTreated.action";
import {
  getDegrees,
  getSpecialities,
} from "../actions/personalProfile.actions";
import { getAffiliations } from "../actions/affiliations.action";
import {
  inviteProvider,
  ORG_INVITE_PROVIDER_SUCCESS,
  ORG_INVITE_PROVIDER_ERROR,
} from "../actions/organizationInviteProvider.action";
import { getorganizationLocations } from "../actions/organizationLocation.action";
import {
  ROUTE_ORG_INVITE_PROVIDER,
  ROUTE_ORG_INVITE_SUBADMIN,
} from "./RouteConstants";
import { getMyDepartments } from "../actions/department.action";
import { getPrevStep } from "../actions/onboardingStatus.actions";
import OrganizationInviteProviderField from "../components/organizationInviteProvider/OrganizationInviteProviderField";
import { getDepartmentList } from "../actions/organizationDepartment.action";
class OrganizationInviteProviderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inviteAnotherProvider: false,
      saveInProgress: false,
      isNextStep: false,
    };

    this.formRef = React.createRef();
    this.inviteProvider = this.inviteProvider.bind(this);
    this.inviteProviderbyadmin = this.inviteProviderbyadmin.bind(this);
  }

  componentWillMount(nextProps) {
    this.props.getCertifications();
    this.props.getDegrees();
    this.props.getConditionsTreated();
    this.props.getAffiliations();
    this.props.getSpecialities();
    this.props.orgId
      ? this.props.getMyDepartments("?org_id=" + this.props.orgId)
      : this.props.getMyDepartments();
    // this.props.getMyDepartments();
    // this.props.orgId
    //   ? this.props.getorganizationLocations("&org_id=" + this.props.orgId)
    //   : this.props.getorganizationLocations();
    this.props.getDepartmentList();
    // this.props.getorganizationLocations();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_PROVIDER_SUCCESS &&
      this.state.inviteAnotherProvider === true
    ) {
      this.setState({ saveInProgress: false });
      this.formRef.current.inviteAnotherProvider();
    }

    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_PROVIDER_ERROR
    ) {
      this.setState({ saveInProgress: false });
    }
  }

  getPrevStep = () => {
    this.props.getPrevStep("?currentStep=ORGANIZATION-INVITE-PROVIDER");
  };

  inviteProvider(values, inviteAnotherProvider) {
    this.setState({
      inviteAnotherProvider: inviteAnotherProvider,
      saveInProgress: true,
    });
    this.props.inviteProvider(values);
  }
  inviteProviderbyadmin(values, inviteAnotherProvider) {
    this.setState({
      inviteAnotherProvider: inviteAnotherProvider,
      saveInProgress: true,
    });
    this.props.inviteProvider(values);
    this.setState({ isNextStep: true });
    this.props.closeProvider(false);
  }
  onSkipForNow = () =>
    this.props.history.push(`/next-step${ROUTE_ORG_INVITE_PROVIDER}`);

  render() {
    // if (
    //   this.props.prevStep !== null &&
    //   this.props.prevStep.name === "ORGANIZATION-INVITE-SUBADMIN" &&
    //   this.state.isNextStep === false
    // ) {
    //   return <Redirect to={ROUTE_ORG_INVITE_SUBADMIN} />;
    // }
    // if (
    //   this.props.organizationInviteAdminStatus ===
    //     ORG_INVITE_PROVIDER_SUCCESS &&
    //   this.state.inviteAnotherProvider === false &&
    //   this.state.isNextStep === false
    // ) {
    //   return <Redirect to={"/next-step" + ROUTE_ORG_INVITE_PROVIDER} />;
    // }

    // Show can skip button if user is adding another provider after finishing adding first provider.
    const canSkip =
      this.props.canSkip === true ||
      (this.props.organizationInviteAdminStatus ===
        ORG_INVITE_PROVIDER_SUCCESS &&
        this.state.inviteAnotherProvider === true);

    return (
      <div>
        <div className="container">
          {this.props.value === true ? (
            <OrganizationInviteProviderField
              ref={this.formRef}
              allDegrees={this.props.allDegrees}
              allCertifications={this.props.allCertifications}
              allConditionsTreated={this.props.allConditionsTreated}
              allAffiliations={this.props.allAffiliations}
              allSpecialities={this.props.allSpecialities}
              inviteProvider={this.inviteProviderbyadmin}
              isSaveInProgress={this.state.saveInProgress}
              departments={this.props.departmentList}
              locations={this.props.locations}
              canSkip={canSkip}
              onSkipForNow={this.onSkipForNow}
              onBoarding={true}
              getPrevStep={this.getPrevStep}
            />
          ) : (
            <div className="container-bg">
              <div className="container">
                {" "}
                <OrganizationInviteProvider
                  ref={this.formRef}
                  allDegrees={this.props.allDegrees}
                  allCertifications={this.props.allCertifications}
                  allConditionsTreated={this.props.allConditionsTreated}
                  allAffiliations={this.props.allAffiliations}
                  allSpecialities={this.props.allSpecialities}
                  inviteProvider={this.inviteProvider}
                  isSaveInProgress={this.state.saveInProgress}
                  departments={this.props.departments}
                  locations={this.props.locations}
                  canSkip={canSkip}
                  onSkipForNow={this.onSkipForNow}
                  onBoarding={true}
                  getPrevStep={this.getPrevStep}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allCertifications: state.certifications.certificates,
  allConditionsTreated: state.conditionsTreated.conditionsTreated,
  allAffiliations: state.affiliations.affiliations,
  allDegrees: state.personalProfile.degrees,
  allSpecialities: state.personalProfile.specialities,
  organizationInviteAdminStatus: state.organizationInviteProvider.state,
  organizationInviteAdminError: state.organizationInviteProvider.error,
  departments: state.departmentReducer.myDepartments,
  locations: state.organizationLocation.locations,
  canSkip: state.onboardingStatus.canSkip,
  prevStep: state.onboardingStatus.prevStep,
  departmentList: state.organizationDepartment.departmentList,
});

const mapDispatchToProps = {
  getCertifications,
  getConditionsTreated,
  getAffiliations,
  getDegrees,
  getSpecialities,
  inviteProvider,
  getMyDepartments,
  getorganizationLocations,
  getPrevStep,
  getDepartmentList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationInviteProviderPage)
);
