import {
  GET_ORGANIZATION_ADMINS_ERROR,
  GET_ORGANIZATION_ADMINS_SUCCESS,
  GET_ORGANIZATION_ADMINS_REQUEST,
  DELETE_ORGANIZATION_ADMIN_ERROR,
  DELETE_ORGANIZATION_ADMIN_REQUEST,
  DELETE_ORGANIZATION_ADMIN_SUCCESS
} from "../actions/organizationManageAdmins.action";

const initialState = {
  state: null,
  administrators: [],
  error: null
};

const organizationAdminReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case GET_ORGANIZATION_ADMINS_ERROR: {
      newState = {
        ...state,
        error: action.error,
        state: GET_ORGANIZATION_ADMINS_ERROR,
        administrators: []
      };
      break;
    }
    case GET_ORGANIZATION_ADMINS_REQUEST: {
      newState = {
        ...state,
        error: null,
        administrators: [],
        state: GET_ORGANIZATION_ADMINS_REQUEST
      };
      break;
    }
    case GET_ORGANIZATION_ADMINS_SUCCESS: {
      newState = {
        ...state,
        error: null,
        administrators: action.data,
        state: GET_ORGANIZATION_ADMINS_SUCCESS
      };
      break;
    }
    case DELETE_ORGANIZATION_ADMIN_ERROR: {
      newState = {
        ...state,
        error: action.error,
        state: DELETE_ORGANIZATION_ADMIN_ERROR
      };
      break;
    }
    case DELETE_ORGANIZATION_ADMIN_REQUEST: {
      const adminIdToDelete = action.data;
      let administrators = Object.assign([], state.administrators);
      const indexOfMatchingAdmin = administrators.findIndex(eachAdmin => {
        return eachAdmin.providerId === adminIdToDelete;
      });

      if (indexOfMatchingAdmin !== -1) {
        administrators.splice(indexOfMatchingAdmin, 1);
      }

      newState = {
        ...state,
        error: null,
        state: DELETE_ORGANIZATION_ADMIN_REQUEST,
        administrators: administrators
      };
      break;
    }
    case DELETE_ORGANIZATION_ADMIN_SUCCESS: {
      newState = {
        ...state,
        error: null,
        state: DELETE_ORGANIZATION_ADMIN_SUCCESS
      };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }
  return newState;
};

export default organizationAdminReducer;
