import React, { Component } from "react";
import "./postShare.css";

export default class postShare extends Component {
  render() {
    return (
      <div className="manage-form">
        <div className="post-container">
          <div className="post-header">
            <div className="row">
              <div className="col-1">
                {this.props.post.providerProfilePicUrl ? (
                  <div
                    className="profile-img-container"
                    style={{
                      backgroundImage:
                        "url(" + this.props.post.providerProfilePicUrl + ")",
                    }}
                  />
                ) : (
                  <div className="no-profile-img" />
                )}
              </div>

              <div className="col-8 my-auto">
                <div>
                  {/* <label className="owner-name">
                    {this.props.post.providerFirstName}&nbsp;
                    {this.props.post.providerLastName}
                  </label>
                  &nbsp; &nbsp; */}

                  <label
                    className="owner-name"
                    dangerouslySetInnerHTML={{
                      __html: this.props.post.authorShareText,
                    }}
                  />
                </div>
              </div>
              <div className="col-3 my-auto">
                <label className="post-date">
                  {this.props.post.sharedDate}
                </label>
                {/* <div className="pull-right">
                  <i className="fa fa-heart post_like_image" /> 1999 Likes
                </div> */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 my-auto">
                <label className="post-title">{this.props.post.title}</label>
              </div>
            </div>
            <hr className="divider" />
          </div>
          <div className="post-content">
            {this.props.post && this.props.post.postType === "Video" ? (
              <div>
                <div className="post-header-text">{this.props.post.title}</div>
                <video controls>
                  <source src={this.props.post.postVideoUrl} />
                </video>
              </div>
            ) : (
              ""
            )}

            {this.props.post && this.props.post.postType === "Image" ? (
              <div>
                <div className="post-header-text">{this.props.post.title}</div>
                <img src={this.props.post.postImageUrl} alt="Post" />
              </div>
            ) : (
              ""
            )}

            {this.props.post && this.props.post.postType === "Document" ? (
              <div>
                <div className="post-header-text">{this.props.post.title}</div>
                <a href={this.props.post.postDocumentUrl}>
                  <img
                    src={document}
                    alt="document"
                    title="click to download"
                  />
                </a>
              </div>
            ) : (
              ""
            )}

            {this.props.post && this.props.post.postType === "Link" ? (
              <div className="setlink">
                <div className="post-header-text">
                  {this.props.post.metaDataValue.title}
                </div>
                <div className="row">
                  <div className="col-9">
                    {this.props.post.metaDataValue.descp}
                  </div>
                  <div className="col-3">
                    <img
                      // src={this.props.post.metaDataValue.image}
                      source={require(this.props.post.metaDataValue.image)}
                      alt="doctor"
                      width="90%"
                      height="50%"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
