import React, { Component } from "react";
import "./aboutMe.css";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import csc from "country-state-city";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
//import "react-phone-number-input/style.css";
//import PhoneInput from "react-phone-number-input";
//import { isValidPhoneNumber } from "react-phone-number-input";

export default class AboutMe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutMeInfo: null,
    };

    this.imageRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    title: Yup.string().required("Title is required"),
    contactPhone: Yup.string().required("Phone is required"),
    country: Yup.string().required("Country is requied"),
    // .test("validPhoneNumber", "Contact phone number invalid", value => {
    //   return isValidPhoneNumber(value);
    // }),
  });

  getProfileImage = () => {
    this.imageRef.current.click();
  };

  onSubmit(values) {
    // if (values.country.length === 1) {
    //   values.countryId = values.country[0].id;
    //   values.country = values.country[0].name;
    // }
    this.props.saveProfile(values);
  }

  render() {
    this.initialValues = {
      firstName: "",
      lastName: "",
      contactPhone: "",
      title: "",
      country: "",
      countryId: "",
    };

    if (this.props.personalProfile) {
      this.initialValues.firstName = this.props.personalProfile.firstName
        ? this.props.personalProfile.firstName
        : "";
      this.initialValues.lastName = this.props.personalProfile.lastName
        ? this.props.personalProfile.lastName
        : "";
      this.initialValues.contactPhone = this.props.personalProfile.contactPhone
        ? this.props.personalProfile.contactPhone
        : "";
      this.initialValues.title = this.props.personalProfile.title
        ? this.props.personalProfile.title
        : "";
      this.initialValues.country = this.props.personalProfile.country
        ? this.props.personalProfile.country
        : "";
      this.initialValues.countryId = this.props.personalProfile.countryId
        ? this.props.personalProfile.countryId
        : "";
    }

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={this.initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validationSchema={this.validationSchema}
        />
      </div>
    );
  }

  renderForm = (props) => {
    let buttonText = this.props.mode !== "edit" ? "Next" : "Done";
    const { handleSubmit, values } = props;

    // filter allCountries and return only USA
    let allCountries = csc.getAllCountries();
    let usaCountries = allCountries.filter(
      (country) => country.name === "United States"
    );

    return (
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="form-rectangle">
            {this.props.mode === "edit" ? (
              <div className="form-header">
                <div className="manage-form-title">
                  {this.initialValues.firstName}&nbsp;
                  {this.initialValues.lastName}
                  {/* <span className="edit-icon col"></span> */}
                </div>
              </div>
            ) : (
              <div className="form-header">
                <h3>TELL US ABOUT YOURSELF…</h3>
                <p>This is the stuff we HAVE to know.</p>
              </div>
            )}

            <div className="form-container h-100">
              <div className="row">
                <div className="col-4">
                  {this.props.mode === "edit" ? (
                    ""
                  ) : !this.props.profileImg ? (
                    <div className="form-lText ">
                      Its helpful for users to see who you are, particularly in
                      larger organizations.
                    </div>
                  ) : null}

                  {this.props.profileImg == null ? (
                    <div
                      className={
                        this.props.mode === "edit"
                          ? "user-oval topMargin"
                          : "user-oval"
                      }
                    />
                  ) : (
                    <div
                      style={{
                        backgroundImage: "url(" + this.props.profileImg + ")",
                      }}
                      className={
                        this.props.profileImg
                          ? "org_logo topMargin"
                          : "org_logo"
                      }
                    />
                  )}

                  <div className="form-group text-center mt-4">
                    <span
                      className="form-link-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.imageRef.current.click()}
                    >
                      Add a profile pic here
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={this.imageRef}
                      onChange={this.props.saveProfileImg}
                    />
                  </div>
                  {this.props.orgProfile && this.props.mode === "edit" ? (
                    this.props.orgProfile.organisationName ? (
                      <div
                        className="form-group text-center mt-4"
                        style={{ fontFamily: "proximaNova" }}
                      >
                        {`Organization : ${this.props.orgProfile.organisationName}`}
                        {this.props.personalProfile.securityMatrix
                          .accessibleDepartments.length > 0 ? (
                          <div>{`Departments : 
                          ${this.props.myDepartments
                            .filter(
                              (dept) =>
                                this.props.personalProfile.securityMatrix.accessibleDepartments.indexOf(
                                  dept.id
                                ) > -1
                            )
                            .map((dept) => dept.departmentName)}`}</div>
                        ) : null}
                      </div>
                    ) : null
                  ) : null}
                </div>
                <div className="col-8">
                  <div className="form-body">
                    <div className="form-group form-lable">
                      <label className="form-lable">First name</label>
                      <Field
                        className="form-control form-input"
                        name="firstName"
                        onChange={(e) => {
                          var name = /^[a-zA-Z\s]*$/;
                          if (
                            e.target.value !== "" &&
                            e.target.value.match(name) === null
                          )
                            alert("Please input alphabetic characters only");
                          else {
                            props.setFieldValue("firstName", e.target.value);
                          }
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="firstName" />
                      </span>
                    </div>
                    <div className="form-group form-lable">
                      <label className="form-lable">Last name</label>
                      <Field
                        className="form-control form-input"
                        name="lastName"
                        onChange={(e) => {
                          var name = /^[a-zA-Z\s]*$/;
                          if (
                            e.target.value !== "" &&
                            e.target.value.match(name) === null
                          )
                            alert("Please input alphabetic characters only");
                          else {
                            props.setFieldValue("lastName", e.target.value);
                          }
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="lastName" />
                      </span>
                    </div>
                    <div className="form-group form-lable">
                      <label className="form-lable">Country</label>
                      <Typeahead
                        id={"basic-typeahead-single"}
                        options={usaCountries}
                        // defaultSelected={[this.initialValues.country]}
                        selected={[csc.getCountryById(values.countryId)]}
                        name="country"
                        labelKey="name"
                        onChange={(selectedOptions) => {
                          console.log("set", selectedOptions);
                          if (selectedOptions.length === 1) {
                            props.setFieldValue(
                              "country",
                              selectedOptions[0].name
                            );
                            // props.setFieldTouched("country", true, true);
                            props.setFieldValue(
                              "countryId",
                              selectedOptions[0].id
                            );
                          }
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="country" />
                      </span>
                    </div>
                    <div className="form-group form-lable">
                      <label className="form-lable">Phone</label>
                      <Field
                        className="form-control form-input"
                        name="contactPhone"
                        type="text"
                        value={values.contactPhone}
                        onChange={(e) => {
                          var number = /^[0-9]+$/;
                          if (
                            e.target.value !== "" &&
                            e.target.value.match(number) === null
                          )
                            alert("Please input numeric characters only");
                          else {
                            props.setFieldValue("contactPhone", e.target.value);
                          }
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="contactPhone" />
                      </span>
                    </div>
                    <div className="form-group form-lable">
                      <label className="form-lable">Title</label>
                      <Field className="form-control form-input" name="title" />
                      <span className="error_text">
                        <ErrorMessage name="title" />
                      </span>
                    </div>
                    <div className="mt-100 text-right">
                      <button
                        type="submit"
                        disabled={this.props.isSaveInProgress}
                        className="btn form-btn"
                        id="btnLogin"
                      >
                        {buttonText}

                        {this.props.isSaveInProgress === true ? (
                          <span className="text-right pl-2">
                            <i
                              className="fa fa-circle-o-notch fa-spin"
                              style={{ color: "#212529" }}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
}
