import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const SET_ORGANIZATIONLOCATION_IN_PROGRESS =
  "SET_ORGANIZATIONLOCATION_IN_PROGRESS";
export const SET_ORGANIZATIONLOCATION_SUCCESS =
  "SET_ORGANIZATIONLOCATION_SUCCESS";
export const SET_ORGANIZATIONLOCATION_ERROR = "SET_ORGANIZATIONLOCATION_ERROR";
export const ADD_ANOTHER_LOCATION = "ADD_ANOTHER_LOCATION";
export const UPDATE_ADD_ANOTHER_LOCATION_STATUS =
  "UPDATE_ADD_ANOTHER_LOCATION_STATUS";
export const UPDATE_SAVE_STATE_TO_NULL = "UPDATE_SAVE_STATE_TO_NULL";
export const GET_ORGANIZATION_LOCATIONS_SUCCESS =
  "GET_ORGANIZATION_LOCATIONS_SUCCESS";
export const GET_ORGANIZATION_ALL_LOCATIONS_SUCCESS =
  "GET_ORGANIZATION_ALL_LOCATIONS_SUCCESS";
export const UPDATE_CURRENT_LOCATION_INDEX = "UPDATE_CURRENT_LOCATION_INDEX";
export const SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS =
  "SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS";

export const setOrganizationLocation = (data) => (dispatch) => {
  dispatch({ type: SET_ORGANIZATIONLOCATION_IN_PROGRESS });

  return apiMethods
    .post("OrganisationLocation", data.orgLocation)
    .then((response) => {
      dispatch(updateAddAnotherLocation(false));
      if (data.addAnotherLocation) dispatch({ type: ADD_ANOTHER_LOCATION });
      else {
        if (data.onBoarding)
          dispatch({ type: SET_ORGANIZATIONLOCATION_SUCCESS });
        else dispatch({ type: SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS });
      }
    })
    .catch((error) => {
      dispatch({ type: SET_ORGANIZATIONLOCATION_ERROR });
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    });
};

export const updateAddAnotherLocation = (data) => (dispatch) => {
  dispatch({ type: UPDATE_ADD_ANOTHER_LOCATION_STATUS, payload: data });
};

export const UpdateSaveStateToNull = () => (dispatch) => {
  dispatch({ type: UPDATE_SAVE_STATE_TO_NULL });
};

export const getorganizationLocations = (qString) => (dispatch) => {
  var url = "";

  if (qString === undefined) url = "OrganisationLocation?ActiveStatus=Active";
  else url = "OrganisationLocation?ActiveStatus=Active" + qString;

  apiMethods.get(url).then(
    (response) => {
      dispatch({
        type: GET_ORGANIZATION_LOCATIONS_SUCCESS,
        payload: response.data,
      });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

export const deleteOrganizationLocation = (id) => (dispatch) => {
  apiMethods.deleteRequest(`OrganisationLocation/Delete?locId=${id}`).then(
    (response) => {
      dispatch(getorganizationLocations());
      dispatch(updateCurrentLocationIndex(0));
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

export const updateCurrentLocationIndex = (id) => (dispatch) => {
  dispatch({ type: UPDATE_CURRENT_LOCATION_INDEX, payload: id });
};

export const updateOrganizationLocation = (data) => (dispatch) => {
  apiMethods.post("OrganisationLocation/Update", data).then(
    (response) => {
      alert("Successfully Updated");
      dispatch(getorganizationLocations());
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};
