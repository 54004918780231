import React from "react";
import { Formik, Field } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { Modal } from "react-bootstrap";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import default_org_Logo from "../../img/profile_icon.jpeg";

class OrgModal extends React.Component {
  renderForm = props => {
    const { values } = props;

    return (
      <form>
        <div className="col-12">
          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-sm-4">
                <label className="form-lable">Location name</label>
                <Field
                  type="text"
                  value={values.locationName}
                  name="locatuonName"
                  className="form-control form-input"
                  disabled
                />
              </div>
              <div className="col-sm-8">
                <label className="form-lable">street address</label>
                <Field
                  type="text"
                  value={values.address}
                  name="address"
                  className="form-control form-input"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-sm-3">
                <label className="form-lable">City</label>
                <Field
                  type="text"
                  value={values.city}
                  name="city"
                  className="form-control form-input"
                  disabled
                />
              </div>
              <div className="col-sm-3">
                <label className="form-lable">state</label>
                <Field
                  type="text"
                  value={values.state}
                  name="state"
                  className="form-control form-input"
                  disabled
                />
              </div>
              <div className="col-sm-3">
                <label className="form-lable">Zip Code</label>
                <Field
                  type="text"
                  value={values.zipCode}
                  name="zipCode"
                  className="form-control form-input"
                  disabled
                />
              </div>
              <div className="col-sm-3">
                <label className="form-lable">Country</label>
                <Field
                  type="text"
                  value={values.country}
                  name="country"
                  className="form-control form-input"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-sm-4">
                <label className="form-lable">Location Phone</label>
                <Field
                  className="form-control form-input"
                  name="phone"
                  disabled
                  value={values.phone}
                />
              </div>
              <div className="col-sm-8">
                <label className="form-lable">Location Fax</label>
                <Field
                  type="text"
                  value={values.fax}
                  name="fax"
                  className="form-control form-input"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row no-gutter">
              <div className="col-sm-4">
                <label className="form-lable">Type</label>
                <Typeahead
                  options={[]}
                  selected={values.type}
                  placeholder=""
                  labelKey="name"
                  name="speciality"
                  disabled
                  className="org_speciality"
                  multiple
                />
              </div>
              <div className="col-sm-8">
                <label className="form-lable">Speciality</label>
                <Typeahead
                  options={[]}
                  selected={values.speciality}
                  placeholder=""
                  labelKey="name"
                  name="speciality"
                  disabled
                  className="org_speciality"
                  multiple
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        dialogClassName="modal-size"
      >
        <div className="manage-form-header">
          <div style={{ float: "right" }}>
            <button onClick={this.props.handleClose}>X</button>
          </div>
          <div className="manage-form-title align-middle">
            {`${this.props.subscriptionDetailsOrg.organisationName || ""}`}
          </div>
        </div>

        <div className="manage-form-container">
          <div className="row">
            <div className="col-12 d-flex">
              <div className="col-3">
                <div className="d-flex" style={{ marginTop: "25%" }}>
                  {this.props.subscriptionDetailsOrg.profileLogo ? (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage:
                          "url(" +
                          this.props.subscriptionDetailsOrg.profileLogo +
                          ")"
                      }}
                    />
                  ) : (
                    <div
                      className="org_logo"
                      style={{
                        backgroundImage: "url(" + default_org_Logo + ")"
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="form-body col-9" style={{ margin: "auto" }}>
                <Formik
                  ref={this.formik}
                  initialValues={{
                    locationName:
                      this.props.subscriptionDetailsOrg.primaryLocationName ||
                      "",
                    address:
                      this.props.subscriptionDetailsOrg.primaryAddress || "",
                    city: this.props.subscriptionDetailsOrg.city || "",
                    state: this.props.subscriptionDetailsOrg.state || "",
                    zipCode: this.props.subscriptionDetailsOrg.zipCode || "",
                    country: this.props.subscriptionDetailsOrg.country || "",
                    phone: this.props.subscriptionDetailsOrg.phone || "",
                    fax: this.props.subscriptionDetailsOrg.fax || "",
                    type:
                      this.props.subscriptionDetailsOrg.type &&
                      this.props.subscriptionDetailsOrg.type[0] === "["
                        ? JSON.parse(this.props.subscriptionDetailsOrg.type)
                        : [],
                    speciality:
                      this.props.subscriptionDetailsOrg.specialities || []
                  }}
                  render={this.renderForm}
                  enableReinitialize={true}
                />
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-3"></div>
              <div className="col-3">
                <button
                  className="btn form-btn-sm btn-sm"
                  id="btnActivate"
                  onClick={() => this.props.activateDeactivateAccount("?organisationId=" + this.props.subscriptionDetailsOrg.id + "&isActive=" + !this.props.subscriptionDetailsOrg.isActive)}
                >
                  {this.props.subscriptionDetailsOrg.isActive ? "Deactivate" : "Activate" }
                </button>
              </div>
              <div className="col-3">
                <button
                  className="btn form-btn-sm btn-sm"
                  id="btnDelete"
                  onClick={() => this.props.deleteAccount("?organisationId=" + this.props.subscriptionDetailsOrg.id)}
                >
                  Delete
                </button>
              </div>
              <br></br>
              <br></br>
            </div>
            {this.props.subscriptionDetailsOrg.members && 
              <div className="col-12 d-flex">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Active/Deactive</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.props.subscriptionDetailsOrg.members.map(member => {
                        return(
                          <tr>
                            <td>{member.firstName}</td>
                            <td>{member.lastName}</td>
                            <td>{member.email}</td>
                            <td>{member.userRole}</td>
                            <td>
                              {member.userRole !== "PrimaryOrganizationAdmin" &&
                                <button
                                  className="btn form-btn-sm btn-sm"
                                  id="btnActivate"
                                  onClick={() => this.props.activateDeactivateAccount("?providerId=" + member.id + "&isActive=" + !member.isActive)}
                                >
                                  {member.isActive ? "Deactivate" : "Activate" }
                                </button>
                              }
                            </td>
                            <td>
                              {member.userRole !== "PrimaryOrganizationAdmin" &&
                                <button
                                  className="btn form-btn-sm btn-sm"
                                  id="btnDelete"
                                  onClick={() => this.props.deleteAccount("?providerId=" + member.id)}
                                >
                                  Delete
                                </button>
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            }
            </div>
        </div>
      </Modal>
    );
  }
}

export default OrgModal;
