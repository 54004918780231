import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AcceptInvite from "../components/acceptInvite/acceptInvite";
import {
  getInvitationDetails,
  acceptInvitation,
  GET_INVITATION_DETAILS_REQUEST,
  GET_INVITATION_DETAILS_SUCCESS,
  ACCEPT_INVITATION_REQUEST,
  ACCEPT_INVITATION_SUCCESS,
} from "../actions/acceptInvite.action";

import { showError } from "../actions/errorDisplay.actions";
import { ROUTE_REGISTRATION_SUCCESS } from "./RouteConstants";

class AcceptInvitePage extends React.Component {
  constructor(props) {
    super(props);

    this.getQueryStringValue = this.getQueryStringValue.bind(this);
    this.acceptInvite = this.acceptInvite.bind(this);
    const invitationCode = this.getQueryStringValue("id");

    this.state = {
      loadingInvitationDetails: false,
      acceptingInviteInProgress: false,
      invitationCode: invitationCode,
    };
  }

  componentDidMount() {
    if (!this.state.invitationCode) {
      this.props.showError("Invalid invitation URL");
    } else {
      this.props.getInvitationDetails(this.state.invitationCode);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.state !== this.props.state) {
      if (nextProps.state === GET_INVITATION_DETAILS_REQUEST) {
        this.setState({ loadingInvitationDetails: true });
      } else if (nextProps.state === GET_INVITATION_DETAILS_SUCCESS) {
        this.setState({ loadingInvitationDetails: false });
      } else if (nextProps.state === ACCEPT_INVITATION_REQUEST) {
        this.setState({ acceptingInviteInProgress: true });
      } else if (nextProps.state === ACCEPT_INVITATION_SUCCESS) {
        this.setState({ acceptingInviteInProgress: false });
      }
    }
  }

  acceptInvite(acceptInviteParams) {
    const acceptInviteRequest = {
      ...acceptInviteParams,
      inviteUserId: this.props.invitationDetails.id,
    };
    this.props.acceptInvitation(acceptInviteRequest);
  }

  getQueryStringValue(key) {
    if (!window.location || !window.location.search) {
      return null;
    }

    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  }

  render() {
    if (!this.state.invitationCode) {
      return <div />;
    }

    if (this.props.state === ACCEPT_INVITATION_SUCCESS) {
      return <Redirect to="/acceptinvitesuccess" />;
    }

    return (
      <div className="container-bg">
        <div className="container">
          <AcceptInvite
            loadingInvitationDetails={this.state.loadingInvitationDetails}
            acceptInviteInProgress={this.state.acceptingInviteInProgress}
            invitationDetails={this.props.invitationDetails}
            acceptInvite={this.acceptInvite}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invitationDetails: state.acceptInvite.invitationDetails,
  state: state.acceptInvite.state,
});

const mapDispatchToProps = {
  getInvitationDetails,
  acceptInvitation,
  showError,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcceptInvitePage)
);
