/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import Explore from "../components/Explore";
import { resetErrorMessage } from "../actions";
// import Header from "../components/header/header";
// import Footer from "../components/Footer/footer";
import "../index.css";
import Phone from "../img/Phone.png";
import BImage from "../img/background_image.png";
import docs from "../img/docs.png";
import devices from "../img/devices.png";
import Patients from "../img/Patients.png";
import "./App.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class App extends Component {
  static propTypes = {
    // Injected by React Redux
    errorMessage: PropTypes.string,
    resetErrorMessage: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    // Injected by React Router
    children: PropTypes.node,
  };

  state = {
    fName: "",
    lName: "",
    email: "",
    company: "",
    comments: "",
    role: "",
  };
  componentDidMount() {
    if (window.localStorage.getItem("login")) {
      this.props.history.push("/dashboard");
    }
  }
  handleDismissClick = (e) => {
    this.props.resetErrorMessage();
    e.preventDefault();
  };

  handleChange = (nextValue) => {
    this.props.history.push(`/${nextValue}`);
  };

  submitForm = (event) => {
    event.preventDefault();
    if (
      this.state.fName === "" ||
      this.state.lName === "" ||
      this.state.email === "" ||
      event.target.role.value === ""
    ) {
      alert("Please enter required field values");
      return false;
    }
    fetch(
      "https://healthswimnew.azurewebsites.net/api/ContactUs/SendContactUsEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: this.state.fName,
          lastName: this.state.lName,
          email: this.state.email,
          company: this.state.company,
          role: this.state.role,
          comments: this.state.comments,
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          alert("Form is submitted successfully");
          this.setState({
            lName: "",
            fName: "",
            email: "",
            company: "",
            comments: "",
            role: "",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  renderErrorMessage() {
    const { errorMessage } = this.props;
    if (!errorMessage) {
      return null;
    }

    return (
      <p style={{ backgroundColor: "#e99", padding: 10 }}>
        <b>{errorMessage}</b>{" "}
        <button onClick={this.handleDismissClick}>Dismiss</button>
      </p>
    );
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <div className="body">
        <ToastContainer />
          <div className="row" style={{ margin: "4% 0 0 1%" }}>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="trust">
                <h2 style={{ lineHeight: "1.3em", textAlign: "center" }}>
                  <span style={{ fontWeight: "bold" }}>
                    <span
                      style={{ fontFamily: "DIN Neuzeit Grotesk LT W01 BdCn" }}
                    >
                      <span style={{ fontSize: "50px" }}>
                        <span style={{ color: "#2F2E2E" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ letterSpacing: "0em" }}>
                              TRUST A
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
                <h2 style={{ lineHeight: "2em", textAlign: "center" }}>
                  <span style={{ fontWeight: "bold" }}>
                    <span
                      style={{ fontFamily: "DIN Neuzeit Grotesk LT W01 BdCn" }}
                    >
                      <span style={{ fontSize: "77px" }}>
                        <span style={{ color: "#2F2E2E" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ letterSpacing: "0em" }}>DOCTOR</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
              <div className="internet">
                <h2 style={{ lineHeight: "2.3em", textAlign: "center" }}>
                  <span style={{ fontWeight: "bold" }}>
                    <span
                      style={{ fontFamily: "DIN Neuzeit Grotesk LT W01 BdCn" }}
                    >
                      <span style={{ fontSize: "50px" }}>
                        <span style={{ color: "#2F2E2E" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ letterSpacing: "0em" }}>
                              NOT THE
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
                <h2 style={{ lineHeight: "1em", textAlign: "center" }}>
                  <span style={{ fontWeight: "bold" }}>
                    <span
                      style={{ fontFamily: "DIN Neuzeit Grotesk LT W01 BdCn" }}
                    >
                      <span style={{ fontSize: "77px" }}>
                        <span style={{ color: "#2F2E2E" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ letterSpacing: "0em" }}>
                              INTERNET
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div className="col-md-5 phone_image">
              <img
                alt="Phone.png"
                src={Phone}
                style={{ width: "67%", marginLeft: "6%" }}
              />
            </div>
            <div className="col-md-1"></div>
          </div>
          <div
            className="row"
            style={{
              margin: "0",
              backgroundImage: "url(" + BImage + ")",
              display: "grid",
              height: "auto",
              width: "100%",
              minHeight: "496px",
            }}
          >
            <div>
              <p className="image_text">
                <span style={{ color: "white" }}>
                  <span
                    style={{
                      fontFamily: "poppins-semibold,poppins,sans-serif",
                    }}
                  >
                    <span className="image_text_span">
                      Healthswim enables you to navigate the sea of complex
                      medical information with the help of a health care
                      provider, including your own.
                    </span>
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div className="row" style={{ margin: "0" }}>
            <div>
              <p className="first_class">
                <span>
                  <span
                    style={{
                      fontFamily: "poppins-semibold,poppins,sans-serif",
                    }}
                  >
                    Content. Curated. Credible. Custom.
                  </span>
                </span>
              </p>
              <p className="second_class">
                <span style={{ fontSize: "24px" }}>
                  <span style={{ fontFamily: "proxima-n-w01-reg,sans-serif" }}>
                    People are looking for information that is specific to their
                    needs, is provided by a credible source and is delivered in
                    a secure environment. On Healthswim, you only see the
                    information that has been approved by a healthcare provider,
                    preferably your own. By choosing specific topics of
                    interest, you can customize the content that is pertinent to
                    you.
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div className="row" style={{ margin: "0" }}>
            <div className="col-md-6" style={{ backgroundColor: "#00c7ff" }}>
              <div style={{ margin: "6.5% 0% 4% 35%" }}>
                <img alt="docs.png" style={{ width: "47%" }} src={docs} />
              </div>
              <div style={{ margin: "0 0 2.5% 0" }}>
                <h2
                  style={{
                    lineHeight: "1em",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "lulo-clean-w01-one-bold,sans-serif",
                    }}
                  >
                    PHYSICIANS
                  </span>
                </h2>
              </div>
              <div style={{ margin: "0 0 17.5% 18%" }}>
                <ul
                  style={{
                    color: "white",
                    fontSize: "17px",
                    textAlign: "left",
                  }}
                >
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Never give out paper-based instructions again.
                    </span>
                  </li>
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Help prevent the spread of misinformation.
                    </span>
                  </li>
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Stay in touch with your patients after the visit.
                    </span>
                  </li>
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Be the champions of the right information.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="devices"
              style={{
                position: "absolute",
                margin: "13% 0 0 40%",
                zIndex: "9999999",
              }}
            >
              <img
                alt="devices.png"
                src={devices}
                style={{ width: "244px", height: "104px" }}
              />
            </div>
            <div className="col-md-6" style={{ backgroundColor: "#00e1ff" }}>
              <div style={{ margin: "6.5% 0% 3.5% 34%" }}>
                <img
                  alt="Patients.png"
                  style={{ width: "46%" }}
                  src={Patients}
                />
              </div>
              <div style={{ margin: "0 0 2.5% 0" }}>
                <h2
                  style={{
                    lineHeight: "1em",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "lulo-clean-w01-one-bold,sans-serif",
                    }}
                  >
                    PATIENTS
                  </span>
                </h2>
              </div>
              <div style={{ margin: "0 0 0 9%", width: "85%" }}>
                <ul
                  style={{
                    color: "white",
                    fontSize: "17px",
                    textAlign: "left",
                  }}
                >
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Tired of sifting through the web? Get relevant information
                      directly from healthcare providers.
                    </span>
                  </li>
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Credible information equals better health.
                    </span>
                  </li>
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      The person providing information should be the one who
                      cares about you.
                    </span>
                  </li>
                  <li style={{ lineHeight: "1.5em" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Papers get lost - information is always available via
                      Healtshwim.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: "0" }}>
            <div className="form_text">
              <div style={{ marginTop: "5%" }}>
                <h5 style={{ fontSize: "30px", textAlign: "center" }}>
                  <span
                    style={{
                      fontFamily: "avenir-lt-w01_85-heavy1475544,sans-serif",
                    }}
                  >
                    <span style={{ fontSize: "30px" }}>
                      <span style={{ letterSpacing: "0.05em" }}>
                        Interested in experiencing it yourself?
                      </span>
                    </span>
                  </span>
                </h5>
              </div>
              <div
                style={{ width: "64%", marginLeft: "18%", marginBottom: "4%" }}
              >
                <p style={{ fontSize: "16px", textAlign: "center" }}>
                  <span>
                    <span
                      style={{
                        fontFamily: "avenir-lt-w01_35-light1475496,sans-serif",
                      }}
                    >
                      Use of the Healthswim platform is by invitation only so
                      please use this form to connect with us for exclusive
                      access.
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: "0" }}>
            <div className="form_class">
              <form
                onSubmit={this.submitForm}
                style={{
                  fontFamily: "avenir-lt-w01_35-light1475496,sans-serif",
                }}
              >
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <label htmlFor="fname">
                      <b>First Name</b>
                    </label>{" "}
                    <b style={{ color: "red" }}>*</b>
                    <input
                      type="text"
                      className="form-control contact-form"
                      id="fname"
                      placeholder="First Name"
                      name="FirstName"
                      required={true}
                      value={this.state.fName}
                      onChange={(e) => {
                        var name = /^[a-z A-Z]+$/;
                        if (
                          e.target.value !== "" &&
                          e.target.value.match(name) === null
                        )
                          alert("Please input alphabetic characters only");
                        else this.setState({ fName: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label htmlFor="fname">
                      <b>Last Name</b>
                    </label>{" "}
                    <b style={{ color: "red" }}>*</b>
                    <input
                      type="text"
                      className="form-control contact-form"
                      id="lname"
                      placeholder="Last Name"
                      name="LastName"
                      required={true}
                      value={this.state.lName}
                      onChange={(e) => {
                        var name = /^[a-z A-Z]+$/;
                        if (
                          e.target.value !== "" &&
                          e.target.value.match(name) === null
                        )
                          alert("Please input alphabetic characters only");
                        else this.setState({ lName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "3%" }}>
                  <div className="col-md-6 col-xs-12">
                    <label htmlFor="email">
                      <b>Email Address</b>
                    </label>{" "}
                    <b style={{ color: "red" }}>*</b>
                    <input
                      type="email"
                      className="form-control contact-form"
                      id="email"
                      placeholder="Enter a valid email address"
                      name="Email"
                      required={true}
                      value={this.state.email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label htmlFor="company">
                      <b>Company</b>
                    </label>
                    <input
                      type="text"
                      className="form-control contact-form"
                      id="company"
                      placeholder="Company"
                      name="Company"
                      value={this.state.company}
                      onChange={(e) => {
                        var name = /^[a-z A-Z]+$/;
                        if (
                          e.target.value !== "" &&
                          e.target.value.match(name) === null
                        )
                          alert("Please input alphabetic characters only");
                        else this.setState({ company: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <br />
                <div className="row" style={{ marginTop: "3%" }}>
                  <div className="col-md-12">
                    <label>
                      <b>I am a:</b>
                    </label>{" "}
                    <b style={{ color: "red" }}>*</b>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        checked={this.state.role === "Provider" ? true : false}
                        type="radio"
                        name="role"
                        value="Provider"
                        required={true}
                        onChange={(e) => {
                          this.setState({ role: e.target.value });
                        }}
                      />
                      <label className="form-check-label">Provider</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        checked={this.state.role === "Patient" ? true : false}
                        type="radio"
                        name="role"
                        value="Patient"
                        required={true}
                        onChange={(e) => {
                          this.setState({ role: e.target.value });
                        }}
                      />
                      <label className="form-check-label">Patient</label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "8%" }}>
                  <div className="col-md-12">
                    <label htmlFor="comments">
                      <b>Comments:</b>
                    </label>
                    <input
                      type="text"
                      className="form-control contact-form"
                      id="comments"
                      placeholder="Enter text here"
                      name="comments"
                      value={this.state.comments}
                      onChange={(e) => {
                        this.setState({ comments: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: "7%",
                    justifyContent: "center",
                    marginBottom: "18%",
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#00e2ff",
                      borderColor: "#00e2ff",
                      width: "33%",
                      height: "45px",
                      borderRadius: "30px 30px 30px 30px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          {this.renderErrorMessage()}
          {children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  errorMessage: state.errorMessage,
  inputValue: ownProps.location.pathname.substring(1),
});

export default withRouter(
  connect(mapStateToProps, {
    resetErrorMessage,
  })(App)
);
