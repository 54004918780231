import {
  SET_FILTER_TYPE_SUCCESS,
  SET_CURRENT_FEED_CHANNEL
} from "../actions/timeLineFilter.action";
const initialState = {
  type: "all",
  currentFeedChannel: null
};

const filterReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case SET_FILTER_TYPE_SUCCESS: {
      newState = { ...state, type: action.payload, error: null };
      break;
    }
    case SET_CURRENT_FEED_CHANNEL: {
      newState = { ...state, currentFeedChannel: action.payload };
      break;
    }
  }

  return newState;
};

export default filterReducer;
