import {
  GET_POST_SHARE_SUCCESS,
  GET_POST_SHARE_ERROR,
  GET_POST_SHARE_PROGRESS,
  UPDATE_HAS_MORE,
  POST_LIKE_TOGGLE_SUCCESS,
  SAVE_POST_SUCCESS,
  REMOVE_POST_SUCCESS,
  DELETE_POST_SUCCESS,
  DELETE_POST_SUCCESS_MESSAGE,
} from "../actions/timeLine.action";

import { EMPTYFEED } from "../actions/publishContent.actions";

const initialState = {
  feed: [],
  error: null,
  pageNumber: 1,
  totalPages: null,
  hasMore: true,
  success: true,
};

const timeLineReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case GET_POST_SHARE_PROGRESS: {
      newState = { ...state, error: null };
      break;
    }
    case GET_POST_SHARE_SUCCESS: {
      if (action.payload.page === 1) {
        newState = {
          ...state,
          feed: action.payload.items,
          pageNumber: action.payload.page,
          totalPages: Math.ceil(action.payload.total / 10),
          error: null,
        };
      } else {
        newState = {
          ...state,
          feed: [...state.feed, ...action.payload.items],
          pageNumber: action.payload.page,
          totalPages: Math.ceil(action.payload.total / 10),
          error: null,
        };
      }

      break;
    }
    case UPDATE_HAS_MORE: {
      newState = {
        ...state,
        hasMore: action.payload,
      };
      break;
    }
    case GET_POST_SHARE_ERROR: {
      newState = { ...state, error: action.payload };
      break;
    }
    case DELETE_POST_SUCCESS_MESSAGE: {
      console.log("rum");
      newState = {
        ...state,
        success: true,
        state: DELETE_POST_SUCCESS_MESSAGE,
      };
      break;
    }
    // case POST_LIKE_TOGGLE_SUCCESS: {
    //   newState = {
    //     ...state,
    //     feed: state.feed.map((item, index) => {
    //       if (index === action.payload.index) {
    //         if (action.payload.isLike) {
    //           return Object.assign({}, state.feed[index], {
    //             liked: action.payload.isLike,
    //             postShareJson: Object.assign(
    //               {},
    //               state.feed[index].postShareJson,
    //               {
    //                 postLikeCount:
    //                   state.feed[index].postShareJson.postLikeCount + 1,
    //               }
    //             ),
    //           });
    //         } else {
    //           return Object.assign({}, state.feed[index], {
    //             liked: action.payload.isLike,
    //             postShareJson: Object.assign(
    //               {},
    //               state.feed[index].postShareJson,
    //               {
    //                 postLikeCount:
    //                   state.feed[index].postShareJson.postLikeCount - 1,
    //               }
    //             ),
    //           });
    //         }
    //       } else return item;
    //     }),
    //   };
    //   break;
    // }
    case POST_LIKE_TOGGLE_SUCCESS: {
      newState = {
        ...state,
        feed: state.feed.map((item, index) => {
          if (index === action.payload.index) {
            const currentItem = state.feed[index];
            const newPostLikeCount = currentItem.postShareJson.postLikeCount + (action.payload.isLike ? 1 : -1);
    
            // Ensure postLikeCount is not negative
            const postLikeCount = Math.max(newPostLikeCount, 0);
    
            return {
              ...item,
              liked: action.payload.isLike,
              postShareJson: {
                ...item.postShareJson,
                postLikeCount: postLikeCount
              }
            };
          } else {
            return item;
          }
        }),
      };
      break;
    }
    
    case SAVE_POST_SUCCESS:
      newState = {
        ...state,
        feed: state.feed.map((item, index) => {
          if (index === action.payload.index)
            return Object.assign({}, state.feed[index], {
              saved: true,
            });
          else return item;
        }),
      };
      break;
    case REMOVE_POST_SUCCESS:
      newState = {
        ...state,
        feed: state.feed.map((item, index) => {
          if (index === action.payload.index)
            return Object.assign({}, state.feed[index], {
              saved: false,
            });
          else return item;
        }),
      };
      break;
    case DELETE_POST_SUCCESS:
      newState = {
        ...state,
        feed: state.feed.map((item, index) => {
          if (index === action.payload)
            return Object.assign({}, state.feed[index], {
              isPostDeleted: true,
            });
          else return item;
        }),
      };
      break;
    case EMPTYFEED:
      return {
        ...state,
        feed: [],
      };
    default: {
      newState = { ...state };
      break;
    }
  }

  return newState;
};

export default timeLineReducer;
