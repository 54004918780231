import {
  GET_CHANNELS_SUCCESS,
  UPDATE_SHOWPREVIEW,
  GET_LINKPREVIEW_SUCCESS,
  CREATE_POST_SUCCESS,
  CREATE_POST_IN_PROGRESS,
  CREATE_POST_ERROR,
  UPDATE_POST_TYPE,
  ADD_SELECTED_CHANNEL,
  REMOVE_SELECTED_CHANNEL,
  HANDLE_INPUT_CHANGE,
  RESET_AUTHORING_UI
} from "../actions/publishContent.actions";

const intialState = {
  channels: [],
  showPreview: false,
  previewData: null,
  createPostState: null,
  linkUrl: "",
  videoTitle: "",
  imageTitle: "",
  documentTitle: "",
  postType: "Link",
  newPostTextBox: "",
  selectedchannels: [],
  isFeatured: false,
};

const publishContent = (state = intialState, action) => {
  switch (action.type) {
    case GET_CHANNELS_SUCCESS:
      return { ...state, channels: action.payload };
    case UPDATE_SHOWPREVIEW:
      return { ...state, showPreview: action.payload };
    case GET_LINKPREVIEW_SUCCESS:
      return { ...state, previewData: action.payload };
    case CREATE_POST_SUCCESS:
      return { ...state, createPostState: CREATE_POST_SUCCESS };
    case CREATE_POST_IN_PROGRESS:
      return { ...state, createPostState: CREATE_POST_IN_PROGRESS };
    case CREATE_POST_ERROR:
      return { ...state, createPostState: CREATE_POST_ERROR };
    case UPDATE_POST_TYPE:
      return { ...state, postType: action.payload };
    case HANDLE_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value
      };
    case ADD_SELECTED_CHANNEL:
      return {
        ...state,
        selectedchannels: action.payload
      };
    case REMOVE_SELECTED_CHANNEL:
      return {
        ...state,
        selectedchannels: [
          ...state.selectedchannels.slice(0, action.payload),
          ...state.selectedchannels.slice(action.payload + 1)
        ]
      };
    case RESET_AUTHORING_UI:
      return {
        ...state,
        linkUrl: "",
        documentTitle: "",
        imageTitle: "",
        videoTitle: "",
        newPostTextBox: "",
        selectedchannels: [],
        postType: "Link",
        isFeatured: false
      };
    default:
      return state;
  }
};

export default publishContent;
