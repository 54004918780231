import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationInviteProvider from "../components/organizationInviteProvider/organizationInviteProvider";
import { getCertifications } from "../actions/certifications.action";
import { getConditionsTreated } from "../actions/conditionsTreated.action";
import {
  getDegrees,
  getSpecialities,
} from "../actions/personalProfile.actions";
import { getAffiliations } from "../actions/affiliations.action";
import {
  inviteProvider,
  ORG_INVITE_PROVIDER_SUCCESS,
  ORG_INVITE_PROVIDER_ERROR,
  updateSaveStateToNull,
} from "../actions/organizationInviteProvider.action";
import { getorganizationLocations } from "../actions/organizationLocation.action";
import { ROUTE_ORG_MANAGE_PROVIDERS } from "./RouteConstants";
import { getMyDepartments } from "../actions/department.action";

class OrgCreateProviderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inviteAnotherProvider: false,
      saveInProgress: false,
      onBoarding: true,
    };

    this.formRef = React.createRef();
    this.inviteProvider = this.inviteProvider.bind(this);
    this.getMyDepartments = this.getMyDepartments.bind(this);
    this.getorganizationLocations = this.getorganizationLocations.bind(this);
  }

  componentWillMount(nextProps) {
    this.props.getCertifications();
    this.props.getDegrees();
    this.props.getConditionsTreated();
    this.props.getAffiliations();
    this.props.getSpecialities();
    this.getMyDepartments();
    this.getorganizationLocations();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_PROVIDER_SUCCESS &&
      this.state.inviteAnotherProvider === true
    ) {
      this.setState({ saveInProgress: false });
      this.formRef.current.inviteAnotherProvider();
    }

    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_PROVIDER_ERROR
    ) {
      this.setState({ saveInProgress: false });
    }
  }

  inviteProvider(values, inviteAnotherProvider, onBoarding) {
    this.setState({
      inviteAnotherProvider: inviteAnotherProvider,
      saveInProgress: true,
    });
    var org_id = this.props.location.search.split("?")[1];
    if (org_id !== undefined) values["org_id"] = org_id;
    this.props.inviteProvider(values, onBoarding);
  }

  getMyDepartments() {
    var org_id = this.props.location.search.split("?")[1];

    if (org_id !== undefined) this.props.getMyDepartments("?org_id=" + org_id);
    else this.props.getMyDepartments();
  }

  getorganizationLocations() {
    var org_id = this.props.location.search.split("?")[1];
    if (org_id !== undefined)
      this.props.getorganizationLocations("&org_id=" + org_id);
    else this.props.getorganizationLocations();
  }

  onSkipForNow = () => this.props.history.push(ROUTE_ORG_MANAGE_PROVIDERS);

  componentWillUnmount = () => {
    this.props.updateSaveStateToNull();
  };

  render() {
    if (
      this.props.organizationInviteAdminStatus ===
        ORG_INVITE_PROVIDER_SUCCESS &&
      this.state.inviteAnotherProvider === false
    ) {
      return <Redirect to={ROUTE_ORG_MANAGE_PROVIDERS} />;
    }

    return (
      <div className="container-bg">
        <div className="container">
          <OrganizationInviteProvider
            ref={this.formRef}
            allDegrees={this.props.allDegrees}
            allCertifications={this.props.allCertifications}
            allConditionsTreated={this.props.allConditionsTreated}
            allAffiliations={this.props.allAffiliations}
            allSpecialities={this.props.allSpecialities}
            inviteProvider={this.inviteProvider}
            isSaveInProgress={this.state.saveInProgress}
            departments={this.props.departments}
            locations={this.props.locations}
            canSkip={this.props.canSkip}
            onSkipForNow={this.onSkipForNow}
            onBoarding={false}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allCertifications: state.certifications.certificates,
  allConditionsTreated: state.conditionsTreated.conditionsTreated,
  allAffiliations: state.affiliations.affiliations,
  allDegrees: state.personalProfile.degrees,
  allSpecialities: state.personalProfile.specialities,
  organizationInviteAdminStatus: state.organizationInviteProvider.state,
  organizationInviteAdminError: state.organizationInviteProvider.error,
  departments: state.departmentReducer.myDepartments,
  locations: state.organizationLocation.locations,
  canSkip: state.onboardingStatus.canSkip,
});

const mapDispatchToProps = {
  getCertifications,
  getConditionsTreated,
  getAffiliations,
  getDegrees,
  getSpecialities,
  inviteProvider,
  getMyDepartments,
  getorganizationLocations,
  updateSaveStateToNull,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrgCreateProviderPage)
);
