import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const GET_PROVIDERS_FOR_ORG_SUCCESS = "GET_PROVIDERS_FOR_ORG_SUCCESS";

export const UPDATE_CURRENT_PROVIDER_INDEX = "UPDATE_CURRENT_PROVIDER_INDEX";

export const getProvidersForOrg = (role) => (dispatch) => {
  apiMethods.post(`provider/getfororg?from=1&size=10000&role=${role}`).then(
    (response) => {
      dispatch({
        type: GET_PROVIDERS_FOR_ORG_SUCCESS,
        payload: response.data.items,
      });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

export const updateCurrentProviderIndex = (index) => (dispatch) => {
  dispatch({ type: UPDATE_CURRENT_PROVIDER_INDEX, payload: index });
};
