import React from "react";
import OrgAdminCard from "./orgAdminCard";
import "./orgManageAdmins.css";
import { Link } from "react-router-dom";
import { ROUTE_ORG_CREATE_ADMIN } from "../../containers/RouteConstants";

export default class OrgManageAdmin extends React.Component {
  removeAdmin = adminId => {
    this.props.removeAdmin(adminId);
  };

  renderAdminCards = () => {
    if (this.props.loadingData) {
      return (
        <div className="col-12 mt-5 section-header text-center">
          Loading data...!
        </div>
      );
    }

    return this.props.admins.map((item, i) => {
      return (
        <OrgAdminCard
          key={`orgadmin_${item.id}`}
          orgAdmin={item}
          removeAdmin={this.removeAdmin}
        />
      );
    });
  };

  render() {
    return (
      <div className="manage-form">
        <div className="row">
          <div className="col-12 text-center">
            <div className="user-oval" />
          </div>
        </div>
        <div
          className="row mtb-60 "
          style={{ display: "flex", marginTop: "30px" }}
        >
          <div
            className="col-4"
            style={{ margin: "auto", textAlign: "center" }}
          >
            <Link
              className="manage-form-label align-middle"
              to={ROUTE_ORG_CREATE_ADMIN}
            >
              + Create new
            </Link>
          </div>
        </div>

        <div>
          <div className="row mt-10 no-gutter row-eq-height">
            {this.renderAdminCards()}
          </div>
        </div>
      </div>
    );
  }
}
