import {
  ORG_INVITE_ADMIN_REQUEST,
  ORG_INVITE_ADMIN_SUCCESS,
  ORG_INVITE_ADMIN_ERROR
} from "../actions/organizationInviteAdmin.action";

const initialState = {
  state: null,
  error: null
};

const organizationInviteAdminReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case ORG_INVITE_ADMIN_REQUEST: {
      newState = { state: ORG_INVITE_ADMIN_REQUEST, error: null };
      break;
    }
    case ORG_INVITE_ADMIN_SUCCESS: {
      newState = { state: ORG_INVITE_ADMIN_SUCCESS, error: null };
      break;
    }
    case ORG_INVITE_ADMIN_ERROR: {
      newState = { state: ORG_INVITE_ADMIN_ERROR, error: action.error };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }
  return newState;
};

export default organizationInviteAdminReducer;
