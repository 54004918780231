import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationInviteAdmin from "../components/organizationInviteAdmin/organizationInviteAdmin";
import { ROUTE_ORG_MANAGE_ADMIN } from "./RouteConstants";
import {
  inviteAdmin,
  ORG_INVITE_ADMIN_SUCCESS,
  ORG_INVITE_ADMIN_ERROR,
} from "../actions/organizationInviteAdmin.action";
import ManageAccountPopUp from "../components/orgManageAccounts/ManageAccountPopUp";
import OrganizationInviteAdminField from "../components/organizationInviteAdmin/OrganizationInviteAdminField";

class OrgCreateAdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addAnotherAdmin: null,
      saveInProgress: false,
    };

    this.inviteAdmin = this.inviteAdmin.bind(this);
    this.formRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_ADMIN_SUCCESS &&
      this.state.addAnotherAdmin === true
    ) {
      this.setState({ saveInProgress: false });
      this.formRef.current.addAnotherAdmin();
    }

    if (
      nextProps.organizationInviteAdminStatus !==
        this.props.organizationInviteAdminStatus &&
      nextProps.organizationInviteAdminStatus === ORG_INVITE_ADMIN_ERROR
    ) {
      this.setState({ saveInProgress: false });
    }
  }

  inviteAdmin(values, addAnotherAdmin) {
    this.setState({ addAnotherAdmin: addAnotherAdmin, saveInProgress: true });
    var org_id = this.props.location.search.split("?")[1];
    if (org_id !== undefined) values["org_id"] = org_id;
    this.props.inviteAdmin({ ...values, org_id: this.props.orgId });
  }

  onSkipForNow = () => this.props.history.push(ROUTE_ORG_MANAGE_ADMIN);

  render() {
    // if (
    //   this.props.organizationInviteAdminStatus === ORG_INVITE_ADMIN_SUCCESS &&
    //   this.state.addAnotherAdmin === false
    // ) {
    //   return <Redirect to={ROUTE_ORG_MANAGE_ADMIN} />;
    // }

    return (
      <div>
        <div className="container">
          {this.props.value === true ? (
            <OrganizationInviteAdminField
              ref={this.formRef}
              onSubmit={this.inviteAdmin}
              isSaveInProgress={this.state.saveInProgress}
              isBoardingOff={true}
              canSkip={true}
              onSkipForNow={this.onSkipForNow}
            />
          ) : (
            <div className="container-bg">
              <div className="container">
                <OrganizationInviteAdmin
                  ref={this.formRef}
                  onSubmit={this.inviteAdmin}
                  isSaveInProgress={this.state.saveInProgress}
                  isBoardingOff={true}
                  canSkip={true}
                  onSkipForNow={this.onSkipForNow}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizationInviteAdminStatus: state.organizationInviteAdmin.state,
  organizationInviteAdminError: state.organizationInviteAdmin.error,
});

const mapDispatchToProps = {
  inviteAdmin,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrgCreateAdminPage)
);
