import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationInviteSubadmin from "../components/organizationInviteSubadmin/organizationInviteSubadmin";
import {
  inviteSubadmin,
  ORG_INVITE_SUBADMIN_SUCCESS,
  ORG_INVITE_SUBADMIN_ERROR,
  updateSaveStateToNull,
} from "../actions/organizationInviteSubadmin.action";
import { ROUTE_ORG_MANAGE_DEPTADMIN } from "./RouteConstants";
import {
  getOrganizationDepartments,
  getDepartmentList,
} from "../actions/organizationDepartment.action";
import ManageAccountPopUp from "../components/orgManageAccounts/ManageAccountPopUp";
import OrganizationInviteSubadminField from "../components/organizationInviteSubadmin/OrganizationInviteSubadminField";

class OrgCreateDeptAdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addAnotherSubadmin: false,
      saveInProgress: false,
    };

    this.inviteSubadmin = this.inviteSubadmin.bind(this);
    this.getOrganizationDepartments =
      this.getOrganizationDepartments.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getOrganizationDepartments();
    this.props.getDepartmentList();
  }

  componentWillUnmount = () => {
    this.props.updateSaveStateToNull();
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organizationInviteSubadminStatus !==
        this.props.organizationInviteSubadminStatus &&
      nextProps.organizationInviteSubadminStatus === ORG_INVITE_SUBADMIN_SUCCESS
    ) {
      this.setState({ saveInProgress: false });

      if (this.state.addAnotherSubadmin === true) {
        this.formRef.current.addAnotherSubadmin();
      }
    }

    if (
      nextProps.organizationInviteSubadminStatus === ORG_INVITE_SUBADMIN_ERROR
    ) {
      this.setState({ saveInProgress: false });
    }
  }

  inviteSubadmin(values, addAnotherSubadmin) {
    this.setState({
      addAnotherSubadmin: addAnotherSubadmin,
      saveInProgress: true,
    });
    var org_id =
      this.props.location.search !== ""
        ? this.props.location.search.split("?")[1]
        : this.props.orgId;
    // var org_id = this.props.location.search.split("?")[1];
    if (org_id !== undefined) values["org_id"] = org_id;
    this.props.inviteSubadmin(values);
  }

  getOrganizationDepartments() {
    var org_id =
      this.props.location.search !== ""
        ? this.props.location.search.split("?")[1]
        : this.props.orgId;
    // var org_id = this.props.location.search.split("?")[1];
    if (org_id !== undefined)
      this.props.getOrganizationDepartments("?org_id=" + org_id);
    else this.props.getOrganizationDepartments();
  }

  onSkipForNow = () => this.props.history.push(ROUTE_ORG_MANAGE_DEPTADMIN);

  render() {
    // if (
    //   this.props.organizationInviteSubadminStatus ===
    //     ORG_INVITE_SUBADMIN_SUCCESS &&
    //   this.state.addAnotherSubadmin === false
    // ) {
    //   return <Redirect to={ROUTE_ORG_MANAGE_DEPTADMIN} />;
    // }

    return (
      <div>
        <div className="container">
          {this.props.value === true ? (
            <OrganizationInviteSubadminField
              ref={this.formRef}
              onSubmit={this.inviteSubadmin}
              isSaveInProgress={this.state.saveInProgress}
              allDepartments={this.props.departmentList}
              canSkip={this.props.canSkip}
              onSkipForNow={this.onSkipForNow}
              isBoardingOff={true}
              orgId={this.props.orgId}
            />
          ) : (
            <div className="container-bg">
              <div className="container">
                {" "}
                <OrganizationInviteSubadmin
                  ref={this.formRef}
                  onSubmit={this.inviteSubadmin}
                  isSaveInProgress={this.state.saveInProgress}
                  allDepartments={this.props.allDepartments}
                  canSkip={this.props.canSkip}
                  onSkipForNow={this.onSkipForNow}
                  isBoardingOff={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizationInviteSubadminStatus: state.organizationInviteSubadmin.state,
  organizationInviteSubadminError: state.organizationInviteSubadmin.error,
  allDepartments: state.organizationDepartment.departments,
  departmentList: state.organizationDepartment.departmentList,
  canSkip: state.onboardingStatus.canSkip,
});

const mapDispatchToProps = {
  inviteSubadmin,
  getOrganizationDepartments,
  updateSaveStateToNull,
  getDepartmentList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrgCreateDeptAdminPage)
);
