import React, { useState } from "react";
import OrgnizationProfilePage from "../../containers/OrgnizationProfilePage";
import Modal from "react-modal";
import CloseButton from "react-bootstrap/CloseButton";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "-20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid gray",
  },
};
function Addorganizationpopup(props) {
  const [openOrganization, setopenOrganization] = useState(true);
  const isClose = () => {
    props.onClose();
  };
  return (
    <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
      <OrgnizationProfilePage openPage={openOrganization} isClose={isClose} />
    </Modal>
  );
}

export default Addorganizationpopup;
