import {
  CREATEDEPARTMENTS_IN_PROGRESS,
  CREATEDEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_LIST_SUCCESS,
  ADD_NEW_DEPARTMENT,
  REMOVE_DEPARTMENT_FROM_LIST
} from "../actions/organizationDepartment.action";

const intialState = {
  createDepartmentsStatus: null,
  departments: [],
  departmentList: []
};

const organizationDepartment = (state = intialState, action) => {
  switch (action.type) {
    case CREATEDEPARTMENTS_SUCCESS:
      return {
        ...state,
        createDepartmentsStatus: CREATEDEPARTMENTS_SUCCESS
      };
    case CREATEDEPARTMENTS_IN_PROGRESS:
      return {
        ...state,
        createDepartmentsStatus: CREATEDEPARTMENTS_IN_PROGRESS
      };
    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.payload
      };
    case GET_DEPARTMENTS_LIST_SUCCESS:
      return {
        ...state,
        departmentList: action.payload
      };
    case ADD_NEW_DEPARTMENT:
      return {
        ...state,
        departments: [...state.departments, action.payload]
      };
    case REMOVE_DEPARTMENT_FROM_LIST:
      return {
        ...state,
        departments: [
          ...state.departments.slice(0, action.payload),
          ...state.departments.slice(action.payload + 1)
        ]
      };
    default:
      return state;
  }
};

export default organizationDepartment;
