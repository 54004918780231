import React from "react";
//import { formatPhoneNumber } from "react-phone-number-input";

export class ProviderCard extends React.Component {
  render() {
    return (
      <div
        className="col-4"
        onClick={() => this.props.updateCurrentProviderIndex(this.props.index)}
      >
        <div className="rectangle-info">
          <div className="row h-100">
            <div className="col-5 my-auto">
              {this.props.provider.profileImg ? (
                <div style={{ display: "flex" }}>
                  <div
                    className="org_provider_img"
                    style={{
                      backgroundImage: `url(${this.props.provider.profileImg})`
                    }}
                  />
                </div>
              ) : (
                <div className="user-pic" />
              )}
            </div>
            <div className="col-7">
              <div className="user-name text-left">{`${
                this.props.provider.firstName
              }${" "}${this.props.provider.lastName}`}</div>
              <div className="loc-info-txt">{this.props.provider.email}</div>
              <div className="loc-info-txt">
                {this.props.provider.contactPhone || ""}
              </div>
              <div className="loc-info-txt">{this.props.provider.location}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProviderCard;
