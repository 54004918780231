import React from "react";

class VideoAuthoring extends React.Component {
  constructor(props) {
    super(props);
    this.uploadNewPostVideoRef = React.createRef();
    this.state={fileName: ""}
  }

  upload_file = (event) =>{
    this.setState({fileName: event.target.files[0].name})
  }

  render() {
    return (
      <div>
        <div className="flex_display">
          <input
            type="text"
            name="videoTitle"
            placeholder="Enter title here..."
            onChange={this.props.inputChange}
            className="upload_title_input form-control form-input"
          />
          <input
            type="file"
            style={{ display: "none" }}
            ref={this.uploadNewPostVideoRef}
            className="upload_new_post_image form-control form-input"
            accept="video/*"
            onChange={this.upload_file}
          />
        </div>
        <div style={{textAlign: "center",marginBottom: "2%"}}>
          {this.state.fileName}
        </div>
        <div className="flex_display">
          <button
            type="button"
            className="btn upload-btn"
            id="btnVideoUpload"
            onClick={() => this.props.upload("Video")}
          >
            Upload Video Here
          </button>
        </div>
      </div>
    );
  }
}

export default VideoAuthoring;
