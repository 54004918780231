import React from "react";
import "./acceptInvite.css";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";

class AcceptInvite extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters long."),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "Password and confirm password do not match."
      ),
  });

  onSubmit(values) {
    this.props.acceptInvite(values);
  }

  renderForm = (props) => {
    const { handleSubmit, values } = props;
    const isAppRequestInProgress =
      this.props.loadingInvitationDetails || this.props.acceptInviteInProgress;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <label className="form-lable">Email</label>
          <input
            readOnly
            type="email"
            value={values.email}
            name="email"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="email" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Password</label>
          <Field
            type="password"
            value={values.password}
            name="password"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="password" />
          </span>
        </div>
        <div className="form-group form-lable">
          <label className="form-lable">Confirm password</label>
          <Field
            type="password"
            value={values.confirmPassword}
            name="confirmPassword"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="confirmPassword" />
          </span>
        </div>

        <div className="mt-5 text-right">
          <button
            type="submit"
            className="btn form-btn"
            id="btnLogin"
            disabled={isAppRequestInProgress}
          >
            Accept Invite
            {isAppRequestInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };

  render() {
    let initialValues = {
      email: "",
      password: "",
      confirmPassword: "",
    };

    if (this.props.invitationDetails) {
      initialValues.email = this.props.invitationDetails.email;
    }

    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>ACCEPT YOUR INVITATION...</h3>
          </div>
          <div className="form-container h-100" />
          <div className="row">
            <div className="col-4">
              <div className="form-lText ">
                Enter the password you will use to login to Healthswim.
              </div>
            </div>
            <div className="col-8">
              <div className="form-body">
                <Formik
                  ref={this.formik}
                  initialValues={initialValues}
                  onSubmit={this.onSubmit}
                  render={this.renderForm}
                  validationSchema={this.validationSchema}
                  enableReinitialize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AcceptInvite;
