import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const GET_SPECIALTY_LIST_SUCCESS = "GET_SPECIALTY_LIST_SUCCESS";

export const getCsv = (id) => (dispatch) => {
  apiMethods
    .get(`FeedSubscription/DownloadMailingList?specialityIds=${id}`)
    .then(
      (response) => {
        if (response.data.csvMailingData) {
          var res = response.data.csvMailingData;
          var csvData = new Blob([res]);
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(csvData, {
            type: "text/csv;charset=utf-8;",
          });
          a.download = "download.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          dispatch({ type: SHOW_ERROR, payload: "No CSV string provided" });
        }
      },
      (error) => {
        dispatch({
          type: SHOW_ERROR,
          payload: error.data.message || error.data.error,
        });
      }
    );
};

export const getSpecialities = () => (dispatch) => {
  apiMethods.post("Speciality").then(
    (response) => {
      dispatch({ type: GET_SPECIALTY_LIST_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};
