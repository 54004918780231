import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import FindContent from "../components/findContent/findContent";
import { loadSearchResults } from "../actions/search.action";
import {
  subscribeChannels,
  SUBSCRIBE_CHANNELS_SUCCESS,
  getSubscribedChannels,
  updateSubscribeStateToNull,
} from "../actions/subscribeChannel.action";
import { ROUTE_DASHBOARD } from "./RouteConstants";
import { unsubscribeChannel } from "../actions/channel.action";

class ManageMyContentPage extends React.Component {
  componentDidMount = () => {
    this.props.getSubscribedChannels();
  };

  loadSearchResults = (searchText) => {
    this.props.loadSearchResults(searchText);
  };

  subscribeToSelectedChannels = (selectedChannelIds) => {
    if (selectedChannelIds) {
      if (
        selectedChannelIds.length < 4 &&
        this.props.profile.securityMatrix.clientId
      ) {
        alert("please subscribe to three or more channels");
        return;
      }
      this.props.subscribeChannels(selectedChannelIds);
    }
  };

  componentWillUnmount = () => {
    this.props.updateSubscribeStateToNull();
  };
  unsubscribeChannel = (data) => {
    this.props.unsubscribeChannel(data);
  };
  render() {
    if (this.props.state === SUBSCRIBE_CHANNELS_SUCCESS) {
      return <Redirect to={ROUTE_DASHBOARD} />;
    }

    return (
      <div className="container-bg">
        <FindContent
          subscribedChannels={this.props.subscribedChannels}
          searchResults={this.props.searchResults}
          isLoading={this.props.isLoading}
          loadSearchResults={this.loadSearchResults}
          subscribeToSelectedChannels={this.subscribeToSelectedChannels}
          handleSkip={this.handleSkip}
          canSkip={false}
          onBoarding={false}
          unsubscribeChannel={this.unsubscribeChannel}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.searchResults.isLoading,
  searchResults: state.searchResults.results,
  state: state.subscribedChannels.state,
  subscribedChannels: state.subscribedChannels.subscribedChannels,
  profile: state.personalProfile.profile,
});

const mapDispatchToProps = {
  loadSearchResults,
  subscribeChannels,
  getSubscribedChannels,
  updateSubscribeStateToNull,
  unsubscribeChannel,
};
// const mapDispatchToProps = (dispatch) => ({
//   loadSearchResults: () => dispatch(loadSearchResults()),
//   subscribeChannels: () => dispatch(subscribeChannels()),
//   getSubscribedChannels: () => dispatch(getSubscribedChannels()),
//   updateSubscribeStateToNull: () => dispatch(updateSubscribeStateToNull()),
//   unsubscribeChannel: (data) => dispatch(unsubscribeChannel(data)),
// });

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageMyContentPage)
);
