import React, { Component } from "react";
import OrgManageProvider from "../components/orgManageProvider/orgManageProvider";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getProvidersForOrg,
  updateCurrentProviderIndex
} from "../actions/organizationManageProvider.actions";
import { getorganizationLocations } from "../actions/organizationLocation.action";
import {
  getDegrees,
  getSpecialities
} from "../actions/personalProfile.actions";
import { getAffiliations } from "../actions/affiliations.action";
import { getCertifications } from "../actions/certifications.action";
import { getConditionsTreated } from "../actions/conditionsTreated.action";
import { ROUTE_ORG_INVITE_PROVIDER_FROM_MANAGE } from "../containers/RouteConstants";
import { ROLE_ORGANIZATION_PHYSICIAN } from "../store/constants";
import { getMyDepartments } from "../actions/department.action";

class OrgManageProviderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  handleClose = () => this.setState({ show: false });

  createNewProvider = () =>
    this.props.history.push(ROUTE_ORG_INVITE_PROVIDER_FROM_MANAGE);

  componentWillMount = () => {
    this.props.getProvidersForOrg(ROLE_ORGANIZATION_PHYSICIAN);
    this.props.getDegrees();
    this.props.getSpecialities();
    this.props.getMyDepartments();
    this.props.getorganizationLocations();
    this.props.getAffiliations();
    this.props.getConditionsTreated();
    this.props.getCertifications();
  };

  updateCurrentProviderIndex = index => {
    this.props.updateCurrentProviderIndex(index);
    this.setState({ show: true });
  };

  render() {
    return (
      <div className="container-white-bg">
        <div className="container">
          <OrgManageProvider
            organizationProviders={this.props.organizationProviders}
            currentProviderIndex={this.props.currentProviderIndex}
            updateCurrentProviderIndex={this.updateCurrentProviderIndex}
            degrees={this.props.degrees}
            specialities={this.props.specialities}
            departments={this.props.departments}
            locations={this.props.locations}
            certifications={this.props.certifications}
            affiliations={this.props.affiliations}
            conditionsTreated={this.props.conditionsTreated}
            show={this.state.show}
            handleClose={this.handleClose}
            createNewProvider={this.createNewProvider}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationProviders:
      state.organizationManageProvider.organizationProviders,
    currentProviderIndex: state.organizationManageProvider.currentProviderIndex,
    certifications: state.certifications.certificates,
    conditionsTreated: state.conditionsTreated.conditionsTreated,
    affiliations: state.affiliations.affiliations,
    degrees: state.personalProfile.degrees,
    specialities: state.personalProfile.specialities,
    departments: state.departmentReducer.myDepartments,
    locations: state.organizationLocation.locations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProvidersForOrg: role => dispatch(getProvidersForOrg(role)),
    updateCurrentProviderIndex: index =>
      dispatch(updateCurrentProviderIndex(index)),
    getMyDepartments: () => dispatch(getMyDepartments()),
    getorganizationLocations: () => dispatch(getorganizationLocations()),
    getDegrees: () => dispatch(getDegrees()),
    getSpecialities: () => dispatch(getSpecialities()),
    getAffiliations: () => dispatch(getAffiliations()),
    getConditionsTreated: () => dispatch(getConditionsTreated()),
    getCertifications: () => dispatch(getCertifications())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrgManageProviderPage)
);
