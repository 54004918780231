import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationInviteSubadmin from "../components/organizationInviteSubadmin/organizationInviteSubadmin";
import {
  inviteSubadmin,
  ORG_INVITE_SUBADMIN_SUCCESS,
  ORG_INVITE_SUBADMIN_ERROR,
} from "../actions/organizationInviteSubadmin.action";
import {
  ROUTE_ORG_INVITE_SUBADMIN,
  ROUTE_ORG_DEPARTMENT,
} from "./RouteConstants";
import { getOrganizationDepartments } from "../actions/organizationDepartment.action";
import { getPrevStep } from "../actions/onboardingStatus.actions";

class OrganizationInviteSubadminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addAnotherSubadmin: false,
      saveInProgress: false,
    };

    this.inviteSubadmin = this.inviteSubadmin.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.getOrganizationDepartments();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organizationInviteSubadminStatus !==
        this.props.organizationInviteSubadminStatus &&
      nextProps.organizationInviteSubadminStatus ===
        ORG_INVITE_SUBADMIN_SUCCESS &&
      this.state.addAnotherSubadmin === true
    ) {
      this.setState({ saveInProgress: false });
      this.formRef.current.addAnotherSubadmin();
    }

    if (
      nextProps.organizationInviteSubadminStatus === ORG_INVITE_SUBADMIN_ERROR
    ) {
      this.setState({ saveInProgress: false });
    }
  }

  getPrevStep = () => {
    this.props.getPrevStep();
  };

  inviteSubadmin(values, addAnotherSubadmin) {
    this.setState({
      addAnotherSubadmin: addAnotherSubadmin,
      saveInProgress: true,
    });
    this.props.inviteSubadmin(values);
  }

  onSkipForNow = () =>
    this.props.history.push(`/next-step${ROUTE_ORG_INVITE_SUBADMIN}`);

  render() {
    if (
      this.props.prevStep !== null &&
      this.props.prevStep.name === "ORGANIZATION-DEPARTMENTS"
    ) {
      return <Redirect to={ROUTE_ORG_DEPARTMENT} />;
    }
    if (
      this.props.organizationInviteSubadminStatus ===
        ORG_INVITE_SUBADMIN_SUCCESS &&
      this.state.addAnotherSubadmin === false
    ) {
      return <Redirect to={"/next-step" + ROUTE_ORG_INVITE_SUBADMIN} />;
    }

    return (
      <div className="container-bg">
        <div className="container">
          <OrganizationInviteSubadmin
            ref={this.formRef}
            onSubmit={this.inviteSubadmin}
            isSaveInProgress={this.state.saveInProgress}
            allDepartments={this.props.allDepartments}
            canSkip={this.props.canSkip}
            onSkipForNow={this.onSkipForNow}
            getPrevStep={this.getPrevStep}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizationInviteSubadminStatus: state.organizationInviteSubadmin.state,
  organizationInviteSubadminError: state.organizationInviteSubadmin.error,
  allDepartments: state.organizationDepartment.departments,
  canSkip: state.onboardingStatus.canSkip,
  prevStep: state.onboardingStatus.prevStep,
});

const mapDispatchToProps = (dispatch) => ({
  inviteSubadmin: (data) => dispatch(inviteSubadmin(data)),
  getOrganizationDepartments: () => dispatch(getOrganizationDepartments()),
  getPrevStep: () =>
    dispatch(getPrevStep("?currentStep=ORGANIZATION-INVITE-SUBADMIN")),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationInviteSubadminPage)
);
