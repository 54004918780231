import React, { Component } from "react";
import "./findContent.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

export default class FindContent extends Component {
  constructor(props) {
    super(props);

    const copyOfInputContent = Object.assign([], props.content);

    this.state = {
      enteredContentName: "",
      content: copyOfInputContent,
      nameToBeRemoved: "",
      subscribedChannels: [],
    };

    this.handleNewContentNameChange =
      this.handleNewContentNameChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.search = this.search.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.removeSelectedChannel = this.removeSelectedChannel.bind(this);
    this.onSkipForNow = this.onSkipForNow.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.subscribedChannels !== this.props.subscribedChannels) {
      const copyOfSubscribedChannels = Object.assign(
        [],
        nextProps.subscribedChannels
      );
      this.setState({ subscribedChannels: copyOfSubscribedChannels });
    }
  }

  goLastStep = () => {
    this.props.getPrevStep();
  };

  handleNewContentNameChange = function (event) {
    this.setState({ enteredContentName: event.target.value });
  };

  onKeyPress = function (event) {
    if (event.key === "Enter") {
      const copyOfContent = Object.assign([], this.state.content);
      copyOfContent.push({ name: this.state.enteredContentName, id: null });
      this.setState({ content: copyOfContent, enteredContentName: "" });
    }
  };

  search = function (searchText) {
    this.props.loadSearchResults(searchText);
  };

  handleSelectionChange = function (selectedChannels) {
    this.setState({ selectedChannels: selectedChannels });
  };

  saveSelectedChannels = function () {
    var newSelectedChannelIds = this.state.selectedChannels
      ? this.state.selectedChannels.map((ch) => ch.channelId)
      : [];
    var remainingOldChannelIds = this.state.subscribedChannels
      ? this.state.subscribedChannels.map((ch) => ch.id)
      : [];
    var finalList = [...newSelectedChannelIds, ...remainingOldChannelIds];
    this.props.subscribeToSelectedChannels(finalList);
  };

  removeSelectedChannel(channel) {
    const indexOfRemovedChannel =
      this.state.subscribedChannels.indexOf(channel);

    this.props.unsubscribeChannel(channel.id);
    if (indexOfRemovedChannel >= 0) {
      let copyOfSubscribedChannels = [];
      Object.assign(copyOfSubscribedChannels, this.state.subscribedChannels);
      copyOfSubscribedChannels.splice(indexOfRemovedChannel, 1);
      this.setState({ subscribedChannels: copyOfSubscribedChannels });
    }
  }

  onSkipForNow() {
    this.props.handleSkip();
  }

  render() {
    const contentList = this.state.subscribedChannels.map((eachContent, i) => {
      return (
        <div className="pill mr-2" key={i} style={{ minHeight: "28px" }}>
          <span style={{ lineHeight: "normal" }}>
            {eachContent.displayName} {"/"} {eachContent.provider.firstName}{" "}
            {eachContent.provider.lastName}
          </span>

          {eachContent.searchText.toLowerCase() !== "healthswim" ? (
            <button
              tabIndex="-1"
              aria-label="Remove"
              className="remove-btn"
              id={eachContent.id}
              onClick={() => this.removeSelectedChannel(eachContent)}
            >
              x
            </button>
          ) : null}
        </div>
      );
    });

    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>Let's find some content...</h3>
            <p>Pick some channels to kick start your content feed.</p>
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText">
                  Search for anything - providers, hospitals, organizations,
                  conditions, treatments and more. The channels you choose help
                  to curate your feed and can be edited any time.
                </div>
                <div className="oval feed-oval" />
              </div>
              <div className="col-8">
                <div className="form-body">
                  <div className="form-group form-lable">
                    <label className="form-lable">
                      Type in any subject you’re interested in and choose from
                      the pop up list. Follow as many as you like.
                    </label>
                    <div className="input-group selectChannel">
                      <AsyncTypeahead
                        labelKey="matchTextWithAuthor"
                        minLength={3}
                        multiple
                        allowNew={false}
                        onSearch={(args) => this.search(args)}
                        isLoading={this.props.isLoading}
                        options={this.props.searchResults}
                        onChange={(selected) =>
                          this.handleSelectionChange(selected)
                        }
                      />
                      <div className="input-group-append">
                        <button className="input-icon" type="button">
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>

                    <div className="list-container">{contentList}</div>
                  </div>

                  <div className="mt-10 text-right">
                    {/* chnages */}
                    {/* {this.props.onBoarding && (
                      <button
                        type="button"
                        className="btn mr-3 pull-left form-btn"
                        onClick={this.goLastStep}
                      >
                        Back
                      </button>
                    )} */}
                    {this.props.canSkip ? (
                      <span
                        className="form-link  mr-5"
                        onClick={() => this.onSkipForNow()}
                        style={{ cursor: "pointer" }}
                      >
                        Skip for now
                      </span>
                    ) : null}

                    <button
                      type="submit"
                      className="btn form-btn"
                      id="btnLogin"
                      disabled={this.props.saveInProgress}
                      onClick={() => this.saveSelectedChannels()}
                    >
                      {!this.props.onBoarding ? "Done" : "Next"}
                      {this.props.saveInProgress === true ? (
                        <span className="text-right pl-2">
                          <i
                            className="fa fa-circle-o-notch fa-spin  "
                            style={{ color: "#fff" }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
