import * as ActionTypes from "../actions";
import merge from "lodash/merge";
import paginate from "./paginate";
import { combineReducers } from "redux";
import login from "../reducers/login.reducer";
import register from "../reducers/register.reducer";
import registerinfo from "../reducers/registerinfo.reducer";
import error from "../reducers/error.reducer";
import publishContent from "../reducers/publishContentReducer";
import personalProfile from "../reducers/personalProfile.reducer";
import professionalInfoReducer from "../reducers/professionalInfo.reducer";
import conditionsTreated from "../reducers/conditionstreated.reducer";
import certifications from "../reducers/boardcertificates.reducer";
import affiliations from "../reducers/affiliation.reducer";
import channelSetup from "../reducers/channelSetup.reducer";
import privacyPolicy from "../reducers/privacyPolicy.reducer";

import termsConditions from "../reducers/termsConditions.reducer";
import onboardingStatus from "../reducers/onboarding.reducer";
import channelReducer from "../reducers/channel.reducer";
import channelData from "../reducers/newchanneldata.reducer";
import organizationProfile from "../reducers/organizationProfile.reducer";
import timeLineReducer from "../reducers/timeLine.reducer";
import searchResults from "../reducers/searchResults.reducer";
import subscribedChannels from "../reducers/subscribedChannels.reducer";
import filterReducer from "../reducers/filter.reducer";
import organizationInviteAdmin from "../reducers/organizationInviteAdmin.reducer";
import organizationInviteSubadmin from "../reducers/organizationInviteSubadmin.reducer";
import organizationInviteProvider from "../reducers/organizationInviteProvider.reducer";
import organizationLocation from "../reducers/organizationLocation.reducer";
import organizationDepartment from "../reducers/organizationDepartments.reducer";
import acceptInvite from "../reducers/acceptInvite.reducer";
import organizationManageProvider from "../reducers/organizationManageProvider.reducer";
import deptAdminReducer from "./deptAdmin.reducer";
import departmentReducer from "./department.reducer";
import ClientProfile from "./clientProfile.reducer";
import organizationAdmins from "./organizationAdmin.reducer";
import postShareReducer from "./postShare.reducer";
import orgManageLists from "./orgManageLists.reducer";
import MailingList from "./orgMailingList.reducer";
import orgManageAccounts from "./orgManageAccounts.reducer";
import premiumAccount from "./premium.reducer";

// Updates an entity cache in response to any action with response.entities.
const entities = (state = { users: {}, repos: {} }, action) => {
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  return state;
};

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type, error } = action;

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  } else if (error) {
    return error;
  }

  return state;
};

// Updates the pagination data for different actions.
const pagination = combineReducers({
  starredByUser: paginate({
    mapActionToKey: (action) => action.login,
    types: [
      ActionTypes.STARRED_REQUEST,
      ActionTypes.STARRED_SUCCESS,
      ActionTypes.STARRED_FAILURE,
    ],
  }),
  stargazersByRepo: paginate({
    mapActionToKey: (action) => action.fullName,
    types: [
      ActionTypes.STARGAZERS_REQUEST,
      ActionTypes.STARGAZERS_SUCCESS,
      ActionTypes.STARGAZERS_FAILURE,
    ],
  }),
});

const rootReducer = combineReducers({
  entities,
  pagination,
  errorMessage,
  login,
  register,
  registerinfo,
  error,
  publishContent,
  personalProfile,
  professionalInfoReducer,
  affiliations,
  certifications,
  conditionsTreated,
  channelSetup,
  privacyPolicy,

  termsConditions,
  onboardingStatus,
  channelReducer,
  channelData,
  organizationProfile,
  timeLineReducer,
  searchResults,
  subscribedChannels,
  filterReducer,
  organizationInviteAdmin,
  organizationInviteSubadmin,
  organizationLocation,
  organizationDepartment,
  organizationInviteProvider,
  acceptInvite,
  organizationManageProvider,
  deptAdminReducer,
  departmentReducer,
  ClientProfile,
  organizationAdmins,
  postShareReducer,
  orgManageLists,
  MailingList,
  orgManageAccounts,
  premiumAccount,
});

export default rootReducer;
