import React from "react";
import "./Accept.css";
import tick from "../img/symbol.png";
import { Link } from "react-router-dom";
function Acceptinvitesuccepage() {
  return (
    <div className="">
      <div className="form-rectangle popup">
        <img src={tick} />
        <h2 style={{ color: "#00e5ff" }}>Thank You!</h2>
        <p>Your details has been successfully submitted. Now you can login</p>

        <Link
          // to="/login"
          to="/loginPage"
          style={{ textDecoration: "none", color: "white", margin: "auto" }}
        >
          <div className="buttons">Login</div>
        </Link>
      </div>
    </div>
  );
}

export default Acceptinvitesuccepage;
