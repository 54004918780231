import {
  SAVE_PROFESSIONAL_INFO_ERROR,
  SAVE_PROFESSIONAL_INFO_IN_PROGRESS,
  SAVE_PROFESSIONAL_INFO_SUCCESS,
  GET_PROFESSIONAL_INFO_SUCCESS
} from "../actions/professionalInfo.actions";

const initialState = {
  npiNumber: "",
  boardCertifications: [],
  conditionsTreated: [],
  saveState: null,
  getSate: null,
  error: null,
  certifications: [],
  affiliations: [],
  professionalInfo: null
};

const professionalInfoReducer = (state = initialState, action) => {
  let newState = {};

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case SAVE_PROFESSIONAL_INFO_IN_PROGRESS: {
      newState = {
        ...state,
        saveState: SAVE_PROFESSIONAL_INFO_IN_PROGRESS,
        error: null
      };
      break;
    }
    case SAVE_PROFESSIONAL_INFO_SUCCESS: {
      newState = {
        ...state,
        ...action.payload,
        saveState: SAVE_PROFESSIONAL_INFO_SUCCESS,
        error: null
      };
      break;
    }
    case SAVE_PROFESSIONAL_INFO_ERROR: {
      newState = {
        ...state,
        saveState: SAVE_PROFESSIONAL_INFO_ERROR,
        error: action.error
      };
      break;
    }
    case GET_PROFESSIONAL_INFO_SUCCESS: {
      newState = {
        ...state,
        professionalInfo: action.payload,
        getSate: GET_PROFESSIONAL_INFO_SUCCESS,
        error: null
      };
    }
  }

  return newState;
};

export default professionalInfoReducer;
