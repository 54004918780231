import React, { Component } from "react";

export default class ManageAccount extends Component {
  render() {
    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>WHO WILL MANAGE YOUR ACCOUNT…</h3>
            <p>
              Your primary admin has the ability to manage all facets of your
              account including other providers.
            </p>
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText ">
                  If you have a pic of them this is the place to put it (don’t
                  worry, they can add or change their own later.)
                </div>
                <div className="oval" />
              </div>
              <div className="col-8">
                <div className="form-body">
                  <div className="form-group form-lable">
                    <label className="form-lable">First name</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group form-lable">
                    <label className="form-lable">Last name</label>
                    <input className="form-control form-input" />
                  </div>

                  <div className="form-group">
                    <label className="form-lable">Phone</label>
                    <input className="form-control form-input" />
                  </div>
                  <div className="form-group">
                    <label className="form-lable">Email</label>
                    <input className="form-control form-input" />
                  </div>

                  <div className="row mt-4 no-gutter">
                    <div className="col-md-4" />
                    <div className="col-md-2">
                      <div className="connect" style={{ margin: "0 auto" }}>
                        <i class="fas fa-user-alt" />
                      </div>
                    </div>
                    <div className="col-md-6 align-self-center">
                      <span style={{ cursor: "pointer" }} className="form-link">
                        Add another admin
                      </span>
                    </div>
                  </div>

                  <div className="mt-5 text-right">
                    <button
                      type="submit"
                      className="btn form-btn"
                      id="btnLogin"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
