import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import AboutMe from "../components/aboutMe/aboutMe";
import {
  savePersonalProfile,
  setProfilePic,
  getPersonalProfile,
  SAVE_PERSONAL_INFO_IN_PROGRESS,
  SAVE_PERSONAL_INFO_SUCCESS,
  updateProfileImg,
} from "../actions/personalProfile.actions";
import { updateOnBoardingStatus } from "../actions/onboardingStatus.actions";
import { ROUTE_ORG_ABOUTME } from "./RouteConstants";

class AboutMePage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.props.getPersonalProfile(this.props.email);
  }

  saveProfile = (data) => {
    this.props.savePersonalProfile(data);
  };

  saveProfieImg = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let formData = new FormData();
      let data = {};
      formData.append("file", event.target.files[0]);
      data["formData"] = formData;
      data["file"] = file;
      this.props.setProfilePic(data);
    }
  };

  render() {
    const isPersonalInfoSaveInProgress =
      this.props.saveState === SAVE_PERSONAL_INFO_IN_PROGRESS;

    if (this.props.saveState === SAVE_PERSONAL_INFO_SUCCESS) {
      return <Redirect to={"/next-step" + ROUTE_ORG_ABOUTME} />;
    }

    return (
      <div className="container-bg">
        <AboutMe
          saveProfile={this.saveProfile}
          saveProfileImg={this.saveProfieImg}
          profileImg={this.props.profileImg}
          personalProfile={this.props.personalProfile}
          isSaveInProgress={isPersonalInfoSaveInProgress}
          updateProfileImg={this.props.updateProfileImg}
          canSkip={this.props.canSkip}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveState: state.personalProfile.saveState,
  profileImg: state.personalProfile.profileImg,
  personalProfile: state.personalProfile.profile,
  canSkip: state.onboardingStatus.canSkip,
  email: state.login.email,
});

const mapDispatchToProps = {
  savePersonalProfile,
  setProfilePic,
  updateOnBoardingStatus,
  getPersonalProfile,
  updateProfileImg,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AboutMePage)
);
