import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

class MailingList extends React.Component {
  onSubmit = (values) => {
    var SpecialityIds = [];
    values.specialities.forEach((element) => {
      SpecialityIds.push(element.id);
    });

    this.props.getCsv(SpecialityIds);
  };

  validationSchema = Yup.object().shape({
    // specialities: Yup.string().required(`Specialty is required`),
    specialities: Yup.array().min(1, "Specialty is required"),
  });

  renderForm = (props) => {
    const { handleSubmit, values } = props;
    return (
      <form onSubmit={handleSubmit} style={{ marginTop: "25%" }}>
        <div className="row" style={{ marginLeft: 0 }}>
          <div
            className="form-group form-lable col-4"
            style={{ paddingLeft: 0, margin: "auto" }}
          >
            <label className="form-lable">Select Specialty</label>
            <Typeahead
              id="basic-typeahead-multiple"
              options={this.props.specialtyList}
              selected={values.name}
              labelKey="name"
              placeholder="select specialty"
              name="specialities"
              onChange={(selectedOptions) => {
                props.setFieldValue("specialities", selectedOptions);
                // props.setFieldTouched("specialities", true, true);
              }}
              onBlur={() => {
                props.setFieldTouched("specialities", true, true);
              }}
              multiple
            />
            <span className="error_text">
              <ErrorMessage name="specialities" />
            </span>
          </div>
          <div style={{ display: "flex", margin: "auto" }} className="col-4">
            <button
              type="submit"
              className="btn form-btn"
              style={{ margin: "auto", height: "40px" }}
            >
              Download
            </button>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>EXPORT TO MAILING LIST</h3>
          </div>
          <div className="form-container h-100">
            <div className="form-body">
              <Formik
                initialValues={{
                  specialities: [],
                }}
                onSubmit={this.onSubmit}
                render={this.renderForm}
                validationSchema={this.validationSchema}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MailingList;
