import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import ChannelSetup from "../components/channelSetup/channelSetup";
import {
  getSpecialityChannels,
  updateSpecialityChannels,
  channelBulkUpdate,
  BULK_UPDATE_CHANNELS_SUCCESS,
} from "../actions/channelsetup.actions";
import { ROUTE_CHANNEL_SETUP } from "./RouteConstants";

class ChannelSetupPage extends Component {
  componentWillMount() {
    this.props.getSpecialityChannels();
  }

  updateSpeclaityChannels = (data) => {
    this.props.updateSpecialityChannels(data);
  };

  bulkUpdate = () => {
    var channelArr = this.props.specialityChannels.map((el) => el.name);
    console.log("channelArr1", channelArr);
    this.props.channelBulkUpdate({ name: channelArr });
  };

  handleSkip = () =>
    this.props.history.push("/next-step" + ROUTE_CHANNEL_SETUP);

  render() {
    if (this.props.channelUpdateState === BULK_UPDATE_CHANNELS_SUCCESS) {
      return <Redirect to={"/next-step" + ROUTE_CHANNEL_SETUP} />;
    }

    return (
      <div className="container-bg">
        <ChannelSetup
          channels={this.props.specialityChannels}
          updateSpeclaityChannels={this.updateSpeclaityChannels}
          bulkUpdate={this.bulkUpdate}
          handleSkip={this.handleSkip}
          channelUpdateState={this.props.channelUpdateState}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialityChannels: state.channelSetup.specialityChannels,
  channelUpdateState: state.channelSetup.channelUpdateState,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialityChannels: () => dispatch(getSpecialityChannels()),
  updateSpecialityChannels: (data) => dispatch(updateSpecialityChannels(data)),
  channelBulkUpdate: (data) => dispatch(channelBulkUpdate(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChannelSetupPage)
);
