import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationLocation from "../components/organizationLocation/organizationLocation";
import { getSpecialities } from "../actions/personalProfile.actions";
import {
  setOrganizationLocation,
  updateAddAnotherLocation,
  ADD_ANOTHER_LOCATION,
  SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS,
  UpdateSaveStateToNull,
  SET_ORGANIZATIONLOCATION_IN_PROGRESS
} from "../actions/organizationLocation.action";
import { ROUTE_ORG_MANAGE_LOCATION } from "../containers/RouteConstants";
import { getOrganizationType } from "../actions/organizationProfile.actions";

class OrgLocationFromManage extends React.Component {
  constructor(props) {
    super(props);
    this.locationRef = React.createRef();
  }

  componentWillMount() {
    this.props.getSpecialities();
    this.props.getOrganizationType();
  }

  componentDidUpdate = () => {
    if (this.props.saveLocationState === ADD_ANOTHER_LOCATION) {
      this.props.UpdateSaveStateToNull();
      this.locationRef.current.addAnotherLocation();
    }
  };

  onSkipForNow = () => this.props.history.push(ROUTE_ORG_MANAGE_LOCATION);

  componentWillUnmount = () => {
    this.props.UpdateSaveStateToNull();
  };

  render() {
    const isSaveInProgress =
      this.props.saveLocationState === SET_ORGANIZATIONLOCATION_IN_PROGRESS;

    if (
      this.props.saveLocationState ===
      SET_ORGANIZATIONLOCATION_FROM_MANAGE_SUCCESS
    ) {
      return <Redirect to={ROUTE_ORG_MANAGE_LOCATION} />;
    }

    return (
      <div className="container-bg">
        <OrganizationLocation
          ref={this.locationRef}
          specialities={this.props.specialities}
          setOrganizationLocation={this.props.setOrganizationLocation}
          updateAddAnotherLocation={this.props.updateAddAnotherLocation}
          addAnotherLocation={this.props.addAnotherLocation}
          skipForNow={this.skipForNow}
          isSaveInProgress={isSaveInProgress}
          canSkip={true}
          onSkipForNow={this.onSkipForNow}
          onBoarding={false}
          orgTypes={this.props.orgTypes}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  specialities: state.personalProfile.specialities,
  addAnotherLocation: state.organizationLocation.addAnotherLocation,
  saveLocationState: state.organizationLocation.saveLocationState,
  canSkip: state.onboardingStatus.canSkip,
  orgTypes: state.organizationProfile.orgTypes
});

const mapDispatchToProps = dispatch => ({
  getSpecialities: () => dispatch(getSpecialities()),
  setOrganizationLocation: data => dispatch(setOrganizationLocation(data)),
  updateAddAnotherLocation: data => dispatch(updateAddAnotherLocation(data)),
  UpdateSaveStateToNull: () => dispatch(UpdateSaveStateToNull()),
  getOrganizationType: () => dispatch(getOrganizationType())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrgLocationFromManage)
);
