import React from "react";
import TImelineFilters from "../timelineFilters/timelineFilters";
import InfiniteScroll from "react-infinite-scroller";
import IndividualPost from "../individualPost/individualPost";
import "./timeline.css";
import Footer from "../Footer/footer";
import Spinner from "./Spinner";

export default class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }
  componentWillMount = () => {
    this.props.getFeedForTimeLine({
      all: true,
      from: 1,
      size: 10,
    });
  };
  loadfeed = () => {
    if (this.props.pageNumber < this.props.totalPages) {
      if (this.props.timelineFilterType === "deletedPosts") {
        this.props.getDeletedPosts({
          from: this.props.pageNumber + 1,
          size: 10,
        });
      } else {
        this.props.getFeedForTimeLine({
          all: true,
          from: 1,
          size: 10,
        });
      }
    }
  };
  loadFunc = () => {
    if (this.props.pageNumber < this.props.totalPages) {
      this.setState({ loader: true });
      if (this.props.timelineFilterType === "deletedPosts") {
        this.props.getDeletedPosts({
          from: this.props.pageNumber + 1,
          size: 10,
        });
      } else {
        this.props.getFeedForTimeLine({
          MyPostsOnly:
            this.props.timelineFilterType === "myPost" ? true : false,
          all: this.props.timelineFilterType === "all" ? true : false,
          channelIds:
            this.props.timelineFilterType === "myChannels"
              ? this.props.currentFeedChannel
              : [],
          savedPostsOnly:
            this.props.timelineFilterType === "savedPost" ? true : false,
          from: this.props.pageNumber + 1,
          size: 10,
        });
      }
    } else {
      this.setState({ loader: false });
    }
  };

  render() {
    return (
      <div className=" page">
        <div className="row newsfeed-container">
          <div className="col-3 my-auto">
            <div className="newsfeed-sidebar newsfeed-sidebar-left newsfeed-sidebar-left-3-column">
              <TImelineFilters
                myChannels={this.props.myChannels}
                subscribedChannels={this.props.subscribedChannels}
                getFeedForTimeLine={this.props.getFeedForTimeLine}
                timelineFilterType={this.props.timelineFilterType}
                setTimeLineType={this.props.setTimeLineType}
                pageNumber={this.props.pageNumber}
                setTimeLineCurrentChannel={this.props.setTimeLineCurrentChannel}
                updateChnannelStatus={this.props.updateChnannelStatus}
                createNewChannel={this.props.createNewChannel}
                unsubscribeChannel={this.props.unsubscribeChannel}
                profile={this.props.profile}
                myDepartments={this.props.myDepartments}
                getDeletedPosts={this.props.getDeletedPosts}
              />
              <div
                style={{
                  margin: "10px 10px 0px 10px",
                  position: "fixed",
                  // top: "67px",
                  bottom: "0",
                }}
              >
                <Footer />
              </div>
            </div>
          </div>

          <div className="col-5 ">
            <div
              style={{
                height: "393px",
                marginTop: "18px",
                paddingRight: "13px",
                marginRight: "37px",
                width: "100%",
                marginLeft: "-24px",
              }}
              className=" newsfeed-content-pane"
              id="Feed_div"
            >
              <InfiniteScroll
                pageStart={1}
                loadMore={this.loadFunc}
                hasMore={true}
                initialLoad={false}
                loader={this.state.loader === true ? <Spinner /> : ""}
                next={this.loadFunc}
              >
                {this.props.feed && this.props.feed.length > 0 ? (
                  this.props.feed.map((item, i) => {
                    switch (this.props.timelineFilterType) {
                      case "myChannels":
                        if (
                          this.props.myChannels.filter(
                            (c) => item.channelId === c.id
                          ).length > 0 &&
                          this.props.myChannels.filter(
                            (c) => item.channelId === c.id
                          )[0].isActive
                        )
                          return (
                            <div key={"post" + i} className="individual_post">
                              <IndividualPost
                                item={item}
                                index={i}
                                toggleLikeForPost={this.props.toggleLikeForPost}
                                savePost={this.props.savePost}
                                removePost={this.props.removePost}
                                channels={this.props.myChannels}
                                sharePost={this.props.sharePost}
                                profile={this.props.profile}
                                timelineFilterType={
                                  this.props.timelineFilterType
                                }
                                // deletePost={this.props.deletePost}
                                getFeedForTimeLine={
                                  this.props.getFeedForTimeLine
                                }
                                success={this.props.success}
                                loadMore={this.loadfeed}
                              />
                            </div>
                          );
                        break;
                      case "savedPost":
                        if (item.saved)
                          return (
                            <div key={"post" + i} className="individual_post">
                              {console.log("itemss", item)}
                              <IndividualPost
                                item={item}
                                index={i}
                                toggleLikeForPost={this.props.toggleLikeForPost}
                                savePost={this.props.savePost}
                                removePost={this.props.removePost}
                                channels={this.props.myChannels}
                                sharePost={this.props.sharePost}
                                profile={this.props.profile}
                                timelineFilterType={
                                  this.props.timelineFilterType
                                }
                                // deletePost={this.props.deletePost}
                                getFeedForTimeLine={
                                  this.props.getFeedForTimeLine
                                }
                                success={this.props.success}
                                loadMore={this.loadfeed}
                              />
                            </div>
                          );
                        break;
                      default:
                        return (
                          <div key={"post" + i} className="individual_post">
                            {console.log("itemss", item)}
                            <IndividualPost
                              item={item}
                              index={i}
                              toggleLikeForPost={this.props.toggleLikeForPost}
                              savePost={this.props.savePost}
                              removePost={this.props.removePost}
                              channels={this.props.myChannels}
                              sharePost={this.props.sharePost}
                              profile={this.props.profile}
                              timelineFilterType={this.props.timelineFilterType}
                              // deletePost={this.props.deletePost}
                              getFeedForTimeLine={this.props.getFeedForTimeLine}
                              success={this.props.success}
                              loadMore={this.loadfeed}
                            />
                          </div>
                        );
                    }
                  })
                ) : (
                  <div className="noFeedText">No Feed Found</div>
                )}
              </InfiniteScroll>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    );
  }
}
