import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

export const GET_DEGREES_SUCCESS = "GET_DEGREES_SUCCESS";
export const GET_SPECIALITIES_SUCCESS = "GET_SPECIALITIES_SUCCESS";

export const SAVE_PERSONAL_INFO_SUCCESS = "SAVE_PERSONAL_INFO_SUCCESS";
export const SAVE_PERSONAL_INFO_ERROR = "SAVE_PERSONAL_INFO_ERROR";
export const SAVE_PERSONAL_INFO_IN_PROGRESS = "SAVE_PERSONAL_INFO_IN_PROGRESS";

export const SAVE_PROFILE_IMG_SUCCESS = "SAVE_PROFILE_IMG_SUCCESS";

export const GET_PERSONAL_PROFILE_REQUEST = "GET_PERSONAL_PROFILE_REQUEST";
export const GET_PERSONAL_PROFILE_SUCCESS = "GET_PERSONAL_PROFILE_SUCCESS";
export const GET_PERSONAL_PROFILE_ERROR = "GET_PERSONAL_PROFILE_ERROR";

export const UPDATE_PROFILE_IMG_SUCCESS = "UPDATE_PROFILE_IMG_SUCCESS";

export const ORGANIZATION_PROFILE_SUCCESS = "ORGANIZATION_PROFILE_SUCCESS";
export const GET_SUPER_ADMIN = "GET_SUPER_ADMIN";
export const getDegrees = () => (dispatch) => {
  apiMethods
    .get("Degree")
    .then((response) => {
      dispatch({ type: GET_DEGREES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error && error.message });
    });
};

export const savePersonalProfile = (data) => (dispatch) => {
  dispatch({ type: SAVE_PERSONAL_INFO_IN_PROGRESS });

  apiMethods
    .post("ProviderPersonalProfile/ProviderPersonal", data)
    .then((response) => {
      dispatch({ type: SAVE_PERSONAL_INFO_SUCCESS, payload: response.data });
      getPersonalProfile()(dispatch);
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
      dispatch({ type: SAVE_PERSONAL_INFO_ERROR, payload: error.message });
    });
};

export const setProfilePic = (data) => (dispatch) => {
  return apiMethods
    .post("ProfilePic", data.formData)
    .then((response) => {
      dispatch({ type: SAVE_PROFILE_IMG_SUCCESS, payload: data.file });
      setTimeout(
        dispatch({
          type: SHOW_ERROR,
          payload: "Profile picture was successfully updated.",
        }),
        2000
      );
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};

export const getSpecialities = () => (dispatch) => {
  return apiMethods
    .post("Speciality")
    .then((response) => {
      dispatch({ type: GET_SPECIALITIES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error && error.message });
    });
};

export const getPersonalProfile = (data) => (dispatch) => {
  dispatch({ type: GET_PERSONAL_PROFILE_REQUEST });
  apiMethods
    .post("Profile", {
      email: data,
      mobile: "",
    })
    .then((response) => {
      let profile = response.data.providerJson
        ? response.data.providerJson
        : response.data.clientJson
        ? response.data.clientJson
        : response.data.organisationJson;

      dispatch({ type: GET_PERSONAL_PROFILE_SUCCESS, payload: profile });
      dispatch({
        type: GET_SUPER_ADMIN,
        payload: response.data.providerJson.securityMatrix.isHealthswimAdmin,
      });
      if (response.data.organisationJson) {
        dispatch({
          type: ORGANIZATION_PROFILE_SUCCESS,
          payload: response.data.organisationJson,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_PERSONAL_PROFILE_ERROR,
        payload: error && error.message,
      });
    });
};

export const updateProfileImg = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE_IMG_SUCCESS,
    payload: data,
  });
};
