import React, { Component } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { REGISTER_REQUEST } from "../../actions/register.actions";
import eye from "../../img/eye.png";
import info from "../../img/info_icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RegisterationSuccessPage from "../../containers/RegisterationSuccessPage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./register.css";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default class RegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleSubmitIsChecked = this.handleSubmitIsChecked.bind(this);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      isOpen: false,
      mobile: "",
      isChecked: false,
      showModel: false,
      checkNumber: "",
      errorPhone: "",
    };
  }

  onSubmit = (values) => {
    if (this.state.checkNumber.length !== 10) {
      this.setState({ errorPhone: "Mobile number is required" });
      return;
    }
    this.props.register({
      username: values.username,
      password: values.password,
      confirmPassword: values.confirmPassword,
      registrationType: values.registrationType,
      mobile: this.state.mobile,
    });
    this.setState({ errorPhone: "" });
  };

  toggleShow = (field, value) => {
    if (field === "password") this.setState({ showPassword: !value });
    else {
      this.setState({ showConfirmPassword: !value });
    }
  };
  handleSubmitIsChecked = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  handleClose = () => {
    this.setState({
      showModel: false,
    });
  };
  handlePhonechange = (value, country) => {
    this.setState({ mobile: value });
    if (country.countryCode === "ca" || country.countryCode === "us") {
      this.setState({ checkNumber: value.substring(1) });
      if (value.substring(1).length === 10) {
        this.setState({ errorPhone: "" });
      } else {
        this.setState({ errorPhone: "Mobile number is required" });
      }
    } else if (country.countryCode === "in" || country.countryCode === "gb") {
      this.setState({ checkNumber: value.substring(2) });
      if (value.substring(2).length === 10) {
        this.setState({ errorPhone: "" });
      } else {
        this.setState({ errorPhone: "Mobile number is required" });
      }
    }
  };
  validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .email("Username should be email."),

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters long."),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "Password and confirm password do not match."
      ),
    // registrationType: Yup.string().required("Registration type is required"),
  });

  renderForm = (props) => {
    const { handleChange, handleSubmit, values } = props;

    const isRegistrationInProgress = this.props.state === REGISTER_REQUEST;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <label className="form-lable">Email</label>
          <Field
            type="text"
            value={values.username}
            name="username"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="username" />
          </span>
        </div>

        <div className="form-group form-lable ">
          <label className="form-lable">Password</label>
          <OverlayTrigger
            placemeKnt="right"
            overlay={
              <Tooltip id="ph_help">
                Password should be minimum 8 characters long.
              </Tooltip>
            }
          >
            <img
              src={info}
              alt="Show"
              className="pull-right"
              style={{ width: "4%", marginRight: "3%" }}
            ></img>
          </OverlayTrigger>
          <div className="input-group ">
            <Field
              type={this.state.showPassword ? "text" : "password"}
              value={values.password}
              name="password"
              className="form-control form-input"
            />
            <span
              class="input-group-addon"
              style={{ borderBottom: "1px solid black", textAlign: "center" }}
            >
              <img
                src={eye}
                alt="Show"
                style={{ width: "50%" }}
                onClick={() => {
                  this.toggleShow("password", this.state.showPassword);
                }}
              />
            </span>
          </div>
          <span className="error_text">
            <ErrorMessage name="password" />
          </span>
        </div>

        <div className="form-group form-lable ">
          <label className="form-lable">Confirm password</label>
          <div className="input-group">
            <Field
              type={this.state.showConfirmPassword ? "text" : "password"}
              value={values.confirmPassword}
              name="confirmPassword"
              className="form-control form-input"
            />
            <span
              className="input-group-addon"
              style={{ borderBottom: "1px solid black", textAlign: "center" }}
            >
              <img
                src={eye}
                alt="Show"
                style={{ width: "50%" }}
                onClick={() => {
                  this.toggleShow(
                    "confirm_password",
                    this.state.showConfirmPassword
                  );
                }}
              />
            </span>
          </div>
          <span className="error_text">
            <ErrorMessage name="confirmPassword" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Mobile Number</label>

          <PhoneInput
            type="text"
            value={this.state.mobile}
            // onChange={
            //   (mobile) => this.setState({ mobile })
            //   // console.log("mobile", mobile)
            // }
            onChange={(value, country) =>
              this.handlePhonechange(value, country)
            }
            name="mobile"
            country={"us"}
            onlyCountries={["us"]}
            style={{
              width: "100%",
              borderBottom: "1px solid #4a4a4a !important",
            }}
            inputStyle={{
              width: "100%",
              borderBottomColor: "#4a4a4a",
              borderTop: "none",
              borderRight: "none",
              borderRadius: "1px",
            }}
            inputProps={{ required: true }}
            rules={{ required: true }}
            inputClass="form-control"
          />

          <span className="error_text">{this.state.errorPhone}</span>
        </div>
        <div className="row">
          <div className="offset-md-3 col-md-9">
            <div className="form-check">
              <input
                className="form-check-input "
                type="radio"
                name="registrationType"
                onChange={handleChange}
                value="INDIVIDUAL"
              />
              <label
                className="form-check-label form-lable"
                style={{
                  color: "#4A4A4A",
                  fontSize: "14px",
                  fontFamily: "proximanova",
                }}
              >
                I am an individual provider
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="registrationType"
                onChange={handleChange}
                value="ORGANISATION"
              />
              <label
                className="form-check-label form-lable"
                style={{
                  color: "#4A4A4A",
                  fontSize: "14px",
                  fontFamily: "proximanova",
                }}
              >
                I am an organization
              </label>
            </div>

            {/* <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="registrationType"
                onChange={handleChange}
                value="HEALTHSWIMADMIN"
              />
              <label
                className="form-check-label form-lable"
                style={{
                  color: "#4A4A4A",
                  fontSize: "14px",
                  fontFamily: "proximanova",
                }}
              >
                I am a Super Admin
              </label>
            </div>
            <span className="error_text">
              <ErrorMessage name="registrationType" />
            </span> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            type="checkbox"
            id="inProgress"
            onChange={this.handleSubmitIsChecked}
            checked={this.state.isChecked}
            data-mssg="In Progress"
          />
          <label for="inProgress" />
          <div
            style={{
              color: "#4A4A4A",
              fontSize: "14px",
              fontFamily: "proximanova",
              marginLeft: "10px",
            }}
          >
            Please tick the checkbox to give your consent to receive OTP on sms
          </div>
        </div>
        <div style={{ textAlign: "right", marginBottom: "27px" }}>
          <button
            type="submit"
            // disabled={isRegistrationInProgress}
            className="btn form-btn"
            id="btnLogin"
          >
            Next{" "}
            <span className="text-right pl-2">
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: "#fff" }}
              />
            </span>
            {/* {isRegistrationInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )} */}
          </button>
        </div>
      </form>
    );
  };

  render() {
    return (
      <>
        <div className="form-rectangle">
          <div className="form-header" style={{ height: "100px" }}>
            <h3>SIGN UP</h3>
          </div>
          <div
            className="form-container h-100"
            style={{ marginBottom: "-72px" }}
          >
            <div>
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                  registrationType: "",
                  confirmPassword: "",
                  mobile: "",
                }}
                onSubmit={this.onSubmit}
                render={this.renderForm}
                validationSchema={this.validationSchema}
              />
            </div>
          </div>
          <div className="form-container form-lable">
            If you are a patient, please visit the Apple app store or Google
            play store to download the Healthswim app
          </div>
          <div
            className="form-container form-lable"
            style={{ marginTop: "-35px" }}
          >
            If you are a healthcare provider and want to help your patients with
            seamless transmission of credible medical information, please email
            us at{" "}
            <a href="mailto:info@healthswim.com" target="_blank">
              info@healthswim.com
            </a>
            . We will help in setting up your account.
          </div>
        </div>
      </>
    );
  }
}
