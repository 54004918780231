import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_VERIFY = "REGISTER_VERIFY";
export const REGISTER_SEND_REQUEST = "REGISTER_SEND_REQUEST";
export const REGISTER_OTP_SUCCESS = "REGISTER_OTP_SUCCESS";
export const LOGIN_EMAIL_ID = "LOGIN_EMAIL_ID";
export class RegisterRequestParams {
  constructor(
    username,
    password,
    registrationType,
    mobile,
    firstName,
    lastName,
    dateOfBirth,
    gender
  ) {
    this.username = username;
    this.password = password;
    this.registrationType = registrationType;
    this.mobile = mobile;
    (this.firstName = firstName),
      (this.lastName = lastName),
      (this.dateOfBirth = dateOfBirth),
      (this.gender = gender);
  }

  getUsername() {
    return this.username;
  }

  getPassword() {
    return this.password;
  }

  getRegistrationType() {
    return this.registrationType;
  }
}

export const requestRegister =
  (registerRequestParams) => (dispatch, getState) => {
    dispatch({ type: REGISTER_REQUEST });

    apiMethods
      .post("SignUp/Register", {
        email: registerRequestParams.username,
        password: registerRequestParams.password,
        registrationType: registerRequestParams.registrationType,
        // registrationType: "CLIENT",
        mobile: registerRequestParams.mobile
          ? "+" + registerRequestParams.mobile
          : "",
        firstName: registerRequestParams.firstName,
        lastName: registerRequestParams.lastName,
        dateOfBirth: registerRequestParams.dateOfBirth,
        gender: registerRequestParams.gender,
      })
      .then((response) => {
        dispatch({ type: REGISTER_SUCCESS });
        dispatch({
          type: LOGIN_EMAIL_ID,
          payload: registerRequestParams.username,
        });
      })
      .catch((error) => {
        dispatch({ type: SHOW_ERROR, payload: error.data.message });
        dispatch({ type: REGISTER_ERROR, error: error.data.message });
        dispatch({
          type: REGISTER_ERROR,
          error: error.data.Mobile && error.data.Mobile[0],
        });
      });
    localStorage.setItem("username", registerRequestParams.username);
    localStorage.setItem("mobile", registerRequestParams.mobile);
  };
export const requestRegisterSuccess =
  (registerRequestParams) => (dispatch, getState) => {
    dispatch({ type: REGISTER_REQUEST });
    apiMethods
      .post("SignIn/ValidateOTP", {
        email: registerRequestParams.email,
        otp: registerRequestParams.otp,
        mobile: localStorage.getItem("mobile"),
      })
      .then((response) => {
        dispatch({ type: REGISTER_SUCCESS });
        // dispatch({
        //   type: LOGIN_EMAIL_ID,
        // });
      })
      .catch((error) => {
        dispatch({ type: SHOW_ERROR, payload: error.data.message });
        dispatch({ type: REGISTER_ERROR, error: error.data.message });
      });
  };

export const requestResendOtpSuccess =
  (registerRequestParams) => (dispatch, getState) => {
    dispatch({ type: REGISTER_SEND_REQUEST });
    apiMethods
      .post("SignIn/ResendOTP", {
        email: registerRequestParams.email,
        mobile: localStorage.getItem("mobile"),
      })
      .then((response) => {
        dispatch({ type: REGISTER_OTP_SUCCESS });
        dispatch({ type: SHOW_ERROR, payload: response.data.message });
      })
      .catch((error) => {
        dispatch({ type: SHOW_ERROR, payload: error.data.message });
        dispatch({ type: REGISTER_ERROR, error: error.message });
      });
  };
