import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const SAVE_PP_ACCEPT_SUCCESS = "SAVE_PP_ACCEPT_SUCCESS";
export const SAVE_PP_ACCEPT_ERROR = "SAVE_PP_ACCEPT_ERROR";
export const SAVE_PP_ACCEPT_IN_PROGRESS = "SAVE_PP_ACCEPT_IN_PROGRESS";

export function saveAccept() {
  return function(dispatch, state) {
    dispatch({ type: SAVE_PP_ACCEPT_IN_PROGRESS });
    apiMethods
      .get("provider/acceptterms ", {
        accept: true
      })
      .then(response => {
        dispatch({ type: SAVE_PP_ACCEPT_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: SHOW_ERROR, payload: error.message });
        dispatch({ type: SAVE_PP_ACCEPT_ERROR, error: error.message });
      });
  };
}
