export const ROUTE_PRIVACY_POLICY = "/privacypolicy";
export const ROUTE_TERMS_AND_CONDITIONS = "/termsandconditions";
export const ROUTE_PERSONAL_PROFILE = "/personal-profile";
export const ROUTE_PROFESSIONAL_INFO = "/professional-info";
export const ROUTE_EDIT_PROFILE = "profile-edit";
export const ROUTE_CHANNEL_SETUP = "/channel-setup";
export const ROUTE_FIND_CONTENT = "/find-content";
export const ROUTE_PUBLISH_CONTENT = "/publish-content";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_ORG_ABOUTME = "/aboutme";
export const ROUTE_ORG_PROFILE = "/organization-profile";
export const ROUTE_ORG_LOCATION = "/organization-location";
export const ROUTE_ORG_INVITE_ADMIN = "/organization-invite-admin";
export const ROUTE_ORG_INVITE_SUBADMIN = "/organization-invite-subadmin";
export const ROUTE_ORG_INVITE_PROVIDER = "/organization-invite-provider";
export const ROUTE_ORG_USER = "/organization-user";
export const ROUTE_ORG_FIND_CONTENT = "/organization-find-content";
export const ROUTE_ORG_DEPARTMENT = "/organization-departments";
export const ROUTE_ORG_MANAGE_LOCATION = "/org-manage-location";
export const ROUTE_ACCEPT_INVITE = "/accept-invite";
export const ROUTE_LOGIN = "/login";
export const ROUTE_ORG_MANAGE_DEPTADMIN = "/org-manage-deptadmin";
export const ROUTE_ORG_MANAGE_PROVIDERS = "/org-manage-provider";
export const ROUTE_ORG_MANAGE_MYPROFILE = "/org-manage-myprofile";
export const ROUTE_ORG_MANAGE_PROFILE = "/org-manage-profile";
export const ROUTE_ORG_MANAGE_DEPARTMENTS = "/org-manage-departments";
export const ROUTE_LOGOUT = "/logout";
export const ROUTE_ORG_LOCATION_FROM_MANAGE = "/org-create-location";
export const ROUTE_ORG_INVITE_PROVIDER_FROM_MANAGE = "/org-create-provider";
export const ROUTE_ORG_MANAGE_ADMIN = "/org-manage-admin";
export const ROUTE_CLIENT_PROFILE = "/personal-info";
export const ROUTE_SUBSCRIBE_CHANNEL = "/subscribe-channel";
export const ROUTE_MANAGE_MY_CONTENT = "/manage-my-content";
export const ROUTE_CLIENT_PROFILE_EDIT = "/personal-info-edit";
export const ROUTE_ORG_CREATE_ADMIN = "/org-create-admin";
export const ROUTE_ORG_CREATE_DEPTADMIN = "/org-create-deptadmin";
export const ROUTE_MANAGE_CHANNELS = "/manage-channels";
export const ROUTE_POST_SHARE = "/post-share/:id";
export const ROUTE_REGISTRATION_SUCCESS = "/register-success";
export const ROUTE_MANAGE_LISTS = "/manage-lists";
export const ROUTE_MAILING_LIST = "/mailing-list";
export const ROUTE_MANAGE_ACCOUNTS = "/manage-accounts";
export const ROUTE_PATIENT_SIGNUP = "/patient-signup";
export const ROUTE_CHANGE_PASSWORD = "/change-password";
export const ROUTE_MOBILE_PRIVACY = "/mobile-privacy";
export const ROUTE_MOBILE_TERMS = "/mobile-terms";
export const ROUTE_DISABLE_ACCOUNT = "/disableAccount";
export const ROUTE_DELETE_ACCOUNT = "/deleteAccount";
