import React, { Component } from "react";
import "./professionalLife.css";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
// import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export default class ProfessionalLife extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSkipForNow = this.onSkipForNow.bind(this);
  }

  onSubmit(values) {
    this.props.saveProfessionalInfo(values);
  }

  onSkipForNow() {
    this.props.handleSkip();
  }

  validationSchema = Yup.object().shape({
    npiNumber: Yup.string()
      .min(10, "NPI number should be of 10 digits")
      .required("NPI number is required"),
    boardCertifications: Yup.array().min(
      1,
      "Atleast one certification is required"
    ),
    // affiliations: Yup.array().min(1, "Atleast one affiliation is required"),
    conditionsTreated: Yup.array().min(
      1,
      "Please select atleast one condition treated"
    ),
  });

  render() {
    let initialValues = {
      npiNumber: "",
      affiliations: [],
      conditionsTreated: [],
      boardCertifications: [],
    };

    if (this.props.professionalInfo) {
      initialValues.npiNumber = this.props.professionalInfo.npInumber
        ? this.props.professionalInfo.npInumber
        : "";
      initialValues.affiliations = this.props.professionalInfo.affiliations;
      initialValues.boardCertifications =
        this.props.professionalInfo.boardCertifications;
      initialValues.conditionsTreated =
        this.props.professionalInfo.conditionTreated;
    }
    initialValues.state = this.props.mode;

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validationSchema={this.validationSchema}
        />
      </div>
    );
  }

  renderForm = (props) => {
    const { handleSubmit, values } = props;

    var title =
      this.props.mode === "edit"
        ? "PROFESSIONAL PROFILE"
        : "Tell us about your professional life...";

    return (
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="form-rectangle">
            <div className="form-header">
              <h3>{title}</h3>
              {this.props.mode === "edit" ? (
                ""
              ) : (
                <p>
                  If you skip this now you can always finish it in your
                  settings.
                </p>
              )}
            </div>
            <div className="form-container h-100">
              <div className="row">
                <div className="col-4">
                  <div className="form-lText ">
                    {this.props.mode === "edit"
                      ? null
                      : `Healthswim dosen't share this information anywhere but on
                         Healthswim sharing it here CAN help users know and
                         understand more about you.`}
                  </div>
                  <div className="oval profileInfo-oval" />
                </div>
                <div className="col-8">
                  <div className="form-body">
                    <div className="form-group form-lable">
                      <label className="form-lable">NPI Number</label>
                      <Field
                        className="form-control form-input"
                        name="npiNumber"
                        onChange={(e) => {
                          var number = /^[0-9]+$/;
                          if (e.target.value.length > 10)
                            alert("NPI number should be of 10 digits");
                          else if (
                            e.target.value !== "" &&
                            e.target.value.match(number) === null
                          )
                            alert("Please input numeric characters only");
                          else if (
                            e.target.value.length === 10 &&
                            parseInt(e.target.value) <= 0
                          )
                            alert("Please enter value greater than 0");
                          else {
                            props.setFieldValue("npiNumber", e.target.value);
                          }
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="npiNumber" />
                      </span>
                    </div>

                    <div className="form-group form-lable">
                      <label className="form-lable">Board certifications</label>
                      <Typeahead
                        selected={values.boardCertifications}
                        multiple
                        placeholder=""
                        options={this.props.certifications}
                        labelKey="name"
                        onChange={(selectedValues) => {
                          props.setFieldValue(
                            "boardCertifications",
                            selectedValues
                          );
                          // props.setFieldTouched(
                          //   "boardCertifications",
                          //   true,
                          //   true
                          // );
                        }}
                        onBlur={() => {
                          props.setFieldTouched(
                            "boardCertifications",
                            true,
                            true
                          );
                        }}
                        allowNew
                      />
                      <span className="error_text">
                        <ErrorMessage name="boardCertifications" />
                      </span>
                    </div>

                    {/* <div className="form-group form-lable">
                      <label className="form-lable">Affiliations</label>
                      <Typeahead
                        labelKey="name"
                        selected={values.affiliations}
                        options={this.props.affiliations}
                        placeholder=""
                        multiple
                        onChange={(selectedValues) => {
                          props.setFieldValue("affiliations", selectedValues);
                          props.setFieldTouched("affiliations", true, true);
                        }}
                        onBlur={() => {
                          props.setFieldTouched("affiliations", true, true);
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="affiliations" />
                      </span>
                    </div> */}

                    <div className="form-group form-lable">
                      <label className="form-lable">Conditions Treated</label>
                      <Typeahead
                        selected={values.conditionsTreated}
                        options={this.props.conditionsTreated}
                        placeholder=""
                        multiple
                        onChange={(selectedValues) => {
                          props.setFieldValue(
                            "conditionsTreated",
                            selectedValues
                          );
                          // props.setFieldTouched(
                          //   "conditionsTreated",
                          //   true,
                          //   true
                          // );
                        }}
                        onBlur={() => {
                          props.setFieldTouched(
                            "conditionsTreated",
                            true,
                            true
                          );
                        }}
                        allowNew
                        labelKey="name"
                      />
                      <span className="error_text">
                        <ErrorMessage name="conditionsTreated" />
                      </span>
                    </div>

                    {/* <div className="form-group form-lable">
                      <label className="form-lable">Connect with:</label>
                    </div>

                    <div className="row">
                      <div className="col col-md-2">
                        <div className="connect">
                          <i className="fab fa-facebook-f" />
                        </div>
                      </div>
                    </div> */}

                    <div className="mt-10 text-right">
                      {this.props.mode !== "edit" ? (
                        <span
                          className="form-link mr-5"
                          onClick={() => this.onSkipForNow()}
                          style={{ cursor: "pointer" }}
                        >
                          Skip for now
                        </span>
                      ) : (
                        ""
                      )}

                      {this.props.mode === "edit" ? (
                        <button
                          type="submit"
                          className="btn form-btn"
                          id="btnLogin"
                          disabled={this.props.isSaveInProgress}
                        >
                          Save
                          {this.props.isSaveInProgress === true ? (
                            <span className="text-right pl-2">
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ color: "#fff" }}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={this.props.isSaveInProgress}
                          className="btn form-btn"
                          id="btnLogin"
                        >
                          Next
                          {this.props.isSaveInProgress === true ? (
                            <span className="text-right pl-2">
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ color: "#fff" }}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
}
