import React, { Component } from "react";
import Modal from "react-modal";
import { Typeahead } from "react-bootstrap-typeahead";
import CloseButton from "react-bootstrap/CloseButton";
import { FiEdit } from "react-icons/fi";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "-20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid gray",
    overflowX: "hidden",
    maxWidth: " 50%",
  },
};

export default class ShowUserInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: true,
      selectDgree: [],
      initialValues: {
        id: "",
        firstName: "",
        lastName: "",
        contactPhone: "",
        title: "",
        country: "",
        countryId: "",
        userRole: "",
        department: [],
        location: [],
        speciality: [],
        board: [],
        degree: [],
        nPInumber: "",
        affiliations: [],
        conditions: [],
        phone: "",
      },
    };
  }
  componentDidMount() {
    if (this.props.providerList) {
      this.setState({
        initialValues: {
          id: this.props.providerList.id,
          firstName: this.props.providerList.firstName,
          lastName: this.props.providerList.lastName,
          userRole:
            this.props.subscriptionDetailsOrg.members[this.props.userIndex]
              .userRole,
          email: this.props.providerList.email,
          phone: this.props.providerList.contactPhone
            ? this.props.providerList.contactPhone
            : this.props.providerList.phone,
          // we cnage after some time

          location:
            this.props.providerList.locationArray &&
            JSON.parse(this.props.providerList.locationArray),
          degree:
            this.props.providerList.degree &&
            JSON.parse(this.props.providerList.degree),
          nPInumber: this.props.providerList.npInumber,
          speciality: this.props.providerList.specialities,
          affiliations:
            this.props.providerList.affiliations &&
            JSON.parse(this.props.providerList.affiliations),
          board:
            this.props.providerList.boardCertifications &&
            JSON.parse(this.props.providerList.boardCertifications),
          conditions:
            this.props.providerList.conditionTreated &&
            JSON.parse(this.props.providerList.conditionTreated),
          department:
            this.props.providerList.departmentNameArray &&
            JSON.parse(this.props.providerList.departmentNameArray),
          //set intial value of dgree
          //this.setState({selectDgree:this.props.subscriptionDetailsOrg.members[this.props.userIndex].dgree})
        },
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userIndex !== this.props.userIndex) {
      this.setState({
        initialValues: {
          id: this.props.providerList.id,
          firstName: this.props.providerList.firstName,
          lastName: this.props.providerList.lastName,
          userRole:
            this.props.subscriptionDetailsOrg.members[this.props.userIndex]
              .userRole,
          email: this.props.providerList.email,
          phone: this.props.providerList.contactPhone,
          // we cnage after some time

          location:
            this.props.providerList.locationArray &&
            JSON.parse(this.props.providerList.locationArray),
          degree:
            this.props.providerList.degree &&
            JSON.parse(this.props.providerList.degree),
          nPInumber: this.props.providerList.npInumber,
          speciality: this.props.providerList.specialities,
          affiliations:
            this.props.providerList.affiliations &&
            JSON.parse(this.props.providerList.affiliations),
          board:
            this.props.providerList.boardCertifications &&
            JSON.parse(this.props.providerList.boardCertifications),
          conditions:
            this.props.providerList.conditionTreated &&
            JSON.parse(this.props.providerList.conditionTreated),
          department:
            this.props.providerList.departmentNameArray &&
            JSON.parse(this.props.providerList.departmentNameArray),
          //set intial value of dgree
          //this.setState({selectDgree:this.props.subscriptionDetailsOrg.members[this.props.userIndex].dgree})
        },
      });
    }
  }
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      initialValues: {
        // object that we want to update
        ...prevState.initialValues, // keep all other key-value pairs
        [name]: value, // update the value of specific key
      },
    }));
  };
  selectedOptions = (name, selected) => {
    let temp = [];
    selected.map((x) => {
      temp.push(x);
    });

    this.setState((prevState) => ({
      initialValues: {
        // object that we want to update
        ...prevState.initialValues, // keep all other key-value pairs
        [name]: temp, // update the value of specific key
      },
    }));
  };
  addsaveAdmin = () => {
    if (this.state.initialValues.userRole === "Provider")
      this.props.saveProviderInfo(this.state.initialValues);
    else if (this.state.initialValues.userRole === "PrimaryOrganizationAdmin")
      this.props.submitUserEditInfo(this.state.initialValues);
    else this.props.saveSuperAdminInfo(this.state.initialValues);
    this.setState({ isEdit: true });
  };
  render() {
    return (
      <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
        <CloseButton aria-label="Hide" onClick={this.props.onClose} />

        <div className="form-header">
          <h3>
            {" "}
            {this.state.initialValues.firstName}{" "}
            {this.state.initialValues.lastName}
          </h3>
        </div>
        {this.state.isEdit && (
          <form>
            <div style={{ display: "flex" }}>
              <div className="col-6 form-group form-lable">
                <label className="form-lable">First name</label>
                <span className="form-control form-input">
                  {this.state.initialValues.firstName}
                </span>
                <label className="form-lable">Phone </label>
                <span className="form-control form-input">
                  {" "}
                  {this.state.initialValues.phone}
                </span>
                {this.state.initialValues.userRole === "DepartmentAdmin" && (
                  <>
                    <label className="form-lable">Department</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.department &&
                        this.state.initialValues.department.map(
                          (index, value) => {
                            return " " + index.departmentName;
                          }
                        )}
                    </span>
                  </>
                )}
                {this.state.initialValues.userRole === "Provider" && (
                  <>
                    <label className="form-lable">Location </label>
                    <span className="form-control form-input">
                      {" "}
                      {this.state.initialValues.location &&
                        this.state.initialValues.location.map(
                          (index, value) => {
                            return " " + index.primaryLocationName;
                          }
                        )}
                    </span>
                    <label className="form-lable">Speciality </label>
                    <span className="form-control form-input">
                      {" "}
                      {this.state.initialValues.speciality &&
                        this.state.initialValues.speciality.map(
                          (index, value) => {
                            return " " + index.name;
                          }
                        )}
                    </span>
                    <label className="form-lable">Board certifications </label>
                    <span className="form-control form-input">
                      {" "}
                      {this.state.initialValues.board &&
                        this.state.initialValues.board.map((index, value) => {
                          return " " + index.name;
                        })}
                    </span>
                    <label className="form-lable">Degree</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.degree &&
                        this.state.initialValues.degree.map((index, value) => {
                          return " " + index.name;
                        })}
                    </span>
                  </>
                )}
              </div>

              <div className="col-6 form-group form-lable">
                <label className="form-lable">Last name</label>
                <span className="form-control form-input">
                  {this.state.initialValues.lastName}
                </span>
                <label className="form-lable">Email</label>
                <span className="form-control form-input">
                  {this.state.initialValues.email}
                </span>

                {this.state.initialValues.userRole === "Provider" && (
                  <>
                    <label className="form-lable">User Role</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.userRole}
                    </span>
                    <label className="form-lable">NPInumber</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.nPInumber}
                    </span>
                    <label className="form-lable">Affiliations</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.affiliations &&
                        this.state.initialValues.affiliations.map(
                          (index, value) => {
                            return " " + index.name;
                          }
                        )}
                    </span>
                    <label className="form-lable">Conditions Treated</label>
                    <span className="form-control form-input">
                      {this.state.initialValues.conditions &&
                        this.state.initialValues.conditions.map(
                          (index, value) => {
                            return " " + index.name;
                          }
                        )}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="mt-5 text-right">
              <FiEdit
                style={{ color: "#00c6ff" }}
                size={30}
                onClick={() => {
                  this.setState({ isEdit: false });
                }}
              />
            </div>
          </form>
        )}
        {this.state.isEdit === false && (
          <form>
            <div style={{ display: "flex" }}>
              <div className="col-6 form-group form-lable">
                <label className="form-lable">First name</label>
                <input
                  type="text"
                  name="firstName"
                  className="form-control form-input"
                  value={this.state.initialValues.firstName}
                  onChange={this.handleInput}
                />

                <label className="form-lable">Phone</label>
                <input
                  type="text"
                  className="form-control form-input"
                  name="phone"
                  value={this.state.initialValues.phone}
                  onChange={this.handleInput}
                />

                {this.state.initialValues.userRole === "DepartmentAdmin" && (
                  <>
                    {" "}
                    <label className="form-lable">Department</label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="departmentName"
                      name="speclocationiality"
                      // onChange={setSingleSelections}
                      options={this.props.departments}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.department}
                      onChange={(selected) => {
                        this.selectedOptions("department", selected);
                      }}
                    />
                  </>
                )}
                {this.state.initialValues.userRole === "Provider" && (
                  <>
                    <label className="form-lable">Location</label>

                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="primaryLocationName"
                      name="speclocationiality"
                      // onChange={setSingleSelections}
                      options={this.props.locations}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.location}
                      onChange={(selected) => {
                        this.selectedOptions("location", selected);
                      }}
                    />
                    <label className="form-lable">Speciality</label>

                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      name="speciality"
                      // onChange={setSingleSelections}
                      options={this.props.allSpecialities}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.speciality}
                      onChange={(selected) => {
                        this.selectedOptions("speciality", selected);
                      }}
                      multiple
                    />
                    <label className="form-lable">Board certifications</label>

                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      name="board"
                      // onChange={setSingleSelections}
                      options={this.props.allCertifications}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.board}
                      onChange={(selected) => {
                        this.selectedOptions("board", selected);
                      }}
                      multiple
                    />
                    <label className="form-lable">Degree</label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      name="degree"
                      // onChange={setSingleSelections}
                      options={this.props.allDegrees}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.degree}
                      onChange={(selected) => {
                        this.selectedOptions("degree", selected);
                      }}
                      multiple
                    />
                  </>
                )}
              </div>

              <div
                className=" col-6 form-group form-lable"
                style={{ marginLeft: "2rem" }}
              >
                <label className="form-lable">Last name</label>
                <input
                  type="text"
                  className="form-control form-input"
                  name="lastName"
                  value={this.state.initialValues.lastName}
                  onChange={this.handleInput}
                />

                <label className="form-lable">Email</label>
                <input
                  type="email"
                  className="form-control form-input"
                  value={this.state.initialValues.email}
                  name="email"
                  onChange={this.handleInput}
                />
                {this.state.initialValues.userRole === "Provider" && (
                  <>
                    {" "}
                    <label className="form-lable">User Role</label>
                    <input
                      type="text"
                      name="userRole"
                      className="form-control form-input"
                      value={this.state.initialValues.userRole}
                      onChange={this.handleInput}
                    />
                    <label className="form-lable">NPI number</label>
                    <input
                      type="text"
                      className="form-control form-input"
                      value={this.state.initialValues.nPInumber}
                      name="nPInumber"
                      onChange={this.handleInput}
                    />
                    <label className="form-lable">Affiliations</label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      name="degree"
                      // onChange={setSingleSelections}
                      options={this.props.allAffiliations}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.affiliations}
                      onChange={(selected) => {
                        this.selectedOptions("affiliations", selected);
                      }}
                      multiple
                    />
                    <label className="form-lable">Conditions Treated</label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      name="conditions"
                      // onChange={setSingleSelections}
                      options={this.props.allConditionsTreated}
                      placeholder="Choose a state..."
                      selected={this.state.initialValues.conditions}
                      onChange={(selected) => {
                        this.selectedOptions("conditions", selected);
                      }}
                      multiple
                    />
                  </>
                )}
              </div>
            </div>

            <div className="mt-5 text-right">
              <button
                style={{ background: "#00c6ff" }}
                type="button"
                className="btn form-btn"
                id="btnLogin"
                onClick={this.addsaveAdmin}
              >
                Save
              </button>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}
