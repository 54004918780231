import {
  ORG_INVITE_PROVIDER_REQUEST,
  ORG_INVITE_PROVIDER_ERROR,
  ORG_INVITE_PROVIDER_SUCCESS,
  UPDATE_SAVE_STATE_TO_NULL_PROVIDER
} from "../actions/organizationInviteProvider.action";

const initialState = {
  state: null,
  error: null
};

const organizationInviteProviderReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case ORG_INVITE_PROVIDER_REQUEST: {
      newState = { state: ORG_INVITE_PROVIDER_REQUEST, error: null };
      break;
    }
    case ORG_INVITE_PROVIDER_SUCCESS: {
      newState = { state: ORG_INVITE_PROVIDER_SUCCESS, error: null };
      break;
    }
    case ORG_INVITE_PROVIDER_ERROR: {
      newState = { state: ORG_INVITE_PROVIDER_ERROR, error: action.error };
      break;
    }
    case UPDATE_SAVE_STATE_TO_NULL_PROVIDER: {
      newState = { state: null, error: null };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }
  return newState;
};

export default organizationInviteProviderReducer;
