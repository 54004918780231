import {
  GET_AFFILIATIONS_SUCCESS,
  GET_AFFILIATIONS_ERROR,
  GET_AFFILIATIONS_PROGRESS
} from "../actions/affiliations.action";

const initialState = {
  affiliations: [],
  error: null
};

const affiliationsReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case GET_AFFILIATIONS_PROGRESS: {
      newState = { ...state, affiliations: [], error: null };
      break;
    }
    case GET_AFFILIATIONS_SUCCESS: {
      newState = { ...state, affiliations: action.payload, error: null };
      break;
    }
    case GET_AFFILIATIONS_ERROR: {
      newState = { ...state, affiliations: [], error: action.payload };
      break;
    }
  }

  return newState;
};

export default affiliationsReducer;
