import React from "react";
import logo from "../img/logo_login.PNG";
import password from "../img/passwoed.svg";
import { Link } from "react-router-dom";
function CheckEmailNotification(props) {
  return (
    <div className="container container-login">
      <header>
        <img src={logo} alt="like" className="log_img" />
        <h1 className="set_text">Check your email</h1>
      </header>
      <div className="set_data">
        <img src={password} alt="like" className="logs_img" />
        <p>
          Check your{" "}
          <span style={{ fontWeight: 500, color: "black" }}>
            {props.history.location.state}
          </span>{" "}
          inbox for instructions from us on how to reset your password.
        </p>
      </div>
      {/* <Link to="/login" style={{ color: "black" }}>
        Go back to login screen
      </Link> */}
      <Link to="/loginPage" style={{ color: "black" }}>
        Go back to login screen
      </Link>
    </div>
  );
}

export default CheckEmailNotification;
