import React, { Component } from "react";
import "./timelineFIlters.css";
import MyChannels from "./myChannelsRender";
import SubscribedChannels from "./subscribedChannels";
import Footer from "../Footer/footer";

export default class TImelineFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMyChannels: false,
      timelineType: this.props.timelineFilterType,
      showDepartments: false,
      showproviders: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(filter) {
    this.props.setTimeLineType(filter);

    if (filter === "deletedPosts") {
      let feedcriteria = {
        from: 1,
        size: 10,
      };
      this.props.getDeletedPosts(feedcriteria);
    } else {
      let feedcriteria = {
        channelIds: [],
        all: filter === "all" ? true : false,
        myPostsOnly: filter === "myPost" ? true : false,
        savedPostsOnly: filter === "savedPost" ? true : false,
        size: 10,
        from: 1,
      };
      this.props.getFeedForTimeLine(feedcriteria);
    }
    document.getElementById("Feed_div").scrollTop = 0;
  }

  handleDeptProv = (filter) => {
    if (filter === "departments") {
      var feedcriteria = {
        channelIds: this.props.subscribedChannels
          .filter((node) => node.departmentId)
          .map((dept) => dept.channelId)
          .filter((id) => id),
        size: 10,
        from: 1,
      };
      this.props.getFeedForTimeLine(feedcriteria);
      document.getElementById("Feed_div").scrollTop = 0;
    }

    if (filter === "providers") {
      var feedcriteria = {
        channelIds: this.props.subscribedChannels
          .filter((node) => node.providerId)
          .map((prov) => prov.childs.map((child) => child.channelId))
          .reduce((prev, curr) => [...prev, ...curr], [])
          .filter((id) => id),
        size: 10,
        from: 1,
      };
      this.props.getFeedForTimeLine(feedcriteria);
      document.getElementById("Feed_div").scrollTop = 0;
    }
  };

  renderFeedForChannel = (item) => {
    let feedcriteria = {
      channelIds: item,
      size: 10,
      from: 1,
    };
    this.props.getFeedForTimeLine(feedcriteria);
    document.getElementById("Feed_div").scrollTop = 0;
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // boxShadow: "2px 2px 4px 3px rgb(0 0 0 / 20%)",
          marginTop: "10px",
          // height: "25vh",
          // position: "sticky",
          // top: "67px",
        }}
        className="responsive-height"
      >
        <div className="timeline_text">Timeline</div>
        <div
          style={{
            marginTop: "2%",
            width: "260px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {this.props.timelineFilterType === "all" ? (
            <div
              className="timelineFIlterOptions filter-active"
              onClick={() => this.handleClick("all")}
            >
              all
            </div>
          ) : (
            <div
              className="timelineFIlterOptions"
              onClick={() => this.handleClick("all")}
            >
              all
            </div>
          )}
          {this.props.profile && !this.props.profile.securityMatrix.clientId ? (
            this.props.timelineFilterType === "myPost" ? (
              <div
                className="timelineFIlterOptions filter-active"
                onClick={() => this.handleClick("myPost")}
              >
                my posts
              </div>
            ) : (
              <div
                className="timelineFIlterOptions"
                onClick={() => this.handleClick("myPost")}
              >
                my posts
              </div>
            )
          ) : null}
          <div>
            {this.props.profile &&
            !this.props.profile.securityMatrix.clientId ? (
              <MyChannels
                myChannels={this.props.myChannels}
                timelineFilterType={this.props.timelineFilterType}
                setTimeLineType={this.props.setTimeLineType}
                renderFeedForChannel={this.renderFeedForChannel}
                setTimeLineCurrentChannel={this.props.setTimeLineCurrentChannel}
                updateChnannelStatus={this.props.updateChnannelStatus}
                createNewChannel={this.props.createNewChannel}
                profile={this.props.profile}
                myDepartments={this.props.myDepartments}
              />
            ) : null}
          </div>
          <div className="d-flex">
            <div
              style={{ margin: "auto auto auto 20%" }}
              className="timelineFIlterOptions"
            >
              {this.props.timelineFilterType === "departments" ? (
                <span
                  className="filter-active"
                  onClick={() => {
                    this.props.setTimeLineType("departments");
                    this.handleDeptProv("departments");
                    this.setState({ showDepartments: true });
                  }}
                >
                  departments
                </span>
              ) : (
                <span
                  onClick={() => {
                    this.props.setTimeLineType("departments");
                    this.handleDeptProv("departments");
                    this.setState({ showDepartments: true });
                  }}
                >
                  departments
                </span>
              )}
            </div>

            <div style={{ cursor: "pointer", marginRight: "74px" }}>
              {this.state.showDepartments ? (
                <i
                  className=" fa fa-angle-up"
                  aria-hidden="true"
                  onClick={() =>
                    this.setState({
                      showDepartments: !this.state.showDepartments,
                    })
                  }
                />
              ) : (
                <i
                  className="fa fa-angle-down"
                  aria-hidden="true"
                  onClick={() => {
                    this.props.setTimeLineType("departments");
                    this.handleDeptProv("departments");
                    this.setState({
                      showDepartments: !this.state.showDepartments,
                    });
                  }}
                />
              )}
            </div>
          </div>
          {this.state.showDepartments
            ? this.props.subscribedChannels.map((node, i) => {
                if (
                  (node.childs.length > 0 || node.channelId !== null) &&
                  node.departmentId
                )
                  return (
                    <SubscribedChannels
                      node={node}
                      key={"node" + i}
                      getFeedSubChannels={this.props.getFeedForTimeLine}
                      timelineFilterType={this.props.timelineFilterType}
                      setTimeLineType={this.props.setTimeLineType}
                      renderFeedForChannel={this.renderFeedForChannel}
                      setTimeLineCurrentChannel={
                        this.props.setTimeLineCurrentChannel
                      }
                      unsubscribeChannel={this.props.unsubscribeChannel}
                    />
                  );
              })
            : null}

          <div className="d-flex">
            <div
              style={{ margin: "auto auto auto 20%" }}
              className="timelineFIlterOptions"
            >
              {this.props.timelineFilterType === "providers" ? (
                <span
                  className="filter-active"
                  onClick={() => {
                    this.props.setTimeLineType("providers");
                    this.handleDeptProv("providers");
                    this.setState({ showproviders: true });
                  }}
                >
                  providers
                </span>
              ) : (
                <span
                  onClick={() => {
                    this.props.setTimeLineType("providers");
                    this.handleDeptProv("providers");
                    this.setState({ showproviders: true });
                  }}
                >
                  providers
                </span>
              )}
            </div>

            <div style={{ cursor: "pointer", marginRight: "74px" }}>
              {this.state.showproviders ? (
                <i
                  className=" fa fa-angle-up"
                  aria-hidden="true"
                  onClick={() =>
                    this.setState({
                      showproviders: !this.state.showproviders,
                    })
                  }
                />
              ) : (
                <i
                  className="fa fa-angle-down"
                  aria-hidden="true"
                  onClick={() => {
                    this.props.setTimeLineType("providers");
                    this.handleDeptProv("providers");
                    this.setState({
                      showproviders: !this.state.showproviders,
                    });
                  }}
                />
              )}
            </div>
          </div>
          {this.state.showproviders
            ? this.props.subscribedChannels.map((node, i) => {
                if (
                  (node.childs.length > 0 || node.channelId !== null) &&
                  node.providerId
                )
                  return (
                    <SubscribedChannels
                      node={node}
                      key={"node" + i}
                      getFeedSubChannels={this.props.getFeedForTimeLine}
                      timelineFilterType={this.props.timelineFilterType}
                      setTimeLineType={this.props.setTimeLineType}
                      renderFeedForChannel={this.renderFeedForChannel}
                      setTimeLineCurrentChannel={
                        this.props.setTimeLineCurrentChannel
                      }
                      unsubscribeChannel={this.props.unsubscribeChannel}
                    />
                  );
              })
            : null}
          <div>
            {this.props.subscribedChannels &&
              this.props.subscribedChannels.map((node, i) => {
                if (
                  (node.childs.length > 0 || node.channelId !== null) &&
                  node.organisationId &&
                  !node.departmentId
                )
                  return (
                    <SubscribedChannels
                      node={node}
                      key={"node" + i}
                      getFeedSubChannels={this.props.getFeedForTimeLine}
                      timelineFilterType={this.props.timelineFilterType}
                      setTimeLineType={this.props.setTimeLineType}
                      renderFeedForChannel={this.renderFeedForChannel}
                      setTimeLineCurrentChannel={
                        this.props.setTimeLineCurrentChannel
                      }
                      unsubscribeChannel={this.props.unsubscribeChannel}
                    />
                  );
              })}
          </div>
          {this.props.timelineFilterType === "savedPost" ? (
            <div
              className="timelineFIlterOptions filter-active"
              onClick={() => this.handleClick("savedPost")}
            >
              saved posts
            </div>
          ) : (
            <div
              className="timelineFIlterOptions"
              onClick={() => this.handleClick("savedPost")}
            >
              saved posts
            </div>
          )}
          {this.props.profile &&
          this.props.profile.securityMatrix &&
          this.props.profile.securityMatrix.canSeeDeletedPosts ? (
            this.props.timelineFilterType === "deletedPosts" ? (
              <div
                className="timelineFIlterOptions filter-active"
                onClick={() => this.handleClick("deletedPosts")}
              >
                deleted posts
              </div>
            ) : (
              <div
                className="timelineFIlterOptions"
                onClick={() => this.handleClick("deletedPosts")}
              >
                deleted posts
              </div>
            )
          ) : null}
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
