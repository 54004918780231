import apiMethods from "../middleware/baseApiService";
import { ROLE_ORGANIZATION_SUBADMIN } from "../store/constants";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const ORG_INVITE_SUBADMIN_REQUEST = "ORG_INVITE_SUBADMIN_REQUEST";
export const ORG_INVITE_SUBADMIN_ERROR = "ORG_INVITE_SUBADMIN_ERROR";
export const ORG_INVITE_SUBADMIN_SUCCESS = "ORG_INVITE_SUBADMIN_SUCCESS";

export const UPDATE_SAVE_STATE_TO_NULL = "UPDATE_SAVE_STATE_TO_NULL";

export const inviteSubadmin = inviteSubadminParams => dispatch => {
  const inviteSubadminRequest = {
    ...inviteSubadminParams,
    userRole: ROLE_ORGANIZATION_SUBADMIN,
    departmentIds: inviteSubadminParams.departments.map(dt => dt.id).toString()
  };

  dispatch({ type: ORG_INVITE_SUBADMIN_REQUEST });
  apiMethods
    .post("InviteUser", inviteSubadminRequest)
    .then(response => {
      dispatch({ type: ORG_INVITE_SUBADMIN_SUCCESS, payload: response.data });
    })
    .catch(error => {
      let errorMessage = "Unknow error occurred while inviting user.";

      if (error && error.data && error.data.message) {
        errorMessage = error.data.message;
      }

      dispatch({ type: SHOW_ERROR, payload: errorMessage });
      dispatch({ type: ORG_INVITE_SUBADMIN_ERROR, error: errorMessage });
    });
};

export const updateSaveStateToNull = () => dispatch => {
  dispatch({ type: UPDATE_SAVE_STATE_TO_NULL });
};
