import React from "react";
import "./sharePost.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Modal, Button } from "react-bootstrap";
import parse from 'react-html-parser'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
const signupUrl = 'https://healthswimstagwebpatient.z19.web.core.windows.net/';



class SocialMediaShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl: "",
      authorName:'',
      dynamicText: null
    };
  }
  
  removeBTags(text) {
    if (!text) return ""; // If text is null or undefined, return empty string
    console.log(text,'textt');
    let result =  text.replace(/<b>/gi, "").replace(/<\/b>/gi, "");
    console.log(result,'removebtags');
    return result;
}
componentDidMount() {
  console.log(this.props.
    shareSocialMediaId
     &&
    this.props.
    shareSocialMediaId
    .authorShareText,'author');
  // Simulating dynamic update of dynamicText
  // setTimeout(() => {
      this.setState({ dynamicText:this.props.
        shareSocialMediaId
         &&
        this.props.
        shareSocialMediaId
        .authorShareText});
  // }); // Example delay of 2 seconds
}

  render() {
 
    const { dynamicText } = this.state;
        const textWithoutBTags = this.removeBTags(dynamicText);
      console.log(textWithoutBTags,'textWithoutBTags');
        const newTitle = `Posted by ${(textWithoutBTags)} via HEALTHSWIM, please SIGN UP to get credible content from physicians. Sign up now: `;
        console.log(newTitle,'textWithoutBTags');
        console.log(dynamicText,'dynamicText');
    // console.log(  this.props.shareSocialMediaId.authorShareText,'  this.props.shareSocialMeaId');
    var shareUrl =
      this.props.shareSocialMeaId &&
      this.props.shareSocialMediaId.postType === "Link"
        ? this.props.shareSocialMediaId.metaDataValue.url
        : this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.postType === "Image"
        ? this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.postImage
        : this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.postType === "Document"
        ? this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.postDocument
        : this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.postType === "Video"
        ? this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.postVideo
        : this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.postType === "Link"
        ? this.props.shareSocialMediaId &&
          this.props.shareSocialMediaId.metaDataValue.url
        : "";

       
    return (
      <div style={{ top: "36%" }}>
        <Modal
          show={this.props.openSocialMediaPopbox}
          onHide={this.props.closeSocialMediaPopBox}
          style={{ top: "36%" }}
        >
          {/* {console.log(
            " this.state.shareUrl",
            shareUrl,
            this.props.shareSocialMediaId &&
              this.props.shareSocialMediaId.metaDataValue.url
          )} */}
          <div style={{ overflow: "hidden" }}>
            <Modal.Header closeButton>
              <Modal.Title>Share</Modal.Title>
            </Modal.Header>
            {this.props.shareSocialMediaId &&
            this.props.shareSocialMediaId.metaDataValue.url !== "" ? (
              
              <Modal.Body>
                <div style={{ fontSize: "21px", fontWeight: " 500" }}>
                  Share on Social Media
                </div>
                <FacebookShareButton
                  url={shareUrl}
                  quote="posted via Healthswim"
                  hashtag="#posted via Healthswim"
                  style={{ padding: "15px" }}
                  title={newTitle}
                  description={"description"}
                >
                  <FacebookIcon size={40} />
                </FacebookShareButton>

                <LinkedinShareButton
                  url={shareUrl}
                  quote="posted via Healthswim"
                  hashtag="#posted via Healthswim"
                  style={{ padding: "15px" }}
                  title={newTitle}
                 // description={"description"}
                >
                  <LinkedinIcon size={40} />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  quote="posted via Healthswim"
                  hashtag="#posted via Healthswim"
                  style={{ padding: "15px" }}
                  title={newTitle}
                >
                  <TwitterIcon size={40} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  quote="posted via Healthswim"
                  hashtag="#posted via Healthswim"
                  style={{ padding: "15px" }}
                  title={newTitle}
                >
                  <WhatsappIcon size={40} />
                </WhatsappShareButton>
              </Modal.Body>
            ) : (
              <Modal.Body>You can not Share this post</Modal.Body>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default SocialMediaShare;
