import {
  REGISTER_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  LOGIN_EMAIL_ID,
} from "../actions/register.actions";

const initialState = {
  state: null,
  email: null,
  error: null,
};

const registerReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...initialState };
      break;
    }
    case REGISTER_REQUEST: {
      newState = { ...initialState, state: REGISTER_REQUEST, error: null };
      break;
    }
    case REGISTER_ERROR: {
      newState = {
        ...initialState,
        state: REGISTER_ERROR,
        error: action.error,
      };
      break;
    }
    case LOGIN_EMAIL_ID: {
      newState = {
        state: LOGIN_EMAIL_ID,
        error: null,
        email: action.payload,
      };
      break;
    }
    case REGISTER_SUCCESS: {
      newState = { ...initialState, state: REGISTER_SUCCESS, error: null };
      break;
    }
  }
  return newState;
};

export default registerReducer;
