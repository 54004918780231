import baseApiService from "../middleware/baseApiService";

export const PREMIUM_ACCOUNT_REQUEST = "PREMIUM_ACCOUNT_REQUEST";
export const PREMIUM_ACCOUNT_SUCCESS = "PREMIUM_ACCOUNT_SUCCESS";
export const PREMIUM_ACCOUNT_ERROR = "PREMIUM_ACCOUNT_ERROR";

export const premiumaccount = (amtCode) => (dispatch, state) => {
  dispatch({ type: PREMIUM_ACCOUNT_REQUEST });
  baseApiService
    .post("ProviderPersonalProfile/UpdatePaymentInfo", {
      amount: amtCode.amount,
      code: amtCode.code,
    })
    .then((response) => {
      dispatch({ type: PREMIUM_ACCOUNT_SUCCESS, payload: amtCode });
    })
    .catch((error) => {
      dispatch({ type: PREMIUM_ACCOUNT_ERROR, error: error.data.message });
    });
};
