import {
  SAVE_TANDC_ACCEPT_ERROR,
  SAVE_TANDC_ACCEPT_IN_PROGRESS,
  SAVE_TANDC_ACCEPT_SUCCESS
} from "../actions/termsAndConditions.action";

const initialState = {
  accepted: false,
  saveState: null,
  error: null
};

const termsConditionsReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case SAVE_TANDC_ACCEPT_IN_PROGRESS: {
      newState = {
        ...state,
        saveState: SAVE_TANDC_ACCEPT_IN_PROGRESS,
        error: null
      };
      break;
    }
    case SAVE_TANDC_ACCEPT_SUCCESS: {
      newState = {
        ...state,
        ...action.payload,
        saveState: SAVE_TANDC_ACCEPT_SUCCESS,
        error: null
      };
      break;
    }
    case SAVE_TANDC_ACCEPT_ERROR: {
      newState = {
        ...state,
        saveState: SAVE_TANDC_ACCEPT_ERROR,
        error: action.error
      };
      break;
    }
  }

  return newState;
};

export default termsConditionsReducer;
