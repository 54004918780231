import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const GET_SPECIALITY_CHANNELS_SUCCESS =
  "GET_SPECIALITY_CHANNELS_SUCCESS";
export const UPDATE_SPECIALITY_CHANNELS = "UPDATE_SPECIALITY_CHANNELS";

export const BULK_UPDATE_CHANNELS_SUCCESS = "BULK_UPDATE_CHANNELS_SUCCESS";
export const BULK_UPDATE_CHANNELS_ERROR = "BULK_UPDATE_CHANNELS_ERROR";
export const BULK_UPDATE_CHANNELS_IN_PROGRESS =
  "BULK_UPDATE_CHANNELS_IN_PROGRESS";

export const getSpecialityChannels = () => (dispatch) => {
  apiMethods
    .post("Channel/SpecialityChannels")
    .then((response) => {
      dispatch({
        type: GET_SPECIALITY_CHANNELS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error && error.message });
    });
};

export const channelBulkUpdate = (data) => (dispatch) => {
  dispatch({ type: BULK_UPDATE_CHANNELS_IN_PROGRESS });
  return apiMethods
    .post("Channel/BulkChannel", data)
    .then((response) => {
      dispatch({ type: BULK_UPDATE_CHANNELS_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};
export const removeChannel = (data) => (dispatch) => {
  // dispatch({ type: BULK_UPDATE_CHANNELS_IN_PROGRESS });
  return apiMethods
    .deleteRequest("Channel/DeleteChannel", { id: data })
    .then((response) => {
      dispatch({ type: SHOW_ERROR, payload: "Channel deleted successfully." });
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};

export const updateSpecialityChannels = (data) => ({
  type: UPDATE_SPECIALITY_CHANNELS,
  payload: data,
});
