import React from "react";
import google_link from "../../img/en_badge_web_generic.png";
import apple_link from "../../img/apple-app-store-icon.png";
import "./footer.css";
import { withRouter } from "react-router-dom";
import "../../index.css";

class Footer extends React.Component {
  navigate = (type) => {
    switch (type) {
      case "about":
        this.props.history.push("/about");
        break;
      default:
        this.props.history.push("/about");
    }
  };
  isopen = () => {
    this.props.history.push("/privacyPolicys");
  };
  isOpenterm = () => {
    this.props.history.push("/termsAndConditionPage");
  };

  render() {
    return (
      <div>
        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-12 hidden" style={{ margin: "2% 0%" }}>
            <div className="col-md-6 col-xs-12" style={{ float: "left" }}>
              <img
                className="google_link"
                alt="en_badge_web_generic.png"
                src={google_link}
              />
            </div>
            <div className="col-md-6 col-xs-12" style={{ float: "right" }}>
              <img
                alt="apple-app-store-icon.png"
                src={apple_link}
                style={{ height: "82px", marginTop: "3%", marginLeft: "5%" }}
              />
            </div>
          </div>
          <div
            className="col-md-12"
            style={{ marginTop: "4%", marginBottom: "2%", marginLeft: "51px" }}
          >
            <h1
              style={{ fontSize: "20px", textAlign: "center", color: "white" }}
            >
              <span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#939498",
                    textAlign: "left",
                  }}
                >
                  <span style={{ fontSize: "9px" }}>
                    <span
                      // href="/privacyPolicys"
                      className="link"
                      style={{ color: "#939498", cursor: "pointer" }}
                      onClick={this.isopen}
                    >
                      Privacy Policy
                    </span>
                    &nbsp; •&nbsp;&nbsp;
                    <span
                      // href="/termsandconditions"
                      className="link"
                      style={{ color: "#939498", cursor: "pointer" }}
                      onClick={this.isOpenterm}
                    >
                      Terms of Use{" "}
                    </span>
                    <div style={{ marginLeft: "40px" }}>
                      {" "}
                      &nbsp; 2022 ©Healthswim
                    </div>
                  </span>
                </span>
              </span>
            </h1>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
