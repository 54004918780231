import {
  GET_NEXT_STEP_ERROR,
  GET_NEXT_STEP_SUCCESS,
  GET_NEXT_STEP_START,
  GET_PREV_STEP_SUCCESS,
} from "../actions/onboardingStatus.actions";

const initialState = {
  nextStep: null,
  error: null,
  canSkip: false,
  roleError: false,
  prevStep: null,
};

const onboarding = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case GET_NEXT_STEP_START: {
      newState = { ...state, nextStep: null, error: null, prevStep: null };
      break;
    }

    case GET_NEXT_STEP_ERROR: {
      if (action.payload.toUpperCase().indexOf("ROLE") > -1)
        newState = {
          ...state,
          nextStep: null,
          roleError: true,
          prevStep: null,
          error: action.payload.error
        };
      else
        newState = {
          ...state,
          nextStep: null,
          roleError: false,
          prevStep: null,
          error: action.payload.error
        };
      break;
    }
    case GET_NEXT_STEP_SUCCESS: {
      newState = {
        ...state,
        nextStep: action.payload,
        canSkip: action.payload.canSkip,
        prevStep: null,
        error: null
      };
      break;
    }
    case GET_PREV_STEP_SUCCESS:{
      newState = {
        ...state,
        nextStep: null,
        prevStep: action.payload,
        canSkip: action.payload.canSkip,
        error: null
      };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }
  return newState;
};

export default onboarding;
