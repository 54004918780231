import React, { Component } from "react";
import EditProvider from "./editProvider";
import "./orgManageProvider.css";
import ProviderCard from "./providerCard";

export default class orgManageProvider extends Component {
  render() {
    return (
      <div className="manage-form">
        <div className="row">
          <div className="col-12 text-center">
            <div className="user-oval" />
          </div>
        </div>
        <div
          className="row mtb-60 "
          style={{ display: "flex", marginTop: "30px" }}
        >
          <div className="col-4" style={{ margin: "auto" }}>
            <div
              className="manage-form-label"
              style={{ cursor: "pointer" }}
              onClick={this.props.createNewProvider}
            >
              + Create new
            </div>
          </div>
        </div>

        <div>
          <div className="row mb-16  no-gutter">
            {this.props.organizationProviders.length > 0 ? (
              this.props.organizationProviders.map((provider, i) => {
                return (
                  <ProviderCard
                    provider={provider}
                    updateCurrentProviderIndex={
                      this.props.updateCurrentProviderIndex
                    }
                    index={i}
                    key={`organizationProvider_${i}`}
                  />
                );
              })
            ) : (
              <div
                style={{
                  margin: "auto",
                  fontFamily: "proximaNova",
                  fontSize: "30px",
                  color: "#C4C4C4"
                }}
              >
                No providers found
              </div>
            )}
          </div>
        </div>

        <div className="row mb-16  no-gutter">
          <div className="col-12">
            {this.props.organizationProviders[
              this.props.currentProviderIndex
            ] ? (
              <EditProvider
                provider={
                  this.props.organizationProviders[
                    this.props.currentProviderIndex
                  ]
                }
                degrees={this.props.degrees}
                specialities={this.props.specialities}
                departments={this.props.departments}
                locations={this.props.locations}
                certifications={this.props.certifications}
                affiliations={this.props.affiliations}
                conditionsTreated={this.props.conditionsTreated}
                show={this.props.show}
                handleClose={this.props.handleClose}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
