import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";
import { LOGIN_EMAIL } from "./login.actions";
import { Cuuent_password } from "./login.actions";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const changePassword = (data) => (dispatch) => {
  {
    console.log("Cuuent_password", Cuuent_password);
  }
  return apiMethods

    .post("SignIn/ChangePasswordUsingEmail", {
      email: data.email,
      password: data.password,
    })
    .then((response) => {
      dispatch({
        type: Cuuent_password,
        payload: data.password,
      });
      dispatch({ type: SHOW_ERROR, payload: " Password Successfully Updated" });
      // alert("Successfully Updated");
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error && error.message });
    });
};
