import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import ProfessionalLife from "../components/professionalLife/professionalLife";
import {
  saveProfessionalInfo,
  SAVE_PROFESSIONAL_INFO_IN_PROGRESS,
  SAVE_PROFESSIONAL_INFO_SUCCESS,
  getProfessionalInfo
} from "../actions/professionalInfo.actions";
import { getCertifications } from "../actions/certifications.action";
import { getAffiliations } from "../actions/affiliations.action";
import { getConditionsTreated } from "../actions/conditionsTreated.action";
import { ROUTE_PROFESSIONAL_INFO } from "./RouteConstants";

class ProfessionalLifePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "new"
    };

    this.save = this.save.bind(this);
  }

  componentWillMount() {
    this.props.getCertifications();
    this.props.getAffiliations();
    this.props.getConditionsTreated();
    this.props.getProfessionalInfo();
  }

  save(professionalInfo) {
    this.props.saveProfessionalInfo(professionalInfo);
  }

  handleSkip = () =>
    this.props.history.push("/next-step" + ROUTE_PROFESSIONAL_INFO);

  render() {
    if (this.props.saveState === SAVE_PROFESSIONAL_INFO_SUCCESS) {
      return <Redirect to={"/next-step" + ROUTE_PROFESSIONAL_INFO} />;
    }

    const isSaveInProgress =
      this.props.saveState === SAVE_PROFESSIONAL_INFO_IN_PROGRESS;

    return (
      <div className="container-bg">
        <ProfessionalLife
          mode={this.state.mode}
          certifications={this.props.certifications}
          affiliations={this.props.affiliations}
          conditionsTreated={this.props.conditionsTreated}
          saveProfessionalInfo={this.save}
          isSaveInProgress={isSaveInProgress}
          handleSkip={this.handleSkip}
          professionalInfo={this.props.professionalInfo}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  saveState: state.professionalInfoReducer.saveState,
  error: state.professionalInfoReducer.error,
  certifications: state.certifications.certificates,
  affiliations: state.affiliations.affiliations,
  conditionsTreated: state.conditionsTreated.conditionsTreated,
  professionalInfo: state.professionalInfoReducer.professionalInfo
});

const mapDispatchToProps = {
  saveProfessionalInfo,
  getCertifications,
  getConditionsTreated,
  getAffiliations,
  getProfessionalInfo
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfessionalLifePage)
);
