import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";
import { emptyFeed } from "./publishContent.actions";

export const GET_POST_SHARE_SUCCESS = "GET_POST_SHARE_SUCCESS";
export const GET_POST_SHARE_ERROR = "GET_POST_SHARE_ERROR";
export const GET_POST_SHARE_PROGRESS = "GET_POST_SHARE_PROGRESS";

export const UPDATE_HAS_MORE = "UPDATE_HAS_MORE";
export const POST_LIKE_TOGGLE_SUCCESS = "POST_LIKE_TOGGLE_SUCCESS";

export const SAVE_POST_SUCCESS = "SAVE_POST_SUCCESS";
export const REMOVE_POST_SUCCESS = "REMOVE_POST_SUCCESS";

export const SHARE_POST_SUCCESS = "SHARE_POST_SUCCESS";

export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_SUCCESS_MESSAGE = "DELETE_POST_SUCCESS_MESSAGE";

export const getFeed = (feedCriteria) => (dispatch, state) => {
  dispatch({ type: GET_POST_SHARE_PROGRESS });
  apiMethods
    .post("Post/MyFeed", feedCriteria)
    .then(function (response) {
      if (response.data.page === 1) dispatch(emptyFeed());
      dispatch({ type: GET_POST_SHARE_SUCCESS, payload: response.data });
      if (
        response.data.page === Math.ceil(response.data.total / 10) ||
        Math.ceil(response.data.total / 10) === 0
      )
        dispatch({ type: UPDATE_HAS_MORE, payload: false });
      else dispatch({ type: UPDATE_HAS_MORE, payload: true });
    })
    .catch(function (error) {
      // dispatch({
      //   type: SHOW_ERROR,
      //   payload: error.data.message || error.data.error || error.data.text,
      // });
      dispatch({
        type: GET_POST_SHARE_ERROR,
        error: error,
      });
    });
};

export const toggleLikeForPost = (data) => (dispatch) => {
  apiMethods.post("PostLike", data).then(
    (response) => {
      dispatch({ type: POST_LIKE_TOGGLE_SUCCESS, payload: data });
    },
    (error) => {
      dispatch({
        type: GET_POST_SHARE_ERROR,
        error: error,
      });
    }
  );
};

export const savePost = (data) => (dispatch) => {
  apiMethods.post("PostSave", data).then(
    (response) => {
      dispatch({ type: SAVE_POST_SUCCESS, payload: data });
    },
    (error) => {
      dispatch({
        type: GET_POST_SHARE_ERROR,
        error: error,
      });
    }
  );
};

export const removePost = (data) => (dispatch) => {
  apiMethods.deleteRequest(`PostSave?postShareId=${data.postShareId}`).then(
    (response) => {
      dispatch({ type: REMOVE_POST_SUCCESS, payload: data });
    },
    (error) => {
      dispatch({
        type: GET_POST_SHARE_ERROR,
        error: error,
      });
    }
  );
};

export const sharePost = (data) => (dispatch) => {
  return apiMethods.post("PostShare", data).then(
    (response) => {
      dispatch({ type: SAVE_POST_SUCCESS, payload: data });
    },
    (error) => {
      dispatch({
        type: GET_POST_SHARE_ERROR,
        error: error,
      });
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};
// export const deletePost = (data) => (dispatch) => {
//   apiMethods
//     .deleteRequest("Post/Delete", data)

//     .then(
//       (response) => {
//         dispatch({ type: DELETE_POST_SUCCESS, payload: data.index });
//       },
//       (error) => {
//         dispatch({ type: SHOW_ERROR, payload: error.data.message });
//       }
//     );
// };
export const deletePost = (data) => (dispatch) => {
  apiMethods
    .post(`Post/DeleteByProvider?postShareId=${data.postShareId}`)

    .then(
      (response) => {
        dispatch({ type: DELETE_POST_SUCCESS_MESSAGE });
        dispatch({ type: SHOW_ERROR, payload: "Post deleted successfully" });
      },
      (error) => {
        dispatch({ type: SHOW_ERROR, payload: error.data.message });
      }
    );
};

export const getDeletedPosts = (data) => (dispatch) => {
  apiMethods
    .get(`Post/GetDeletedPost?from=${data.from}&&size=${data.size}`)
    .then(
      (response) => {
        dispatch({ type: GET_POST_SHARE_SUCCESS, payload: response.data });
        if (
          response.data.page === Math.ceil(response.data.total / 10) ||
          Math.ceil(response.data.total / 10) === 0
        )
          dispatch({ type: UPDATE_HAS_MORE, payload: false });
        else dispatch({ type: UPDATE_HAS_MORE, payload: true });
      },
      (error) => {
        dispatch({ type: SHOW_ERROR, payload: error.data.message });
      }
    );
};
