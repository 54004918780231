import {
  ORG_INVITE_SUBADMIN_REQUEST,
  ORG_INVITE_SUBADMIN_SUCCESS,
  ORG_INVITE_SUBADMIN_ERROR,
  UPDATE_SAVE_STATE_TO_NULL
} from "../actions/organizationInviteSubadmin.action";

const initialState = {
  state: null,
  error: null
};

const organizationInviteSubadminReducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case ORG_INVITE_SUBADMIN_REQUEST: {
      newState = { state: ORG_INVITE_SUBADMIN_REQUEST, error: null };
      break;
    }
    case ORG_INVITE_SUBADMIN_SUCCESS: {
      newState = { state: ORG_INVITE_SUBADMIN_SUCCESS, error: null };
      break;
    }
    case ORG_INVITE_SUBADMIN_ERROR: {
      newState = { state: ORG_INVITE_SUBADMIN_ERROR, error: action.error };
      break;
    }
    case UPDATE_SAVE_STATE_TO_NULL: {
      newState = { state: null, error: null };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }
  return newState;
};

export default organizationInviteSubadminReducer;
