import baseApiService from "../middleware/baseApiService";

export const SUBSCRIBE_CHANNELS_REQUEST = "SUBSCRIBE_CHANNELS_REQUEST";
export const SUBSCRIBE_CHANNELS_SUCCESS = "SUBSCRIBE_CHANNELS_SUCCESS";
export const SUBSCRIBE_CHANNELS_ERROR = "SUBSCRIBE_CHANNELS_ERROR";

export const GET_CHANNELS_SUBSCRIBED_REQUEST =
  "GET_CHANNELS_SUBSCRIBED_REQUEST";
export const GET_CHANNELS_SUBSCRIBED_SUCCESS =
  "GET_CHANNELS_SUBSCRIBED_SUCCESS";
export const GET_CHANNELS_SUBSCRIBED_ERROR = "GET_CHANNELS_SUBSCRIBED_ERROR";
export const UPDATE_SUBSCRIBE_STATE_TO_NULL = "UPDATE_SUBSCRIBE_STATE_TO_NULL";

export const subscribeChannels = (channelIds) => (dispatch, state) => {
  dispatch({ type: SUBSCRIBE_CHANNELS_REQUEST });
  baseApiService
    .post("FeedSubscription", { channelId: channelIds })
    .then((response) => {
      dispatch({ type: SUBSCRIBE_CHANNELS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: SUBSCRIBE_CHANNELS_ERROR, error: error.data.message });
    });
};

export const getSubscribedChannels = () => (dispatch) => {
  dispatch({ type: GET_CHANNELS_SUBSCRIBED_REQUEST });
  baseApiService
    .post("channel/SubscribedChannels")
    .then((response) => {
      dispatch({
        type: GET_CHANNELS_SUBSCRIBED_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CHANNELS_SUBSCRIBED_ERROR,
        error: error.data.message,
      });
    });
};

export const updateSubscribeStateToNull = () => (dispatch) => {
  dispatch({ type: UPDATE_SUBSCRIBE_STATE_TO_NULL });
};
