import {
  GET_CERTIFICATES_SUCCESS,
  GET_CERTIFICATES_ERROR,
  GET_CERTIFICATES_PROGRESS
} from "../actions/certifications.action";

const initialState = {
  certificates: [],
  error: null
};

const certificatesReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case GET_CERTIFICATES_PROGRESS: {
      newState = { ...state, certificates: [], error: null };
      break;
    }
    case GET_CERTIFICATES_SUCCESS: {
      newState = { ...state, certificates: action.payload, error: null };
      break;
    }
    case GET_CERTIFICATES_ERROR: {
      newState = { ...state, certificates: [], error: action.payload };
      break;
    }
  }

  return newState;
};

export default certificatesReducer;
