import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrgManageLocation from "../components/orgManageLocation/orgManageLocation";
import {
  getorganizationLocations,
  deleteOrganizationLocation,
  updateCurrentLocationIndex,
  updateOrganizationLocation
} from "../actions/organizationLocation.action";
import { getSpecialities } from "../actions/personalProfile.actions";
import { ROUTE_ORG_LOCATION_FROM_MANAGE } from "../containers/RouteConstants";
import { getOrganizationType } from "../actions/organizationProfile.actions";

class OrgManageLocationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocationIndex: 0,
      show: false
    };
  }

  handleClose = () => this.setState({ show: false });

  componentWillMount = () => {
    this.props.getorganizationLocations();
    this.props.getSpecialities();
    this.props.getOrganizationType();
  };

  updateCurrentLocationIndex = index => {
    this.props.updateCurrentLocationIndex(index);
    this.setState({ show: true });
  };

  deleteOrganizationLocation = index => {
    this.props.deleteOrganizationLocation(index);
    this.setState({ show: false });
  };

  createNewLocation = () =>
    this.props.history.push(ROUTE_ORG_LOCATION_FROM_MANAGE);

  render() {
    return (
      <div className="container-white-bg">
        <div className="container">
          <OrgManageLocation
            locations={this.props.locations}
            currentLocationIndex={this.props.currentLocationIndex}
            updateCurrentLocationIndex={this.updateCurrentLocationIndex}
            deleteOrganizationLocation={this.deleteOrganizationLocation}
            updateOrganizationLocation={this.props.updateOrganizationLocation}
            specialities={this.props.specialities}
            createNewLocation={this.createNewLocation}
            show={this.state.show}
            handleClose={this.handleClose}
            orgTypes={this.props.orgTypes}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    locations: state.organizationLocation.locations,
    currentLocationIndex: state.organizationLocation.currentLocationIndex,
    specialities: state.personalProfile.specialities,
    orgTypes: state.organizationProfile.orgTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getorganizationLocations: () => dispatch(getorganizationLocations()),
    deleteOrganizationLocation: id => dispatch(deleteOrganizationLocation(id)),
    updateCurrentLocationIndex: id => dispatch(updateCurrentLocationIndex(id)),
    updateOrganizationLocation: data =>
      dispatch(updateOrganizationLocation(data)),
    getSpecialities: () => dispatch(getSpecialities()),
    getOrganizationType: () => dispatch(getOrganizationType())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrgManageLocationPage)
);
