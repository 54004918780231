import React from "react";
import ManageLists from "../components/manageLists/manageLists";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getListTypeData,
  addNewListTypevalue,
  removeListTypeValue,
  resetState
} from "../actions/orgmanageLists.action";
import { getSpecialities } from "../actions/personalProfile.actions";

class ManageListsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listTypes: [
        { name: "Type of Organizations", value: "orgTypes" },
        { name: "Departments", value: "departments" },
        { name: "specialities", value: "specialities" },
        { name: "Channels by Specialities", value: "specialityChannels" },
        { name: "Physician Degrees", value: "degrees" },
        { name: "Board Certifications", value: "certifications" },
        { name: "Affiliations", value: "affliations" },
        { name: "Conditions Treated", value: "conditionsTreated" }
      ],
      type: null,
      show: false,
      name: null
    };
  }

  componentDidMount = () => {
    this.props.getSpecialities();
  };

  openModal = (type, name) => {
    if (type !== "specialityChannels") this.props.getListTypeData(type);

    this.setState({ type: type, name: name });
    this.setState({ show: true });
  };

  handleClose = () => {
    this.props.resetState();
    this.setState({ type: null, show: false });
  };

  render() {
    return (
      <div>
        <div className="container-bg">
          <ManageLists
            listTypes={this.state.listTypes}
            type={this.state.type}
            show={this.state.show}
            name={this.state.name}
            openModal={this.openModal}
            handleClose={this.handleClose}
            listTypeData={this.props.listTypeData}
            addNewListTypevalue={this.props.addNewListTypevalue}
            removeListTypeValue={this.props.removeListTypeValue}
            specialities={this.props.specialities}
            getListTypeData={this.props.getListTypeData}
            resetState={this.props.resetState}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    listTypeData: state.orgManageLists.listTypeData,
    specialities: state.personalProfile.specialities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getListTypeData: (data, id) => dispatch(getListTypeData(data, id)),
    addNewListTypevalue: (data, type, id) =>
      dispatch(addNewListTypevalue(data, type, id)),
    removeListTypeValue: (index, type, id) =>
      dispatch(removeListTypeValue(index, type, id)),
    resetState: () => dispatch(resetState()),
    getSpecialities: () => dispatch(getSpecialities())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManageListsPage)
);
