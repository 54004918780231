import React from "react";

export default class DepartmentPill extends React.Component {
  render() {
    return (
      <div className="pill mr-4 mb-4">
        {this.props.department.departmentName || ""}
      </div>
    );
  }
}
