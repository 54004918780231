import React from "react";
import MailingList from "../components/mailingList/mailingList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCsv, getSpecialities } from "../actions/mailingList.action";

class MalilingListPage extends React.Component {
  componentDidMount = () => {
    this.props.getSpecialities();
  };

  render() {
    return (
      <div>
        <div className="container-bg">
          <MailingList
            getCsv={this.props.getCsv}
            specialtyList={this.props.specialtyList}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    specialtyList: state.MailingList.specialtyList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCsv: id => dispatch(getCsv(id)),
    getSpecialities: () => dispatch(getSpecialities())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MalilingListPage)
);
