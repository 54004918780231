import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { withRouter, Link } from "react-router-dom";
import * as Yup from "yup";
import {
  REGISTER_SUCCESS,
  requestResendOtpSuccess,
  REGISTER_REQUEST,
} from "../actions/register.actions";

class ResendOtpPage extends Component {
  componentDidUpdate() {
    if (this.props.state === REGISTER_SUCCESS) {
      this.props.history.push("/register-success");
    }
  }
  onSubmit = (values) => {
    this.props.requestResendOtpSuccess({
      username: this.props.email,
      mobile: values.mobile,
    });
  };
  validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Email can't be blank")
      .email("Username should be email."),
  });

  renderForm = (props) => {
    const { handleChange, handleSubmit, values } = props;
    const isRegistrationInProgress = this.props.state === REGISTER_REQUEST;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable">
          <label className="form-lable">Email</label>
          <Field
            type="text"
            value={values.username}
            name="username"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="username" />
          </span>
        </div>

        <div className="form-group form-lable">
          <label className="form-lable">Mobile Number</label>
          <Field
            type="text"
            value={values.mobile}
            name="mobile"
            className="form-control form-input"
          />
        </div>

        <button
          className="set-button"
          type="submit"
          disabled={isRegistrationInProgress}
          style={{
            backgroundColor: "#00d3ff",
            display: "block",
          }}
        >
          {" "}
          GET OTP{" "}
          {isRegistrationInProgress === true ? (
            <span className="text-right pl-2">
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: "#fff" }}
              />
            </span>
          ) : (
            ""
          )}
        </button>
      </form>
    );
  };

  render() {
    return (
      <div className="container-bg">
        <div className="container">
          <div className="set_box">
            <div className="col-md-8 offset-md-2">
              <div className="card">
                <div className="manage-form-header">
                  <div className="manage-form-title">OTP Verification </div>
                </div>

                <div className="form-container h-100">
                  <div>
                    <Formik
                      initialValues={{
                        username: "",
                        mobile: "",
                      }}
                      onSubmit={this.onSubmit}
                      render={this.renderForm}
                      validationSchema={this.validationSchema}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.register.state,
  erorr: state.register.error,
  email: state.register.email,
});
const mapDispatchToProps = {
  requestResendOtpSuccess,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResendOtpPage)
);
