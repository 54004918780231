import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "../actions/errorDisplay.actions";

export const CREATEDEPARTMENTS_IN_PROGRESS = "CREATEDEPARTMENTS_IN_PROGRESS";
export const CREATEDEPARTMENTS_SUCCESS = "CREATEDEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT";
export const REMOVE_DEPARTMENT_FROM_LIST = "REMOVE_DEPARTMENT_FROM_LIST";

export const GET_DEPARTMENTS_LIST_SUCCESS = "GET_DEPARTMENTS_LIST_SUCCESS";

export const createDepartments = (data) => (dispatch) => {
  console.log("createDepartments1");
  dispatch({ type: CREATEDEPARTMENTS_IN_PROGRESS });
  apiMethods.post("Department", data).then(
    (response) => {
      dispatch({ type: CREATEDEPARTMENTS_SUCCESS });
      dispatch(getOrganizationDepartments());
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

export const getOrganizationDepartments = (qString) => (dispatch) => {
  console.log("createDepartments2", qString);
  var url = "";
  if (qString === undefined) url = "department";
  else url = "department" + qString;
  apiMethods.get(url).then(
    (response) => {
      dispatch({ type: GET_DEPARTMENTS_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

export const getDepartmentList = () => (dispatch) => {
  apiMethods.get("DepartmentList").then(
    (response) => {
      dispatch({ type: GET_DEPARTMENTS_LIST_SUCCESS, payload: response.data });
    },
    (error) => {
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    }
  );
};

export const addNewDepartment = (data) => (dispatch) => {
  dispatch({ type: ADD_NEW_DEPARTMENT, payload: data });
};

export const removeDepartmentFromList = (index) => (dispatch) => {
  dispatch({ type: REMOVE_DEPARTMENT_FROM_LIST, payload: index });
};
