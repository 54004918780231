import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";

class OrganizationInviteAdminField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addAnotherAdminAfterSave: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.addAnotherAdmin = this.addAnotherAdmin.bind(this);
    this.onSubmitWithAdd = this.onSubmitWithAdd.bind(this);
    this.formik = React.createRef();
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    ContactPhone: Yup.string().required("Contact Phone is required"),
    // .test("validPhoneNumber", "ContactPhone number is invalid", value => {
    //   return isValidPhoneNumber(value);
    // })
  });

  goLastStep = () => {
    this.props.getPrevStep();
  };

  onSubmit(values) {
    this.props.onSubmit(values, this.state.addAnotherAdminAfterSave);
  }

  onSubmitWithAdd() {
    this.setState({ addAnotherAdminAfterSave: true });
    this.formik.current.submitForm();
  }

  addOneAdmin = () => {
    this.setState({ addAnotherAdminAfterSave: false });
    this.formik.current.submitForm();
  };

  addAnotherAdmin(values) {
    this.setState({ addAnotherAdminAfterSave: false });
    this.formik.current.resetForm();
  }

  renderForm = (props) => {
    const { handleSubmit, values } = props;

    let buttonText = this.props.isBoardingOff === true ? "Done" : "Next";

    return (
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div className="col-12 form-group form-lable">
              <label className="form-lable">First name</label>
              <Field
                type="text"
                value={values.firstName}
                name="firstName"
                className="form-control form-input"
                onChange={(e) => {
                  var name = /^[a-zA-Z]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(name) === null
                  )
                    alert("Please input alphabetic characters only");
                  else {
                    props.setFieldValue("firstName", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="firstName" />
              </span>
            </div>
            <div className="col-12 form-group form-lable">
              <label className="form-lable">Phone</label>
              <Field
                className="form-control form-input"
                name="ContactPhone"
                type="text"
                value={values.ContactPhone}
                onChange={(e) => {
                  var number = /^[0-9]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(number) === null
                  )
                    alert("Please input numeric characters only");
                  else {
                    props.setFieldValue("ContactPhone", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="ContactPhone" />
              </span>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              className=" col-12 form-group form-lable"
              // style={{ marginLeft: "3rem" }}
            >
              <label className="form-lable">Last name</label>
              <Field
                type="text"
                value={values.lastName}
                name="lastName"
                className="form-control form-input"
                onChange={(e) => {
                  var name = /^[a-zA-Z]+$/;
                  if (
                    e.target.value !== "" &&
                    e.target.value.match(name) === null
                  )
                    alert("Please input alphabetic characters only");
                  else {
                    props.setFieldValue("lastName", e.target.value);
                  }
                }}
              />
              <span className="error_text">
                <ErrorMessage name="lastName" />
              </span>
            </div>
            <div className="col-12 form-group form-lable">
              <label className="form-lable">Email</label>
              <Field
                type="email"
                value={values.email}
                name="email"
                className="form-control form-input"
              />
              <span className="error_text">
                <ErrorMessage name="email" />
              </span>
            </div>
          </div>
        </div>
        <div
          className="row mt-5 no-gutter"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <div style={{ margin: "auto 10px auto auto", display: "flex" }}>
            <div
              className="user-icon"
              style={{ cursor: "pointer" }}
              onClick={() => this.onSubmitWithAdd()}
            />
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "left",
              margin: "auto auto auto 0px",
            }}
          >
            <span
              className="form-link"
              onClick={() => this.onSubmitWithAdd()}
              style={{ height: "37px", lineHeight: "37px", cursor: "Pointer" }}
            >
              Save and add another admin
            </span>
          </div>
        </div>

        <div className="mt-5 text-right">
          {/* {this.props.isBoardingOff !== true && (
            <button
              type="button"
              className="btn mr-3 pull-left form-btn"
              onClick={this.goLastStep}
            >
              Back
            </button>
          )}
          {this.props.canSkip ? (
            <span
              className="form-link mr-5"
              onClick={() => this.props.onSkipForNow()}
            >
              Skip for now
            </span>
          ) : null} */}
          <button
            type="button"
            className="btn form-btn"
            id="btnLogin"
            disabled={this.props.isSaveInProgress}
            onClick={this.addOneAdmin}
          >
            {buttonText}
            {this.props.isSaveInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };

  render() {
    let initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      ContactPhone: "",
    };

    return (
      <div className="container">
        <div className="form-rectangle">
          {/* <div className="form-header">
            <h3>WHO WILL MANAGE YOUR ACCOUNT...</h3>
            <p>
              Your primary admin has the ability to manage all facets of your
              account including other providers.
            </p>
          </div> */}
          <div className="form-container h-100" />
          <div className="col-12">
            <div
              style={{
                color: "#4a4a4a",
                fontFamily: "PTSansNarrow",
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: "26px",
                textAlign: "center",
              }}
            >
              When you set up an admin they will receive an email confirmation
              which will allow them to set up their own password.
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-body">
                <Formik
                  innerRef={this.formik}
                  initialValues={initialValues}
                  onSubmit={this.onSubmit}
                  render={this.renderForm}
                  validationSchema={this.validationSchema}
                  // onReset={this.addAnotherAdmin}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationInviteAdminField;
