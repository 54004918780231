import { GET_SPECIALTY_LIST_SUCCESS } from "../actions/mailingList.action";

const intialState = {
  specialtyList: []
};

const MailingList = (state = intialState, action) => {
  switch (action.type) {
    case GET_SPECIALTY_LIST_SUCCESS:
      return { ...state, specialtyList: action.payload };
    default:
      return state;
  }
};

export default MailingList;
