import React from "react";
import "./organizationDepartment.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

class OrganizationDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.addNewDepartmentRef = React.createRef();
  }

  onKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      event.target.value &&
      this.props.departments.findIndex(
        (dept) => dept.departmentName === event.target.value
      ) === -1
    ) {
      this.props.addNewDepartment(event.target.value);
      this._typeahead.getInstance().clear();
    } else {
      if (event.key === "Enter") this._typeahead.getInstance().clear();
    }
  };

  departmentSelected = (department) => {
    if (
      department[0] &&
      department[0].name &&
      this.props.departments.findIndex(
        (dept) => dept.departmentName === department[0].name
      ) === -1
    ) {
      this.props.addNewDepartment(department[0].name);
    }
    this._typeahead.getInstance().clear();
  };

  goLastStep = () => {
    this.props.getPrevStep();
  };

  render() {
    return (
      <div className="container">
        <div className="form-rectangle">
          <div className="form-header">
            <h3>LETS SET UP YOUR DEPARTMENTS…</h3>
            <p>
              Departments are a good way to organize the content you’ll create.
            </p>
          </div>
          <div className="form-container h-100">
            <div className="row">
              <div className="col-4">
                <div className="form-lText ">
                  You can select from the dropdown list or type in your own
                  custom department. Departments allow you to post content on
                  behalf of the organization in an organized way - i.e.
                  Radiology or health tips or general, etc.
                </div>
                <div className="oval department-oval" />
              </div>
              <div className="col-8">
                <div className="form-body" style={{ marginTop: "80px" }}>
                  <label className="form-lable">Department name</label>
                  {/* <input
                    type="text"
                    name="organisationName"
                    className="form-control form-input"
                    onKeyPress={this.onKeyPress}
                    ref={this.addNewDepartmentRef}
                  /> */}
                  <Typeahead
                    multiple
                    placeholder=""
                    labelKey="name"
                    options={this.props.departmentList}
                    onKeyDown={this.onKeyPress}
                    onChange={this.departmentSelected}
                    ref={(ref) => (this._typeahead = ref)}
                  />
                  <div
                    style={{
                      marginTop: "30px",
                      height: "300px",
                      overflow: "auto",
                    }}
                  >
                    {this.props.departments.length > 0
                      ? this.props.departments.map((item, i) => {
                          return (
                            <div className="pill mr-4 mb-4" key={`dept_${i}`}>
                              {item.departmentName}
                              {item.remove ? (
                                <button
                                  tabIndex="-1"
                                  aria-label="Remove"
                                  className="remove-btn"
                                  id={`item_${i}`}
                                  onClick={() => this.props.removeDepartment(i)}
                                >
                                  x
                                </button>
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                  </div>

                  <div className="mt-5 text-right">
                    {/* changes */}
                    {/* {this.props.isBoardingOff !== true && (
                      <button
                        type="button"
                        className="btn mr-3 pull-left form-btn"
                        onClick={this.goLastStep}
                      >
                        Back
                      </button>
                    )} */}
                    {this.props.canSkip ? (
                      <span
                        className="form-link mr-5"
                        onClick={() => this.props.onSkipForNow()}
                        style={{ cursor: "pointer" }}
                      >
                        Skip for now
                      </span>
                    ) : null}
                    <button
                      type="submit"
                      className="btn form-btn"
                      id="btnLogin"
                      onClick={() =>
                        this.props.createDepartments({
                          name: this.props.departments
                            .filter((dept) => dept.remove)
                            .map((dept) => dept.departmentName),
                        })
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationDepartment;
