import {
  REGISTERINFO_ERROR,
  REGISTERINFO_REQUEST,
  REGISTERINFO_SUCCESS
} from "../actions/registerinfo.actions";

const initialState = {
  state: null,
  error: null
};

const registerInfoReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...initialState };
      break;
    }
    case REGISTERINFO_REQUEST: {
      newState = { ...initialState, state: REGISTERINFO_REQUEST, error: null };
      break;
    }
    case REGISTERINFO_ERROR: {
      newState = {
        ...initialState,
        state: REGISTERINFO_ERROR,
        error: action.error
      };
      break;
    }
    case REGISTERINFO_SUCCESS: {
      newState = { ...initialState, state: REGISTERINFO_SUCCESS, error: null };
      break;
    }
  }
  return newState;
};

export default registerInfoReducer;
