import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import OrganizationLocation from "../components/organizationLocation/organizationLocation";
import { getSpecialities } from "../actions/personalProfile.actions";
import {
  setOrganizationLocation,
  updateAddAnotherLocation,
  ADD_ANOTHER_LOCATION,
  SET_ORGANIZATIONLOCATION_SUCCESS,
  UpdateSaveStateToNull,
  SET_ORGANIZATIONLOCATION_IN_PROGRESS
} from "../actions/organizationLocation.action";
import { ROUTE_ORG_LOCATION,ROUTE_ORG_INVITE_ADMIN } from "./RouteConstants";
import { getPrevStep } from "../actions/onboardingStatus.actions";
import { getOrganizationType } from "../actions/organizationProfile.actions";

class OrganizationLocationPage extends Component {
  constructor(props) {
    super(props);
    this.locationRef = React.createRef();
  }

  componentWillMount() {
    this.props.getSpecialities();
    this.props.getOrganizationType();
  }

  componentDidUpdate = () => {
    if (this.props.saveLocationState === ADD_ANOTHER_LOCATION) {
      this.props.UpdateSaveStateToNull();
      this.locationRef.current.addAnotherLocation();
    }
  };

  getPrevStep = () =>{
    this.props.getPrevStep();
  }

  onSkipForNow = () =>
    this.props.history.push(`/next-step${ROUTE_ORG_LOCATION}`);

  render() {
    if(this.props.prevStep !== null && this.props.prevStep.name === "ORGANIZATION-INVITE-ADMIN")
    {
      return <Redirect to={ROUTE_ORG_INVITE_ADMIN} />;
    }
    const isSaveInProgress =
      this.props.saveLocationState === SET_ORGANIZATIONLOCATION_IN_PROGRESS;

    if (this.props.saveLocationState === SET_ORGANIZATIONLOCATION_SUCCESS) {
      return <Redirect to={`/next-step${ROUTE_ORG_LOCATION}`} />;
    }

    return (
      <div className="container-bg">
        <OrganizationLocation
          ref={this.locationRef}
          specialities={this.props.specialities}
          setOrganizationLocation={this.props.setOrganizationLocation}
          updateAddAnotherLocation={this.props.updateAddAnotherLocation}
          addAnotherLocation={this.props.addAnotherLocation}
          skipForNow={this.skipForNow}
          isSaveInProgress={isSaveInProgress}
          canSkip={this.props.canSkip}
          onSkipForNow={this.onSkipForNow}
          onBoarding={true}
          orgTypes={this.props.orgTypes}
          getPrevStep={this.getPrevStep}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  specialities: state.personalProfile.specialities,
  addAnotherLocation: state.organizationLocation.addAnotherLocation,
  saveLocationState: state.organizationLocation.saveLocationState,
  canSkip: state.onboardingStatus.canSkip,
  orgTypes: state.organizationProfile.orgTypes,
  prevStep: state.onboardingStatus.prevStep,
});

const mapDispatchToProps = dispatch => ({
  getSpecialities: () => dispatch(getSpecialities()),
  setOrganizationLocation: data => dispatch(setOrganizationLocation(data)),
  updateAddAnotherLocation: data => dispatch(updateAddAnotherLocation(data)),
  UpdateSaveStateToNull: () => dispatch(UpdateSaveStateToNull()),
  getOrganizationType: () => dispatch(getOrganizationType()),
  getPrevStep: () => dispatch(getPrevStep("?currentStep=ORGANIZATION-LOCATION"))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganizationLocationPage)
);
