import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  requestForgotPasswordEmail,
  LOGIN_ERROR,
  LOG_OUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from "../actions/login.actions";
import LoginPage from "./LoginPage";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import logo from "../../img/thumbnail_hs_logo_horiz.png";
import logo from "../img/thumbnail_hs_logo_horiz.png";
import CheckEmailNotification from "./CheckEmailNotification";
import eye from "../../src/img/eye.png";
import info from "../../src/img/info_icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
class EmailResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      isOpen: false,
    };
  }

  onSubmit = (values) => {
    const location = this.props.location.search;
    const params = new URLSearchParams(location);
    const hash = params.get("hash");

    this.props.requestForgotPasswordEmail({
      hash: hash,
      password: values.password,
    });
  };
  componentDidUpdate() {
    if (this.props.isLoggedIn === true && this.props.state === LOGIN_SUCCESS) {
      this.props.history.push({
        pathname: "/success-reset-password",
        state: this.state.username,
      });
    }
  }
  toggleShow = (field, value) => {
    if (field === "password") this.setState({ showPassword: !value });
    else {
      this.setState({ showConfirmPassword: !value });
    }
  };

  validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters long."),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "Password and confirm password do not match."
      ),
  });

  renderForm = (props) => {
    const { handleChange, handleSubmit, values } = props;

    const isRegistrationInProgress = this.props.state === LOGIN_REQUEST;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group form-lable ">
          <label className="form-lable">Password</label>
          <OverlayTrigger
            placemeKnt="right"
            overlay={
              <Tooltip id="ph_help">
                Password should be minimum 8 characters long.
              </Tooltip>
            }
          >
            <img
              src={info}
              alt="Show"
              className="pull-right"
              style={{ width: "4%", marginRight: "3%" }}
            ></img>
          </OverlayTrigger>
          <div className="input-group ">
            <Field
              type={this.state.showPassword ? "text" : "password"}
              value={values.password}
              name="password"
              className="form-control form-input"
            />
            <span
              class="input-group-addon"
              style={{ borderBottom: "1px solid black", textAlign: "center" }}
            >
              <img
                src={eye}
                alt="Show"
                style={{ width: "50%" }}
                onClick={() => {
                  this.toggleShow("password", this.state.showPassword);
                }}
              />
            </span>
          </div>
          <span className="error_text">
            <ErrorMessage name="password" />
          </span>
        </div>

        <div className="form-group form-lable ">
          <label className="form-lable">Confirm password</label>
          <div className="input-group">
            <Field
              type={this.state.showConfirmPassword ? "text" : "password"}
              value={values.confirmPassword}
              name="confirmPassword"
              className="form-control form-input"
            />
            <span
              className="input-group-addon"
              style={{ borderBottom: "1px solid black", textAlign: "center" }}
            >
              <img
                src={eye}
                alt="Show"
                style={{ width: "50%" }}
                onClick={() => {
                  this.toggleShow(
                    "confirm_password",
                    this.state.showConfirmPassword
                  );
                }}
              />
            </span>
          </div>
          <span className="error_text">
            <ErrorMessage name="confirmPassword" />
          </span>
        </div>

        <div className="mt-10" style={{ textAlign: "right" }}>
          <button
            className="set-button"
            type="submit"
            disabled={isRegistrationInProgress}
            style={{
              backgroundColor: "#00d3ff",
              display: "block",
            }}
          >
            Reset Password
            {isRegistrationInProgress === true ? (
              <span className="text-right pl-2">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ color: "#fff" }}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div>
        <div className="form-rectangle set-box">
          <div className="password-headers">
            <img src={logo} alt="symbol" className="logo-set" />
            <div className="center-texts">Reset your password</div>
          </div>
          <div
            style={{ marginLeft: "18px", marginRight: "29px" }}
            className="message"
          >
            {" "}
            Enter your new password. After confirming, you will be asked to log
            in again.
          </div>
          <div className="form-container h-100">
            <div>
              <Formik
                initialValues={{
                  username: "",
                }}
                onSubmit={this.onSubmit}
                render={this.renderForm}
                validationSchema={this.validationSchema}
              />
            </div>
          </div>
        </div>
        {/* <div style={{ textAlign: "center" }}>
          Never mind!{" "}
          <Link to="/login" style={{ color: "black", fontWeight: "500" }}>
            {" "}
            Take me back to login
          </Link>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.login.state,
  error: state.login.error,
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = {
  requestForgotPasswordEmail,
  CheckEmailNotification,
  LoginPage,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailResetPassword)
);
