import React, { Component } from "react";
import "./mobiletermsAndCondition.css";
import tokenService from "../../middleware/tokenService";

export default class mobileTermsAndCondition extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.props.saveAccept();
  }

  render() {
    return (
      <div className="terms">
        <p>
          <span className="heading">Healthswim Terms of Use</span>
        </p>
        <p>
          {" "}
          Please read these Terms of Use (“TOU”) carefully before accessing,
          downloading or using the Services, which are the services Healthswim
          agrees to provide Subscriber. By accessing, downloading or using the
          Services, you, in your role as a healthcare professional (the
          “Subscriber”), agree individually to be bound by the terms of these
          TOU. Any such person hereby represents and warrants that he or she has
          authority, or has been provided authority, to bind themselves to these
          TOU.
        </p>
        <p>
          Health Swim LLC, a limited liability company (“Healthswim”) is only
          willing to grant Subscriber access to the Services if Subscriber
          accepts all of the terms of these TOU. Each of Subscriber and
          Healthswim may be referred to herein as a “party,” and Subscriber and
          Healthswim together may be referred to as the “parties.”
        </p>
        <p>
          <span className="heading">1) PERMITTED USES AND RESTRICTIONS</span>
        </p>
        <p>
          <ul style={{ listStyleType: "none" }}>
            <li>
              a) Subject to the terms of these TOU, during the applicable TOU
              Term (defined below), Healthswim grants Subscriber a
              non-exclusive, non-transferable and non-sublicensable right for
              Subscriber to access, download and use the Services in accordance
              with Healthswim’s applicable documentation.
            </li>
            <li>
              b) Subscriber is granted permission to access, download and use
              the Services, provided that Subscriber shall:
              <ul style={{ listStyleType: "none" }}>
                <li>
                  i) Not use the Services for any illegal or unauthorized
                  purpose, or beyond the scope of the Services’ expected use;
                </li>
                <li>
                  ii) Not intentionally interfere with the operation of the
                  Services or with any other person’s use of the Services;
                </li>
                <li>
                  iii) Not intentionally gain unauthorized access to the
                  Services;
                </li>
                <li>iv) Not merge the Services with other software;</li>
                <li>
                  v) Not resell, sell, lease, lend, redistribute, sublicense,
                  assign or otherwise transfer the Services to any third party;
                </li>
                <li>
                  vi) Not use the Services for any purpose other than
                  Subscriber’s internal business needs;
                </li>
                <li>
                  vii) Not use the Services to compete against Healthswim;
                </li>
                <li>
                  viii) Not alter, modify, adapt, reverse engineer, decompile,
                  disassemble or hack the Services, create derivative works from
                  the Services or otherwise attempt to derive the source code
                  for the Services;
                </li>
                <li>
                  ix) Not remove or alter any copyright notices or other notices
                  included in the Services;
                </li>
                <li>
                  x) Not alter or modify another website so as to falsely imply
                  that it is associated with Healthswim;
                </li>
                <li>
                  xi) Not use the Services to create or transmit unwanted email,
                  junk email, bulk email, promotions, spam or content that
                  includes any advertising;
                </li>
                <li>
                  xii) Not intentionally transmit any viruses, bugs, worms, or
                  any other computer code of a destructive nature or that may
                  harm a network, computer, server, hardware, software or
                  telephone equipment using the Services;
                </li>
                <li>
                  xiii) Not violate anyone else’s legal rights (e.g. privacy
                  rights) or any laws (e.g. copyright laws) in Subscriber’s
                  jurisdiction while using the Services;
                </li>
                <li>
                  xiv) Remain solely responsible for any message data sent from
                  Subscriber’s account;
                </li>
                <li>
                  xv) Use the Services in accordance with all applicable laws;
                </li>
                <li>
                  xvi) Not use any data mining, robots or similar data gathering
                  and extraction methods in connection with the Services;
                </li>
                <li>
                  xvii) Not impose an unreasonable burden on the Services or
                  network;
                </li>
                <li>
                  xviii) Not breach, or attempt to breach, the security of the
                  Services;
                </li>
                <li>
                  xix) Not use the Services or export the Services in violation
                  of U.S. export laws and regulations;
                </li>
                <li>
                  xx) Not solicit any activity, unlawful or otherwise, that
                  infringes Healthswim’s rights or the rights of any other
                  party;
                </li>
                <li>
                  xxi) Not enable others to violate any of these terms and
                  conditions; and
                </li>
                <li>
                  xxii) Ensure that all users of the Services are at least 18
                  years of age or older or have received the consent of a parent
                  or guardian if under 18 years of age.
                </li>
              </ul>
              <li>
                c) Any violation of these TOU by Subscriber may result in the
                termination of Subscriber’s Healthswim account and/or
                Subscriber’s ability to access or use the Services.
              </li>
            </li>
          </ul>
        </p>

        <p>
          <span className="heading">2) TERM AND TERMINATION</span>
        </p>
        <ul className="unordered-list">
          <li>
            a) These TOU commences on the date that each Subscriber agrees to
            these terms and shall remain in effect until the Subscriber ceases
            use of the Services for a period of thirty (30) days, Subscriber
            removes Subscriber’s account, or the Services Agreement expires or
            terminates, whichever is earlier (the “TOU Term”).
          </li>
          <li>
            b) If the potential Subscriber organization and Healthswim have not
            entered into a Services Agreement, the Effective Date is the date
            Subscriber accepts the terms herein or first accesses, downloads or
            uses any of the Services, and the TOU Term shall be for so long as
            Subscriber uses or accesses any of the Services.
          </li>
          <li>
            c) Healthswim may terminate these TOU without penalty when
            Subscriber has materially breached the terms of these TOU and fails
            to cure such breach within fifteen (15) days’ notice of such breach.{" "}
          </li>
          <li>
            d) Upon termination of the TOU Term, Subscriber shall no longer be
            permitted to use or access the Services. The terms herein that
            contemplate obligations after the TOU Term, including but not
            limited to Indemnification, Disclaimer, Limitation of Liability,
            Controlling Law and Severability, and Confidentiality, shall survive
            termination.
          </li>
        </ul>

        <p>
          <span className="heading">3) SUBSCRIBER ACKNOWLEDGEMENTS</span>
        </p>
        <ul className="unordered-list">
          <li>
            a) Subscriber acknowledges that the Services, and their respective
            structures, organization, documentation, software and source code,
            constitute Healthswim’s valuable intellectual property owned solely
            by Healthswim and its licensors. Healthswim does not grant, and
            Subscriber acknowledges that it has no right, license or interest
            in, any patents, copyrights, trademarks, or trade secrets owned,
            used or claimed now or in the future by Healthswim. No title to or
            ownership of the intellectual property contained in (a) the
            Services, the software or any part of the Services or the software,
            (b) any enhancements, updates, modifications, local versions or any
            derivatives of the Services or the software, (c) any and all
            intellectual property and proprietary rights therein, or (d)
            Healthswim’s confidential information is transferred to Subscriber.
          </li>
          <li>
            b) “Healthswim” and other Healthswim graphics, logos, designs, page
            headers, button icons, scripts and service names are trademarks in
            the United States and other countries. Healthswim’s trademarks and
            trade dress, as well as third-party trademarks, logos and service
            marks used in conjunction with the Services, may not be used in
            connection with any product or service in any manner that is likely
            to cause confusion and may not be copied, imitated, or used, in
            whole or in part, without Healthswim’s prior written permission.
          </li>
          <li>
            c) Subscriber fully understands and acknowledges that: (a) the
            messaging system that may comprise part of the Services is intended
            to deliver non-critical, non-emergency messages between users; (b)
            the Services are dependent upon a number of factors outside
            Healthswim’s control, including but not limited to the operation of
            third party provided hardware and network services; (c) the Services
            are not a substitute for any of Subscriber’s current systems of
            administering and safeguarding medical treatment and/or medicine;
            (d) there may be occasional communication failures or delays in
            delivering or receiving properly sent Healthswim messages or in
            Subscriber being able to access or use the Services in the manner
            intended; (e) the Services are not expected to perform at the same
            level of performance and/or reliability one might expect from
            medical devices used in delivering critical medical care
            environments; and (f) Subscriber is solely responsible for providing
            to Healthswim accurate information regarding Subscriber’s account or
            the delivery of the Services, including, without limitation, user
            information, changes in personnel, phone number changes and email
            address changes.
          </li>
        </ul>

        <p>
          <span className="heading">4) MOBILE DEVICE APP TERMS</span>
        </p>
        <ul className="unordered-list">
          <li>
            a) The following additional terms and conditions apply with respect
            to any Mobile Application that Healthswim provides to you designed
            for use on an Apple iOS-powered mobile device (an “iOS App”):
            <ul className="unordered-list">
              <li>
                i) You acknowledge that these Terms are between you and
                Healthswim only, and not with Apple, Inc. (“Apple”).
              </li>
              <li>
                {" "}
                ii) Your use of Healthswim’s iOS App must comply with Apple’s
                then-current App Store Terms of Service.
              </li>
              <li>
                iii) Healthswim, and not Apple, is solely responsible for our
                iOS App and the Services and content available thereon. You
                acknowledge that Apple has no obligation to provide maintenance
                and support services with respect to our iOS App. To the maximum
                extent permitted by applicable law, Apple will have no warranty
                obligation whatsoever with respect to our iOS App.
              </li>
              <li>
                iv) You agree that Healthswim, and not Apple, is responsible for
                addressing any claims by you or any third-party relating to our
                iOS App or your possession and/or use of our iOS App, including,
                but not limited to: (i) product liability claims; (ii) any claim
                that the iOS App fails to conform to any applicable legal or
                regulatory requirement; and (iii) claims arising under consumer
                protection or similar legislation, and all such claims are
                governed solely by these Terms and any law applicable to us as
                provider of the iOS App.
              </li>
              <li>
                v) You agree that Healthswim, and not Apple, shall be
                responsible, to the extent required by these TOU for the
                investigation, defense, settlement and discharge of any
                third-party intellectual property infringement claim related to
                our iOS App or your possession and use of our iOS App.
              </li>
              <li>
                vi) You represent and warrant that (i) you are not located in a
                country that is subject to a U.S. Government embargo or that has
                been designated by the U.S. Government as a “terrorist
                supporting” country; and (ii) You are not listed on any U.S.
                Government list of prohibited or restricted parties.
              </li>
              <li>
                vii) You agree to comply with all applicable third-party terms
                of agreement when using our iOS App (e.g., you must not be in
                violation of your wireless data service terms of agreement when
                using the iOS App).
              </li>
              <li>
                viii) The parties agree that Apple and Apple’s subsidiaries are
                third-party beneficiaries to these terms as they relate to your
                license of Healthswim’s iOS App. Upon your acceptance of these
                terms, Apple will have the right (and will be deemed to have
                accepted the right) to enforce these terms against you as they
                relate to your license of the iOS App as a third-party
                beneficiary thereof.
              </li>
            </ul>
          </li>

          <li>
            b) The following additional terms and conditions apply with respect
            to any Mobile Application that Healthswim provides to you designed
            for use on an Android-powered mobile device (an “Android App”):
            <ul className="unordered-list">
              <li>
                i) You acknowledge that these terms are between you and
                Healthswim only, and not with Google, Inc. (“Google”).
              </li>
              <li>
                ii) Your use of Healthswim’s Android App must comply with
                Google’s then-current Android Market Terms of Service.
              </li>
              <li>
                iii) Google is only a provider of the Android Market where you
                obtained the Android App. Healthswim, and not Google, is solely
                responsible for Healthswim’s Android App and the Services and
                content available thereon. Google has no obligation or liability
                to you with respect to Healthswim’s Android App or these terms.
              </li>
              <li>
                iv) You acknowledge and agree that Google is a third-party
                beneficiary to the terms as they relate to Healthswim’s Android
                App.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <span className="heading">5) PRIVACY NOTICE</span>
        </p>
        <ul className="unordered-list">
          <li>
            These TOU is subject to Subscriber’s acceptance of the terms and
            conditions set forth in Healthswim’s Privacy Notice (the “Privacy
            Notice”), the terms of which are incorporated into these TOU, and
            which can be found at{" "}
            <a href="https://www.healthswim.com/mobile-privacy">
              www.healthswim.com/privacy
            </a>
            . The terms and conditions in the Privacy Notice may be changed by
            Healthswim in its sole discretion, and those changes become
            effective upon posting. It is the Subscriber’s responsibility to
            review the Privacy Notice for any revisions.
          </li>
        </ul>

        <p>
          <span className="heading">6) CONFIDENTIAL INFORMATION</span>
        </p>
        <ul className="unordered-list">
          <li>
            “Confidential Information” means any non-public data, information
            and other materials regarding the products, software, services,
            prices, discounts or business of a party (or of third parties, to
            the extent a party is bound to protect the confidentiality of any
            third party’s information) provided by a party, its employees,
            contractors or affiliates (“Disclosing Party”) to the other party
            (“Receiving Party”) where such information is marked or otherwise
            communicated as being “proprietary” or “confidential” or the like,
            or where such information should, by its nature, be reasonably
            considered to be confidential or proprietary. The parties agree,
            without limiting the foregoing, that any performance and security
            data, product roadmaps, source code, benchmark results and technical
            information relating to the Services, including pricing information,
            shall be deemed Healthswim’s Confidential Information.
            Notwithstanding the foregoing, Confidential Information shall not
            include information which: (a) is already known to the Receiving
            Party without obligations of confidentiality prior to disclosure by
            the Disclosing Party; (b) becomes publicly available without fault
            of the Receiving Party; (c) is rightfully obtained by the Receiving
            Party from a third party without restriction as to disclosure, or is
            approved for release by written authorization of the Disclosing
            Party; (d) is independently developed or created by the Receiving
            Party without using Disclosing Party’s Confidential Information; or
            (e) is required to be disclosed by law or governmental regulation,
            provided that Receiving Party provides reasonable notice to
            Disclosing Party of such required disclosure to the extent allowed
            by law, and reasonably cooperates with Disclosing Party in limiting
            such disclosure. Except as expressly authorized herein, Receiving
            Party shall: (i) use the Confidential Information of the Disclosing
            Party only to perform hereunder or exercise rights granted to it
            hereunder; and (ii) treat all Confidential Information of the
            Disclosing Party in the same manner as it treats its own similar
            proprietary information, but in no case with less than reasonable
            care.
          </li>
        </ul>

        <p>
          <span className="heading">7) INDEMNIFICATION</span>
        </p>
        <ul className="unordered-list">
          <li>
            Subscriber agrees to indemnify, hold harmless and defend Healthswim
            and its officers, shareholders, predecessors, successors in
            interest, directors, employees, agents, subsidiaries, affiliates,
            licensors and suppliers from and against any and all claims,
            charges, complaints, damages, losses, liabilities, costs and
            expenses (including attorneys’ fees and experts’ fees) due to,
            arising out of or relating in any way to Subscriber’s use of, or
            access to, the Services.
          </li>
        </ul>

        <p>
          <span className="heading">8) DISCLAIMER</span>
        </p>
        <ul className="unordered-list">
          <li>
            SUBSCRIBER EXPRESSLY ACKNOWLEDGES AND AGREES THAT, TO THE EXTENT
            PERMITTED BY APPLICABLE LAW, AND NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED IN ANY AGREEMENT BETWEEN SUBSCRIBER AND
            HEALTHSWIM, USE OF THE SERVICES IS AT SUBSCRIBER’S SOLE RISK AND
            THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
            ACCURACY, AND EFFORT IS WITH SUBSCRIBER. TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, THE SERVICES ARE PROVIDED “AS IS” AND “AS
            AVAILABLE” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND.
            HEALTHSWIM AND ITS LICENSORS HEREBY DISCLAIM ALL WARRANTIES AND
            CONDITIONS WITH RESPECT TO THE SERVICES, EITHER EXPRESS, IMPLIED OR
            STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
            AND/OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS
            FOR A PARTICULAR PURPOSE, ACCURACY, QUIET ENJOYMENT AND
            NON-INFRINGEMENT OF THIRD PARTY RIGHTS. HEALTHSWIM DOES NOT WARRANT
            AGAINST INTERFERENCE WITH SUBSCRIBER’S ENJOYMENT OF THE SERVICES,
            THAT THE FUNCTIONS CONTAINED IN OR PERFORMED BY THE SERVICES WILL
            MEET SUBSCRIBER’S REQUIREMENTS, THAT THE OPERATION OF THE SERVICES
            WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY UPDATE WILL CONTINUE
            TO BE MADE AVAILABLE, THAT DEFECTS IN THE SERVICES WILL BE CORRECTED
            OR THAT THE SERVICES WILL BE COMPATIBLE OR WORK WITH ANY THIRD PARTY
            SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES. INSTALLATION OF THE
            SERVICES MAY AFFECT THE USABILITY OF THIRD PARTY SOFTWARE,
            APPLICATIONS OR THIRD PARTY SERVICES. SUBSCRIBER FURTHER
            ACKNOWLEDGES THAT THE SERVICES ARE NOT INTENDED OR SUITABLE FOR USE
            IN SITUATIONS OR ENVIRONMENTS WHERE THE FAILURE OR TIME DELAY OF, OR
            ERRORS OR INACCURACIES IN, THE CONTENT, DATA OR INFORMATION PROVIDED
            BY THE SERVICES COULD LEAD TO DEATH, PERSONAL INJURY OR SEVERE
            PHYSICAL, FINANCIAL OR ENVIRONMENTAL DAMAGE. SOME JURISDICTIONS DO
            NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON
            APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSIONS
            AND LIMITATIONS MAY NOT APPLY IN THOSE JURISDICTIONS.
          </li>
        </ul>

        <p>
          <span className="heading">9) LIMITATION OF LIABILITY</span>
        </p>
        <ul className="unordered-list">
          <li>
            Except where prohibited by law, in no event will Healthswim, its
            officers, shareholders, predecessors, successors in interest,
            directors, employees, agents, subsidiaries, affiliates, licensors or
            suppliers be liable for any indirect, special, punitive, incidental,
            exemplary or consequential damages even if Healthswim has been
            advised of the possibility of such damages or any direct damages
            that result from: (1) the use of, or inability to use, the Services;
            (2) the performance of the Services; or (3) any failure in the
            Services. Subscriber assumes total responsibility for using the
            Services. Subscriber’s only remedy against Healthswim for
            dissatisfaction with the Services is to stop using the Services. If,
            notwithstanding these terms, Healthswim is found liable to
            Subscriber for any damage or loss that arises out of or is in any
            way connected with Subscriber’s use of the Services, Healthswim’s
            liability shall in no event exceed the amount paid by Subscriber
            personally and individually to Healthswim during the previous twelve
            (12) months. In addition to the foregoing limitations, Subscriber
            agrees that Subscriber will not join any claim against Healthswim
            with the claim of any other person or entity in a lawsuit,
            arbitration or other proceedings; that no claim Subscriber has
            against Healthswim shall be resolved on a class-wide basis; and that
            Subscriber will not assert a claim in a representative capacity
            against Healthswim on behalf of anyone else.
          </li>
        </ul>

        <p>
          <span className="heading">10) MISCELLANEOUS</span>
        </p>
        <ul className="unordered-list">
          <li>
            a) Governing Law and Venue. These TOU and all matters arising out of
            or relating to these TOU shall be governed by and construed in
            accordance with the laws of the State of Washington, without regard
            to any conflict of law provisions. Each party irrevocably agrees
            that any claim brought by it in any way arising out of these TOU or
            the Services must be brought solely and exclusively in state or
            federal court located in Washington, and each party irrevocably
            accepts and submits to the sole and exclusive jurisdiction of such
            courts, generally and unconditionally, with respect to any action,
            suit, or proceeding brought by it or against it by the other party.
          </li>
          <li>
            b) Assignment. Neither party may assign, subcontract, delegate or
            otherwise transfer these TOU or any of its rights or obligations
            hereunder, nor may it contract with third parties to perform any of
            its obligations hereunder except as contemplated in these TOU,
            without the other party’s prior written consent; provided, however,
            that a reorganization, merger or sale of all, or substantially all,
            of Healthswim’s assets or equity shall not be considered an
            assignment under this provision and Subscriber’s written consent
            shall not be needed to consummate such a transaction.
          </li>
          <li>
            c) Entire Agreement. These TOU shall constitute the entire agreement
            between Healthswim and Subscriber and contains all of the
            understandings and agreements of the parties in respect of the
            subject matter hereof. Any and all prior understanding and
            agreements, expressed or implied, between the parties in respect of
            the subject matter of these TOU are superseded hereby.
          </li>
          <li>
            d) Severability. If any terms or provisions in these TOU or any
            application thereof is declared by a court of competent jurisdiction
            to be invalid, illegal or unenforceable in any respect, the
            validity, legality, and enforceability of the remaining provisions
            of these TOU or any application thereof shall not in any way be
            affected or impaired, except that, in such event, these TOU shall be
            deemed revised in order to provide the party adversely affected by
            such declaration with the benefit of its expectation, evidenced by
            the provision(s) affected by such declaration, to the maximum extent
            legally permitted.
          </li>
          <li>
            e) Modifications and Amendments. Healthswim may modify or amend
            these TOU at any time and such modification or amendment will take
            effect thirty (30) days after Healthswim makes the updated terms
            available to Subscriber. No course of conduct shall constitute an
            amendment or modification of these TOU.
          </li>
          <li>
            f) Force Majeure. Healthswim shall not be liable to Subscriber or
            others for failure to perform under these TOU if the failure
            results, directly or indirectly, from government action or inaction,
            mechanical or electrical breakdown, war, civil, unrest, natural
            disaster or other cause beyond its reasonable control. If either
            party is affected by an interruption or delay contemplated by this
            Section, it will: (a) promptly provide notice to the other party,
            explaining the full particulars and the expected duration of the
            delay and (b) use its commercially reasonable efforts to remedy the
            interruption or delay if it is reasonably capable of being remedied.
          </li>
          <li>
            g) Breach and Waiver. No waiver of any breach of these TOU shall:
            (a) be effective unless it is in a writing which is executed by the
            party charged with the waiver, or (b) constitute a waiver of a
            subsequent breach, whether or not of the same nature. All waivers
            shall be strictly construed. No delay in enforcing any right or
            remedy as a result of a breach of these TOU shall constitute a
            waiver thereof.
          </li>
          <li>
            h) Survival of Terms. Notwithstanding anything contained herein to
            the contrary, all of Healthswim’s and Subscriber’s respective
            obligations, representations and warranties under these TOU that are
            not, by the express terms of these TOU, fully to be performed while
            these TOU is in effect shall survive the termination of these TOU
            for any reason.
          </li>
        </ul>
      </div>
    );
  }
}
