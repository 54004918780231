import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";
import Axios from "axios";
// import { LOGIN_VALUE } from "../actions/login.actions";
export const GET_NEXT_STEP_START = "GET_NEXT_STEP_START";
export const GET_NEXT_STEP_SUCCESS = "GET_NEXT_STEP_SUCCESS";
export const GET_NEXT_STEP_ERROR = "GET_NEXT_STEP_ERROR";
export const GET_PREV_STEP_SUCCESS = "GET_PREV_STEP_SUCCESS";

export const updateOnBoardingStatus = (data) => (dispatch) => {
  dispatch({ type: GET_NEXT_STEP_START, payload: null });
  apiMethods
    .post("OnboardingStatus/MarkStepAsDone", { StepToBeDone: data })
    .then((response) => {
      dispatch(getNextStep());
    })
    .catch((error) => {
      dispatch({ type: SHOW_ERROR, payload: error.message });
    });
};
{
  // console.log("LOGIN_VALUE", LOGIN_VALUE);
}
export const getNextStep = (data) => (dispatch) => {
  apiMethods
    .post("OnboardingStatus/GetNextStep", {
      email: data,
      mobile: localStorage.getItem("mobile"),
    })
    .then((response) => {
      dispatch({ type: GET_NEXT_STEP_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      // dispatch({ type: GET_NEXT_STEP_ERROR, payload: error.data.message });
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    });
};

export const getPrevStep = (qString) => (dispatch) => {
  apiMethods
    .post("OnboardingStatus/GetPrevStep" + qString)
    .then((response) => {
      dispatch({ type: GET_PREV_STEP_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_NEXT_STEP_ERROR, payload: error.data.message });
      dispatch({ type: SHOW_ERROR, payload: error.data.message });
    });
};
