import apiMethods from "../middleware/baseApiService";
import { SHOW_ERROR } from "./errorDisplay.actions";

export const GET_POSTSHARE_SUCCESS = "GET_POSTSHARE_SUCCESS";
export const GET_POSTSHARE_REQUEST = "GET_POSTSHARE_REQUEST";
export const GET_POSTSHARE_ERROR = "GET_POSTSHARE_ERROR";

export const getPostShare = id => dispatch => {
  apiMethods
    .get("NoAuthPostShare?externalPostShareId=" + id)
    .then(response => {
      dispatch({ type: GET_POSTSHARE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SHOW_ERROR, payload: error && error.message });
      dispatch({ type: GET_POSTSHARE_ERROR, payload: error.message });
    });
};
