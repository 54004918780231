import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

class Premium extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (values) => {
    this.props.submit(values);
  };
  onSkip = () => {
    this.props.onSkipForNow();
  };
  validationSchema = Yup.object().shape({
    code: Yup.string()
      .min(6, "Code should be of 6 digits")
      .required("Code is required"),

    amount: Yup.string().required("Amount is required"),
  });
  render() {
    let initialValues = {
      code: "",
      amount: "",
    };
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validationSchema={this.validationSchema}
        />
      </div>
    );
  }
  renderForm = (props) => {
    const { handleSubmit, values } = props;

    var title = "";

    return (
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="form-rectangle">
            <div className="form-header">
              <h3>CREATE A PREMIUM ACCOUNT?</h3>
            </div>
            <div className="form-container h-100">
              <div className="row">
                <div className="col-4">
                  <div className="form-lText ">
                    <div className="oval profileInfo-oval" />
                    You can create premium account if you want to have content
                    behind a paywall or accessible by code.
                  </div>
                </div>
                <div className="col-8">
                  <div className="form-body">
                    <div className="form-group form-lable">
                      <label className="form-lable">Create a code</label>
                      <Field
                        className="form-control form-input"
                        name="code"
                        onChange={(e) => {
                          var number = /^[0-9]+$/;
                          if (
                            e.target.value !== "" &&
                            e.target.value.match(number) === null
                          )
                            alert("Please input numeric characters only");
                          else if (
                            e.target.value.length === 10 &&
                            parseInt(e.target.value) <= 0
                          )
                            alert("Please enter value greater than 0");
                          else {
                            props.setFieldValue("code", e.target.value);
                          }
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="code" />
                      </span>
                    </div>
                    <div className="form-group form-lable">
                      <label className="form-lable">
                        How much would you charge a patient for monthly access
                        (in USD)?
                      </label>
                      <Field
                        className="form-control form-input"
                        name="amount"
                        onChange={(e) => {
                          var number = /^[0-9]+$/;
                          if (
                            e.target.value !== "" &&
                            e.target.value.match(number) === null
                          )
                            alert("Please input numeric characters only");
                          else if (
                            e.target.value.length === 10 &&
                            parseInt(e.target.value) <= 0
                          )
                            alert("Please enter value greater than 0");
                          else {
                            props.setFieldValue("amount", e.target.value);
                          }
                        }}
                      />
                      <span className="error_text">
                        <ErrorMessage name="amount" />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "38px",
                      }}
                    >
                      <label className="error_text">
                        {/* Healthswim will receive 30% of these proceeds. */}
                      </label>
                    </div>
                    <div className=" text-right">
                      <span
                        className="form-link  mr-5"
                        onClick={this.onSkip}
                        style={{ cursor: "pointer" }}
                      >
                        Skip for now
                      </span>
                      <button
                        type="submit"
                        disabled={this.props.isSaveInProgress}
                        className="btn form-btn"
                        id="btnLogin"
                      >
                        Next
                        {/* {this.props.isSaveInProgress === true ? (
                            <span className="text-right pl-2">
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ color: "#fff" }}
                              />
                            </span>
                          ) : (
                            ""
                         */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
}

export default Premium;
