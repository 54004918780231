import {
  GET_SPECIALITY_CHANNELS_SUCCESS,
  UPDATE_SPECIALITY_CHANNELS,
  BULK_UPDATE_CHANNELS_IN_PROGRESS,
  BULK_UPDATE_CHANNELS_SUCCESS,
} from "../actions/channelsetup.actions";

const intialState = {
  specialityChannels: [],
  channelUpdateState: null,
};

const channelSetup = (state = intialState, action) => {
  switch (action.type) {
    case GET_SPECIALITY_CHANNELS_SUCCESS:
      return {
        ...state,
        channelUpdateState: null,
        specialityChannels: action.payload,
        state: GET_SPECIALITY_CHANNELS_SUCCESS,
      };
    case UPDATE_SPECIALITY_CHANNELS:
      return {
        ...state,
        channelUpdateState: null,
        specialityChannels: action.payload,
        state: UPDATE_SPECIALITY_CHANNELS,
      };
    case BULK_UPDATE_CHANNELS_IN_PROGRESS:
      return {
        ...state,
        channelUpdateState: BULK_UPDATE_CHANNELS_IN_PROGRESS,
        state: BULK_UPDATE_CHANNELS_IN_PROGRESS,
      };
    case BULK_UPDATE_CHANNELS_SUCCESS:
      return {
        ...state,
        channelUpdateState: BULK_UPDATE_CHANNELS_SUCCESS,
        state: BULK_UPDATE_CHANNELS_SUCCESS,
      };
    default:
      return state;
  }
};

export default channelSetup;
