import {
  DEPTADMIN_SEARCH_REQUEST,
  DEPTADMIN_SEARCH_SUCCESS,
  DEPTADMIN_SEARCH_ERROR,
  DEPTADMIN_ADD_REQUEST,
  DEPTADMIN_ADD_SUCCESS,
  DEPTADMIN_ADD_ERROR,
  GET_DEPTADMINS_REQUEST,
  GET_DEPTADMINS_SUCCESS,
  GET_DEPTADMINS_ERROR,
  GET_DEPTADMIN_REQUEST,
  GET_DEPTADMIN_SUCCESS,
  GET_DEPTADMIN_ERROR,
  REMOVE_DEPTADMIN_REQUEST,
  REMOVE_DEPTADMIN_SUCCESS,
  REMOVE_DEPTADMIN_ERROR
} from "../actions/departmentAdmin.action";

const initialState = {
  deptAdmins: [],
  saveState: null,
  error: null,
  isLoading: false,
  searchResults: [],
  addResult: null,
  getDeptAdminsState: null,
  deptAdmin: null,
  getDeptAdminState: null,
  removeDeptAdminState: null
};

const deptAdminReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case DEPTADMIN_SEARCH_REQUEST: {
      newState = { ...state, isLoading: true, error: null };
      break;
    }
    case DEPTADMIN_SEARCH_ERROR: {
      newState = { ...state, isLoading: false, error: action.message };
      break;
    }
    case DEPTADMIN_SEARCH_SUCCESS: {
      newState = {
        ...state,
        isLoading: false,
        searchResults: action.payload,
        error: null
      };
      break;
    }
    case DEPTADMIN_ADD_REQUEST: {
      newState = { ...state, saveState: DEPTADMIN_ADD_REQUEST, error: null };
      break;
    }
    case DEPTADMIN_ADD_ERROR: {
      newState = {
        ...state,
        saveState: DEPTADMIN_ADD_ERROR,
        error: action.message
      };
      break;
    }
    case DEPTADMIN_ADD_SUCCESS: {
      newState = {
        ...state,
        saveState: DEPTADMIN_ADD_SUCCESS,
        addResult: action.payload,
        error: null
      };
      break;
    }
    case GET_DEPTADMINS_REQUEST: {
      newState = {
        ...state,
        getDeptAdminsState: GET_DEPTADMINS_REQUEST,
        error: null
      };
      break;
    }
    case GET_DEPTADMINS_ERROR: {
      newState = {
        ...state,
        getDeptAdminsState: GET_DEPTADMINS_ERROR,
        error: action.message
      };
      break;
    }
    case GET_DEPTADMINS_SUCCESS: {
      newState = {
        ...state,
        getDeptAdminsState: GET_DEPTADMINS_SUCCESS,
        deptAdmins: action.payload,
        error: null
      };
      break;
    }
    case GET_DEPTADMIN_REQUEST: {
      newState = {
        ...state,
        getDeptAdminState: GET_DEPTADMIN_REQUEST,
        error: null
      };
      break;
    }
    case GET_DEPTADMIN_ERROR: {
      newState = {
        ...state,
        getDeptAdminState: GET_DEPTADMIN_ERROR,
        error: action.message
      };
      break;
    }
    case GET_DEPTADMIN_SUCCESS: {
      newState = {
        ...state,
        getDeptAdminState: GET_DEPTADMIN_SUCCESS,
        deptAdmin: action.payload,
        error: null
      };
      break;
    }
    case REMOVE_DEPTADMIN_REQUEST: {
      const adminIdToDelete = action.data;
      let administrators = Object.assign([], state.deptAdmins);
      const indexOfMatchingAdmin = administrators.findIndex(eachAdmin => {
        return eachAdmin.providerId === adminIdToDelete;
      });

      if (indexOfMatchingAdmin !== -1) {
        administrators.splice(indexOfMatchingAdmin, 1);
      }
      newState = {
        ...state,
        removeDeptAdminState: REMOVE_DEPTADMIN_REQUEST,
        deptAdmins: administrators,
        error: null
      };
      break;
    }
    case REMOVE_DEPTADMIN_SUCCESS: {
      newState = {
        ...state,
        removeDeptAdminState: REMOVE_DEPTADMIN_SUCCESS,
        deptAdmin: action.payload,
        error: null
      };
      break;
    }
    case REMOVE_DEPTADMIN_ERROR: {
      newState = {
        ...state,
        removeDeptAdminState: REMOVE_DEPTADMIN_ERROR,
        error: action.message
      };
      break;
    }
    default: {
      newState = { ...state };
      break;
    }
  }

  return newState;
};

export default deptAdminReducer;
