import healthswim_home from "../img/heathswim_home (3).svg";
import AppleAppStoreButton from "../img/AppleAppStoreButton.png";
import GooglePlayStoreButton from "../img/GooglePlayStoreButton.png";
import Hs_logo from "../img/HS_logo.png";
import PlaceHolder from "../img/AdobeStock.jpeg";

function Healthswim_home_page() {
  return (
    <>
      <div
        style={{
          display: "block",
          position: "sticky",
          background: "white",
          width: "100%",
          zIndex: "1000",
          top: "0",
          marginTop: "-124px",
        }}
      >
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            position: "fixed",
            background: "white",
            padding: "10px 20px",
          }}
        >
          <div style={{ maxWidth: "1200px" }}>
            <img
              src={Hs_logo}
              alt=""
              style={{
                display: "block",
                width: "auto",
                maxWidth: " 100%",
                maxHeight: "95px",
              }}
            />
            <div
              style={{
                position: "absolute",

                textAlign: "center",
                // marginTop: "3rem",
                marginBottom: "4rem",
                color: "#00CCFF",
                display: "flex",
                justifyContent: "flex-end",
                bottom: "0px",
                width: "473px",
              }}
            >
              <p
                style={{
                  fontSize: "11px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  position: "absolute",
                  bottom: "-37px",
                  marginBottom: "0px",
                  color: "#00CCFF",
                }}
              >
                Customized medical information, vetted by health care providers
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ position: "relative", textAlign: "center" }}>
          <img
            style={{ height: "auto", paddingTop: "30px", width: "100%" }}
            src={healthswim_home}
            alt=""
          />
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
          >
            {/* <p
              style={{
                fontSize: "1.4rem",
                textAlign: "center",
                // marginTop: "3rem",
                marginBottom: "4rem",
                fontWeight: "500",
                color: "#00CCFF",
              }}
            >
              Customized medical information, vetted by health care providers.
            </p> */}
            <h2
              // className="centered"
              style={{
                // fontSize: "3.25rem",
                width: "347px",
                fontWeight: "500",
                fontFamily: "Poppins",
                fontStyle: "normal",
                textTransform: "none",
                letterSpacing: "0rem",
                // position: "absolute",
                // top: "47%",
                // left: "50%",

                width: "388px",
                fontSize: "3rem",
                marginTop: "219px",
              }}
            >
              Trust a Provider Not the Internet
            </h2>
            <button
              // onClick={handOpenModal}
              style={{
                backgroundColor: "#00CCFF",
                border: "none",
                color: "#fff",
                width: "147px",
                height: "48px",
                fontSize: "medium",
                // position: "absolute",
                // top: "56%",
                // left: "45%",
                fontFamily: "Poppins",
                marginTop: "8px",
              }}
            >
              Login
            </button>
            {/* <LoginPage /> */}
            <div
              style={{
                textAlign: "center",
                marginTop: "-1px",
                fontWeight: "600",
                fontSize: "small",
                textTransform: "capitalize",
                marginBottom: "139px",
              }}
            >
              <a
                href=""
                style={{
                  fontWeight: "400",
                  fontSize: "small",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                }}
              >
                Create new account
              </a>
            </div>
            {/* <div
              style={{
                width: "678px",
                fontFamily: "Poppins",
                marginTop: "64px",
              }}
            >
              <p
                style={{
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Healthswim is your trusted partner in searching the internet for
                credible medical information. Healthswim brings together
                information seekers, like yourself, and licensed healthcare
                providers. When you search for a medical condition, every piece
                of information has been vetted by a provider, preferably your
                own.
                Customized medical information, vetted by health care providers.
              </p>
            </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-118px",
            position: "relative",
            zIndex: "999",
          }}
        >
          <a
            href="https://apps.apple.com/us/app/healthswim/id1529080934"
            style={{ padding: "5px" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppleAppStoreButton} alt="" height={"41px"} />
          </a>
          <a
            href="https://apps.apple.com/us/app/healthswim/id1529080934"
            style={{ padding: "5px" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={GooglePlayStoreButton} alt="" height={"41px"} />
          </a>
        </div>
        {/* <div style={{ display: "flex" }}>
          <img src={PlaceHolder} height="50%" width={"50%"} alt="" />
          <div style={{ width: "40%", height: "100%" }}>
            <h2
              style={{
                fontFamily: "Poppins",
                paddingLeft: "35px",
                fontSize: "2.2rem",
              }}
            >
              What is Healthswim?
            </h2>
            <p
              style={{
                fontSize: "1.3rem",
                // textAlign: "center",
                fontFamily: "Poppins",
                letterSpacing: "0.3px",
                paddingLeft: "35px",
                paddingTop: "70px",
                color: "#656262",
                lineHeight: "40px",
              }}
            >
              Healthswim is your trusted partner in searching the internet for
              credible medical information. Healthswim brings together
              information seekers, like yourself, and licensed healthcare
              providers. When you search for a medical condition on the mobile
              app, every piece of information has been picked by a healthcare
              provider — preferably your own.
            </p>
            <div
              style={{
                display: "flex",
                paddingLeft: "35px",
                paddingTop: "50px",
              }}
            >
              <a
                href="https://apps.apple.com/us/app/healthswim/id1529080934"
                style={{ padding: "5px" }}
                target="_blank"
                rel="noreferrer"
              >
                <img src={AppleAppStoreButton} alt="" height={"41px"} />
              </a>
              <a
                // href="https://apps.apple.com/us/app/healthswim/id1529080934"
                style={{ padding: "5px" }}
                target="_blank"
                rel="noreferrer"
              >
                <img src={GooglePlayStoreButton} alt="" height={"41px"} />
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <div style={{ marginTop: "69px" }}></div>
      <div className="footer-element">
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          // onClick={registerpage}
        >
          Sign Up
        </button>{" "}
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          // onClick={loginPage}
        >
          Login
        </button>{" "}
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          // onClick={privacyPolicyPage}
        >
          Privacy Policy
        </button>
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          // onClick={termsPage}
        >
          Terms
        </button>{" "}
      </div>
    </>
  );
}

export default Healthswim_home_page;
