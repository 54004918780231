import {
  SUBSCRIBE_CHANNELS_REQUEST,
  SUBSCRIBE_CHANNELS_SUCCESS,
  SUBSCRIBE_CHANNELS_ERROR,
  GET_CHANNELS_SUBSCRIBED_REQUEST,
  GET_CHANNELS_SUBSCRIBED_SUCCESS,
  GET_CHANNELS_SUBSCRIBED_ERROR,
  UPDATE_SUBSCRIBE_STATE_TO_NULL,
} from "../actions/subscribeChannel.action";

const initialState = {
  error: null,
  state: null,
  subscribedChannels: [],
};

const subscribedChannelsReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }
    case SUBSCRIBE_CHANNELS_REQUEST: {
      newState = { ...state, state: SUBSCRIBE_CHANNELS_REQUEST, error: null };
      break;
    }
    case SUBSCRIBE_CHANNELS_SUCCESS: {
      newState = { ...state, state: SUBSCRIBE_CHANNELS_SUCCESS, error: null };
      break;
    }
    case SUBSCRIBE_CHANNELS_ERROR: {
      newState = {
        ...state,
        state: SUBSCRIBE_CHANNELS_ERROR,
        error: action.payload.error,
      };
      break;
    }
    case GET_CHANNELS_SUBSCRIBED_REQUEST: {
      newState = {
        ...state,
        state: GET_CHANNELS_SUBSCRIBED_REQUEST,
        error: null,
        subscribedChannels: [],
      };
      break;
    }
    case GET_CHANNELS_SUBSCRIBED_SUCCESS: {
      newState = {
        ...state,
        state: GET_CHANNELS_SUBSCRIBED_SUCCESS,
        subscribedChannels: action.payload,
        error: null,
      };
      break;
    }
    case GET_CHANNELS_SUBSCRIBED_ERROR: {
      newState = {
        ...state,
        state: GET_CHANNELS_SUBSCRIBED_ERROR,
        subscribedChannels: [],
        error: action.payload,
      };
      break;
    }
    case UPDATE_SUBSCRIBE_STATE_TO_NULL: {
      newState = { ...state, state: null };
    }
  }

  return newState;
};

export default subscribedChannelsReducer;
