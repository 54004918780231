import { GET_NEW_CHANNEL_DATA } from "../actions/channel.action";

const initialState = {
  newChannelId: [],
};

const channelData = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    default: {
      newState = { ...state };
      break;
    }

    case GET_NEW_CHANNEL_DATA: {
      console.log("action.payload.id", action.payload.id);
      newState = {
        ...state,
        newChannelId: action.payload.id,
      };
      break;
    }
  }

  return newState;
};

export default channelData;
