import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { withRouter, Link } from "react-router-dom";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import healthswim_home from "../img/heathswim_home.png";
import AppleAppStoreButton from "../img/AppleAppStoreButton.png";
import GooglePlayStoreButton from "../img/GooglePlayStoreButton.png";
import Hs_logo from "../img/HS_logo.png";
import Healthswim_home_page from "../components/Healthswim_home_page";
import {
  REGISTER_SUCCESS,
  requestRegisterSuccess,
  REGISTER_REQUEST,
  requestResendOtpSuccess,
  LOGIN_EMAIL_ID,
} from "../actions/register.actions";

class RegistrationSuccess extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidUpdate() {
    if (this.props.state === REGISTER_SUCCESS) {
      // this.props.history.push("/login");
      this.props.history.push("/loginPage");
    }
  }
  handleClick = () => {
    console.log("this.props.email", this.props.email);
    this.props.requestResendOtpSuccess({ email: this.props.email });
  };
  onSubmit = (values) => {
    this.props.requestRegisterSuccess({
      otp: values.otp,
      email: this.props.email,
    });
  };
  validationSchema = Yup.object().shape({
    // username: Yup.string()
    //   .required("Email can't be blank")
    //   .email("Username should be email."),
    otp: Yup.string().required("OTP can't be blank"),
  });

  renderForm = (props) => {
    const { handleChange, handleSubmit, values } = props;
    const isRegistrationInProgress = this.props.state === REGISTER_REQUEST;

    return (
      <form onSubmit={handleSubmit}>
        {/* <div className="form-group form-lable">
          <label className="form-lable">Email</label>
          <Field
            type="text"
            value={values.username}
            name="username"
            className="form-control form-input"
          />
          <span className="error_text">
            <ErrorMessage name="username" />
          </span>
        </div> */}

        <div className="form-group form-lable ">
          <label className="form-lable">OTP</label>

          <div className="input-group ">
            <Field
              type="text"
              value={values.otp}
              name="otp"
              className="form-control form-input"
            />
          </div>
          <span className="error_text">
            <ErrorMessage name="otp" />
          </span>
        </div>

        <button
          className="set-button"
          type="submit"
          disabled={isRegistrationInProgress}
          style={{
            backgroundColor: "#00d3ff",
            display: "block",
            width: "30%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {" "}
          Verify{" "}
          {isRegistrationInProgress === true ? (
            <span className="text-right pl-2">
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: "#fff" }}
              />
            </span>
          ) : (
            ""
          )}
        </button>
      </form>
    );
  };

  render() {
    return (
      <>
        <Modal
          style={{ width: "100%" }}
          show={true}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="modal-26w"
          // onHide={this.handleClose}
        >
          <div
          // className="container-bg"
          // style={{ position: "fixed", top: "29%", left: "0%", width: "100%" }}
          >
            <div>
              <div>
                <div>
                  <div className="card">
                    <div className="manage-form-header">
                      <div className="manage-form-title">
                        Verify Your Identity
                      </div>
                    </div>

                    <div className="form-container h-100">
                      <div>
                        <Formik
                          initialValues={{
                            otp: "",
                          }}
                          onSubmit={this.onSubmit}
                          render={this.renderForm}
                          validationSchema={this.validationSchema}
                        />
                      </div>
                    </div>
                    <div className="card-body" style={{ display: "flex" }}>
                      <p
                        className="form-l"
                        style={{ margin: "auto", padding: "0px 0px 30px 0px" }}
                      >
                        {/* You will receive an email for OTP. */}
                        Please check your text messages or email for the OTP
                      </p>
                    </div>
                    {console.log("this.props.email", this.props.email)}
                    <span className="send-otp">
                      Didn't receive OTP?{" "}
                      <span className="resend_otp" onClick={this.handleClick}>
                        Resend OTP
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Healthswim_home_page />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.register.state,
  erorr: state.register.error,
  email: state.register.email,
});
const mapDispatchToProps = {
  requestRegisterSuccess,
  requestResendOtpSuccess,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegistrationSuccess)
);
