import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FindContent from "../components/findContent/findContent";
import { loadSearchResults } from "../actions/search.action";
import {
  subscribeChannels,
  SUBSCRIBE_CHANNELS_SUCCESS,
  getSubscribedChannels,
  SUBSCRIBE_CHANNELS_REQUEST,
} from "../actions/subscribeChannel.action";
import { Redirect } from "react-router-dom";
import { getPrevStep } from "../actions/onboardingStatus.actions";
import {
  ROUTE_FIND_CONTENT,
  ROUTE_ORG_INVITE_PROVIDER,
} from "./RouteConstants";
import { unsubscribeChannel } from "../actions/channel.action";

class FindContentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savingSubscribedChannels: false,
    };

    this.loadSearchResults = this.loadSearchResults.bind(this);
    this.subscribeToSelectedChannels =
      this.subscribeToSelectedChannels.bind(this);
  }

  componentDidMount() {
    this.props.getSubscribedChannels();
  }

  loadSearchResults(searchText) {
    this.props.loadSearchResults(searchText);
  }

  subscribeToSelectedChannels = function (selectedChannelIds) {
    if (selectedChannelIds != null && selectedChannelIds.length > 0) {
      this.props.subscribeChannels(selectedChannelIds);
    }
  };

  getPrevStep = () => {
    this.props.getPrevStep("?currentStep=FIND-CONTENT");
  };
  unsubscribeChannel = (data) => {
    this.props.unsubscribeChannel(data);
  };
  handleSkip = () => this.props.history.push("/next-step" + ROUTE_FIND_CONTENT);

  render() {
    if (
      this.props.prevStep !== null &&
      this.props.prevStep.name === "ORGANIZATION-INVITE-PROVIDER"
    ) {
      return <Redirect to={ROUTE_ORG_INVITE_PROVIDER} />;
    }
    const saveInProgress = this.props.state === SUBSCRIBE_CHANNELS_REQUEST;

    if (this.props.state === SUBSCRIBE_CHANNELS_SUCCESS) {
      return <Redirect to={"/next-step" + ROUTE_FIND_CONTENT} />;
    }

    return (
      <div className="container-bg">
        <FindContent
          subscribedChannels={this.props.subscribedChannels}
          searchResults={this.props.searchResults}
          isLoading={this.props.isLoading}
          loadSearchResults={this.loadSearchResults}
          subscribeToSelectedChannels={this.subscribeToSelectedChannels}
          handleSkip={this.handleSkip}
          canSkip={this.props.canSkip}
          saveInProgress={saveInProgress}
          onBoarding={true}
          getPrevStep={this.getPrevStep}
          unsubscribeChannel={this.unsubscribeChannel}
        />
      </div>
    );
  }
}

const mapPropsToState = (state, dispatch) => ({
  isLoading: state.searchResults.isLoading,
  searchResults: state.searchResults.results,
  state: state.subscribedChannels.state,
  subscribedChannels: state.subscribedChannels.subscribedChannels,
  canSkip: state.onboardingStatus.canSkip,
  prevStep: state.onboardingStatus.prevStep,
});

const mapDispatchToState = {
  loadSearchResults,
  subscribeChannels,
  getSubscribedChannels,
  getPrevStep,
  unsubscribeChannel,
};
// const mapDispatchToProps = (dispatch) => ({
//   loadSearchResults: () => dispatch(loadSearchResults()),
//   subscribeChannels: () => dispatch(subscribeChannels()),
//   getSubscribedChannels: () => dispatch(getSubscribedChannels()),
//   getPrevStep: () => dispatch(getPrevStep()),
//   unsubscribeChannel: (data) => dispatch(unsubscribeChannel(data)),
// });
export default withRouter(
  connect(
    mapPropsToState,

    mapDispatchToState
  )(FindContentPage)
);
