import {
  SET_CLIENT_PROFILE_IN_PROGRESS,
  SET_CLIENT_PROFILE_SUCCESS,
  SET_CLIENT_PROFILE_ERROR
} from "../actions/clientProfile.action";

const intialState = {
  setProfileState: null
};

const ClientProfile = (state = intialState, action) => {
  switch (action.type) {
    case SET_CLIENT_PROFILE_IN_PROGRESS:
      return { ...state, setProfileState: SET_CLIENT_PROFILE_IN_PROGRESS };
    case SET_CLIENT_PROFILE_SUCCESS:
      return { ...state, setProfileState: SET_CLIENT_PROFILE_SUCCESS };
    case SET_CLIENT_PROFILE_ERROR:
      return { ...state, setProfileState: SET_CLIENT_PROFILE_ERROR };
    default:
      return state;
  }
};

export default ClientProfile;
