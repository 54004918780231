import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ManageAccounts from "../components/orgManageAccounts/orgManageAccounts";
import {
  getList,
  getOrganisationList,
  getSubscriptionDetails,
  resetState,
  activateDeactivateAccount,
  deleteAccount,
  uploadSpreadsheet,
  changePassword,
  submitUserInformation,
  submitProviderInformation,
  submitOrgInformation,
  editOrglogo,
  submitSuperAdminInformation,
  getProviderInfo,
  getDepAdminInfo,
} from "../actions/orgManageAccounts.action";
import {
  getDegrees,
  getSpecialities,
} from "../actions/personalProfile.actions";
import {
  getOrganizationType,
  setOrganizationLogo,
} from "../actions/organizationProfile.actions";
import ManageAccountPopUp from "../components/orgManageAccounts/ManageAccountPopUp";
import { getAffiliations } from "../actions/affiliations.action";
import { getCertifications } from "../actions/certifications.action";
import { getConditionsTreated } from "../actions/conditionsTreated.action";
import { getMyDepartments } from "../actions/department.action";
import { getorganizationLocations } from "../actions/organizationLocation.action";

class ManageAccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showProviderModal: false,
      searchText: "",
      orgId: "",
      role: "",
      isOpen: false,
      showDepartment: false,
      showProvider: false,
      showAdmin: false,
      isShowList: false,
      id: 1,
    };
  }
  componentWillMount() {
    this.props.getSpecialities();
    this.props.getDegrees();
    this.props.getAffiliations();
    this.props.getCertifications();
    this.props.getConditionsTreated();
    this.props.getMyDepartments();
    this.props.getOrganizationType();
  }
  onFileUpload = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0], event.target.files[0].name);
    this.props.uploadSpreadsheet(formData);
  };

  showDetails = (type, id) => {
    this.props.getorganizationLocations("&org_id=" + id);
    if (type === "Organisation") {
      this.setState({ show: true, isShowList: true });
    } else this.setState({ showProviderModal: true });

    this.props.getSubscriptionDetails(type, id);
  };
  isBack = () => {
    this.setState({ isShowList: false });
  };
  handleClose = () => {
    this.setState({ show: false, showProviderModal: false });
    this.props.resetState();
  };

  componentDidMount = () => {
    this.props.getList(1, this.state.searchText);
    this.props.getOrganisationList();
  };

  loadFunc = () => {
    if (this.props.pageNumber + 1 <= this.props.totalPages)
      this.props.getList(this.props.pageNumber + 1, this.state.searchText);
  };

  updateSearchtext = (event) => {
    this.setState({ searchText: event.target.value });
  };

  setOrgId = (org) => {
    if (org.length === 1) this.setState({ orgId: org[0].id });
    else this.setState({ orgId: "" });
  };

  setRole = (role) => {
    if (role.length === 1) this.setState({ role: role[0].id });
    else this.setState({ role: "" });
  };
  handleOnClick = () => {
    this.setState({ isOpen: true });
  };
  onClose = () => {
    this.setState({ isOpen: false });
    this.setState({ showAdmin: false });
    this.setState({ showDepartment: false });
    this.setState({ showProvider: false });
  };
  handleAddClick = () => {
    if (this.state.orgId === "" || this.state.role === "")
      alert("Please select Organisation and Role.");
    else {
      switch (this.state.role) {
        case "ROLE_ORGANIZATION_ADMIN":
          // this.props.history.push(
          //   ROUTE_ORG_CREATE_ADMIN + "?" + this.state.orgId
          // );
          this.setState({ showAdmin: true });
          this.setState({ showDepartment: false });
          this.setState({ showProvider: false });
          break;
        case "ROLE_ORGANIZATION_SUBADMIN":
          // this.props.history.push(
          //   ROUTE_ORG_CREATE_DEPTADMIN + "?" + this.state.orgId
          // );
          this.setState({ showDepartment: true });
          this.setState({ showAdmin: false });
          this.setState({ showProvider: false });
          break;
        case "ROLE_ORGANIZATION_PHYSICIAN":
          // this.props.history.push(
          //   ROUTE_ORG_INVITE_PROVIDER_FROM_MANAGE + "?" + this.state.orgId
          // );
          this.setState({ showProvider: true });
          this.setState({ showAdmin: false });
          this.setState({ showDepartment: false });
          break;
        default:
      }
    }
  };

  getListDetails = () => {
    this.props.getList(1, this.state.searchText);
    document.getElementById("MangeAccounts_div").scrollTop = 0;
  };

  keyPress = (event) => {
    if (event.key === "Enter") this.getListDetails();
  };

  activateDeactivateAccount = (qString) => {
    this.props.activateDeactivateAccount(qString);
    this.setState({ show: false, showProviderModal: false, isShowList: false });
    this.props.resetState();
  };
  changePassword = (value, id) => {
    this.props.changePassword(value, id);
  };
  deleteAccount = (qString) => {
    this.props.deleteAccount(qString);
    this.setState({ show: false, showProviderModal: false, isShowList: false });
    this.props.resetState();
  };
  submitUserInformation = (info) => {
    this.props.submitUserInformation(info);
  };
  submitProviderInformation = (info) => {
    this.props.submitProviderInformation(info);
  };
  submitOrgInformation = (info) => {
    this.props.submitOrgInformation(info);
    console.log("getinfo", info);
    this.props.editOrglogo(info);
  };
  submitSuperAdminInformation = (info) => {
    this.props.submitSuperAdminInformation(info);
  };
  getProviderInfo = (id) => {
    this.props.getProviderInfo(id);
  };
  getDepAdminInfo = (id) => {
    this.props.getDepAdminInfo(id);
  };
  setOrganizationLogo = (file, formData) => {
    console.log("formData", file, formData);
    this.props.setOrganizationLogo(file, formData);
  };
  render() {
    return (
      <div>
        <ManageAccounts
          subscriptionList={this.props.subscriptionList}
          submitUserInformation={this.submitUserInformation}
          submitProviderInformation={this.submitProviderInformation}
          submitOrgInformation={this.submitOrgInformation}
          submitSuperAdminInformation={this.submitSuperAdminInformation}
          orgList={this.props.orgList}
          providerList={this.props.providerList}
          depList={this.props.depList}
          roleList={this.props.roleList}
          hasMore={this.props.hasMore}
          loadFunc={this.loadFunc}
          handleClose={this.handleClose}
          showDetails={this.showDetails}
          isShowList={this.state.isShowList}
          subscriptionDetailsOrg={this.props.subscriptionDetailsOrg}
          usersName={this.props.subscriptionDetailsOrg.members}
          subscriptionDetailsProvider={this.props.subscriptionDetailsProvider}
          showProviderModal={this.state.showProviderModal}
          updateSearchtext={this.updateSearchtext}
          getListDetails={this.getListDetails}
          keyPress={this.keyPress}
          setOrgId={this.setOrgId}
          setRole={this.setRole}
          handleAddClick={this.handleAddClick}
          handleOnClick={this.handleOnClick}
          onClose={this.onClsoe}
          activateDeactivateAccount={this.activateDeactivateAccount}
          deleteAccount={this.deleteAccount}
          onFileChange={this.onFileChange}
          onFileUpload={this.onFileUpload}
          changePassword={this.changePassword}
          isBack={this.isBack}
          allDegrees={this.props.allDegrees}
          allSpecialities={this.props.allSpecialities}
          allAffiliations={this.props.allAffiliations}
          allCertifications={this.props.allCertifications}
          allConditionsTreated={this.props.allConditionsTreated}
          departments={this.props.departments}
          locations={this.props.locations}
          getProviderInfo={this.getProviderInfo}
          getDepAdminInfo={this.getDepAdminInfo}
          specalities={this.props.specalities}
          orgTypes={this.props.orgTypes}
          setOrganizationLogo={this.props.setOrganizationLogo}
        />
        {this.state.isOpen && (
          <ManageAccountPopUp
            subscriptionList={this.props.subscriptionList}
            orgList={this.props.orgList}
            roleList={this.props.roleList}
            hasMore={this.props.hasMore}
            loadFunc={this.loadFunc}
            handleClose={this.handleClose}
            showDetails={this.showDetails}
            show={this.state.show}
            subscriptionDetailsOrg={this.props.subscriptionDetailsOrg}
            subscriptionDetailsProvider={this.props.subscriptionDetailsProvider}
            showProviderModal={this.state.showProviderModal}
            updateSearchtext={this.updateSearchtext}
            getListDetails={this.getListDetails}
            keyPress={this.keyPress}
            setOrgId={this.setOrgId}
            setRole={this.setRole}
            showDepartment={this.state.showDepartment}
            showProvider={this.state.showProvider}
            showAdmin={this.state.showAdmin}
            handleAddClick={this.handleAddClick}
            onClose={this.onClose}
            handleOnClick={this.handleOnClick}
            activateDeactivateAccount={this.activateDeactivateAccount}
            deleteAccount={this.deleteAccount}
            onFileChange={this.onFileChange}
            onFileUpload={this.onFileUpload}
            role={this.state.role}
            orgId={this.state.orgId}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageNumber: state.orgManageAccounts.pageNumber,
    subscriptionList: state.orgManageAccounts.subscriptionList,
    orgList: state.orgManageAccounts.orgList,
    providerList: state.orgManageAccounts.providerList,
    depList: state.orgManageAccounts.depList,
    roleList: state.orgManageAccounts.roleList,
    hasMore: state.orgManageAccounts.hasMore,
    subscriptionDetailsProvider:
      state.orgManageAccounts.subscriptionDetailsProvider,
    subscriptionDetailsOrg: state.orgManageAccounts.subscriptionDetailsOrg,
    totalPages: state.orgManageAccounts.totalPages,
    allDegrees: state.personalProfile.degrees,
    allSpecialities: state.personalProfile.specialities,
    allAffiliations: state.affiliations.affiliations,
    allCertifications: state.certifications.certificates,
    allConditionsTreated: state.conditionsTreated.conditionsTreated,
    departments: state.departmentReducer.myDepartments,

    locations: state.organizationLocation.locations,
    specalities: state.personalProfile.specialities,
    orgTypes: state.organizationProfile.orgTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (pageNo, searchText) => dispatch(getList(pageNo, searchText)),
    getOrganisationList: () => dispatch(getOrganisationList()),
    getSubscriptionDetails: (type, id) =>
      dispatch(getSubscriptionDetails(type, id)),
    activateDeactivateAccount: (qString) =>
      dispatch(activateDeactivateAccount(qString)),
    deleteAccount: (qString) => dispatch(deleteAccount(qString)),
    changePassword: (value, id) => dispatch(changePassword(value, id)),
    submitUserInformation: (info) => dispatch(submitUserInformation(info)),
    submitProviderInformation: (info) =>
      dispatch(submitProviderInformation(info)),
    submitOrgInformation: (info) => dispatch(submitOrgInformation(info)),
    editOrglogo: (info) => dispatch(editOrglogo(info)),
    submitSuperAdminInformation: (info) =>
      dispatch(submitSuperAdminInformation(info)),
    resetState: () => dispatch(resetState()),
    uploadSpreadsheet: (data) => dispatch(uploadSpreadsheet(data)),
    getDegrees: () => dispatch(getDegrees()),
    getSpecialities: () => dispatch(getSpecialities()),
    getAffiliations: () => dispatch(getAffiliations()),
    getCertifications: () => dispatch(getCertifications()),
    getConditionsTreated: () => dispatch(getConditionsTreated()),
    getMyDepartments: () => dispatch(getMyDepartments()),
    getorganizationLocations: (data) =>
      dispatch(getorganizationLocations(data)),
    getProviderInfo: (id) => dispatch(getProviderInfo(id)),
    getDepAdminInfo: (id) => dispatch(getDepAdminInfo(id)),
    getOrganizationType: () => dispatch(getOrganizationType()),
    setOrganizationLogo: (file, data) =>
      dispatch(setOrganizationLogo(file, data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageAccountPage)
);
